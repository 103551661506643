import React, { Component, useEffect } from 'react';
import { MainRouteChild } from './main-page';

interface Props extends MainRouteChild {

}

function Analytics ({
  setCurrentPage
}: Props) {

  useEffect(() => {
    setCurrentPage('analytics');
  }, [])

  return (
    <div>
      gsghsghhfhdfsh
    </div>
  );
}

export default Analytics;