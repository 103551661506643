import React, { useState } from 'react';
import MyInput, {MyInputProps} from './input';

function PasswordInput({
  ...inputProps
}: MyInputProps) {
  const [type, setType] = useState('password'); // password | text
  const blokedEyeIcon = type !== 'password'

  return (
    <MyInput
      {...inputProps}
      type={type}
    >
      <div 
        className="password-visibility-btn" 
        onClick={() => setType(type === 'password' ? 'text' : 'password')}>
          <i className={`${blokedEyeIcon ? 'icofont-eye-blocked' : 'icofont-eye'}`}/>
      </div> 
    </MyInput>
  );
}

export default PasswordInput;