import React from 'react';
import { Id } from 'merchery-lib';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import MyButton from '../../../../_utility-components/button/button';
import Popup from '../../../../_utility-components/popup';

interface Props {
  selectedClients: Id[]
  parentPopupClose: () => void,
  mainActionHandler: () => void
}

const ClientsDeletePopupRender = ({
  selectedClients,
  parentPopupClose, 
  mainActionHandler
}: Props) => {
  const clients = useAppSelector(state => state.clients)
  const clientsToDelete = clients ? clients.filter(c => selectedClients.some(selected => c.id === selected)) : []

  const headerText = `Удаление ${selectedClients.length === 1 ? 'клиента' : 'клиентов'}`

  return <Popup
    className={'confirm-window fixed-on-center clients-delete-popup'}
    popupClose={parentPopupClose}
    withBlackout
    withCloseBtn
    tabIndexDeep={2}
  >
    <h2>{headerText}</h2>
    <span>
      {
        selectedClients.length === 1 ? 
          <div className='clients-to-delete'>
            <h3 className='header-font-s'>{'Подтвердите удаление клиента: '}</h3>
            {clientsToDelete[0].name}
          </div>
        : 
          <div className='clients-to-delete'>
            <h3 className='header-font-s'>{'Подтвердите удаление клиентов: '}</h3>
            {clientsToDelete.map(c => <div key={c.id}>{c.name}</div>)}
          </div>
      }
    </span> 

    <div className="confirm-window-btn">
      <MyButton 
        className={'white-btn'} 
        onClick={parentPopupClose}
      >
        Отменить
      </MyButton>
      <MyButton 
        className="red-btn" 
        onClick={() => {
          mainActionHandler()
          parentPopupClose()
        }}
      >
        Удалить
      </MyButton>
    </div>
  </Popup>
}

export default ClientsDeletePopupRender