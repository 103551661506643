import React from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

const OrdersLoader = (props: IContentLoaderProps) => (
  <ContentLoader
    speed={2}
    width={1150}
    height={48}
    viewBox="0 0 1150 48"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    preserveAspectRatio="none"
    {...props}
  >
    <rect x="16" y="8" rx="0" ry="0" width="67" height="32" /> 
    <rect x="95" y="8" rx="0" ry="0" width="58" height="32" /> 
    <rect x="164" y="8" rx="0" ry="0" width="270" height="32" /> 
    <rect x="445" y="8" rx="0" ry="0" width="185" height="32" /> 
    <rect x="640" y="8" rx="0" ry="0" width="160" height="32" /> 
    <rect x="810" y="8" rx="0" ry="0" width="80" height="32" /> 
    <rect x="900" y="8" rx="0" ry="0" width="90" height="32" /> 
    <rect x="1040" y="12" rx="0" ry="0" width="25" height="24" />
  </ContentLoader>
)

export default OrdersLoader;