import React, {useContext} from 'react';
import MyInput from '../../../../../_utility-components/input/index';
import { CharsLabelDto, CharScopeDto, CharLabelValueWithId } from 'merchery-lib';
import CharLabelBodyEditing from './label-body-editing';
import CharLabelMultiSelect from './char-multi-select';
import { PossibleValuesPopup, usePossibleValues } from 'src/scripts/hooks/use-possible-values';
import { batch } from 'react-redux';
import { CharLabelIntermediateValue } from '../char-label';
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";

interface Props {
  label: CharsLabelDto,
  value: CharLabelIntermediateValue,
  currentShownScope: CharScopeDto | null,
  setValue: React.Dispatch<React.SetStateAction<CharLabelIntermediateValue>>,
  charEditing: boolean,
  labelChangeByField: (changes: Partial<CharsLabelDto>) => void,
  scopeCreate: (props: Partial<CharScopeDto>) => CharScopeDto | false,
}

const CharLabelBody = ({
  label,
  value,    
  currentShownScope,
  setValue,
  charEditing,
  labelChangeByField,
  scopeCreate
}: Props) => {
  const {
    multiSelects,
  } = useContext(CharacteristicsContext)
  const thisMultiSelect = multiSelects.find(ms => ms.label_id === label.id);
  const inputType = (() => {
    switch(label.type_id){
      case 4: return null;
      case 2: return 'text';
      case 3: return 'textarea';
      case 1: return 'number';
      default: return null;
    }
  })()

  const {
    setSearch, 
    search, 
    setShowData,
    dataLoading, 
    data, 
    showData
  } = usePossibleValues<CharLabelValueWithId, 'value'>({
    urlPath: 'characteristics/labels/possible-values',
    textField: 'value',
    params: {
      filters: {
        labelId: label.id
      }
    }
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    batch(() => {
      setSearch(e.target.value)
      setValue({
        id: null,
        value: e.target.value
      })
    })
  }

  const possibleValueHandler = (data: CharLabelValueWithId) => {
    batch(() => {
      setValue(data)
      setShowData(false)
    })
  }

  if(charEditing) {
    return (
      <CharLabelBodyEditing
        label={label}
        labelChangeByField={labelChangeByField}
      />
    )
  }

  if(label.type_id === 4 && thisMultiSelect) {
    return (
      <CharLabelMultiSelect
        currentShownScope={currentShownScope}
        scopeCreate={scopeCreate}
        thisMultiSelect={thisMultiSelect}
        myClassName={'char-label__body char-label__body_textarea'}
      />
    )
  }

  if(!inputType) {
    return null
  }

  return (
    <>
      <MyInput
        type={inputType}
        noPlaceholder
        placeholder={(inputType === 'number' && label.unit) || ''}
        value={value.value || ''}
        onChange={onChangeHandler}
        myClassName={'char-label__body placeholder--animation-off'}
        placeholderClass={`placeholder--pos-right-16`}
      />

      <PossibleValuesPopup
        clickHandler={possibleValueHandler} 
        dataLoading={dataLoading} 
        data={data}
        textField={'value'}
        search={search}
        showData={showData} 
        setShowData={setShowData}
      />
    </>
  )
}

export default CharLabelBody