import React from 'react';
import { TabIndex } from '../../../../dto/master-dto/tabindex.dto';
import { calcPrice, EntityWithPrice, num2str, numWithSep, ptsansCurrency } from '../../../../scripts/functions';
import MyButton from '../../../_utility-components/button/button';

export function AddItemsFooter<T>({
  canBeAddedSelected,
  confirmHandler,
  cancelHandler,
  tabIndex,
  withTotalSumm,
  selectedItemsTextOptions = ['товар', 'товара', 'товаров'],
  noSelectedText = 'Не выбрано ни одного товара',
}: {
  withTotalSumm?: true,
  tabIndex: TabIndex,
  canBeAddedSelected: T[],
  confirmHandler: () => void,
  cancelHandler: () => void,
  selectedItemsTextOptions?: [string, string, string]
  noSelectedText?: string
}) {
  const summ = withTotalSumm && canBeAddedSelected.every(isCalcPriceItem) ? ' на сумму ' + numWithSep(calcPrice(canBeAddedSelected)) : '';
  
  const selectedItemsText = 
    canBeAddedSelected.length ? 
      num2str(
        canBeAddedSelected.length, 
        selectedItemsTextOptions, 
        ['Выбран', 'Выбрано', 'Выбрано']
      ) + summ
    : noSelectedText;

  return <div className="items-in-order-footer">
    <div className="text-div">

      {selectedItemsText}
      &nbsp; 
      {summ && canBeAddedSelected.length ? ptsansCurrency('₽') : null}
    </div>
    
    <div className="items-in-order-btns">
      <MyButton 
        tabIndex={tabIndex}
        className="white-btn header-font-m" 
        onClick={cancelHandler}
      >
        Отменить
      </MyButton>
      
      <MyButton 
        tabIndex={tabIndex}
        className="blue-btn header-font-m" 
        onClick={confirmHandler}
        disabled={!canBeAddedSelected.length}
      >
        Добавить
      </MyButton>
    </div>
  </div>
}

function isCalcPriceItem<T>(item: T): item is T & EntityWithPrice {
  return typeof item === 'object' && item !== null && 'price' in item && 'count' in item;
}