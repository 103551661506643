import React from 'react';
import { ItemChanger } from 'src/scripts/with-row-components';
import { calcPrice, numWithSep, ptsansCurrency } from 'src/scripts/functions';
import MyInput from '../../../_utility-components/input/index';
import { MyInputProps } from '../../../_utility-components/input/input';
import { Price } from 'merchery-lib';

export interface ProductItemPriceRequirement {
  price: Price,
  priceField?: keyof ProductItemPriceRequirement;
}

export interface ProductItemPriceProps<T extends ProductItemPriceRequirement> {
  withInlineChange?: true
  changer?: ItemChanger<T>,
  item: T,
  priceField?: keyof T,
}

function ProductItemPrice <T extends {price: Price}>(props: ProductItemPriceProps<T>) {
  const { changer, withInlineChange, item, priceField = 'price' } = props;

  const price = item[priceField];

  if (typeof price !== 'number' && !Array.isArray(price)) {
    throw new Error('Invalid price field: ' + String(priceField))
  }

  if(withInlineChange !== undefined && changer !== undefined) {
    if(typeof price !== 'number') {
      return null
    }

    const inputProps: MyInputProps = {
      name: "price-inline-input",
      placeholder: "₽",
      myClassName: 'inline-input placeholder--animation-off',
      messagetext: '',
      withBlur: false,
      onChange: (event) => changer({
        ...item,
        price: +event.target.value
      }, 'change'),
      type: "number",
      value: price,
      min: 0,
    }

    return <div className='row-item__price'>
      <MyInput {...inputProps}/> 
    </div>
  }

  const priceWithFloat = calcPrice([{
    price: price,
    count: 1,
    cutDigits: 2
  }])
  const priceWithSeparator = numWithSep(priceWithFloat)

  return (
    <div className='row-item__price'>
      <div>{priceWithSeparator}</div>
      &nbsp;
      {ptsansCurrency('₽')}
    </div>
  );
}

export default ProductItemPrice;