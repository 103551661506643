import React, {useCallback, useContext, useMemo} from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyButton from "../../../../_utility-components/button/button";
import {objOfArraysHasProps} from "src/scripts/functions";
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";
import {batch} from "react-redux";

interface Props {
  disabled?: boolean
}

export default function CharsTop ({
  disabled
}: Props) {
  const topPanelHeight = useAppSelector(state => state.staticValues.top_panel_height)
  const pageTopPanelShown = useAppSelector(state => state.topPanelShown)

  const {
    charGroupsDiff,
    charsDiff,
    scopesDiff,
    multiSelectValuesDiff,
    saveHandler,
    cancelHandler,
  } = useContext(CharacteristicsContext)

  const charModuleDiff = [
    charsDiff,
    scopesDiff,
    multiSelectValuesDiff,
  ].map(Object.values).flat();

  const charModuleHaveDiff = useMemo(() =>
    charModuleDiff.some(a => a.length)
  , [charModuleDiff])

  const charsHaveChanges = useMemo(() =>
    charModuleHaveDiff && !objOfArraysHasProps(charGroupsDiff)
  , [charGroupsDiff, charModuleHaveDiff])

  const topStyle = useMemo(() => ({
    ...(pageTopPanelShown && {
      top: +topPanelHeight - 1 + 'px'
    })
  }), [pageTopPanelShown, topPanelHeight]);

  return (
    <div
      id='chars-top'
      className='characteristics-top side-padding-24'
      style={topStyle}
    >
      <h3 className="product-page-h3 section-title">Характеристики</h3>

      {charsHaveChanges ?
        <div className='characteristics-top__btn-wrapper'>
          <MyButton
            className={'white-btn btn_side-padding_36'}
            onClick={cancelHandler}
          >
            Отменить
          </MyButton>
          <MyButton
            {...(disabled !== undefined && {disabled})}
            className={'blue-btn btn_side-padding_36'}
            onClick={saveHandler}
          >
            Сохранить
          </MyButton>
        </div>
      : null}
    </div>
  )
}