import React, { useContext, useEffect, useMemo } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { batch, useDispatch } from 'react-redux';
import FallbackComponent from 'src/components/_utility-components/error-boundary';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id, ProductAttr, ProductAttrExtended, ProductsAttrValues, ProductVariantExtended } from 'merchery-lib';
import { uuidv4 } from '../../../../scripts/functions';
import MyButton from '../../../_utility-components/button/button';
import { ProductVariantFilter } from '../dto/variants.dto';
import { ProductPageContext } from '../product';
import ProductOneVariant from './variants-modules/one-variant';
import SelectedVariantsController from './variants-modules/selected-variants-controller';
import { ProductVariantFilterModule } from './variants-modules/variants-filter';
import { VariantsTableFilters } from './variants-modules/variants-table-filters';

function ProductVariants() {
  const topPanelOpened = useAppSelector(state => state.topPanelShown);
  const product = useAppSelector(state => state.product);
  const options = useAppSelector(state => state.productOptions);
  const variants = useAppSelector(state => state.productVariants);
  const initVariants = useAppSelector(state => state.initVariants);
  const selectedVariantsFilters = useAppSelector(state => state.selectedVariantsFilters);
  const selectedVariantsAttributes = useAppSelector(state=> state.selectedVariantsAttributes);
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false);

  const dispatch = useDispatch()
  const variantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_VARIANTS', payload: variants })
  // const initVariantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_INIT_VARIANTS', payload: variants })
  const selectedVariantAttributesDispatch = (attrs: ProductAttr[][]) => dispatch({ type: 'SELECTED_PRODUCTS_VARIANTS_ATTRIBUTES', payload: attrs })
  const selectedVariantFiltersDispatch = (filters: ProductVariantFilter[]) => dispatch({ type: 'SELECTED_PRODUCTS_VARIANTS_FILTERS', payload: filters })
  
  const { optionsLoaded: optionsLoaded } = useContext(ProductPageContext);

  const clearedOptions = useMemo(() => options.filter(o => !o.notSaved) , [options])
  const filteredVariants = useMemo(() => {
    const currentShonwVariantsAttributes = variants.filter((variant) => 
      selectedVariantsFilters.every((f) => 
        f.values.some(value => 
          variant.attributes.some(attr => 
            attr.value === value.value)
        )
      )
    )
    return currentShonwVariantsAttributes.length ? currentShonwVariantsAttributes : variants
  }, [selectedVariantsFilters, variants])

  useEffect(() => {
    if(!moySkladIntegrationOn && optionsLoaded) {
      const convertOptionIntoVariant = (): ProductVariantExtended[] | false => {
        if(!product) {
          return false
        }
    
        const contertedVariants: ProductVariantExtended[] = []

        if(!clearedOptions.length) {
          return contertedVariants
        }
    
        const {
          weight, id,
          price, external_id, barcode
        } = product;

        const numericPrice = 
          typeof price === 'number' 
            ? price 
            : Array.isArray(price) 
              ? price.find(msPrice => msPrice.currency.isoCode === 'RUB')?.value || 0
              : typeof price === 'string' 
                ? +price
                : 0;
        
        function generateCombinations(currIdx: number, currVariantAttr: ProductAttrExtended[]) {
          if (currIdx >= clearedOptions.length) {
            // Find if the generated variant already exists in old variants
            for (let variant of variants) {
              if (
                variant.attributes.length === currVariantAttr.length && 
                variant.attributes.every((attr, idx) => 
                  attr.attr === currVariantAttr[idx].attr
                )
              ) {
                break;
              }
            }
            // Add new variant only if it doesn't already exist
            const newVariant: ProductVariantExtended = {
              id: uuidv4(),
              order: contertedVariants.length + 1,
              product_id: id,
              external_id: external_id,
              price: numericPrice,
              remain: 0,
              weight: weight || 0,
              show_date: new Date().getTime(),
              barcode: barcode,
              src: [],
              attributes: currVariantAttr,
              newVariant: true,
              noRemain: true
            };
            contertedVariants.push(newVariant);
            return;
          }
    
          const option = clearedOptions[currIdx];
          for (let value of option.values) {
            if(option.name_id) {
              let newAttr: ProductAttrExtended = {
                attr: option.name_id,
                value_id: value.id,
                value: value.value,
                attr_name: '',
              }
              generateCombinations(currIdx + 1, [...currVariantAttr, newAttr]);
            }
          }
        }
    
        generateCombinations(0, []);
        return contertedVariants
      }
      let newVariants = convertOptionIntoVariant()

      function areAttributesEqual(attributes1: ProductAttr[], attributes2: ProductAttr[]) {
        if (attributes1.length !== attributes2.length) {
          return false;
        }
        for (let i = 0; i < attributes1.length; i++) {
          const attr1 = attributes1[i];
          const attr2 = attributes2.find(attr => 
            attr.attr === attr1.attr && 
            attr.value_id === attr1.value_id
          );
          if (!attr2) {
            return false;
          }
        }
        return true;
      }

      if(newVariants) {
        const changedVariants = newVariants.map(newVariant => {
          const existingVariant = initVariants.find(variant => {
            const isExist = areAttributesEqual(variant.attributes, newVariant.attributes)
            return isExist
          }
          );
          if(existingVariant && existingVariant.order !== newVariant.order) {
            return {
              ...existingVariant,
              attributes: newVariant.attributes,
              order: newVariant.order
            }
          }
          if (existingVariant) {
            const updatedAttributes = newVariant.attributes.map((nAttr) => {
              const sameAttribute = existingVariant.attributes.find(
                (attr) =>
                  nAttr.value_id === attr.value_id && nAttr.attr === attr.attr
              );
        
              // Retain all properties of same attribute but also include any additional properties from nAttr
              return { ...sameAttribute, ...nAttr };
            });
        
            return { ...existingVariant, attributes: updatedAttributes };
          }
          
          // If no existing variant is found, return the new variant as is
          return newVariant;
        });

        batch(() => {
          variantsDispatch(changedVariants)
          selectedVariantAttributesDispatch([])
          selectedVariantFiltersDispatch([])
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearedOptions])

  const calcCurrentFilters = (name_id: Id, value: ProductsAttrValues): ProductVariantFilter[] => {
    const filterIndex = selectedVariantsFilters.findIndex(f => f.name_id === name_id)
    if(filterIndex === -1) {
      return [...selectedVariantsFilters, {name_id, values: [value]}]
    } 

    const filterWithValues = selectedVariantsFilters[filterIndex].values.some(v => v.value === value.value)
    if(!filterWithValues) {
      return selectedVariantsFilters.map((svf, index) => index !== filterIndex ? svf : {
        ...svf, 
        values: [ ...svf.values, value ]
      })
    }

    const filterWithOneValue = selectedVariantsFilters[filterIndex].values.length === 1

    if(filterWithOneValue) {
      return selectedVariantsFilters.flatMap((svf, index) => index !== filterIndex ? svf : [])
    } else {
      return selectedVariantsFilters.map((svf, index) => 
        index !== filterIndex ? svf : {
          ...svf, 
          values: svf.values.flatMap(v => v !== value ? v : [])
        }
      )
    }
  }

  const variantsFilterHandler = (name_id?: Id, value?: ProductsAttrValues) => {
    if(!name_id || !value) {
      selectedVariantFiltersDispatch([])
      return false
    }

    const shallowSelectedVariantsFilters = calcCurrentFilters(name_id, value);

    const selectedAttributes = selectedVariantsAttributes
      .map((sva) => 
        shallowSelectedVariantsFilters.every(svf => 
          sva.some(attr => 
            svf.values.some(v => 
              v.value === attr.value)
        )) 
          ? sva 
          : null
      )
      .filter(a => a) as ProductAttr[][]

    batch(() => {
      selectedVariantFiltersDispatch(shallowSelectedVariantsFilters)
      selectedVariantAttributesDispatch(selectedAttributes)
    })
  }

  const allVariantsCheckboxHandler = () => {
    if(moySkladIntegrationOn) return false

    const reselectedAttrs = 
      filteredVariants.length !== selectedVariantsAttributes.length 
        ? filteredVariants.map((v) => v.attributes) 
        : []

    selectedVariantAttributesDispatch(reselectedAttrs)
  }

  if(!variants.length) {
    return null
  }

  return (
    <div id='variants' className="product-page-element product-page-variants">
      <div className='product-page-variants-header'>
        <div className="left">
          <h3 className="product-page-h3">Варианты товара</h3>

          {selectedVariantsFilters.length && (selectedVariantsFilters.length > 1 || selectedVariantsFilters[0].values.length > 1) ? (
            <MyButton
              className='clear-product-variants-filters'
              onClick={() => variantsFilterHandler()}
            >
              очистить фильтры
            </MyButton>
          ) : null}
        </div>
        <div className='product-page-variants-header-filters'>
          {clearedOptions.map((o) => (
            <ProductVariantFilterModule
              key={o.order}
              option={o}
              variantsFilterHandler={variantsFilterHandler}
            />
          ))}
        </div>
      </div>

      {selectedVariantsFilters.length ? (
        <VariantsTableFilters
          variantsFilterHandler={variantsFilterHandler}
        />
      ) : null}

      <div className='product-page-variants-table-items-wrapper'>
        <div id='table-items' className='table-items product-page-variants-table-items'>
          {selectedVariantsAttributes?.length ? (
            <SelectedVariantsController 
              filteredVariants={filteredVariants} 
              allVariantsCheckboxHandler={allVariantsCheckboxHandler}              
            />
          ) : (
            <div className={`table-item table-items-header ${topPanelOpened ? 'after-top-panel' : ''}`}>
              <div className="op-check hide-for-print">
                <MyButton
                  removeDefaultClass
                  className="checkbox"
                  onClick={allVariantsCheckboxHandler}
                >
                  <i className='icofont-check-alt'></i>
                </MyButton>
              </div>
              <div className="op-image"><div>Фoто</div></div>
              <div className="op-variant"><div>Вариант</div></div>
              <div className="op-variant-price"><div>Цена</div></div>
              <div className="op-quantity"><div>Остаток</div></div>
              <div className="op-weight"><div>ВЕС, ГР</div></div>
              <div className="op-vendor-code"><div>артикул</div></div>
              <div className="op-barcode"><div>штрихкод</div></div>
              <div className="op-config"><div></div></div>
            </div>
          )}

          {filteredVariants.map((variant, index) =>
            <ProductOneVariant 
              key={index}
              variant={variant}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withErrorBoundary(ProductVariants, { FallbackComponent: FallbackComponent });