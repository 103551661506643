import React from 'react';
import {MainRouteChild} from "src/components/main-pages/main-page";

interface Props extends MainRouteChild {
}

function StartPage (props: Props) {
	return (
		<div>
			<div className={'header-font-xl'}>
				Начальная страничка
			</div>

			<div className={'text-font-m'}>
				скоро здесь появится статистика
			</div>

		</div>
	);
}

export default StartPage;