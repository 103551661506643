import Popup, {PopupProps} from "../../../../../_utility-components/popup";
import MyButton from "../../../../../_utility-components/button/button";
import React from "react";

export function DeliveryNoteDeletePopup ({
	mainActionHandler,
	popupClose,
	...restProps
}: {
	mainActionHandler: () => unknown | Promise<unknown>,
	popupClose: PopupProps['popupClose']
} & Partial<PopupProps>) {

	return <Popup
		className={'confirm-window fixed-on-center delivery-delete-popup'}
		withBlackout
		withCloseBtn
		tabIndexDeep={3}
		popupClose={popupClose}
		{...restProps}
	>
		<h2>Удаление выбранной доставки</h2>

		<span>
			Подтвердите удаление накладной
    </span>

		<div className="confirm-window-btn">
			<MyButton
				className={'white-btn'}
				onClick={popupClose}
			>
				Отменить
			</MyButton>

			<MyButton
				className="red-btn"
				onClick={mainActionHandler}
			>
				Удалить
			</MyButton>
		</div>
	</Popup>
}