import React from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

const CommonTableLoader = (props: IContentLoaderProps) => {
  const viewBox = "0 0 1150 40";
  const speed = 2;
  const backgroundColor = 'rgba(0, 0, 0, 0.1)' // #f3f3f3
  const foregroundColor = '#ecebeb';

  return (
    <div className='common-table-loader'>
      
      <ContentLoader
        speed={speed}
        width={1150}
        height={32}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="16" y="8" rx="4" ry="4" width="52" height="20" /> 
        <rect x="115" y="8" rx="4" ry="4" width="70" height="20" /> 
        <rect x="375" y="8" rx="4" ry="4" width="48" height="20" /> 
        <rect x="480" y="8" rx="4" ry="4" width="35" height="20" /> 
        <rect x="700" y="8" rx="4" ry="4" width="52" height="20" /> 
        <rect x="795" y="8" rx="4" ry="4" width="52" height="20" /> 
        <rect x="910" y="8" rx="4" ry="4" width="65" height="20" /> 
        <rect x="1030" y="8" rx="4" ry="4" width="65" height="20" /> 
      </ContentLoader>

      <ContentLoader
        speed={speed}
        width={1150}
        height={40}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="0" y="0" rx="4" ry="4" width="1150" height="2" /> 
        <rect x="0" y="14" rx="4" ry="4" width="1150" height="16" /> 
      </ContentLoader>

      <ContentLoader
        speed={speed}
        width={1150}
        height={40}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="0" y="0" rx="4" ry="4" width="1150" height="2" /> 
        <rect x="0" y="14" rx="4" ry="4" width="1150" height="16" /> 
      </ContentLoader>

      <ContentLoader
        speed={speed}
        width={1150}
        height={40}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="0" y="0" rx="4" ry="4" width="1150" height="2" /> 
        <rect x="0" y="14" rx="4" ry="4" width="1150" height="16" /> 
      </ContentLoader>

      <ContentLoader
        speed={speed}
        width={1150}
        height={40}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="0" y="0" rx="4" ry="4" width="1150" height="2" /> 
        <rect x="0" y="14" rx="4" ry="4" width="1150" height="16" /> 
      </ContentLoader>
    </div>
  )
}

export const CommonFiltersLoader = (props: IContentLoaderProps) => {
  const viewBox = "0 0 1150 40";
  const speed = 2;
  const backgroundColor = 'rgba(0, 0, 0, 0.1)' // #f3f3f3
  const foregroundColor = '#ecebeb';

  return (
    <div className='common-table-loader'>
      <ContentLoader
        speed={speed}
        width={1150}
        height={40}
        viewBox={viewBox}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="0" y="0" rx="4" ry="4" width="950" height="40" /> 
        <rect x="1020" y="6" rx="4" ry="4" width="130" height="28" /> 
      </ContentLoader>
    </div>
  )
}

export default CommonTableLoader;