// The useShiftSelect hook is a custom React hook that provides functionality for shift-selecting
// items in a list or table. It accepts an array of items and an optional array of default selected items,
// and returns an array containing the currently selected items, a
// function to handle item selection, and a function to select all items.

import { useEffect, useRef, useState } from "react";
import { Id } from "merchery-lib";

export function useShiftSelect<T extends Entity>(items: T[], defaultSelected?: Id[]): ShiftSelectReturn<T> {
  const [selectedIds, setSelectedIds] = useState<Id[]>(defaultSelected || []);
  const [multiSelectEnabled, setMultiSelectEnabled] = useState(false);
  let lastSelectedId = useRef<Id | null>(null);

  useEffect(() => {
    const enableMultiSelect = () => setMultiSelectEnabled(true);
    const disableMultiSelect = () => setMultiSelectEnabled(false);

    window.addEventListener('keydown', enableMultiSelect);
    window.addEventListener('keyup', disableMultiSelect);

    return () => {
      window.removeEventListener('keydown', enableMultiSelect);
      window.removeEventListener('keyup', disableMultiSelect);
    };
  }, []);

  const handleSelect = (clickedItem: T) => {
    let newSelectedIds: Id[] = [];

    const multi = multiSelectEnabled && lastSelectedId.current !== null

    if (multi) {
      const start = items.findIndex(item => item.id === lastSelectedId.current);
      const end = items.findIndex(item => item.id === clickedItem.id);

      if (selectedIds.includes(clickedItem.id)) {
        const idsToRemove = items
          .slice(Math.min(start, end), Math.max(start, end) + 1)
          .map(item => item.id);
    
        newSelectedIds = selectedIds.filter(id => !idsToRemove.includes(id));
      } else {
        const ids = items
          .slice(Math.min(start, end), Math.max(start, end) + 1)
          .map(item => item.id);
          
        newSelectedIds = Array.from(new Set([...selectedIds, ...ids]));
      }
    } else {
      newSelectedIds = selectedIds.includes(clickedItem.id) 
        ? selectedIds.filter(id => id !== clickedItem.id)
        : [...selectedIds, clickedItem.id]
    }


    if(!multiSelectEnabled) {
      lastSelectedId.current = clickedItem.id;
    }
    setSelectedIds(newSelectedIds);
    return newSelectedIds
  }

  const selectAll = (allSelectedItems: Id[]) => {
    setSelectedIds(allSelectedItems)
  }

  return [selectedIds, handleSelect, selectAll];
}

interface Entity {
  id: Id
}

type ShiftSelectReturn<T extends Entity> = [
  Id[],
  (clickedItem: T) => Id[],
  (allSelectedItems: Id[]) => void
];
