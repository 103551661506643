import qs from 'qs';
import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import { batch, useDispatch } from 'react-redux';
import {Link, useLocation, useRouteMatch} from 'react-router-dom';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { errorWithStatus, validateResponse } from 'src/scripts/functions';
import history from '../../../scripts/history';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { useLoad } from 'src/scripts/hooks/use-load';
import useMounted from '../../../scripts/hooks/use-mounted';
import { useTabIndex } from 'src/scripts/hooks/use-tabindex';
import { NotFoundLocalApp } from '../../_utility-components/not-found';
import { MainRouteChild } from '../main-page';
import { ExtendedClientDto, Id, Order, OrderItemExtended } from 'merchery-lib';
import { OrderWithItems } from './dto/order-responses.dto';
import ClientInOrder from './order-modules/client-in-order';
import DeliverySection from './order-modules/delivery/delivery';
import OrderPageHeader from './order-modules/header';
import ItemsList from './order-modules/items-list/items-list';
import OrderChangeBar from './order-modules/order-change-bar';
import OrderPayment from 'src/components/main-pages/orders/order-modules/payment/payment';
import OrderRefunds from './order-modules/refund-modules/refunds';
import usePageViewers from "../../../scripts/hooks/use-page-viewers";

export type OrderChangeHandler = (changes: Partial<Order>, withLoader?: boolean) => Promise<boolean>

export const OrderContext = React.createContext<{
  orderDeleted: boolean,
  order: Order | undefined,
  isOrderUnchangeable: boolean,
  managersOnPage: Array<Id>,
  setOrderDeleted: (value: boolean) => void,
  orderChange: OrderChangeHandler
}>({
  orderDeleted: false,
  order: undefined,
  isOrderUnchangeable: true,
  managersOnPage: [],
  setOrderDeleted: () => {},
  orderChange: () => {return new Promise(() => false)}
});

interface Props extends MainRouteChild {

}

function OrderPage (props: Props) {
  const notFoundNotification = useAppSelector(state => state.staticValues.not_found_order_page_notification || '')

  const notfoundContextLinks = [
    {
      to: '/app/orders',
      text: 'К списку заказов',
    }
  ]

  const location = useLocation<{prevPage?: string}>()
  const match = useRouteMatch<{orderid: string}>();
  const isNew = match.params.orderid === 'new';
  const orderId = +match.params.orderid;
  const _isMounted = useMounted();
  const tabIndex = useTabIndex();

  const toParams = useMemo(() => ({
    pathname: '/app/orders',
    search: location.state ? location.state.prevPage : '',
    state: {toOrder: orderId}
  }), [location, orderId])

  const statuses = useAppSelector(state => state.orderStatus)
  const orders = useAppSelector(state => state.orders)
  const clients = useAppSelector(state => state.clients);

  const order = useMemo(() =>
    orders.find(o => o.id === orderId)
  , [orderId, orders])

  const dispatch = useDispatch()
  const setOrders = (orders: Order[]) => dispatch({type: 'ORDERS', payload: orders})
  const setItems = (items: OrderItemExtended[]) => dispatch({type: 'PRODUCT_ITEMS_IN_CONTEXT', payload: items})
  const setSelectedItems = (selectedItemsIds: Id[]) => dispatch({ type: 'PRODUCT_ITEMS_IN_CONTEXT_SELECTED', payload: selectedItemsIds})

  const pathname = location.pathname;
  const managersOnPage = usePageViewers(pathname)

  const [, setLoad] = useLoad();
  const [notFound, setNotFound] = useState(false);
  const [orderDeleted, setOrderDeleted] = useState<boolean>(Boolean(order?.deleted));

  const user = order && order.client_id ? clients?.find(u => u.id === order.client_id) : null;
  const setUser = (clients: ExtendedClientDto[]) => dispatch({type: 'CLIENTS', payload: clients});

  const isOrderUnchangeable = useMemo(() => {
    if(!order) {
      return true
    }
    const thisStatus = statuses && statuses.find(status => order.status === status.id);

    return !thisStatus ||
      thisStatus.type === 'Profit' ||
      thisStatus.type === 'Cancelled' ||
			Boolean(
				order.deliveries?.some(
          orderDelivery =>
					  orderDelivery.note && orderDelivery.shipping_date
        )
			)
  }, [statuses, order])

  useEffect(() => {
    if(isNew) {
      createNewDraft()
    } else {
      getDatas()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  useEffect(() => {
    props.setCurrentPage('orders')

    return () => {
      setSelectedItems([])
    }
  }, [])

  const createNewDraft = async () => {

    const orderCreateParams: Omit<Order, 'id' | 'UUID' | 'price' | 'total' | 'date' | 'modified' | 'deleted'> = {
      admin_id: localStorage.getItem('admin'),
      status: (statuses && statuses.find(os => os.type === 'Draft')?.id) || 5,
      client_id: null,
      session_id: null,
      comment: null,
      deliveries: null,
      promo_code: null,
      discount: null,
      paymethod: null,
      session: null,
      another_recipient: false,
      complete: true,
      paid: false,
      percentage_discount: false,
      client: null,
      refunded: false,
      current_delivery_id: null,
    }

    return await mercheryFetch<Order>('orders/create', 'POST', orderCreateParams)
      .then((res) => {
        if(!_isMounted.current) return false
        if(!validateResponse(res)) {
          throw new Error(res?.message || 'Непредвиденная ошибка')
        }

        const order = res.records

        setOrders([...orders, order])

        history.replace({
          pathname: '/app/orders/' + order.id
        })

        return true
      })
  };

  const getUser = async (client_id: Id) => {
    const localClients = clients || [];

    mercheryFetch<ExtendedClientDto>(`client/one/${client_id}`, 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res))
        return false;

      const userRes = res.records;

      if(!userRes) {
        return false
      }

      const updatedClients: ExtendedClientDto[] = !user ? 
        [...localClients, userRes]
      : localClients.map(c => c.id !== userRes.id ? c : userRes);

      setUser(updatedClients)
    })
  };

  const getDatas = async () => {
    getOrdersItems()

    const order = await getOrder()

    if(order && order?.client_id) {
      getUser(order.client_id)
    }
  }

  const getOrdersItems = () => {
    if(Number.isNaN(orderId)) {
      return false
    }

    const params = qs.stringify({
      order: orderId,
    }, {arrayFormat: 'comma'})

    mercheryFetch<OrderItemExtended[]>(`orders/item?${params}`, "GET", {})
    .then((res) => {
      if(!_isMounted.current)
        return false

      if(errorWithStatus(res) && res.status === 204) {
        setNotFound(true)
        return false
      }

      if(validateResponse(res)) {
        setItems(res.records)
        setNotFound(false)
      }
    })
  };

  const getOrder = () => {
    if(Number.isNaN(orderId)) {
      return false
    }

    return mercheryFetch<Order>(`orders/get-one?id=${orderId}`, 'GET', {}, true)
      .then((res) => {
        if(!_isMounted.current || !validateResponse(res) || !res.records) return false
        const gettedOrder = res.records;

        const allOrders = !order ?
          [...orders, gettedOrder]
          : orders.map(o => o.id !== orderId ? o : gettedOrder)

        batch(() => {
          setOrderDeleted(Boolean(gettedOrder.deleted))
          setOrders(allOrders)
        })

        return gettedOrder
      })
  };

  const updateOrderState = (orderChanges: Partial<Order>) => {
    if(!orderChanges || !order) {
      return false
    }

    setOrders(
      orders.map(o =>
        o.id !== orderId ? o : {
          ...o,
          ...orderChanges
        }
      )
    )
  }

  const changeData: OrderChangeHandler = useCallback(async (changes, withLoader) => {
    if(!order) {
      return false
    }
    if(changes.date !== undefined)
      delete changes.date;

    if(withLoader) {
      setLoad(true)
    }

    return await mercheryFetch<OrderWithItems>(`orders/update-one/${order?.id}`, 'PATCH', {
      changes: {
        id: orderId,
        ...changes
      }
    })
    .then((res) => {
      if(!_isMounted.current)
        return false

      if(errorWithStatus(res) && res.status === 204) {
        setNotFound(true)
        return false
      }
      if(!validateResponse(res)) {
        return false;
      }

      batch(() => {
        setItems(res.records.items)
        updateOrderState(res.records.order)
      })
      return true
    })
    .finally(() => {
      if(withLoader)
        setLoad(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, orders, setLoad]);

  if(!isNew && Number.isNaN(orderId)) {
    return <NotFoundLocalApp/>
  }

  if(!order) {
    return <NotFoundLocalApp/>
  }

  if(notFound) {
    return (
      <NotFoundLocalApp
        optionalMessage={notFoundNotification}
        contextLinks={notfoundContextLinks}
      />
    )
  }

  return (
    <OrderContext.Provider value={{
      orderDeleted,
      order,
      isOrderUnchangeable,
      managersOnPage,
      setOrderDeleted,
      orderChange: changeData,
    }}>
      <div className="order-page">
        <Link
          tabIndex={tabIndex}
          to={toParams}
          className="to-orders-btn hide-for-print"
        >
          <i className="icofont-simple-left"></i>
          Заказы
        </Link>

        <div className="order-page-container">
          <OrderPageHeader/>

          {!orderDeleted ?
            <OrderChangeBar />
          : null}

          <ItemsList />

          <OrderPayment />

          <ClientInOrder />

          <DeliverySection
            updateOrderState={updateOrderState}
          />

          <OrderRefunds/>
        </div>
      </div>
    </OrderContext.Provider>
  );
}

export default memo(OrderPage)
