import React, {useContext} from 'react';
import Paymethod from "src/components/main-pages/orders/order-modules/payment/paymethod";
import PaymentBodyPaid from "src/components/main-pages/orders/order-modules/payment/payment-body-paid";
import {OrderContext} from "src/components/main-pages/orders/order-page";

function PaymentBody () {
	const {
		order
	} = useContext(OrderContext)

	const paid = order?.paid;

	return (
		<div className='order-page__payment__body'>
			<Paymethod />

			{paid
				? <PaymentBodyPaid />
			: null}
		</div>
	);
}

export default PaymentBody;