import React, {useContext, useMemo} from "react";
import SortedDroppableList from "./categories-of-products-rows";
import {ProductsTableContext} from "../table";
import { ExtendedProduct, Id } from 'merchery-lib';

const ProductsTableBody = () => {
  const {
    sortedProducts,
    orderingMode,
    uniqueCategories
  } = useContext(ProductsTableContext);

  if(orderingMode === 'category') {
    return <>{
      uniqueCategories.map((category) =>
        <CategorySortedDroppableList
          key={category}
          sortedProducts={sortedProducts}
          category={category}
        />
      )
    }</>
  }

  return <SortedDroppableList
    droppableId={1} 
    sortedProducts={sortedProducts}  
  />
}

export default ProductsTableBody

function CategorySortedDroppableList({
  sortedProducts, 
  category
}: {sortedProducts: ExtendedProduct[], category: Id}) {
  const filteredSortedProducts = useMemo(() => 
    [...sortedProducts]
    .filter((p) => p.top === category)
  ,[sortedProducts, category]);

  return <SortedDroppableList
    key={category}
    droppableId={category}
    sortedProducts={filteredSortedProducts}
  />;
}
