import { useDispatch } from "react-redux";
import { useAppSelector } from "../pre-type/use-selector";
import {useEffect} from "react";

export function useLoad () {
  const load = useAppSelector(state => state.load)
  const dispatch = useDispatch();
  const setLoad = (bool: boolean) => dispatch({type: 'LOADING', payload: bool})

  useEffect(() => {
    return () => {
      setLoad(false)
    }
  }, [])

  return [load, setLoad] as const;
}