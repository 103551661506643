import React from 'react';
import MyCheckbox from 'src/components/_utility-components/checkbox';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { ProtoProduct } from 'merchery-lib';

function NoveltyFlag({
  productChange
}: {
  productChange: (changes: Partial<ProtoProduct>) => void
}) {
  const product = useAppSelector(state => state.product);

  if(!product) {
    return null
  }

  const actionHandler = () => 
    productChange({show_novelty_flag: !product.show_novelty_flag})
  
  return (
    <MyCheckbox 
      text='Новинка'
      className={'text-font-m'}
      condition={product.show_novelty_flag} 
      actionHandler={actionHandler}      
    />
  );
}

export default NoveltyFlag;