import React, { useEffect, useMemo, useState } from 'react';
import { SortableItem, SortableKnob } from 'react-easy-sort';
import { batch, useDispatch } from 'react-redux';
import { ExtractedLinks, ExtendedProduct, ImageDeleteResponseDto, ImageExtended, Id, ImageFile } from 'merchery-lib';
import { extractImages } from '../../../../scripts/utils/extractImages';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { openInNewTab, validateResponse } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../scripts/hooks/use-mounted';
import useRouteId from '../../../../scripts/hooks/use-route-id';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import MyButton from '../../../_utility-components/button/button';
import Picture from '../../../_utility-components/picture/picture'
import { ImageMainChangeResponse } from './dto/image-main-change-response.dto';
 
interface Props <T extends ImageFile | ImageExtended>{
  photo: T, 
  mainId: Id | null, 
  files: ImageExtended[],
  initialFiles: React.MutableRefObject<ImageExtended[]>,
  setMainId: (id: Id | null) => void,
  setFiles: (images: ImageExtended[]) => void,
}

function photoAlreadyUploaded (photo: ImageFile | ImageExtended): photo is ImageExtended {
  return (photo as ImageExtended).id !== undefined 
}

function ProductMediaItem <T extends ImageExtended | ImageFile>({
  photo, 
  mainId,
  files,
  initialFiles,
  setMainId,
  setFiles,
}: Props<T>) {
  const _isMounted = useMounted()
  const productId = useRouteId('productid')
  const product = useAppSelector(state => state.product)
  const tabIndex = useTabIndex();
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

  // const type = photo?.videoSource || (!photoAlreadyUploaded(photo) ? photo.imageType?.replace(/^image[/]/, '') : undefined)
  const disableHref = photo.newFile;
  const href = !disableHref ? photo?.videoLink || photo.src : undefined;

  const dispatch = useDispatch()
  const productDispatch = (product: ExtendedProduct) => 
    dispatch({ type: 'PRODUCT_ITEM', payload: product })

  const favoriteImageHandler = (id: Id) => {
    if(mainId === id) {
      return false
    }

    batch(() => {
      setMainImage(id)
      setMainId(id)
    })

    mercheryFetch<ImageMainChangeResponse>('images/main', 'PATCH', {
      imageId: id,
      id: productId,
      module: 'Catalog',
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) 
        return false

      const mainId = res.records.imageId;

      batch(() => {
        setMainId(mainId)
        setFiles(filesWithChangedMain(files, mainId))
      })
      if(photoAlreadyUploaded(photo)) {
      // if(document.activeElement?.attributes.getNamedItem('class').textContent.includes('new-loaded_file-favorite-btn')) {
        (document.activeElement as HTMLElement | null)?.blur()
      }
    })
  }

  const filesWithChangedMain = (images: ImageExtended[], newMainId: Id) => images.map(f => {
    if(f.id === newMainId) {
      return {...f, main: true};
    }

    if(f.id !== newMainId && f.main) {
      return {...f, main: false}
    }

    return f
  })

  const setMainImage = (newMainId: Id) => {
    if(moySkladIntegrationOn || !product) 
      return false // TODO

    const images = 
      Array.isArray(product.src) 
        ? product.src 
        : [product.src];

    productDispatch({
      ...product,
      src: filesWithChangedMain(
        images, 
        newMainId
      )
    })
  }

  const deleteImages = async (filesToDelete: ImageExtended[]) => {
    if(!product) {
      return false
    }

    const needNewMain = filesToDelete.length < initialFiles.current.length && filesToDelete.some(f => f.main)
    
    const res = await mercheryFetch<ImageDeleteResponseDto>('images', 'DELETE', {
      filters: {
        id: filesToDelete.map(i => i.id),
        module: 'Catalog',
        module_id: productId
      },
      needNewMain: needNewMain
    })

    if(!_isMounted.current || !validateResponse(res)) 
      return false

    const reordered = res?.records.reordered
    const deletedIds = res?.records.deletedImagesId
    const newMainId = res?.records.newMain
    const reorderedFiles = files
      .filter(f => !deletedIds.some(id => id === f.id))
      .map((f) => {
        const s = reordered?.find(d => d.id === f.id)
        return s 
          ? ({
            ...f, 
            main: s?.main, 
            order: s.order
          }) 
          : f
      })
      .sort((a, b) => a.order - b.order)
    
    batch(() => {
      setFiles(reorderedFiles)
      if(newMainId) {
        setMainId(newMainId)
        productDispatch({
          ...product, 
          src: product.src.flatMap((img) => 
            deletedIds.some(id => id === img.id) 
              ? [] 
              : newMainId && newMainId === img.id 
                ? {...img, main: true} 
                : img
          )
        })
      }
    })
  }

  const clickHandler = (e: React.MouseEvent) => {
    e.stopPropagation()

    const imageIsVideo = photo.videoSource;
    if(imageIsVideo && photo.videoLink) {
      openInNewTab(photo.videoLink)
    } else {
      openInNewTab(photo.src)
    }
  }

  return (
    <SortableItem>
      <div className={`droppable photos-container-photo`}>
        <div
          className="new-loaded_file-image-container"
          onClick={clickHandler}
          onAuxClick={clickHandler}
          tabIndex={tabIndex}
          title={href}
        >
          <SortableKnob>
            <div className="new-loaded_file-image-wrapper">
              <FileImageItemComponent
                photo={photo}
              /> 
            </div>
          </SortableKnob>
          
          {photoAlreadyUploaded(photo) ? 
            <>
              <MyButton className="new-loaded_file-favorite-btn" 
                onClick={(e) => {
                  e.stopPropagation()
                  favoriteImageHandler(photo.id)
                }}
                onKeyUp={(e) => e.key === 'Enter' ? favoriteImageHandler(photo.id) : false}
              >
                <div className={`new-loaded_file-favorite-btn-inner ${mainId === photo.id ? 'active' : ''}`}>
                  <i className="icofont-star"></i>
                </div>
              </MyButton>

              <MyButton
                removeDefaultClass
                className="new-loaded_file-close-btn"
                onClick={(e) => {
                  e.stopPropagation()
                  deleteImages([photo])
                }}
                onKeyUp={(e) => e.key === 'Enter' ? deleteImages([photo]) : false}
              >
                <i className="icofont-close-line-circled"></i>
              </MyButton>
            </>
          : null}
        </div>
      </div>
    </SortableItem>
  )
}

export default ProductMediaItem;


function FileImageItemComponent ({photo}: {photo: ImageExtended | ImageFile}) {
  const [images, setImages] = useState<ExtractedLinks | null>(null);

  const imageIsVideo = photo.videoSource;

  const extractedImages = useMemo(() => {
    return extractImages(photo, 'small') || null
  }, [photo])

  useEffect(() => {
    setImages(photo.newFile ? {default: photo.src} : extractedImages);
  }, [photo.newFile])

  if(imageIsVideo && photo.videoSource !== 'internal' && photo.videoLink) {
    return (
      <video src={photo.videoLink} width={140} height={140} autoPlay={false} muted/>
    )
  }

  // if(imageIsVideo) {
  //   return (
  //     <video src={photo.src} width={140} height={140} autoPlay={false} muted/>
  //   )
  // }
  
  return (
    <Picture
      images={images}
    />
  )
}