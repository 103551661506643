import React from "react";
import { Id, ProductsAttrValues, ProductOption } from "merchery-lib";;
import { useAppSelector } from "../../../../../scripts/pre-type/use-selector";
import { usePopup } from "../../../../../scripts/hooks/use-popup";
import MyButton from "../../../../_utility-components/button/button";

interface Props {
  option: ProductOption,
  variantsFilterHandler: (name_id: Id, value: ProductsAttrValues) => false | undefined
}

export function ProductVariantFilterModule({
  option,
  variantsFilterHandler
}: Props) {
  const {name_id} = option
  const selectedVariantsFilters = useAppSelector(state => state.selectedVariantsFilters)
  const { RenderButton, RenderPopup, } = usePopup()

  if(!name_id) {
    return null
  }

  return (
    <div className='product-page-variants-header-filter-wrapper'>
      <RenderButton
        className='product-page-variants-header-filter'
        tabIndex={-1}
      >
        <div>{option.title}</div>
        <i className="icofont-caret-down"></i>
      </RenderButton>

      {option.values.length ? (
        <RenderPopup
          changingDirection={true} 
          className={'table-popup status'}
        >
          <div className="popup-group">
            {option.values.map((v, index) => {
              const active = selectedVariantsFilters.some(filter => filter.name_id === name_id && filter.values.some(filterV => filterV.value === v.value))
              return <MyButton
                key={index}
                removeDefaultClass
                className={`popup-element ${active ? 'active' : ''}`}
                onClick={(() => variantsFilterHandler(name_id, v))}
              >
                <div className="checkbox">
                  <i className="icofont-check-alt"></i>
                </div>
                <div is="x3d">{v.value}</div>
              </MyButton>
            })}
          </div>
        </RenderPopup>
      ) : null}
    </div>
  );
}