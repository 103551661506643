import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { querify, validateResponse } from 'src/scripts/functions';
import { mercheryFetch } from '../../../scripts/fetchConstructor';
import { Delivery, ExtendedClientDto } from 'merchery-lib';
import { ClearAndSetIndexDb, MainRouteChild } from '../main-page';
import OrdersHeader from './orders-modules/orders-header';
import { OrderTable } from './orders-modules/table';
import useMounted from "../../../scripts/hooks/use-mounted";

interface Props extends MainRouteChild {
  clearAndSet: ClearAndSetIndexDb,
}

function OrdersPage(props: Props) {
  const _isMounted = useMounted()

  const dispatch = useDispatch();

  const deliveriesDispatch = (deliveries: Delivery[]) => 
    dispatch({ type: 'ORDERS_DELIVERIES', payload: deliveries })
  const clientsDispatch = (clients: ExtendedClientDto[]) => 
    dispatch({type: 'CLIENTS', payload: clients});

  useEffect(() => {
    initPage()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initPage = async () => {
    props.setCurrentPage('orders');

    await getClientsWithFilters()

    getDeliveries()
  }

  const getDeliveries = () => {
    return mercheryFetch<Delivery[]>('delivery', 'GET', {})
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;
      deliveriesDispatch(res.records)
    })
  };

  const getClientsWithFilters = async (filters?: {}) => {
    const query = filters ? '?' + querify(filters) : '';

    return await mercheryFetch<ExtendedClientDto[]>(`client${query}`, 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      batch(() => {
        // setClientsLoaded(true)
        clientsDispatch(res.records)
      })
    })
  }

  return (
    <div className='orders-wrapper'>
      <OrdersHeader
      />

      <OrderTable
        clearAndSet={props.clearAndSet}
      />
    </div>
  );
}

export default OrdersPage;