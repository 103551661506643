import { RefundDto } from 'merchery-lib';

interface Props {
  refund: RefundDto,
}

export const renameExportHeaders = ({
  refund,
}: Props): any => {
  return {
    'Заказ': refund.order_id,
    'Получен': refund.order_receive_date,
    'Возвращен': refund.refund_date,
    'Покупатель': refund.client?.name || '',
    'Сумма возврата': refund.refund_total,
    'Статус': refund.status || '',
  }
}