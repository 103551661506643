import React, {useCallback, useContext, useEffect, useRef} from "react";
import {batch, useDispatch} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import { Id, Category } from "merchery-lib";
import {mercheryFetch} from "src/scripts/fetchConstructor";
import {toastUp, validateResponse} from "src/scripts/functions";
import {useAppSelector} from "src/scripts/pre-type/use-selector";
import transliterate from "../../../scripts/utils/transliteration";
import useMounted from "../../../scripts/hooks/use-mounted";
import {useTabIndex} from "src/scripts/hooks/use-tabindex";
import MyButton from "../../_utility-components/button/button";
import MyInput from "../../_utility-components/input/index";
import {CategoryFilterChanger} from "./categories-filter-and-control";
import {CategoryItemMenu} from "./category-item-menu";
import {CategoriesContext} from "./categories";

export interface CategoryItemProps {
  category: Category,
  categoryHandler: CategoryFilterChanger, 
  addCategory?: (top: Id) => void,
  closePopupParent?: () => void,
  disabledConfig?: boolean,
  filtersMod?: boolean,
}

export const CategoryItem = ({
  category, 
  closePopupParent, 
  addCategory, 
  disabledConfig, 
  filtersMod, 
  categoryHandler
}: CategoryItemProps) => {
  const nameRef = useRef<HTMLInputElement | null>(null);
  const _isMounted = useMounted();
  const inlineNameEditing = useAppSelector(state => state.inlineNameChangingCategory === category.id);

  const categories = useAppSelector(state => state.categories);
  const tabIndex = useTabIndex();
  const history = useHistory();

  const {
    setSelectedCategory
  } = useContext(CategoriesContext);
  
  const dispatch = useDispatch();
  const setInlineNameEditing = (id: Id | null) =>
    dispatch({ type: 'INLINE_CHANGING_CATEGORY', payload: id });

  const categoriesDispatch = (categories: Category[]) =>
    dispatch({ type: 'CATEGORIES', payload: categories })

  useEffect(() => {
    setTimeout(() => {
      nameRef.current?.focus()
    }, .04)
  }, [])

  const clearNewCategory = useCallback(() => {
    if(categories) {
      const clearedCategories = categories.filter(c => !c.newCategory);
      if(clearedCategories.length !== categories.length) {
        categoriesDispatch(categories.filter(c => !c.newCategory))
      }
    }
  }, [categories])

  const nameChanger = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if(!categories) {
      return false
    }

    if(!category.newCategory) {
      updateCategory(e)
      return;
    }

    const name = e.target.value;

    categoriesDispatch(
      categories.map(c => c.id !== category.id ? c : {
        ...category,
        name: name,
        nav: transliterate(name).toLowerCase()
      })
    )
  }

  const updateCategory = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const labelName = event.target.name;
    const value = event.target.value;
    if(!categories || value === category[(labelName as keyof Category)]) {
      return false
    }
    
    mercheryFetch<Category[]>('category', 'PATCH', {
      changes: [{
        id: category.id,
        [labelName]: value
      }]
    })
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false;
      }

      const updatedCategory = res.records.find(c => c.id === category.id);

      updatedCategory &&
      batch(() => {
        setSelectedCategory(updatedCategory)
        categoriesDispatch(
          categories.map(c =>
            c.id !== updatedCategory.id ? c : updatedCategory
          )
        )
      })
    })
  }
  
  const createCategory = () => {
    if(!categories) {
      return false
    }

    mercheryFetch<Category>('category', 'POST', {
      toCreate: category
    })
    .then(res => {
      if(!_isMounted.current) return false;
      if(!validateResponse(res)) {
        toastUp(res.message)

        clearNewCategory()
        return false
      } 

      const createdCategory = res.records;

      createdCategory &&
        categoriesDispatch(
          categories.map(c => 
            c.id !== category.id 
              ? c 
              : createdCategory)
        );

      !closePopupParent && 
        history.replace('/app/categories/' + createdCategory.id);
    })
  }
  
  const stopInlineEditing = () => {
    if(category?.newCategory) {
      if(!category.name)
        clearNewCategory()
      else
        createCategory()
    }

    setInlineNameEditing(null)
  }

  return (
    <div className="category-item">
      {inlineNameEditing ?
        <MyInput inputRef={nameRef}
          value={category.name}
          name={'name'}
          shortInput
          onChange={nameChanger}
          onBlur={stopInlineEditing}
          onEnter={stopInlineEditing}
          onEscape={stopInlineEditing}
        />
      : (
        !filtersMod ?
          <Link className="category-item__btn"
            to={{ pathname: '/app/categories/' + category.id }}
            onClick={() => categoryHandler(category.id)}
          >
            {category.name}

            {category.productCount ?
              <div className={'items-counter'}>
                {category.productCount}
              </div>
            : null}
          </Link>
        :
          <MyButton className="category-item__btn"
            tabIndex={tabIndex}
            removeDefaultClass
            onClick={() => categoryHandler(category.id)}
          >
            {category.name}
          </MyButton>
      )}

      <CategoryItemMenu
        closePopupParent={closePopupParent}
        category={category}
        addCategory={addCategory}
        nameRef={nameRef}
        filtersMod={filtersMod}
        disabledConfig={disabledConfig}
      />
    </div>
  )
}