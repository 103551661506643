import {useAppSelector} from "../../../../scripts/pre-type/use-selector";
import useOrderFilters from "../hooks/use-order-filters";
import {batch, useDispatch} from "react-redux";
import React, {useState} from "react";
import {CommonFiltersLoader} from "../../../_utility-components/loaders/common-table-loader";
import MyButton from "../../../_utility-components/button/button";
import arrowLeft from "../../../../img/arrow-left.png";
import SearchInput from "../../../_utility-components/search-input/search-input";
import HeaderStatusFilter from "./header-status-filter";
import HeaderManagerFilter from "./header-manager-filter";
import magnifier from "../../../../img/magnifier.png";
import SearchFilters from "./search-filters-cancel";
import ExportButton from "../../../_utility-components/export-btn/export-btn";
import {Order} from "merchery-lib";
import {renameExportHeaders} from "../utils/rename-export-headers";

function OrdersFilters () {
	const orders = useAppSelector(state => state.orders);
	const admins = useAppSelector(state => state.admin);
  const searchInput = useAppSelector(state => state.ordersSearch);
	const [ordersFilters] = useOrderFilters();
  const [searchState, setSearchState] = useState(Boolean(searchInput));

	const statuses = useAppSelector(state => state.orderStatus);
	const deliveries = useAppSelector(state => state.deliveryServices);
	const paymethod = useAppSelector(state => state.paymethod);

	const dispatch = useDispatch()
	const searchInputDispatch = (text: string) => dispatch({type: 'ORDERS_SEARCH_INPUT_VALUE', payload: text});

	const filtersBackBtn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		(e.target as HTMLElement).blur();

		batch(() => {
			setSearchState(false);
			searchInputDispatch('');
		})
	};

	const searchHandler = async (e: React.MouseEvent) => {
		const target = e.target as HTMLElement;
		target.blur()

		setSearchState(true)

		setTimeout(() => {
			document.querySelector<HTMLElement>('#order-search')?.focus()
			return;
		}, 4)
		// this.filterTabHandler(0, [])
	};

	if (!statuses?.length || !admins?.length) {
		return <CommonFiltersLoader/>
	}

	return <div className={'orders-page__filters-and-export'}>
		<div className={`search-and-filters-bar ${searchState ? 'search-state' : ''}`}>
			{searchState ? (
				<>
					<MyButton className="search-button arrow-left-icon"
										onClick={filtersBackBtn}
					>
						<img alt="Назад" src={arrowLeft}/>
					</MyButton>

					<SearchInput
						id='order-search'
						searchInput={searchInput}
						applySearch={searchInputDispatch}/>

					<div className="search-open-bar-btns">
						<HeaderStatusFilter/>

						<HeaderManagerFilter/>
					</div>
				</>
			) : (
				ordersFilters ?
					<>
						<MyButton className="search-button search-icon" onClick={searchHandler}>
							<img alt="Поиск" src={magnifier}/>
						</MyButton>
						<SearchFilters/>
					</>
					: null
			)}
		</div>

		<ExportButton<Order>
			text={'экспорт'}
			renameExportHeaders={order => renameExportHeaders({
				order,
				statuses,
				deliveries,
				paymethod,
				admins,
				// paidStatuses,
			})}
			items={orders}/>
	</div>;
}

export default OrdersFilters