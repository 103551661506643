import { ProductsAttrNames } from 'merchery-lib';
import React, { useContext, useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { batch } from 'react-redux';
import { PossibleValuesPopup, usePossibleValues } from '../../../../../scripts/hooks/use-possible-values';
import { useTabIndex } from '../../../../../scripts/hooks/use-tabindex';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import MyButton from '../../../../_utility-components/button/button';
import Grabber from '../../../../_utility-components/grabber';
import MyInput from '../../../../_utility-components/input/index';
import { OneOptionContext } from '../one-option';

interface Props {
  titleInput: React.MutableRefObject<HTMLInputElement | null>
  deleteOption: () => void,
  dragProvided?: DraggableProvided,
}

export function ProductOneOptionHeader({
  titleInput,
  deleteOption,
  dragProvided,
}: Props) {
  const {
    // edit,
    option,
    // setEdit,
    changeOption,
    setNeedCallPossibleValues,
    updateOption,
  } = useContext(OneOptionContext);
  const [iniitalPossibleNames, setIniitalPossibleNames] = useState<ProductsAttrNames[]>([]);
  const [title, setTitle] = useState(option?.title || '');
  
  const options = useAppSelector(state => state.productOptions)
  const tabIndex = useTabIndex()

  const {setSearch, search, data, setData, dataLoading, showData, setShowData} = usePossibleValues<ProductsAttrNames>({
    urlPath: 'products-attributes/search/name',
  });

  useEffect(() => {
    if(option && option.title !== title) {
      setTitle(option.title)
    }
  }, [option])

  useEffect(() => {
    setData(filterPossibleNames())
  }, [iniitalPossibleNames])

  useEffect(() => {
    if(option?.notSaved && search) {
      data.length && setData(filterPossibleNames())
    }
  }, [options.length])

  useEffect(() => {
    if(option && option.title !== title) {
      let timeOutId = setTimeout(() => {
        changeOption({
          title
        })
      }, 50);
      return () => clearTimeout(timeOutId)
    }
  }, [title]);

  const editBtnHandler = () => {
    if(!option) {
      return false
    }
    updateOption(option.id, {...option, notSaved: true})
    // setEdit(true)
    setTimeout(() => {
      titleInput.current?.focus()
    }, 0)
  }

  const titleHandler = async (inputValue: string) => {
    batch(() => {
      setTitle(inputValue)
      setData([])
      setSearch(inputValue)
    })
  }

  const possibleNameHandler = async (possibleName: ProductsAttrNames) => {
    await changeOption({
      title: possibleName.name,
      name_id: possibleName.id,
      newName: false
    })

    batch(() => {
      setTitle(possibleName.name)
      setShowData(false)
      setSearch('')
      setData([])
      setIniitalPossibleNames([])
      setNeedCallPossibleValues(true)
    })
  }

  const filterPossibleNames = () => {
    if(!iniitalPossibleNames.length) {
      return []
    }
    if(!options.length) {
      return iniitalPossibleNames
    }
    return iniitalPossibleNames.filter(r => !options.some(v => v.title === r.name && !v.notSaved))
  }

  const addNewOptionHandler = () => {
    setShowData(false)
  }
  
  const classPrefix = 'product-page-additional-options-one-option'

  const grabberClassName = option?.notSaved ? 'disabled' : ''
  const headerClassName = `${classPrefix}-header ${option?.notSaved ? `${classPrefix}-header__edit` : ''}`

  return (
    <div className={headerClassName}>
      <div className={`${classPrefix}-header-left`}>
        {dragProvided?.dragHandleProps ? 
          <Grabber {...dragProvided.dragHandleProps} className={grabberClassName} />
        : null}

        {!option?.notSaved ?
          <div className={`${classPrefix}-header-title-values`}>
            <div className={`${classPrefix}-header-title`}>{option?.title || ''}</div>
            <div className={`${classPrefix}-header-values-wrapper`}>
              {(option?.values || []).map((v, index) => 
                <div className={`${classPrefix}-header-value`} key={index}>{v?.value || ''}</div>
              )}
            </div>
          </div>
        :
          <div className={`${classPrefix}-header-title-values`}>
            <div className={`${classPrefix}-title`}>
              <div className={`${classPrefix}-title-h header-font-s`}>
                Название опции
              </div>
              <div className={`${classPrefix}-title-input`}>
                <MyInput
                  inputRef={titleInput}
                  tabIndex={tabIndex}
                  value={title || ''}
                  onChange={(e) => titleHandler(e.target.value)}
                  onFocus={() => search && setShowData(true)}
                  noPlaceholder
                  withBlur={false}
                />
                
                <PossibleValuesPopup
                  clickHandler={possibleNameHandler} 
                  dataLoading={dataLoading} 
                  data={data}
                  withAddNew={addNewOptionHandler}
                  search={search}
                  showData={showData} 
                  setShowData={setShowData}
                />
              </div>
            </div>
          </div>
        }

        {!option?.notSaved ?
          <MyButton
            tabIndex={-1}
            onClick={editBtnHandler}
            className={`${classPrefix}-context-btn edit-btn`}
          >
            <i className='icofont-ui-edit'></i>
          </MyButton>
        :
          <MyButton
            tabIndex={-1}
            onClick={deleteOption}
            className={`${classPrefix}-context-btn delete-btn`}
          >
            <i className="icofont-ui-delete"></i>
          </MyButton>
        }
      </div>
    </div>
  )
}