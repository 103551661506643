import React, {useEffect, useState} from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import useVatValues from 'src/scripts/hooks/use-vat-values';
import SelectInput from 'src/components/_utility-components/select-input/select-input';
import { ProductInputHandler } from '../product';
import { Id } from 'merchery-lib';

function VatValueSelect({
  inputHandler,
}: {
  inputHandler: ProductInputHandler
}) {
  const vatValues = useVatValues();
  const product = useAppSelector(state => state.product);
  const [selectedVat, setSelectedVat] = useState<Id | null>(product?.vat || null)

  useEffect(() => {
    if(vatValues && vatValues?.[0]?.id !== selectedVat) {
      setSelectedVat(product?.vat || vatValues?.[0]?.id || null)
    }
  }, [vatValues])

  useEffect(() => {
    if(product?.vat && product.vat !== selectedVat) {
      setSelectedVat(product.vat)
    }
  }, [product])

  useEffect(() => {
    if(product?.vat !== selectedVat) {
      inputHandler('vat', selectedVat)
    }
  }, [selectedVat])

  if(!vatValues) {
    return null
  }

  return (
    <SelectInput 
      selectedItem={vatValues.find(value => value.id === selectedVat)}
      items={vatValues} 
      inputProps={{ shortInput: true }}
      valueLabelName={'value'}
      selectHandler={value => setSelectedVat(value.id)}
    />
  )
}

export default VatValueSelect;