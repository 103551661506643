import React from 'react';
import { Id } from 'merchery-lib';
import { ProductItemAttributes, ProductItemAttributesProps } from './attributes';

export interface ProductItemNameProps extends ProductItemAttributesProps {
  id: Id,
  name: string, 
  disabled: boolean,
}

function ProductItemName(props: ProductItemNameProps) {
  const { disabled, name } = props;

  return (
    <div>
      <div>{name}</div>

      {!disabled ? 
        <ProductItemAttributes
          {...props}
        /> 
      : null}
    </div>
  );
}

export default ProductItemName;