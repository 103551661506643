import React, { useEffect } from 'react';
import { MainRouteChild } from '../../main-page';
import RefundHeader from './refunds-header';
import RefundTable from './refunds-table';

interface Props extends MainRouteChild {

}

function Refunds({
  setCurrentPage
}: Props) {

  useEffect(() => {
    setCurrentPage('refunds');
  }, [])

  return (
    <div className={`refund-wrapper`}>
      <RefundHeader/>

      <RefundTable/>
    </div>
  );
}

export default Refunds;