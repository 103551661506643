import {SortableDataConfig, SortableDataHandler} from "../../../scripts/hooks/use-sortable-data";
import MyButton from "../button/button";
import React from "react";
import SortingArrows from "../sorting-arrows/sorting-arrows";

export type HeaderCellProps<T> = SimpleHeaderCellProps | SortableHeaderCellProps<T>
export interface SimpleHeaderCellProps {
  children: string | JSX.Element,
  className?: string, 
}
export interface SortableHeaderCellProps<T> {
  children: string,
  label: keyof T | string,
  className?: string, 
  config: SortableDataConfig<T> | undefined,
  handler: SortableDataHandler<T>
}

export default function HeaderCell <T>(props: HeaderCellProps<T>) {
  const {
    children,
    className,
  } = props;

  if ('label' in props) {
    const { label, config, handler } = props as SortableHeaderCellProps<T>;

    return (
      <div className={`table__cell table__header-cell clickable ${className || ''}`}>
        <div className='table__cell-inner'>
          <MyButton
            removeDefaultClass
            className='wide-text-font-xxs'
            onClick={() => handler(label)}
          >
            {children}
          </MyButton>

          <SortingArrows 
            label={label as string}
            direction={config?.direction} 
            currentSortingLabel={config?.key as string | undefined}
            handler={direction => handler(label, direction)}
          />
        </div>
      </div>
    )
  } 
  
  return (
    <div className={`table__cell clickable ${className || ''}`}>
      <div className='table__cell-inner'>
        {children}
      </div>
    </div>
  )
}