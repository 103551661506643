/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext} from 'react';
import { uuidv4 } from '../../../../../../scripts/functions';
import MultiSelect from '../../../../../_utility-components/multi-select/multi-select';
import { CharScopeDto, MSValueAsCheckboxOption, MultiSelectDto, MultiSelectValueDto, Id } from 'merchery-lib';
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";

interface Props {
  myClassName: string,
  currentShownScope: CharScopeDto | null,
  thisMultiSelect: MultiSelectDto,
  scopeCreate: (props: Partial<CharScopeDto>) => CharScopeDto | false,
}

function CharLabelMultiSelect({
  myClassName = '',
  currentShownScope,
  thisMultiSelect,
  scopeCreate,
}: Props) {
  const {
    multiSelects,
    multiSelectNames: names,
    multiSelectValues: values,
    setMultiSelectValues,
  } = useContext(CharacteristicsContext)

  const thisMultiSelectValues: MSValueAsCheckboxOption[] =
    thisMultiSelect.names
      .map((nameId) => ({
        name_id: nameId,
        value: values.find(v => v.scope_id === currentShownScope?.id && v.name_id === nameId)?.value || false,
        name: names.find(n => n.id === nameId)?.name || ''
      }))

  const valueChange = (nameId: Id) => {
    
    if(!currentShownScope) {
      const newScope = scopeCreate({ value: null, value_id: thisMultiSelect.id })
      if(!newScope) return false;

      const newMSValue: MultiSelectValueDto = {
        id: uuidv4(),
        multiselect_id: thisMultiSelect.id,
        scope_id: newScope.id,
        name_id: nameId,
        value: true
      }

      setMultiSelectValues([...values, newMSValue])
      return false
    }

    const valueExist = values.some(v => v.name_id === nameId && currentShownScope.id === v.scope_id)

    if(valueExist) {
      const updatedValues = values.map(v => {
        if( v.name_id !== nameId || v.scope_id !== currentShownScope.id ) {
          return v
        }
        return {...v, value: !v.value}
      })

      setMultiSelectValues([...updatedValues])
    } else {
      const newMSValue: MultiSelectValueDto = {
        id: uuidv4(),
        multiselect_id: thisMultiSelect.id,
        scope_id: currentShownScope.id,
        name_id: nameId,
        value: true
      }

      setMultiSelectValues([...values, newMSValue])
    }
  }

  if(!thisMultiSelectValues.length) {
    return null
  }

  return (
    <div className={`${myClassName} char-label__multi-select`}>
      <MultiSelect
        handler={valueChange}
        selects={thisMultiSelectValues}
      />
    </div>
  );
}

export default CharLabelMultiSelect;