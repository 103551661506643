import React from 'react';
import { Id, Order } from 'merchery-lib';
import SetStatus from '../../order-modules/popups/set-status';
import { ChangeOrderHandler } from '../table';

import {BodyCell} from "../../../../_utility-components/common-table/body-cell";

interface Props {
  order: Order,
  changeOrder: ChangeOrderHandler
}

export function OrderRowStatus({
  order,
  changeOrder
}: Props) {
  const statusHandler = (statusId: Id) => {
    changeOrder([{
      id: order.id, 
      status: statusId,
    }])
  };

  return <BodyCell myClassName="tt-status header-font-s">
    <SetStatus order={order} 
      statusHandler={statusHandler}
    />
  </BodyCell>;
}