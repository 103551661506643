import React, {useState} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import MyInput from '../_utility-components/input/index';
import { addMessage, validateResponse } from '../../scripts/functions';
import MyButton from '../_utility-components/button/button';
import successfulRecovery from '../../img/successfull-recovery.svg'
import { useLoad } from '../../scripts/hooks/use-load';
import { mercheryFetch } from '../../scripts/fetchConstructor';
import useMounted from '../../scripts/hooks/use-mounted';

function PasswordRecoveryForm ({

}: RouteComponentProps) {
  const _isMounted = useMounted();
  const [email, setEmail] = useState('');
  const [recoveryIsDone, setRecoveryIsDone] = useState(false);
  const [, setload] = useLoad();

  const sendRequest = () => {
    let pattern = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
    if(!email || !pattern.test(email)) {
      addMessage('#recovery .merchery-label__inner', 'Некорректный email')
      return false
    }

    setload(true)

    mercheryFetch<boolean>('auth/pass_recovery', 'POST', {
      email: email
    })
    .then((res) => {
      if(!_isMounted.current) {
        return false
      }
      if(validateResponse(res)) {
        setRecoveryIsDone(true)
      } else {
        addMessage('.sign-in-form h1', res.message)
      }
    })
    .finally(() => {
      setload(false)
    })
  };


  if(recoveryIsDone) {
    return <div className="recovery-done">
      <h2>Отлично!</h2>
      <div className="picture-div"><img alt="завершено" src={String(successfulRecovery)}/></div>
      <div className="text-div">Проверьте почту</div>
    </div>
  }
  return (
    <div
      id="recovery"
      className="sign-in-form recovery"
    >
      <h1>Восстановление пароля</h1>
      <MyInput
        name="email"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
          onEnter={sendRequest}
          value={email}
      />

      <MyButton
        type="button"
        className="blue-btn"
        onClick={sendRequest}
      >Сменить пароль</MyButton>

      <Link className="password-recovery-link" to="/sign-in">Вернуться на страницу входа</Link>
    </div>
  )
}

export default PasswordRecoveryForm