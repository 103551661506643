import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import { batch, useDispatch } from 'react-redux';
import { Id, Category } from "merchery-lib";
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { uuidv4, validateResponse } from 'src/scripts/functions';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { useTabIndex } from 'src/scripts/hooks/use-tabindex';
import MyButton from '../../_utility-components/button/button';
import CategoriesTree from './tree';
import useCollectionsChangesListener from "../../../ws-listeners/collections.listener";
import PageIsOutOfDate from "../../_utility-components/page-is-out-of-date/page-is-out-of-date";

interface Props {
  categoryHandler: CategoryFilterChanger,
  closePopupParent?: () => void,
  filtersMod: boolean,
  withNoCategory: boolean,
  noCategoryOptionText?: string,
  additionalFilters?: React.ReactNode,
}

function CategoriesFilterAndControl ({
  categoryHandler,
  closePopupParent,
  filtersMod,
  withNoCategory,
  noCategoryOptionText = 'Без учета категорий',
  additionalFilters,
}: Props) {
  const _isMounted = useRef(true)
  const tabIndex = useTabIndex()

  const categories = useAppSelector(state => state.categories)
  const firstCategory = useMemo(() =>
    categories &&
    categories.find(c => c.top === 0)
  , [categories])

  const dispatch = useDispatch();
  const categoriesDispatch = (categories: Category[]) =>
    dispatch({
      type: 'CATEGORIES',
      payload: categories
    })
  const setInlineNameEditing = (id: Id | null) =>
    dispatch({
      type: 'INLINE_CHANGING_CATEGORY',
      payload: id
    })

  const addCategory = useCallback((top: Id): Category | false => {
    if(!categories || categories.filter(c => c.newCategory).length){
      return false
    }

    const arrayOfOrders = [
      ...categories
        .filter(c => c.top === top)
        .map((v) => v.order),
      0
    ];
    const maxOrder = Math.max.apply(null, arrayOfOrders);
    const newOrder = maxOrder + 1;

    const uuid = uuidv4()
    const newCategory: Category = {
      id: uuid,
      name: '',
      show_date: null,
      newCategory: true,
      top: top,
      order: newOrder,
      nav: '',
      UUID: uuid,
      description: '',
      sorting_id: 1,
      seo_title: null,
      seo_description: null,
      og_title: null,
      og_description: null,
      deleted: null
    }

    batch(() => {
      categoriesDispatch([...categories, newCategory])
      setInlineNameEditing(newCategory.id)
    })
    return newCategory
  }, [categories, categoriesDispatch])

  useEffect(() => {
    _isMounted.current = true

    if(!categories?.length) {
      mercheryFetch<Category[]>('category','GET')
      .then((res) => {
        if(!_isMounted.current || !validateResponse(res)) return false;

        categoriesDispatch(res.records)
      })
    }
      
    return () => {
      _isMounted.current = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [changesInQueue, applyRenderChanges] = useCollectionsChangesListener()

  const disableDraggable = useMemo((() =>
    Boolean(changesInQueue.length))
  , [changesInQueue])

  return (
    <div className={`categories-tree__wrapper`}>
      {firstCategory ?
        <>
          {withNoCategory ?
            <div className="categories-tree__group categories-tree__single-option">
              <MyButton className="categories-tree__selected-item"
                        removeDefaultClass
                        tabIndex={tabIndex}
                        onClick={() => categoryHandler(undefined)}
              >
                {noCategoryOptionText}
              </MyButton>
            </div>
          : null}

          <div className="categories-tree__group categories-tree__single-option">
            <MyButton className="categories-tree__selected-item"
              tabIndex={tabIndex}
              removeDefaultClass
              onClick={() => categoryHandler(+firstCategory.id)}
            >
              {firstCategory.name}
            </MyButton>

            {!filtersMod ?
              <MyButton className={'categories-tree__add-new-btn'}
                tabIndex={tabIndex}
                removeDefaultClass
                onClick={() => addCategory(+firstCategory.id)}
              >
                <i className="icofont-plus"></i>
              </MyButton>
            : null}
          </div>
        </>
      : null}

      <div className='categories-tree__group'>
        <CategoriesTree
          closePopupParent={closePopupParent}
          addCategory={addCategory}
          categoryHandler={categoryHandler}
          filtersMod={filtersMod}
          disabledReordering={disableDraggable}
        />
      </div>

      {filtersMod
        ? <div className='categories-tree__group'> {additionalFilters} </div>
        : null}

      {changesInQueue.length ?
        <PageIsOutOfDate
          condition={Boolean(changesInQueue.length)}
          actionHandler={applyRenderChanges}
        />
      : null}
    </div>
  );
}

export default CategoriesFilterAndControl;

export type CategoryFilterChanger = (categoryId: Id | undefined) => void