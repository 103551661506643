import { useEffect, useRef } from 'react';

function useMounted() {
  const _isMounted = useRef(true);

  useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    };
  }, []);

  return _isMounted;
}

export default useMounted