import React, { useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { Id } from "merchery-lib";;
import { useAppSelector } from "../../../../../scripts/pre-type/use-selector";
import { usePopup } from "../../../../../scripts/hooks/use-popup";
import { ManagerBtn } from "../../orders-modules/header-manager-filter";

// const nullAdmin = {
//   id: null,
//   name: 'Без менеджера', 
//   color: 'white', 
// } as Admin

interface Props {
  admin_id: Id | null
  changeManager: (managerId: Id | null) => void,
  className?: string
}

function SetManager({
  admin_id,
  changeManager,
  className
}: Props) {

  const managers = useAppSelector(state => state.admin)
  const manager = useMemo(() => managers && managers.find(m => m.id === admin_id), [managers, admin_id])

  const {RenderButton, RenderPopup, closePopup, isOpen} = usePopup()

  if(!managers) {
    return <div></div>
  }
  
  const popupElementHandler = (manager: Id | null) => {
    changeManager(manager)
    closePopup();
  }

  const toolTipPlace = window.outerWidth > 1024 ? 
    (window.outerWidth > 1200 ? 'right' : 'top') 
    : (window.outerWidth < 501 ? 'top' : 'right');

  return (
    <div
      className={`${className ? className : ''} select-btn`}
    >
      <RenderButton
        className="manager-icon-wrapper"
        removeDefaultClass
      >
        <div className={`${!manager ? 'add-manager' : ''} manager-icon manager-icon_size--small`}
          style={{ 
            backgroundColor: manager?.color || 'unset', 
          }} 
          data-tip={manager?.name || ''}
          data-for={`order-manager`}
        >
          {manager ? manager.name.charAt(0) : null}
        </div>
      </RenderButton>

      <RenderPopup
        changingDirection={true} 
        tabIndexDeep={2}
        className={'table-popup manager'}
      >
        <div className="popup-group">

          <ManagerBtn manager={undefined}
            btnHandler={popupElementHandler}
          />

          {managers.map((admin) => (
            <ManagerBtn 
              key={admin.id}
              manager={admin}
              btnHandler={popupElementHandler}
            />
          ))}

        </div>
      </RenderPopup>
      
      <ReactTooltip
        id={`order-manager`}
        resizeHide={true}
        place={toolTipPlace}
        multiline={true}
        effect={"solid"}
        isCapture={true}
        className={'fff'}
      />

    </div>
  );
}

// interface ManagerBtnProps {
//   manager: Admin | undefined
//   btnHandler: (manager: Admin) => void,
// }

// function ManagerBtn ({
//   manager,
//   btnHandler,
// }: ManagerBtnProps) {
//   if(!manager) {
//     return null
//   }

//   return (
//     <MyButton className="popup-element"
//       key={manager.id}
//       removeDefaultClass
//       onClick={() => btnHandler(manager)}
//     >
//       <div className={`selected ${!manager ? 'add-manager' : ''}`} 
//         style={{backgroundColor: manager.color}}
//       >
//         {manager ? manager.name.charAt(0) : null}
//       </div>
//       <span>
//         {manager.name}
//         <div style={{backgroundColor: manager.color}}></div>
//       </span>
//     </MyButton>
//   )
// }

export default SetManager;