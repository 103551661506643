import ProductItemCheckBox from "../../../products/products-items/check-box";
import SetItemImage from "./image";
import SetItemName from "./name";
import SetItemShowProducts from './show-products'

export const setRowCells = {
  id: undefined,
  name: SetItemName,
  url: undefined,
  order: undefined,
  show_date: undefined,
  sorting_id: undefined,
  template_id: undefined,
  description: undefined,
  seo_title: undefined,
  seo_description: undefined,
  og_title: undefined,
  og_description: undefined,
  products_ids: SetItemShowProducts,
  src: SetItemImage,
  og_src: undefined,
  collections: undefined,
  newSet: undefined,
  products: undefined,
  checkbox: ProductItemCheckBox,
}