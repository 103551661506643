import React from 'react';
import { memo } from 'react';
import { useTabIndex } from '../../../scripts/hooks/use-tabindex';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  removeDefaultClass?: true,
  tabIndexLevel?: number
}

const MyButton = memo((props: Props) => {
  let {
    name, 
    className = '', 
    type = 'button',
    id,
    onClick,
    onFocus,
    onBlur,
    onKeyUp,
    tabIndexLevel = 1,
    children,
    onMouseDown,
    disabled = false,
    removeDefaultClass,
    ...rest
  } = props;

  const calculatedTabIndex = useTabIndex(tabIndexLevel);

  return (
    <button 
      type={type}
      className={`${className} ${removeDefaultClass ? '' : 'my-btn'}`}
      tabIndex={disabled ? -1 : calculatedTabIndex}
      onFocus={(e) => onFocus ? onFocus(e) : null} 
      onBlur={(e) => onBlur ? onBlur(e) : null}
      disabled={!!disabled}
      onClick={(e) => {
        if(disabled) return false
        if(onClick) onClick(e)
      }}
      onMouseDown={(e) => {
        if(disabled) return false
        if(onMouseDown && e.button === 0) onMouseDown(e)
      }}
      onKeyUp={(e) => {
        if(disabled) return false
        if(onKeyUp) onKeyUp(e);
      }} 
      {...rest}
    >
      {children}
    </button>
  );
})

export default MyButton