import React, {ReactNode} from 'react';

function MyTable({
  myClassName,
  children,
  id
}: {
  myClassName?: string,
  children: ReactNode | ReactNode[],
  id: string
}) {
  return (
    <div id={id}
      className={`table ${myClassName ? myClassName : ''}`}
    >
      {children}
    </div>
  );
}

export default MyTable;