// The useRowFocus hook is a hook that manages the focus state of a row in a table.
// It accepts an initial value and returns an object containing the currently focused item,
// a function to handle focus changes, the previously checked item,
// and a function to set the previously checked item.

import { useState, useEffect, MouseEvent } from 'react';
import useMounted from './use-mounted';
import { Id } from 'merchery-lib';

const useRowFocus = <T extends {id: Id}>(initialValue: T | null) => {
  const _isMounted = useMounted();
  const [focusedItem, setFocusedItem] = useState<T | null>(initialValue);
  const [prevChecked, setPrevChecked] = useState<Id | undefined>(undefined);

  const focusHandler = (id: Id | null, items: T[]) => {
    if(!id) {
      setFocusedItem(null);
      return false
    }

    const item = items.find(o => o.id === id);
    if (!item || focusedItem?.id === item.id) return false;

    setFocusedItem(item);
    document.body.addEventListener('mousedown', unfocus);
  };

  const unfocus = function(event: globalThis.MouseEvent) {
    if (!event.shiftKey && _isMounted.current) {
      setFocusedItem(null);
      setPrevChecked(undefined)
      document.body.removeEventListener('mousedown', unfocus);
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup function to remove event listener when component is unmounted
      document.body.removeEventListener('mousedown', unfocus);
    };
  }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount

  return { focusedItem, focusHandler, prevChecked, setPrevChecked };
};

export default useRowFocus;




export function activeCounter <T extends {id: Id}>(items: T[], selectedItems: Id[], prevChecked: Id | undefined) {
  return (e: MouseEvent, itemId: Id): [Id[], boolean] | false => {
    const multiSelect = e.shiftKey;
    let array = [...selectedItems];
  
    if (!multiSelect) {
      let counter = -1;
  
      array.forEach((el, index) => {
        if (el === itemId)
          counter = index
      })
  
      if (counter !== -1) {
        array.splice(counter, 1)
      } else {
        array[array.length] = itemId
      }

      return [array, false]
    }
  
    let focused: number = items.findIndex((obj) => obj.id === prevChecked),
      lastChecked: number = items.findIndex((obj) => obj.id === itemId),
      severalItems: Id[];
  
    const sliceStart = focused < lastChecked ? ++focused : lastChecked;
    const sliceEnd = focused < lastChecked ? ++lastChecked : focused;
  
    severalItems = items.slice(sliceStart, sliceEnd).map(item => item.id)
  
    if (!severalItems.length)
      return false;
  
    const needToAdd = !array.some((el) => el === itemId)
  
    if (!needToAdd) {
      array = array.filter((or) => !severalItems.some(el => el === or));
    } else {
      array = [...array, ...severalItems.filter(or => !array.some((el) => el === or))]
    }
  
    return [array, true]
  };
} 