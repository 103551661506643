import React, {createContext, useEffect, useMemo, useState} from 'react';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import RefundIcon from '../../../../../img/refund-icon.svg'
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { validateResponse } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import { RefundCreateOptionalData } from '../../refunds/dto/refund-create.api';
import { OrderItemsToRefund } from './order-items-to-refund';
import { OrderItemsToRefundFooter } from './order-items-to-refund-footer';
import OrderRefundCause from './refund-cause';
import { Order, RefundCauseDto } from 'merchery-lib';
import { RefundItem } from './dto/refund-item';

export const RefundStartContext = createContext<{
  refundCreateData: RefundCreateOptionalData,
  setRefundCreateData: (data: RefundCreateOptionalData) => void,
  selectedItems: RefundItem[],
  setSelectedItems: (ids: RefundItem[]) => void
}>({
  refundCreateData: {},
  setRefundCreateData: (data) => {},
  selectedItems: [], 
  setSelectedItems: () => {}
})

function RefundOrder({
  order
}: {
  order: Order
}) {
  const _isMounted = useMounted()
  const orderItems = useAppSelector(state => state.productItemsInContext)
  const refunds = useAppSelector(state => state.refunds)
  const thisOrderRefunds = useMemo(() => refunds?.filter(refund => refund.order_id === order.id), [order.id, refunds])

  const [selectedItems, setSelectedItems] = useState<RefundItem[]>([]);
  const [refundCreateData, setRefundCreateData] = useState<RefundCreateOptionalData>({
    refund_cause_id: 1,
  });

  const allItemsRefunded = useMemo(() => thisOrderRefunds && orderItems.every(item => {
    let totalReturnedCount = 0;
    thisOrderRefunds.forEach(refund => {
      refund.returned_items.forEach(returnedItem => {
        if (returnedItem.id === item.id) {
          totalReturnedCount += returnedItem.count;
        }
      });
    });
    return totalReturnedCount === item.count;
  }), [orderItems, thisOrderRefunds]);
  

  const dispatch = useDispatch()
  const refundCausesDispatch = (causes: RefundCauseDto[]) => dispatch({ type: 'ORDERS_REFUND_CAUSES', payload: causes})

  useEffect(() => {
    mercheryFetch<RefundCauseDto[]>('orders/refund/cause', 'GET')
    .then((res) => {
      if(_isMounted.current && validateResponse(res)) {
        refundCausesDispatch(res.records)
      }
    })
  }, [])

  const {RenderPopup, RenderButton, closePopup} = usePopup()

  const {client} = order

  const notDisabledRefund = Boolean(client !== null && !allItemsRefunded && order.status === 3)

  return (
    <RefundStartContext.Provider value={{
      refundCreateData,
      setRefundCreateData, 
      selectedItems, 
      setSelectedItems
    }}>
      <RenderButton 
        className={`order-page__refund-btn ${!notDisabledRefund ? 'disabled' : ''}`}
        disabled={!notDisabledRefund}
        data-tip={'Доступно только для выполненных заказов'}
        data-for={`refund-start-btn`}
      >
        <img src={String(RefundIcon)} alt={'refund'}/>
        оформить возврат
      </RenderButton>

      <ReactTooltip
        id={`refund-start-btn`}
        resizeHide={true}
        place={'top'}
        multiline={true}
        effect={"solid"}
        isCapture={true}
        className={'fff'}
      />

      {client ? 
        <RenderPopup
          className={'confirm-window fixed-on-center refund-registration '}
          withBlackout
          withCloseBtn
          tabIndexDeep={3}
        >
          <div className="refund-registration__header refund-registration__popup-element">
            <h2>{`Возврат заказа №${order.id}`}</h2>
            <div>
              {client.name}
            </div>
          </div>

          <OrderItemsToRefund/>

          <OrderRefundCause/>

          <OrderItemsToRefundFooter
            closePopup={closePopup}
            order={order}
          />
          
        </RenderPopup>
      : null} 
      </RefundStartContext.Provider>
  );
}

export default RefundOrder;