import React from 'react';
import { SubscriptionDto } from 'merchery-lib';
import { dateToRuLocale } from 'src/scripts/utils/date-to-locale';
import { BodyCell } from 'src/components/_utility-components/common-table/body-cell';

interface Props {
  subscription: SubscriptionDto
}

function SubscriptionDate({
  subscription
}: Props) {
  const localDate = dateToRuLocale(subscription.created);
  return (
    <BodyCell myClassName='center-align'>
      {localDate}
    </BodyCell>
  );
}

export default SubscriptionDate;