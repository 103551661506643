import React from 'react';

function ProductItemEmptyGap() {

  return (
    <div className="row-item__empty-gap">
    </div>
  );
}

export default ProductItemEmptyGap;