import React, {MouseEvent, ReactElement, useCallback, useMemo, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { batch, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Id, Order } from "merchery-lib";
import { scrollPageTo, validateResponse } from 'src/scripts/functions';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import useRowClick from '../../../../scripts/hooks/use-row-click';
import { useTabIndex } from 'src/scripts/hooks/use-tabindex';
import OrdersLoader from '../../../_utility-components/loaders/orders-loader';
import { ClearAndSetIndexDb } from '../../main-page';
import { OrderRowClient } from './order-row/client';
import { OrderRowConfig } from './order-row/config';
import { OrderRowCost } from './order-row/cost';
import { OrderRowDate } from './order-row/date';
import { OrderRowDelivery } from './order-row/order-row-delivery';
import { OrderRowIdAndCheck } from './order-row/id-and-check';
import { OrderRowManager } from './order-row/manager';
import { OrderRowStatus } from './order-row/status';
import { OrderTableHeader } from './table-header';
import SelectedOrdersMenu from '../popups/selected-orders-menu';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import OrderRowRefund from './order-row/refund';
import useRowFocus, { activeCounter } from 'src/scripts/hooks/use-row-focus';
import useMounted from 'src/scripts/hooks/use-mounted';
import useInfiniteScrollShowMore from 'src/scripts/hooks/use-infinite-scroll-show-more';
import CommonTableLoader from '../../../_utility-components/loaders/common-table-loader';
import MyTable from "../../../_utility-components/common-table/table";
import useSortableData from "../../../../scripts/hooks/use-sortable-data";
import useOrderFilters from "../hooks/use-order-filters";
import {AppliedFilters} from "../../../_utility-components/applied-filters";
import {TableBody} from "../../../_utility-components/common-table/table-body";
import {BodyRow} from "../../../_utility-components/common-table/body-row";
import useInitFilters, {EntityGetter, entityGetterQuery} from "../../../../scripts/hooks/use-init-filters";
import OrdersFilters from "./orders-filters";

interface OrderTableRowProps {
  clearAndSet: ClearAndSetIndexDb,
}

const ordersGetSize = 40;

export const OrderTable: React.FC<OrderTableRowProps> = ({
  clearAndSet,
}): ReactElement => {
  const _isMounted = useMounted();

  const searchInput: string = useAppSelector(state => state.ordersSearch);
  const [ordersFilters, updateOrdersFilters] = useOrderFilters();
  const orders = useAppSelector(state => state.orders);
  const [shownOrders, setShownOrders] = useState<Id[]>([]);

  const currentlyShownOrders = useMemo(() =>
    orders.filter(o =>
      shownOrders.some(shownId => o.id === shownId))
  , [orders, shownOrders]);

  const defaultSorting = {
    key: "id",
    direction: "descending",
  } as const;

  const [
    sortedOrders,
    requestSort,
    config
  ] = useSortableData<Order>(
    [...(currentlyShownOrders || [])],
    defaultSorting
  );

  const [selectedOrders, setSelectedOrders] = useState<Id[]>([]);
  const [allOrdersCount, setAllOrdersCount] = useState(0);
  const [currentOrdersCount, setCurrentOrdersCount] = useState(0);
  const location = useLocation<OrdersLocationState>();

  const dispatch = useDispatch();
  const searchInputDispatch = (text: string) => dispatch({ type: 'ORDERS_SEARCH_INPUT_VALUE', payload: text});
  const ordersDispatch = (items: Order[]) => dispatch({type: 'ORDERS', payload: items});

  const tabIndex = useTabIndex();
  const [load, setLoad] = useState(false)

  const orderCardLinkProps = (id: Id) => ({
    to: `/app/orders/${id}`,
    state: {prevPage: location.search}
  })

  const [rowClick, setActiveRowId] = useRowClick(
    orderCardLinkProps,
    ['.tt-select-outside', '.tt-config', '.tt-status', '.tt-manager', '.fixed-on-center', '.client-info-popup-open-btn', '.client-info-popup']
  );
  const {focusedItem, focusHandler, prevChecked, setPrevChecked} = useRowFocus<Order>(null);

  const initialCount = {
    prev: 0,
    next: 40
  };
  const [count,] = useState(initialCount);

  const getOrders: EntityGetter = ({
    filters,
  }) => {
    const queryFilters = entityGetterQuery({
      ...(filters && {
        filters,
      }),
      ...(searchInput && {
        search: searchInput,
      }),
      pageSize: ordersGetSize
    })

    setLoad(true)

    mercheryFetch<Order, true>(`orders?${queryFilters}`, 'GET')
      .then(async (res) => {
        if (!_isMounted.current || !validateResponse<Order, true>(res)) {
          return false
        }

        const gotOrders = res.records.rows;
        await clearAndSet('orders', gotOrders);

        const allOrdersCount = res.records.count;
        const a = toCurrentOrder(gotOrders, location.state);
        const shownOrders = gotOrders.slice(count.prev, count.next) || [];

        batch(() => {
          setShownOrders(shownOrders.map(order => order.id));
          ordersDispatch(gotOrders);
          setAllOrdersCount(allOrdersCount);
          setCurrentOrdersCount(allOrdersCount);
          focusHandler(a.orderId || null, orders);
          setPrevChecked(a.orderId);
        })

        if (a.orderId) {
          scrollPageTo(`#orders-table [orderid="${a.orderId}"]`)
        }

        return res
      })
      .finally(() => {
        setLoad(false)
      })
  }

  const [hasMore, showMore] = useInfiniteScrollShowMore({
    getItems: getOrders,
    currentCount: currentOrdersCount,
    getSize: ordersGetSize,
    allCount: allOrdersCount,
  })

  const orderActiveCounter = useCallback((e: MouseEvent, itemId: Id) => {
    const result = activeCounter(orders, selectedOrders, prevChecked)(e, itemId)
    if(!result) {
      return;
    }

    const [newSelectedItems, isMultiSelect] = result; 

    if(!isMultiSelect) {
      batch(() => {
        setSelectedOrders(newSelectedItems)
        setPrevChecked(focusedItem?.id)
      })
    } else {
      setSelectedOrders(newSelectedItems)
    }
  }
  , [orders, selectedOrders, prevChecked])

  const orderCheckHandler: (e: React.MouseEvent, el: Order) => void = (e, el) => {
    orderActiveCounter(e, el.id);
    if (!e.shiftKey) focusHandler(el.id, orders)
  };

  const toCurrentOrder = (orders: Order[], state: OrdersLocationState) => {
    const orderId = state && state.toOrder
    if (!orderId) return {};

    const orderIndex = orders.findIndex((o) => o.id === orderId);
    if (orderIndex === -1) return {};

    let count = initialCount;
    let i = 1;

    while (i) {
      const iterator = count.next;
      if (i - 1 === count.next) {
        count.next += iterator
      }

      i = i - 1 === orderIndex ? 0 : i + 1
    }

    return {
      count,
      orderId
    }
  }

  const clearSelectedOrders = () => setSelectedOrders([]);

  useInitFilters(
    {
      filters: ordersFilters,
      searchInput,
      setSearchInput: searchInputDispatch,
      getEntity: getOrders,
      updateFilters: updateOrdersFilters
    }
  )

  const changeOrder: ChangeOrderHandler = (changes) => {
    changes.forEach(ch => {
      if (ch.date !== undefined)
        delete ch.date
    })

    const ordersBackup = [...orders]

    const changedOrders = orders.map(order => {
      const thisOrderChanges = changes.find(ch => ch.id === order.id)
      if (thisOrderChanges) {
        return {...order, ...thisOrderChanges}
      }
      return order
    })

    ordersDispatch(changedOrders)

    mercheryFetch<Order[]>(`orders`, 'PATCH', {
      changes: changes
    })
    .then((res) => {
      if (!_isMounted.current || !validateResponse(res)) {
        ordersDispatch(ordersBackup)
        return false
      }
      clearAndSet('orders', changedOrders)
    })
  }

  const deleteOrder = (ids?: Id[]) => {
    setLoad(true)

    const idsToDelete = ids || selectedOrders;

    mercheryFetch<Order[]>('orders', 'DELETE', {
      id: idsToDelete
    })
      .then((res) => {
        if (!_isMounted.current || !validateResponse(res)) {
          return false
        }

        batch(() => {
          clearSelectedOrders()
          ordersDispatch(
            orders.filter(order => 
              !idsToDelete.some(id => id === order.id))
          )
        })
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setLoad(false)
      })
  };

  return (
    <>
      {selectedOrders.length ?
        <SelectedOrdersMenu
          deleteOrder={deleteOrder}
          changeOrder={changeOrder}
          clearSelectedOrders={clearSelectedOrders}
          selectedOrders={selectedOrders}
        />
      : null}

      <OrdersFilters
      />

      <AppliedFilters<Order>
        filterNames={['status', 'admin_id', 'client_id']}
        filters={ordersFilters}
        updateFilters={updateOrdersFilters}
      />

      {load ?
        <CommonTableLoader/>
      :
        <InfiniteScroll
          dataLength={orders.length}
          next={showMore}
          hasMore={hasMore}
          loader={ Array(10).map(() => <OrdersLoader/>) }
          scrollableTarget={<>{window}</>}
        >
          {sortedOrders.length ?
            <MyTable id={"orders-table" } myClassName={!sortedOrders.length ? 'hide-table' : ''}>
              <OrderTableHeader
                config={config}
                requestSort={requestSort}
              />

              <TableBody>
              {sortedOrders.map((order) => {
                const focused = focusedItem && focusedItem.id === order.id ? 'focused' : '';
                const active = selectedOrders.filter(s => s === order.id).length ? 'active' : '';

                return (
                  <BodyRow
                    key={order.id} // @ts-ignore: Unreachable code error
                    orderid={order.id}
                    className={`orders-table__cells-template ${active} ${focused}`}
                    onFocus={() => focusHandler(order.id, orders)}
                    tabIndex={tabIndex}
                    onMouseUp={(e) => rowClick(e, order.id)}
                    onMouseDown={() => setActiveRowId(order.id)}
                    onKeyUp={(e) => e.key === 'Enter' ? rowClick(e, order.id) : null
                  }>
                    <OrderRowIdAndCheck orderCheckHandler={orderCheckHandler} order={order}/>
                    <OrderRowDate order={order} />
                    <OrderRowClient order={order} />
                    <OrderRowDelivery order={order} />
                    <OrderRowCost order={order} />
                    {/* <OrderRowPayment order={order}/> */}
                    <OrderRowStatus order={order} changeOrder={changeOrder}/>
                    <OrderRowRefund order={order} />
                    <OrderRowManager order={order} changeOrder={changeOrder}/>
                    <OrderRowConfig order={order} linkProps={orderCardLinkProps} deleteOrder={deleteOrder}/>
                  </BodyRow>
                )
              })}
              </TableBody>
            </MyTable>
          : 'Заказов с заданными фильтрами не найдено'
          }
        </InfiniteScroll>
      }
    </>
  );
};


export type ChangeOrderHandler = (changes: Array<Partial<Order> & {id: Id}>) => void

export interface OrdersLocationState {
  toOrder?: Id
}