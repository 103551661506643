import React from 'react';
import MyInput from '../../_utility-components/input/index';
import {MyInputProps} from "../../_utility-components/input/input";

interface SiteSettingsGroupColumnProps extends Omit<MyInputProps, 'onChange'> {
  value: string | number;
  edit: boolean;
  header: string;
  onChange: (value: string) => void;
}

function SiteSettingsGroupColumn({
  value,
  edit,
  onChange,
  header,
  ...rest
}: SiteSettingsGroupColumnProps) {

  return (
    <div className='site-settings__group-column'>
      <h4>{header}</h4>

      {edit ?
        <MyInput 
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          {...rest}
        />
      : <span>{value}</span>}
    </div>
  );
}

export default SiteSettingsGroupColumn;