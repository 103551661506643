import $ from 'jquery';
import qs from 'qs';
import React, { CSSProperties } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoyaltyRange, LoyaltySettingsBuyoutRanges } from '../components/main-pages/clients/dto/loyalty-range.dto';
import { CustomError, HttpException, MoySkladPrice, MyResponse, SuccessResponse } from 'merchery-lib';
import { Id } from 'merchery-lib';
import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const toastUp = (messageTextOrError: string | Error) => {
  let element: Element | null = document.querySelector('.merchery-wrapper');
  if(!element) return false

  element.classList.add('has-error'); 

  const errorContent: React.ReactNode | string = messageTextOrError instanceof Error ? 
    (<div title={messageTextOrError.stack}>{messageTextOrError.message}</div>)
    : messageTextOrError;
  
  let errorId = toast.error(errorContent, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    progress: 0,
  })

  return errorId;
};

const toastDown = (selector: string, errorId?: Id) => {
  const element = document.querySelector(selector);
  
  if(element?.classList.contains('has-error')) {
    element.classList.remove('has-error');
  }
  if(errorId) toast.dismiss(errorId)
  else toast.dismiss();
};

const addMessage = (selector: string | Element, messagetext: string, parent = false, isNotError: boolean = false) => {
  try {
    let element: Element | null = selector instanceof Element ? selector : document.querySelector(selector);
    if(!element) return  false
    if(parent) element = element.parentElement;
    if(!element) return  false

    element.setAttribute('messagetext', messagetext);
    element.classList.add(!isNotError ? 'has-error' : 'has-message');
  
    setTimeout(removeMessage, 100000, selector)
  } catch (error) {
    console.log(error)
  }
};

const removeMessage = (selector: string | Element, parent = false) => {
  let element = selector instanceof Element ? selector : document.querySelector(selector);
  if(!element) return false;
  if(parent) element = element.parentElement;
  if(!element) return  false
  
  element.setAttribute('messagetext', '');

  element.classList.remove('has-message')
  element.classList.remove('has-error')
};

const numWithSep = <T extends unknown,>(number: T): T | string => {
  if(typeof number !== 'string' && typeof number !== 'number') {
    return number
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const createCurrencySymbol = (cur: string) => {// eslint-disable-next-line
  let sym;
  switch (cur) {
    case 'RUB': return sym = '₽';

    case 'USD': return sym = '$';

    case 'UAH': return sym = '₴';

    case 'EUR': return sym = '€';
// eslint-disable-next-line
    default: return sym = cur
  }
};

const ptsansCurrency = (text: string, style?: CSSProperties) => {
  return <span className="currency-pt-sans" style={style}>{text}</span>
};

const num2str = (n: number, text_forms: string[], stx?: string[]) => {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  const func = (num: number) => `${stx ? stx[num]+' ' : ''}${n} ${text_forms[num]}`
  
  if(n > 10 && n < 20) return func(2)
  // eslint-disable-next-line 
  return n1 > 1 && n1 < 5 ? func(1) : n1 == 1 ? func(0) : func(2)
};

export interface EntityWithPrice {
  [key: string]: any;
  price: number | MoySkladPrice[];
  count: number;
}

const calcPrice = <T extends EntityWithPrice[],>(array: T) => {
  let r = 0;

  for (const el of array) {
    if(!el.price){
      continue
    }

    const price = typeof el.price === 'number'
      ? +el.price
      : el.price.find(msPrice => msPrice.currency.isoCode === 'RUB')?.value || 0;

    r += priceWithPennies(price * +el.count)
  }

  return r.toFixed(2);
};

function priceWithPennies (price: any): any {
  if(typeof price !== 'number') {
    return price
  }
  // return price
  return price / 100
}

function priceToPennies (price: any): any {
  if(typeof price !== 'number') {
    return price
  }
  // return price
  return price * 100
}

function kgToGram (weight: any): any {
  if(typeof weight !== 'number') {
    return weight
  }
  return weight
  // return weight * 1000 

}

function gramToKg (weight: any): any {
  if(typeof weight !== 'number') {
    return weight
  }
  return weight
  // return weight / 1000
  
}

const bodyOnMouseDown = (func: () => void, selector: string) => {
  const ignoreList = ['.Toastify__toast-container'];

  $('body').on('mousedown', function(event) {
    const target = $(event.target),
          ignoreClick = ignoreList.some(item => target.closest(item).length),
          noClosest = !target.closest(selector).length,
          notSelector = !$(event.target).is(selector);

    if(!ignoreClick && noClosest && notSelector) {
      func();
      event.stopImmediatePropagation();
    }
  });
  // @ts-ignore: Unreachable code error
  const bodyEvents = $._data($('body')[0], "events");
  bodyEvents.mousedown.unshift(bodyEvents.mousedown.pop())
  bodyEvents.mousedown.length = 1; 
  if(bodyEvents.keydown) bodyEvents.keydown.length = 1; 
};

const bodyOffBoundFunc = (func?: () => void) => {
  $('body').off('mousedown')
		.off('mouseout')
		.off('keydown');
  if(func) func();
};

const bodyOnMouseOut = (func: () => void, selector: string, closest: string | Element) => {
  $('body').on('mouseout', function(event) {
    if(!$(event.target).closest($(selector).closest(closest)).length && !$(event.target).is(selector)) {
      func();
      event.stopImmediatePropagation();
    }
  });
};

const bodyOnEscEnter = (func: () => void) => {
  $('body').on('keydown', function(event) {
    if(event.key === "Escape") {
      func();
      event.stopImmediatePropagation();
    }
  });
  // @ts-ignore: Unreachable code error
  const bodyEvents = $._data($('body')[0], "events");
  bodyEvents.keydown.unshift(bodyEvents.keydown.pop())
  bodyEvents.keydown.length = 1; 
  if(bodyEvents.mousedown) bodyEvents.mousedown.length = 1; 
};

const currencyFont = (cur:string ) => {
  return <span className="currency-pt-sans">{cur}</span>
};

const getCoords = (elem: Element) => {
  const box = elem.getBoundingClientRect();

  return {
    top: box.top + window.pageYOffset,
    left: box.left + window.pageXOffset,
    right: box.right + window.pageXOffset,
    bottom: box.bottom + window.pageYOffset,
    width: box.width,
    height: box.height
  };
};

const filterBySeveralId = <T extends { [key: string]: any },>(arrayOfElement: T[], arrayOfKeys: string[], arrayOfValues: any[]) =>
  arrayOfElement.filter((element) => arrayOfKeys.every((key, keyIndex) => element[key] === arrayOfValues[keyIndex]));


const arrayOfSelectedFilters = <T extends {id: Id | null, name: string, type?: any}>
(array: T[], currentfilter?: string | string[])
  : {id: Id | null, selected: boolean, text: string, type: undefined | any}[] => {
  const checkFilter = (
    v: string,
    el: T
  ) =>
    v === el.name ||
    v === el.id;

  return array.map((el) => ({
    id: el.id,
    selected: currentfilter !== undefined && (Array.isArray(currentfilter) ? currentfilter.some(f => checkFilter(f, el)) : false),
    text: el.name,
    type: el?.type,
  }))
};

const getToday = () => {
  let today = new Date(),
        dd: string | number = today.getDate(), 
        mm: string | number = today.getMonth()+1,
        yyyy = today.getFullYear();

  if(dd < 10) dd='0'+dd;
  if(mm < 10) mm='0'+mm; 
  return dd+'-'+mm+'-'+yyyy;
};

const scrollPageTo = (element: string ) => {
  const d = document.querySelector(element)
  if(d) d.scrollIntoView({block: "center", behavior: "smooth"});
};

const printPage = () => window.print();

const getSelectedText = () => window.getSelection()?.toString() || '';

const arraymove = <T,>(arr: T[], fromIndex: number, toIndex: number): T[] => {
  if (toIndex === fromIndex || toIndex >= arr.length) return arr;

  const toMove = arr[fromIndex];
  const movedForward = fromIndex < toIndex;

  return arr.reduce<T[]>((res, next, index) => {
    if (index === fromIndex) return res;
    if (index === toIndex) return res.concat(movedForward ? [next, toMove] : [toMove, next]);

    return res.concat(next);
  }, []);
};

function uniqByKeepLast<T>(a: T[], key: (first: T) => void) {
  return [
    ...new Map(
      a.map(x => [key(x), x])
    ).values()
  ]
}

function getObjectsDiffsByFields <T extends {[name: string]: any}>(obj1: T, obj2: T | undefined, arrOfFields: string[]): Partial<T> {
  if(typeof obj1 !== typeof obj2) return {...obj1}
  if(!obj1 || !obj2) return {}

  const difference: Partial<T> = {}

  arrOfFields.forEach(field => {
    if(JSON.stringify(obj1[field]) !== JSON.stringify(obj2[field])) {
      difference[field as keyof T] = obj1[field]
    }
  })

  return difference
}

export interface TwoArraysDiffs<T> {
  changes: (Partial<T> & {id: Id})[];
  added: T[];
  deleted: Id[];
}
function getArrayOfObjectsChanges <T extends {id: Id, [name: string]: any},>(arrFirst: T[], arrSec: T[], arrOfFields: string[]): TwoArraysDiffs<T> {
  try {
    const deleted = 
      arrSec
        .filter(sv => sv && !arrFirst.some(s => s.id === sv.id))
        .map(item => item.id);
    const added = arrFirst.filter(s => !arrSec.some(sv => sv && s.id === sv.id));
    const changes: (Partial<T> & {id: Id})[] = [];
    
    for (const v of arrFirst) {
      for (const sv of arrSec) {
        if(sv && v.id === sv.id) {
          const difference = getObjectsDiffsByFields(v, sv, arrOfFields)
          if(Object.keys(difference).length) {
            changes.push({
              id: v.id, 
              ...difference
            })
          }
        }
      }
    }

    return {
      changes,
      added,
      deleted
    }
  } catch (error) {
    console.log(error)
    throw error
  }
}
/**
 * Check if response valid 
 * @param {MyResponse} res Custom response object
 * @param {boolean} res.success True if success
 * @param {any} res.records Records from api
 * @returns {boolean} true when valid
 */
const validateResponse = <T, ByRows extends boolean = false,>(res: MyResponse<T, ByRows> | undefined): res is SuccessResponse<T, ByRows> => {
  try {
    if(!res) return false

    if('errors' in res && res.errors) {
      for (const valError of res.errors) {
        const selector = `.merchery-label__input[name="${valError.property}"]`
        if(!valError.constraints) 
          continue;

        if(document.querySelector(selector) !== null) {
          const errorsMessage = 
            valError.constraints.isNotEmpty !== undefined ||
            valError.constraints.isDefined !== undefined ? 
              'Поле обязательно к заполнению'
            : 'Некорректный ввод';

            errorsMessage && addMessage(selector, errorsMessage, true, false)
        } else {
          const errorsMessages = Object.values(valError.constraints).join()

          errorsMessages && toastUp(errorsMessages)
        }

      }
      return false
    }

    if('success' in res) {
      return res.success
    }

    return false
  } catch (error) {
    console.log(error)
    throw error
  }
}
export function errorWithResponse<T extends (HttpException | CustomError) & {response?: any}>(error: MyResponse): error is T {
  return (error as {response?: any})?.response !== undefined
}

export function errorWithStatus<T extends (HttpException | CustomError) & {status: number}>(error: MyResponse): error is T {
  return (error as {status?: any})?.status !== undefined
}
/**
 * Check if response valid 
 * @param {Object[]} reses Array of custom response objects
 * @returns {boolean} true when valid
 */
const validateMultipleResponses = <T, ByRows extends boolean = false>(reses: (MyResponse<T, ByRows> | undefined)[]): reses is SuccessResponse<any, ByRows>[] => {
  return reses && reses.every(validateResponse)
}

/**
 * Create UUID of 4 version
 * @returns {string} UUID
 */
function uuidv4() {
  // @ts-ignore: Unreachable code error
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    // eslint-disable-next-line no-mixed-operators
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

/**
 * Check if object has own props
 * @param {object} obj Any object
 * @returns {boolean} If object has its own props return true.
 */
function objOfArraysHasProps (obj: object | unknown): boolean {
  return obj && typeof obj === 'object' ? Object.values(obj).some(a => a.length) : false
}

/**
 * Separated with comma params
 * @param {object} query Object of request params
 * @returns {string} Query string
 */
const querify = (query: object) => qs.stringify(query, {arrayFormat: 'comma'})

/**
  * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  * 
  * @param {String} text The text to be rendered.
  * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  * 
  * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  */
let lCanvas: HTMLCanvasElement | undefined = undefined;

function getTextWidth(text: string, font: string) {
  // re-use canvas object for better performance
  const canvas = lCanvas || (lCanvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  if(!context) return 0
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

// function getCssStyle(element, prop) {
//     return window.getComputedStyle(element, null).getPropertyValue(prop);
// }

// function getCanvasFont(el = document.body) {
//   const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
//   const fontSize = getCssStyle(el, 'font-size') || '16px';
//   const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';
  
//   return `${fontWeight} ${fontSize} ${fontFamily}`;
// }

function formatBytes({ 
  bytes, 
  decimals = 2, 
  ru = false,
  targetUnit,
  withoutUnit,
}: { 
  bytes: number; 
  decimals?: number; 
  ru?: boolean; 
  targetUnit?: string;
  withoutUnit?: boolean;
}) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = !ru ? 
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  : ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЭБ', 'ЗБ', 'ЙБ'];

  let i = Math.floor(Math.log(bytes) / Math.log(k))
  if (targetUnit) {
    const targetIndex = sizes.indexOf(targetUnit);
    if (targetIndex !== -1) {
      i = targetIndex;
    }
  }

  const decimalText = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  const unitText = !withoutUnit ? ' ' + sizes[i] : ''

  return `${decimalText}${unitText}`
}

function findRangeOfLoyalty (num: number, arr: LoyaltySettingsBuyoutRanges | string | undefined): undefined | LoyaltyRange {
  if(!arr) {
    return undefined
  }

  if(typeof arr === 'string' ) {
    arr = JSON.parse(arr) as LoyaltySettingsBuyoutRanges
  }

  // Check if array is sorted
  for(let i = 0; i < arr.length - 1; i++) {
    if(arr[i].to > arr[i + 1].to) {
      return undefined;
    }
  }
  
  // If num is smaller than the first element
  if(num < arr[0].to) {
    return arr[0];
  }
  
  // Find the index
  for(let i = 1; i < arr.length; i++) {
    if(arr[i - 1].to < num && num < arr[i].to) {
      return arr[i];
    }
  }
  
  // If num is larger than the last element
  if(num > arr[arr.length - 1].to) {
    return arr[arr.length - 1];
  }
  
  // If no such index found
  return undefined;
}

const priceWithStep = (price: number): string => {
  if(typeof price !== 'number') {
    return price
  }

  let priceArray = price.toString().split('');

  const decimalPointIndex = priceArray.indexOf('.');

  const length = decimalPointIndex === -1
    ? priceArray.length
    : decimalPointIndex;

  for (let i = length - 3; i > 0; i -= 3) {
    priceArray.splice(i, 0, ' ');
  }

  const formattedPrice = priceArray.join('');

  return formattedPrice;
}

/**
 * Converts an RGBA alpha value to its hexadecimal representation.
 * @param {number} alpha - The alpha value between 0 and 1.
 * @returns {string} The hexadecimal representation of the alpha value.
 */
function alphaToHex(alpha: number): string {
  const roundedAlpha = Math.round(alpha * 255);
  return roundedAlpha.toString(16).padStart(2, '0');
}

/**
* Takes a hex color code and an RGBA alpha value, returning an 8-symbol hex code including alpha.
* @param {string} hexColor - The 6-digit hexadecimal color code.
* @param {number} alpha - The alpha value between 0 and 1.
* @returns {string} The 8-symbol hexadecimal color code including alpha.
*/
function addAlphaToHex(hexColor: string, alphaRgba: number): string {
  if (!/^[0-9A-F]{6}$/i.test(hexColor) || alphaRgba === 1) {
    return hexColor
  }

  // Convert the alpha value to its hexadecimal representation
  const alphaHex = alphaToHex(alphaRgba);

  // Return the combined hex color with alpha
  return `${hexColor}${alphaHex}`;
}

/**
 * Converts a hexadecimal alpha value to its decimal representation.
 * @param {string} alphaHex - The hexadecimal representation of the alpha value.
 * @returns {number} The decimal representation of the alpha value.
 */
function hexAlphaToDecimal(alphaHex: string): number {
  const decimalValue = parseInt(alphaHex, 16);
  return decimalValue / 255;
}


interface ColorInfo {
  alpha: number;
  hexColor: string;
}

/**
 * Extracts the alpha value from an 8-symbol hex color code and returns it along with the hex color without alpha.
 * @param {string} hexColorWithAlpha - The 8-symbol hexadecimal color code including alpha.
 * @returns {ColorInfo} An object containing the RGBA alpha value and the hex color without alpha.
 */
function extractAlphaAndHexWithoutAlpha(hexColorWithAlpha: string): ColorInfo {
  let alpha: number = 1;
  let hexColor = '';

  if (/^#[0-9A-F]{8}$/i.test(hexColorWithAlpha)) {
    // Extract the alpha component and convert it to decimal
    const alphaHex = hexColorWithAlpha.slice(7);
    alpha = hexAlphaToDecimal(alphaHex);
    // Remove the alpha component from the hex color
    hexColor = hexColorWithAlpha.slice(0, 7);
  } else {
    hexColor = hexColorWithAlpha;
  }

  return { alpha, hexColor };
}

export type UpdateItem<T> = Partial<T> & {id: Id}

export interface CompareResult<T extends {id: Id}> {
  changes: UpdateItem<T>[], 
  added: T[], 
  deleted: T[], 
}

/**
 * Compare two arrays and sort their elements into three categories: updated, added, removed
 * @param {T[]} [init] - Original array
 * @param {T[]} actual - Updated array
 * @param {(keyof T[])} [leaveInitFields] - In updated array fields from leaveInitFields taken from init array
 * @returns {{ updated: T[], added: T[], removed: T[] }} An object containing three arrays: updated, added, removed
 */
function compareArrays<T extends {id: Id}>({ 
  init, 
  actual, 
  leaveInitFields,
}: { 
  init: T[]; 
  actual: T[];
  leaveInitFields?: Array<keyof T>; 
}): CompareResult<T> {
  const updated: UpdateItem<T>[] = [];

  const added = differenceBy(actual, init, 'id');
  const removed = differenceBy(init, actual, 'id');

  init.forEach((initElement) => {
    const actualElement: T | undefined = find(actual, ['id', initElement.id]); //Find the corresponding item in 'actual' array based on 'id'
    if(actualElement) {
      const updatedKeys = (Object.keys(initElement) as Array<keyof T>).reduce((result, key) => {
        if (!isEqual(initElement[key], actualElement[key])) {
          result[key] = actualElement[key]; // If there is a difference, set the new value
        }
        return result;
      }, {} as Partial<T>);
      if (Object.keys(updatedKeys).length > 0) {
        updated.push({ 
          id: initElement.id, 
          ...(leaveInitFields && Object.fromEntries(leaveInitFields.map(field => [field, initElement[field]]))),
          ...updatedKeys 
        }); // Push the updated item to 'updated' array
      }
    }
  });

  return { 
    changes: updated, 
    added, 
    deleted: removed, 
  };
}

export {
  arraymove,
  numWithSep,
  createCurrencySymbol,
  num2str,
  bodyOnMouseDown,
  bodyOffBoundFunc,
  currencyFont,
  getCoords,
  getObjectsDiffsByFields,
  openInNewTab,
  addMessage,
  removeMessage,
  calcPrice,
  bodyOnMouseOut,
  // tryCatchConstructor,
  arrayOfSelectedFilters,
  formatBytes,
  getTextWidth,
  filterBySeveralId,
  getToday,
  ptsansCurrency,
  bodyOnEscEnter,
  scrollPageTo,
  printPage,
  getSelectedText,
  toastUp,
  toastDown,
  uniqByKeepLast,
  getArrayOfObjectsChanges,
  validateResponse,
  validateMultipleResponses,
  uuidv4,
  objOfArraysHasProps,
  querify,
  priceWithPennies,
  priceToPennies,
  kgToGram,
  gramToKg,
  findRangeOfLoyalty,
  priceWithStep,
  addAlphaToHex,
  extractAlphaAndHexWithoutAlpha,
  compareArrays,
};
