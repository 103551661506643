import React, { FunctionComponent, useEffect } from 'react';
import { MainRouteChild } from '../main-page';
import { SettingsModuleDto } from './dto/settings-module.dto';
import settingsModulesConfig from './settings-modules-config';

interface DynamicModulePageProps extends MainRouteChild {
  module: SettingsModuleDto
}

function DynamicModulePage({
  module,
  setCurrentPage
}: DynamicModulePageProps) {

  useEffect(() => {
    setCurrentPage(module.front_link);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const SettingModule = settingsModulesConfig[module.front_link] || DefaultComponent;

  return (
    <div className={`settings-page__wrapper header-font-l ${module.front_class_name}`} >
      <h1>{module.description}</h1>

      <SettingModule
        module={module}
      />
    </div>
  );
}

export default DynamicModulePage;

const DefaultComponent: FunctionComponent<{module: SettingsModuleDto}> = ({ module }) => (
  <div>No settings module found for {module.front_link}</div>
);

/* {settings
  .filter(setting => setting.module === module.name)
  .map((setting) => {
    return <OneSetting 
      setting={setting} 
      key={setting.id}/>
  })} */