import React, { useState } from 'react';
import { Id } from 'merchery-lib';
import { num2str } from 'src/scripts/functions';
import TopPagePanel from 'src/components/_utility-components/top-page-panel';
import MyButton from 'src/components/_utility-components/button/button';
import SubscriptionsDeletePopup from '../delete-popup';

interface Props {
  deleteHandler: () => void
  clearSelected: () => void,
  selectedSubs: Id[],
}

const SelectedSubsMenu = ({
  deleteHandler,
  clearSelected,
  selectedSubs,
}: Props) => {
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);
  const countText = num2str(selectedSubs.length, ['подписка выбрана', 'подписки выбрано', 'подписок выбрано']);

  return (
    <div className="selected-top-page-menu">
      <TopPagePanel topPanelOpened={true} className={'selected-top-page-menu-inner'}>
        <div className="left">
          <MyButton 
            className="uncheck-all-items selected-top-page-menu-btn header-font-s"
            onClick={clearSelected}
          >
            {countText} 
            <div><div></div></div>
          </MyButton>
        </div>
        
        <MyButton 
          className="right header-font-s"
          onClick={() => setDeletePopupOpened(true)}
        >
          <div><i className="icofont-trash"></i> Удалить</div>
        </MyButton>

        {deletePopupOpened ? 
          <SubscriptionsDeletePopup 
            popupClose={() => setDeletePopupOpened(false)} 
            mainActionHandler={deleteHandler}
          />
        : null} 

      </TopPagePanel>
    </div>
  );
};
export default SelectedSubsMenu;
