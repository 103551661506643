import { useEffect, useState } from "react"

export function useStorageUiState <T>(defaultValue: T, localStorageKey: string) {
  const valueType = typeof defaultValue;
  const [value, setValue] = useState<T>(defaultValue);

  useEffect(() => {
    const storageValue = localStorage.getItem(localStorageKey)

    if(storageValue) {
      const parsedValue: T | null = (() => {
        switch(valueType){
          case 'string': return storageValue;
          case 'number': return JSON.parse(storageValue);
          case 'object': return JSON.parse(storageValue);
          case 'boolean': return JSON.parse(storageValue);
          default: return null;
        }
      })()

      parsedValue !== null && setValue(parsedValue)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return [value, setValue] as const
}

