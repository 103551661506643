import { ProductItemAttributes } from "./attributes";
import ProductItemCheckBox from "./check-box";
import ItemConfig from "./config";
import ProductItemCount from "./count";
import ProductItemEmptyGap from "./empty-gap";
import ProductItemExternalId from "./external-id";
import ProductItemGrab from "./grab";
import ProductItemImage from "./image";
import ProductItemName from "./name";
import ProductItemPrice from "./price";
import ProductItemRefund from "./refund";
import ShowVariants from "./show-variants";
import ProductItemTotal from "./total";
import ProductItemWeight from "./weight";

export const productLikeFields = {
  id: undefined,
  product: undefined,
  brand: undefined,
  order: undefined,
  name: ProductItemName,
  link: undefined,
  external_id: ProductItemExternalId,
  top: undefined,
  price: ProductItemPrice,
  count: ProductItemCount,
  total: ProductItemTotal,
  discount: undefined,
  returned: ProductItemRefund,
  attributes: ProductItemAttributes,
  created: undefined,
  modified: undefined,
  variant_id: undefined,
  src: ProductItemImage,
  anons: undefined,
  country: undefined,
  deleted: undefined,
  description: undefined,
  hit_sales: undefined,
  is_order: undefined,
  keywords: undefined,
  multiplicity: undefined,
  nav: undefined,
  new_items: undefined,
  price_old: undefined,
  remain: undefined,
  type: undefined,
  purchase_price: undefined,
  rate: undefined,
  show: undefined,
  title: undefined,
  weight: ProductItemWeight,
  product_id: undefined,
  price_old_or_discount: undefined,
  measure_id: undefined,
  package_amount: undefined,
  ship_in_packages: undefined,
  price_per_pack: undefined,
  barcode: undefined,
  variants: ShowVariants,
  emptyGap: ProductItemEmptyGap,
  checkbox: ProductItemCheckBox,
  grab: ProductItemGrab,
  config: ItemConfig,
} as const