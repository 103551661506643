export const leavePage = 'LEAVE_PAGE';
export const enterPage = 'ENTER_PAGE';
export const pageViewersCount = 'PAGE_VIEWERS_COUNT';
export const forceReenterViewers = 'FORCE_REENTER';

export const newOrdersCount = 'NEW_ORDERS_COUNT';
export const updateOrder = 'UPDATE_ORDER';
export const createOrder = 'CREATE_ORDER';
export const deleteOrder = 'DELETE_ORDER';

export const updateOrderItem = 'UPDATE_ORDER_ITEM';
export const createOrderItem = 'CREATE_ORDER_ITEM';
export const deleteOrderItem = 'DELETE_ORDER_ITEM';

export const updateProduct = 'UPDATE_PRODUCT';
export const createProduct = 'CREATE_PRODUCT';
export const deleteProduct = 'DELETE_PRODUCT';
export const reorderProduct = 'REORDER_PRODUCT';

export const updateClient = 'UPDATE_CLIENT';
export const createClient = 'CREATE_CLIENT';
export const deleteClient = 'DELETE_CLIENT';

export const updateSets = 'UPDATE_SET';
export const createSets = 'CREATE_SET';
export const deleteSets = 'DELETE_SET';
export const reorderSets = 'REORDER_SET';

export const updateCollection = 'UPDATE_COLLECTION';
export const createCollection = 'CREATE_COLLECTION';
export const deleteCollection = 'DELETE_COLLECTION';
export const reorderCollection = 'REORDER_COLLECTION';

export const multipleImagesProgress = 'MULTIPLE_IMAGES_PROGRESS';

export const updatePromocode = 'UPDATE_PROMOCODE';
export const createPromocode = 'CREATE_PROMOCODE';
export const deletePromocode = 'DELETE_PROMOCODE';

export const updateCategory = 'UPDATE_CATEGORY';
export const createCategory = 'CREATE_CATEGORY';
export const deleteCategory = 'DELETE_CATEGORY';
export const reorderCategory = 'REORDER_CATEGORY';

export const updateRefund = 'UPDATE_REFUND';
export const createRefund = 'CREATE_REFUND';
export const deleteRefund = 'DELETE_REFUND';