import React, { useState } from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { RefundFilters } from './dto/refund-filters.dto';
import { Id, RefundDto } from "merchery-lib";
import ExportButton from '../../../_utility-components/export-btn/export-btn';
import { renameExportHeaders } from './refunds-modules/rename-export-headers';
import { CommonFiltersLoader } from '../../../_utility-components/loaders/common-table-loader';
import {RefundSearch} from "src/components/main-pages/orders/refunds/refunds-modules/refund-search";

interface FilterChangerProps {
  itemIds: (Id | null)[] | undefined;
  filterName: (keyof RefundFilters) | undefined;
  cleanPrev?: boolean | undefined;
  concreteSelected?: boolean,
  cleanOthers?: true,
}

export type RefundFilterChanger = (props: FilterChangerProps) => void

function RefundHeader() {
  const refunds = useAppSelector(state => state.refunds);
  const searchInput = useAppSelector(state => state.ordersSearch);

  const [searchState, setSearchState] = useState(Boolean(searchInput));

  return (
    <div className={`orders-header ${searchState ? 'with-filters' : ''}`}>
      <h1>Возвраты</h1>
      <div></div>

      {refunds ? 
        <>
          <RefundSearch searchState={searchState}
                        setSearchState={setSearchState}/>

          <ExportButton<RefundDto>
            text={'экспорт'}
            renameExportHeaders={refund => renameExportHeaders({
              refund,
            })}
            items={refunds}
          />
        </>
      : <CommonFiltersLoader/>}
    </div>
  );
}

export default RefundHeader;