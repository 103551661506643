import React from 'react';
import { gramToKg } from '../../../../scripts/functions';

export interface ProductItemWeightProps {
  weight?: string | number | null
  count: number,
}

function ProductItemWeight(props: ProductItemWeightProps) {
  const {
    weight,
    count,
  } = props;
  
  return (
    <div className='row-item__weight center-align'>
      {weight !== null && weight !== undefined ?
        gramToKg(+weight) * count
      : null}
    </div>
  );
}

export default ProductItemWeight;