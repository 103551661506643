import { ExtendedProductStories, ExtendedProductStoriesItem, Id } from 'merchery-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { uuidv4 } from 'src/scripts/functions';
import { useDragEndHandler } from 'src/scripts/hooks/use-drag-end-handler-react-beautiful-dnd';
import useRouteId from 'src/scripts/hooks/use-route-id';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import AddNewStoriesItem from './add-new-stories-item';
import StoriesItem from './stories-item';

const getDefaultStories = (productId: Id): ExtendedProductStories => ({
  id: uuidv4(),
  product_id: productId,
  name: '',
  subtitle: null,
  duration: null,
  items: [],
})

const getDefaultStoriesItem = (storiesId: Id, order: number): ExtendedProductStoriesItem => ({
  id: uuidv4(),
  stories_id: storiesId,
  order,
  name: null,
  description: null,
  color: null,
  newItem: true,
  image: null,
})

function useDefaultStories (): ExtendedProductStories {
  const productid = useRouteId('productid');
  const [state, setState] = useState(
    productid 
      ? getDefaultStories(productid) 
      : null
  );

  useEffect(() => {
    if(!state && productid) {
      setState(getDefaultStories(productid))
    }
  }, [productid])

  return state
}

function ProductStoriesBody() {
  const defaultValue = useDefaultStories()
  const stories = useAppSelector(state => state.productStories || defaultValue);

  const filteredItems = useMemo(() => 
    stories 
      ? [...stories.items].sort((a, b) => a.order - b.order) 
      : []
  , [stories?.items])

  const dispatch = useDispatch()
  const itemsDispatch = (changedItems: ExtendedProductStoriesItem[]) => 
    dispatch({ 
      type: 'PRODUCT_STORIES', 
      payload: stories ? {
        ...stories,
        items: changedItems
      } : stories
    })

  const onDragEnd = useDragEndHandler<ExtendedProductStoriesItem> (
    filteredItems || [],
    (result, provided, newList, removed) => {
      itemsDispatch(
        newList.map((item, index) => ({
          ...item,
          order: index + 1
        }))
      )
    }
  )

  const addNewHandler = () => {
    if(!stories || !defaultValue) {
      return false
    }

    const arrayOfOrders = [...filteredItems.map((c) => c.order), 0];
    const maxOrder = Math.max.apply(null, arrayOfOrders);
    const newOrder = maxOrder + 1;

    const newItem: NonNullable<ExtendedProductStoriesItem> = getDefaultStoriesItem(stories.id, newOrder);

    itemsDispatch([
      ...filteredItems,
      newItem
    ])
  }

  if(!stories) {
    return null
  }

  return (
    <div>
      {filteredItems.length ?
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`stories-items`} type={`stories-items`}>
            {(provided, snapshot) => (
              <div 
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div className='product-page__stories-items-wrapper'>
                  {filteredItems.map((item, index) => 
                    <Draggable 
                      key={item.id} 
                      draggableId={'' + item.id}
                      index={index}
                      isDragDisabled={item.newItem}
                    >
                      {(provided, snapshot) => (
                        <div className='product-stories-item'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <StoriesItem 
                            item={item} 
                            stories={stories}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  )}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      : null}

      <AddNewStoriesItem addNewHandler={addNewHandler}/>
    </div>
  );
}

export default ProductStoriesBody;