import React, { useEffect, useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { Id } from "react-toastify";
import { calcPrice, toastUp, uuidv4 } from "src/scripts/functions";
import { useAppSelector } from "src/scripts/pre-type/use-selector";
import { useTabIndex } from "src/scripts/hooks/use-tabindex";
import FallbackComponent from "src/components/_utility-components/error-boundary";
import { ProductRow } from "../../products/products-items/product-items";
import { productLikeFields } from "../../products/products-items/product-items-utils";
import { CanBeAddedProduct } from "./can-be-added-product.dto";
import VariantRowToBeAdded from "./variant-row-to-be-added";
import { ExtendedProduct } from 'merchery-lib';

function ProductToBeAddedInOrder ({
  product,
  canBeAddedSelected,
  setCanBeAddedSelected,
}: {
  product: ExtendedProduct,
  canBeAddedSelected: CanBeAddedProduct[],
  setCanBeAddedSelected: (items: CanBeAddedProduct[]) => void,
}) {
  const showRemain = useAppSelector(
    state =>
      state.settings.find(
        setting =>
          setting.callname === 'remainder'
      )
  )?.value as boolean | null;

  const items = useAppSelector(state => state.productItemsInContext);
  const [showedVariants, setShowedVariants] = useState(false);
  const [count, setCount] = useState(0);
  const tabIndex = useTabIndex(2);

  const productPredicate: <T extends {product: Id, variant_id: Id | null},>(value: T, index: number, obj: T[]) => unknown = arrayItem => 
    arrayItem.product === product.id && arrayItem.variant_id === null;

  useEffect(() => {
    if(canBeAddedSelectedProduct && canBeAddedSelectedProduct?.count !== count) {
      changeCanBeAddedSelected(canBeAddedSelectedProduct.id, {
        count: count,
        total: +calcPrice([{
          count,
          price: product.price
        }]),
      })
    }
    if(!canBeAddedSelectedProduct && count > 0) {
      addSelected()
    }
    if(canBeAddedSelectedProduct && count === 0) {
      removeSelected()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  const checkboxHandler = (productFromHandlerRow: ExtendedProduct) => {
    const selected = canBeAddedSelected.some(item => item.product === productFromHandlerRow.id)

    if(selected) {
      setCount(0)
    } else {
      setCount(1)
    }
  }

  const addSelected = (productFromHandlerRow?: ExtendedProduct) => {
    const productToBeAdded = productFromHandlerRow || product;
    setCanBeAddedSelected([...canBeAddedSelected, productToItem(productToBeAdded)])
  }

  const removeSelected = () => {
    setCanBeAddedSelected(canBeAddedSelected.filter(item => item.product !== product.id))
  }
  
  const changeCanBeAddedSelected = (id: Id, changes: Partial<CanBeAddedProduct>) => {
    setCanBeAddedSelected(canBeAddedSelected.map(item => 
      item.id === id ? {...item, ...changes} : item
    ))
  }

  const itemIsItem = (product: ExtendedProduct | CanBeAddedProduct): product is CanBeAddedProduct => 
    (product as CanBeAddedProduct).product !== undefined
  
  const productToItem = (product: CanBeAddedProduct | ExtendedProduct): CanBeAddedProduct => {
    const isItem = itemIsItem(product);
    
    let item: CanBeAddedProduct = isItem ? {
      ...product,
      count: count || 1,
      price_with_discount: +calcPrice([{count: 1, price: product.price, cutDigits: 2}]),
      total: +calcPrice([{count, price: product.price}]),
    } : {
      ...product,
      order: product.category_order,
      id: uuidv4(),
      src: Array.isArray(product.src) ? product.src.find(image => image.main) || product.src[0] : product.src,
      link: null,
      count: count || 1,
      total: +calcPrice([{count, price: product.price}]),
      price: +calcPrice([{count: 1, price: product.price}]),
      price_with_discount: +calcPrice([{count: 1, price: product.price, cutDigits: 2}]),
      returned: 0,
      attributes: null,
      created: '',
      modified: '',
      variant_id: null,
      product: product.id,
      sumWeight: product.weight ? (count || 1) * product.weight : null
    }

    return item
  }

  const canBeAddedSelectedProduct = canBeAddedSelected?.find(productPredicate)

  const counter = (newCount: number) => {
    if(canBeAddedSelectedProduct &&
      newCount === canBeAddedSelectedProduct.remain &&
      newCount === canBeAddedSelectedProduct.count
    ) {
      const message = countOverRemainMessage(canBeAddedSelectedProduct.remain)
      toastUp(message)
    }
    setCount(newCount)
  }

  const externalIdForLabels = !canBeAddedSelectedProduct
    ? product.external_id
    : canBeAddedSelectedProduct.external_id;

  const remainForLabels = !canBeAddedSelectedProduct
    ? product.remain
    : canBeAddedSelectedProduct.remain;

  const withVariants = product?.variants?.length;

  const alreadyAdded = items.some(productPredicate)
  const disabled = Boolean(alreadyAdded || (!withVariants && !!showRemain && !remainForLabels));

  const shownProductsFieldsWithVariants = [
    'src',
    'name',
    {
      key: 'variants',
      options: {
        showedVariants: showedVariants,
        showHandler: () => setShowedVariants(!showedVariants)
      }
    }
  ] as const;

  const shownProductFieldsSelfProduct = [
    'checkbox',
    'src',
    'name',
    // { key: 'name', options: { attrValueHandler, variant }},
    {
      key: 'external_id',
      options: {
        remain: remainForLabels,
        external_id: externalIdForLabels,
        showRemain: false
      }
    },
    {
      key: 'count',
      options: {
        count,
        counter: counter,
        remain: remainForLabels,
        min: 0
      }
    },
    'price'
  ] as const;

  const shownProductFields = withVariants
    ? shownProductsFieldsWithVariants
    : shownProductFieldsSelfProduct;

  return (
    <>
      <ProductRow
        item={product}
        shownFields={shownProductFields} 
        className={`${withVariants ? 'add-items-rows--variants-template' : ''} row-item order-add-items-rows--template`}
        changer={undefined}
        selectHandler={checkboxHandler}
        disabled={disabled}
        tabIndex={tabIndex}
        componentsFields={productLikeFields}
        selected={canBeAddedSelectedProduct !== undefined || alreadyAdded}
      />
        
      {showedVariants ? 
        product.variants.map((variant) => 
          <VariantRowToBeAdded 
            key={variant.id}
            variant={variant}
            setCanBeAddedSelected={setCanBeAddedSelected}
            canBeAddedSelected={canBeAddedSelected} 
            product={product}
          />
        )
      : null}
    </>
  )
}

export function countOverRemainMessage(remain: number): string {
  return `Количество товаров на добавление превышает наличие товара (${remain})`
}

export default withErrorBoundary(ProductToBeAddedInOrder, {FallbackComponent: FallbackComponent})