import React from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { usePopup } from 'src/scripts/hooks/use-popup';
import MyButton from 'src/components/_utility-components/button/button';
import triangleDown from 'src/img/triangle-down.png';
import { FilterSelectElement } from './filter-item';
import useProductsFilters from "../hooks/use-products-filters";

export const FiltersPopup = () => {
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false);
  const [productFilters] = useProductsFilters();

  const { 
    RenderButton, 
    RenderPopup, 
    closePopup: popupParentClose 
  } = usePopup()

  if(!productFilters) {
    return null
  }

  return (
    <div className="filter-btn">
      <RenderButton 
        className="filter-btn-inner"
      >
        Фильтры <img alt="" src={triangleDown}/>
      </RenderButton>

      <RenderPopup
        tabIndexDeep={3}
        popupName={'.product-filters-popup'}
        className={'product-filters-popup'}
      >

        {!moySkladIntegrationOn ? 
          <BrandsFiltersPopup 
            popupParentClose={popupParentClose}
          />
        : null}

        <ShowFiltersPopup 
          popupParentClose={popupParentClose}
        />

      </RenderPopup>
    </div>
  )
}

export const showDateOptions = [
  {
    id: 'show',
    name: 'Видимые'
  },
  {
    id: 'hide',
    name: 'Скрытые'
  },
  {
    id: 'planned',
    name: 'Запланированные'
  }
]

function ShowFiltersPopup({
  popupParentClose,
}: {
  popupParentClose: () => void
}) {
  const [, , cleanFilters] = useProductsFilters();
  const {
    RenderButton, 
    RenderPopup, 
    closePopup
  } = usePopup();

  return <div className="popup-group">
    <RenderButton
      removeDefaultClass
      className="popup-element product-filter-container">
      Статус
    </RenderButton>

    <RenderPopup
      tabIndexDeep={3}
      popupName={'.product-filters.show'}
      popupParent={'.product-filters-popup'}
      popupParentClose={popupParentClose}
      className={'product-filters show status sub-popup'}>

      <div className="popup-group">
        {showDateOptions.map((option) => {
          return <FilterSelectElement
            key={option.id}
            text={option.name}
            num={option.id}
            statusName={'show_date'}
          />
        })}
      </div>

      <div className="popup-group">
        <MyButton 
          className="popup-element general-popup-clean-btn" 
          tabIndexLevel={3}
          onClick={() => {
            cleanFilters('show_date')
            closePopup()
          }}>
            
          <div>Очистить</div>
        </MyButton>
      </div>

    </RenderPopup>
  </div>;
}

function BrandsFiltersPopup({
  popupParentClose
}: {
  popupParentClose: () => void
}) {
  const brands = useAppSelector(state => state.brands || [])
  const [, , cleanFilters] = useProductsFilters();
  const {
    RenderButton, 
    RenderPopup, 
    closePopup 
  } = usePopup()

  return <div className="popup-group">
    <RenderButton
      removeDefaultClass
      tabIndexLevel={2}
      className="popup-element product-filter-container"
    >
      Бренды
    </RenderButton>

    <RenderPopup 
      tabIndexDeep={3}
      popupName={'.product-filters.brands'}
      popupParent={'.product-filters-popup'}
      popupParentClose={popupParentClose}
      className={'product-filters brands status sub-popup'}
    >
      <div className="popup-group">
        {brands.map((b) => 
          <FilterSelectElement
            key={b.name}
            text={b.name}
            num={b.id}
            statusName={'brand'}
          />
        )}
      </div>

      <div className="popup-group">
        <MyButton
          tabIndexLevel={3}
          removeDefaultClass
          className="popup-element general-popup-clean-btn flex-center" 
          onClick={() => {
            cleanFilters('brand')
            closePopup()
          }}>
          <div>Очистить</div>
        </MyButton>
      </div>
    </RenderPopup>
  </div>;
}
