import React from 'react';
import MyButton from 'src/components/_utility-components/button/button';

function PerformMailingBtn() {

  function handler () {

  }

  return (
    <div>
      <MyButton className='blue-btn' onClick={handler}>
        Выполнить рассылку
      </MyButton>
    </div>
  );
}

export default PerformMailingBtn;