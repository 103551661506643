import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector'
import { OrderDelivery, CdekCalcTariffDto } from 'merchery-lib';

export default function useCdekCost({
  delivery,
  currentTariff
}: {
  delivery: OrderDelivery | null,
  currentTariff: CdekCalcTariffDto | undefined
}): number {
  const settings = useAppSelector(state => state.settings);
  const paidDelivery = settings.find(setting => setting.callname === 'paid_delivery')?.value;

  const [ resultCost, setResultCost ] = useState(0);

  useEffect(() => {
    if(paidDelivery && currentTariff) {
      setResultCost(currentTariff.delivery_sum)
    }
  }, [currentTariff])

  if(delivery?.cost && Number(delivery.cost)) {
    return Number(delivery.cost)
  }
  
  return resultCost;
}