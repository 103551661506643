import React from 'react';
import { SortableDataConfig, SortableDataHandler } from 'src/scripts/hooks/use-sortable-data';
import { SubscriptionDto } from 'merchery-lib';
import { HeaderRow } from 'src/components/_utility-components/common-table/header-row';
import HeaderCell from 'src/components/_utility-components/common-table/header-cell';

function SubscriptionsTableHeader({
  requestSort,
  config
}: {
  config: SortableDataConfig<SubscriptionDto> | undefined,
  requestSort: SortableDataHandler<SubscriptionDto>,
}) {
  return (
    <HeaderRow className='subscriptions-table__cells-template'>
      <HeaderCell children={'№'} label={'id'} config={config} handler={requestSort} />
      <HeaderCell children={'Наименование'} label={'productItem.name'} config={config} handler={requestSort}/>
      <HeaderCell children={'Атрибуты'} label={'attributesText'} config={config} handler={requestSort} className='center-align'/>
      <HeaderCell children={'Остатки'} label={'productItem.remain'} config={config} handler={requestSort} className='center-align'/>
      <HeaderCell children={'Email'} label={'client.email'} config={config} handler={requestSort} className='center-align' />
      <HeaderCell children={'Дата подписки'} label={'created'} config={config} handler={requestSort} className='center-align'/>
      <div></div>
    </HeaderRow>
  );
}

export default SubscriptionsTableHeader;