import React, { useEffect, useMemo, useRef, useState } from 'react';
import useMounted from 'src/scripts/hooks/use-mounted';
import { PossibleValuesPopup, usePossibleValues } from 'src/scripts/hooks/use-possible-values';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { ExtendedProduct, RelatedProduct } from 'merchery-lib';
import MyInput from 'src/components/_utility-components/input';
import { batch, useDispatch } from 'react-redux';
import Bubbles from 'src/components/_utility-components/bubbles';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { validateResponse } from 'src/scripts/functions';
import isEqual from 'lodash/isEqual';
import FindProductsInCatalog from './find-products-in-catalog';

function RelatedProducts() {
  const _isMounted = useMounted()
  const inputRef = useRef<HTMLInputElement | null>(null);
  const product = useAppSelector(state => state.product);

  const [relatedProducts, setRelatedProducts] = useState<RelatedProduct[]>(product?.relatedProducts || [])

  const {setSearch, search, setShowData, dataLoading, data, showData} = usePossibleValues<ExtendedProduct>({
    urlPath: 'products',
  });
  
  const dispatch = useDispatch();
  const changeProduct = (changes: Partial<ExtendedProduct>) => 
    dispatch({ type: 'PRODUCT_ITEM', payload: {...product, ...changes}})
  
  useEffect(() => {
    if(product?.relatedProducts) { 
      const isLocalHasDifference = product.analogProducts && !isEqual(relatedProducts, product.analogProducts)
      if(isLocalHasDifference) {
        setRelatedProducts(product.relatedProducts || [])
      }
    }
  }, [product]);

  const notIncludedYetData = useMemo(() => {
    const removedIncludedAndThisProduct = data.filter(possibleProduct => 
      possibleProduct.id !== product?.id &&
      !relatedProducts.some(added => 
        added.id === possibleProduct.id))

    return removedIncludedAndThisProduct
  }, [relatedProducts, data, product])

  const addRelatedProduct = async (relatedProduct: ExtendedProduct | ExtendedProduct[]) => {
    if(!relatedProduct || !product) {
      return false
    }

    const relatedIds = 
      Array.isArray(relatedProduct) 
        ? relatedProduct.map(item => item.id) 
        : [relatedProduct.id]
    
    const res = await mercheryFetch<RelatedProduct[]>('products/add-related-product', 'PATCH', {
      product_id: product.id,
      related_product: relatedIds,
    })

    batch(() => {
      if(_isMounted.current && validateResponse(res)) {
        setSearch('')
        setShowData(false)

        changeProduct({
          analogProducts: res.records
        })
      }
    })

    return true
  };

  const removeRelatedProductFromProduct = async (relatedProduct: RelatedProduct) => {
    if(!relatedProduct || !product) {
      return false
    }

    const res = await mercheryFetch<RelatedProduct[]>('products/remove-related-product', 'PATCH', {
      product_id: product.id,
      related_product: relatedProduct.id,
    })

    
    if(_isMounted.current && res && validateResponse(res)) {
      changeProduct({
        analogProducts: res.records
      })
    }
  };

  if(!product || product.newProduct) {
    return null
  }

  return (
    <div id='related-products' className='product-page-element product-page__related-products'>
      <h3 className='product-page-h3'>Связанные товары</h3>
      
      <div className='product-page-element__find-products'>
        <MyInput
          inputRef={inputRef}
          value={search}
          myClassName='product-page-element__find-products__search-input'
          placeholder='поиск по наименованию'
          onChange={(e) => setSearch(e.target.value)}
        />

        <FindProductsInCatalog 
          handler={addRelatedProduct}
        />
      </div>
      
      <PossibleValuesPopup<ExtendedProduct>
        clickHandler={addRelatedProduct} 
        dataLoading={dataLoading} 
        search={search}
        data={notIncludedYetData} 
        showData={showData} 
        setShowData={setShowData}
        className={'related-products__possible-values'}
        changingDirection={false}
      />

      <Bubbles<RelatedProduct>
        deleteHandler={removeRelatedProductFromProduct}
        items={relatedProducts}
        linkConfig={{
          pathname: (item) => `/app/products/${item.id}`
        }}
      />
    </div>
  );
}

export default RelatedProducts;