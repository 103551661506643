import React, { useRef } from 'react';
import MyCheckbox from '../../_utility-components/checkbox/index';
import { Id, Integration } from 'merchery-lib';
import { validateResponse } from '../../../scripts/functions';
import { mercheryFetch } from '../../../scripts/fetchConstructor';

interface Props {
  integChange: (id: Id, data: Partial<Integration>) => void,
  integration: Integration
}

function SingleIntegration({
  integration,
  integChange

}: Props) {
  const { id, title, turned_on } = integration;
  const _isMounted = useRef(true);

  const checkBoxHandler = () => {
    mercheryFetch<Integration[]>('integrations', 'PATCH', {
      updateData: [{
        id: id,
        turned_on: !turned_on
      }]
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) 
        return false
      
      const thisInteg = res.records.find(i => i.id === id);
      if(!thisInteg) return false

      integChange(id, thisInteg)
    })
  }

  return (
    <div className={`flex-center`}>
      Наименование: {title}
      <MyCheckbox
        className='checkbox-wrapper'
        condition={turned_on}
        actionHandler={checkBoxHandler}
      />
    </div>
  );
}

export default SingleIntegration;