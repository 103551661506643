import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Id, RefundDto } from "merchery-lib";
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { numWithSep, priceWithPennies, ptsansCurrency, validateResponse } from 'src/scripts/functions';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import useMounted from 'src/scripts/hooks/use-mounted';
import { OrderContext } from '../../order-page';
import SetStatus from '../../refunds/refunds-modules/set-status';
import PaymentBodyPaid from 'src/components/main-pages/orders/order-modules/payment/payment-body-paid';
import RefundDelivery from './refund-delivery';
import RefundedItems from './refunded-items';

interface Props {
  refund: RefundDto
}

function OneRefundSection({
  refund
}: Props) {
  const _isMounted = useMounted();
  const refunds = useAppSelector(state => state.refunds)

  const {
    order, orderDeleted, 
  } = useContext(OrderContext)

  const dispatch = useDispatch();
  const refundsDispatch = (refunds: RefundDto[]) => dispatch({ type: 'ORDERS_REFUNDS', payload: refunds });

  const changeRefund = (id: Id, changes: Partial<RefundDto>) => {
    if(!refunds) {
      return false
    }

    mercheryFetch<RefundDto[]>('orders/refund', 'PATCH', {
      changes: [{
        id: id,
        ...changes
      }]
    })
    .then((res) => {
      if(_isMounted.current && validateResponse(res)) {
        const updatedRefunds = res.records
        const refundsWithUpdated = refunds.map((r) =>
          updatedRefunds.find(ur => ur.id === r.id) ||
          r
        )
        
        refundsDispatch(refundsWithUpdated)
      }
    })
  }

  const statusHandler = (status: string) =>
    changeRefund(refund.id, { status: status });

  return (
    <section className='order-page__refund order-page__section'>
      <div className='order-page__refund__header'>

        <h3 className='header-font-m'>Возврат</h3>
        
        <SetStatus 
          className='header-font-xxs'
          refund={refund} 
          disabled={!order || orderDeleted}
          statusHandler={statusHandler}          
        />

      </div>

      <div className='order-page__refund__body'>
      </div>

      <RefundedItems 
        refund={refund}
      />

      <div className='order-page__refund__footer'>
        <h3 className='header-font-m'>Итого к возврату</h3>
        
        <div className='order-page__refund-total header-font-m'>
          – {numWithSep(priceWithPennies(refund.refund_total))} {ptsansCurrency('₽')}
        </div>
      </div>

      <RefundDelivery/>

      <PaymentBodyPaid/>

    </section>
  );
}

export default OneRefundSection;