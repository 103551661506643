import React from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyButton from '../../../_utility-components/button/button';

function LoadMore({
  showMoreProducts,
}: {
  showMoreProducts: () => void
}) {
  const currentProductsCount = useAppSelector(state => state.currentProductsCount)
  const allProductsCount = useAppSelector(state => state.allProductsCount)
  const hasMore = allProductsCount && allProductsCount > currentProductsCount

  if(!hasMore) {
    return null
  }

  return (
    <div>
      Загружено {currentProductsCount} из {allProductsCount} товаров
      <MyButton
        onClick={showMoreProducts}
      >
        Показать больше
      </MyButton>
    </div>
  );
}

export default LoadMore