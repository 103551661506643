import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../scripts/hooks/use-mounted';
import { usePopup } from '../../../../scripts/hooks/use-popup';
import CategoriesFilterAndControl, { CategoryFilterChanger } from '../../categories/categories-filter-and-control';
import { Category, Id } from 'merchery-lib';
import { validateResponse } from 'src/scripts/functions';

interface Props {
  selectedCategoryId: Id | undefined,
  topChangeHandler: (changes: { top?: Id }) => any,
  tabIndexLevel?: number,
  headerText?: string
}

function SelectProductCategory({
  topChangeHandler,
  selectedCategoryId,
  tabIndexLevel,
  headerText
}: Props) {
  const _isMounted = useMounted()
  const categories: Category[] = useAppSelector(state => state.categories || []);

  const selectedCategory = useMemo(() => {
    return selectedCategoryId ? categories.find(c => c.id === selectedCategoryId) : null
  }, [selectedCategoryId, categories])

  const dispatch = useDispatch();

  const { RenderButton, RenderPopup, closePopup } = usePopup()

  const getCategories = async () => {
    const res = await mercheryFetch<Category[]>('category', 'GET')
    if(!_isMounted.current || !validateResponse(res)) return false;

    if(categories && selectedCategoryId) {
      setSelectedCategory(res.records.find(c => c.id === selectedCategoryId))
    }
    
    dispatch({type: 'CATEGORIES', payload: res.records})
  }

  const setSelectedCategory = useCallback((category: Category | undefined) => {
    category && topChangeHandler({top: +category.id})
  }, [topChangeHandler])
  
  useEffect(() => {
    getCategories()
  }, [])

  const categoryHandler: CategoryFilterChanger = (categoryId) => {
    topChangeHandler({
      top: categoryId !== undefined ? categoryId : 1
    })
    closePopup()
  }

  return (
    <div className='product-page__right-side-bar__label select-product-category'>
      <h5 className="header-font-m">
        {headerText ? headerText : 'Категория товара'}
      </h5>

      <RenderButton className='input-like-button select-product-category-btn'>
        {selectedCategoryId !== undefined ? selectedCategory?.name || 'Без категории' : ''}

        <div className="my-select-input__down-icon">
          <i className="icofont-simple-down"></i>
        </div>
      </RenderButton>

      <RenderPopup
        className={'my-select-options'}
        tabIndexDeep={tabIndexLevel || 2}
      >
        <CategoriesFilterAndControl
          categoryHandler={categoryHandler} 
          filtersMod={true}
          withNoCategory={false}
          noCategoryOptionText={'Без категории'}
        />
      </RenderPopup>

      {/* <SelectInput
        valueLabelName={'name'}
        selectedItem={selectedCategory}
        selectHandler={setSelectedCategory}
        items={categories}
      /> */}
    </div>
  );
}

export default SelectProductCategory;