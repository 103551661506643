import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { mercheryFetch } from '../fetchConstructor';
import { validateResponse } from '../functions';
import { useAppSelector } from '../pre-type/use-selector';
import useMounted from './use-mounted';
import { TaxForm } from 'merchery-lib';

function useTaxForms(): TaxForm[] | null {
  const _isMounted = useMounted()
  const taxForms = useAppSelector(state => state.taxForms);

  const dispatch = useDispatch()
  const taxFormsDispatch = (items: TaxForm[]) => dispatch({ type: 'TAX_FORMS', payload: items })

  useEffect(() => {
    if(!taxForms) {
      mercheryFetch<TaxForm[]>('static-data/tax-forms', 'GET')
      .then(res => {
        if(!_isMounted.current || !validateResponse(res)) {
          return false
        }

        taxFormsDispatch(res.records)
      })
    }
  }, [])
  
  return taxForms
}

export default useTaxForms;