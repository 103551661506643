import React, {useMemo, useState} from 'react';
import { useRouteMatch } from 'react-router';
import { CollectionDto, SortingItem } from 'merchery-lib';
import {extractImages} from 'src/scripts/utils/extractImages';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import transliterate from '../../../../scripts/utils/transliteration';
import MyInput from '../../../_utility-components/input/index';
import SelectInput from '../../../_utility-components/select-input/select-input';
import MyDropzone, { DropzoneFileHandler, dropzoneAcceptAllImagePreset, dropzoneAcceptAllVideoPreset, postBase64 } from '../../../_utility-components/dropzone';
import { withDescription } from '../../categories/description';
import { CollectionImageModules } from '../collection-page';
import DropzoneContent from 'src/components/_utility-components/dropzone-content';
import useUrlToClipBoard from "../../../../scripts/utils/url-to-clip-board";

interface Props {
  collectionChanger: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  collectionDispatch: (collectionProps: Partial<CollectionDto>) => void, 
  sendImage: DropzoneFileHandler,
  deleteImage: (module: CollectionImageModules) => Promise<false | undefined>,
  thisCollection: CollectionDto | undefined
}

function CollectionSummary({
  collectionChanger,
  collectionDispatch,
  sendImage,
  deleteImage,
  thisCollection,
}: Props) {
  const match = useRouteMatch<{id: string}>();
  const isNew = match.params.id === 'new';
  const [loading, setLoading] = useState<boolean>(false)

  const sorting = useAppSelector(state => state.sorting);
  const urlToClipBoard = useUrlToClipBoard(thisCollection?.url)

  const extractedImages = useMemo(() => {
    return extractImages(thisCollection?.src, 'small')
  }, [thisCollection?.src])
  
  const nameChanger = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    const changes: Partial<CollectionDto> = {
      name: value
    }

    if(isNew) {
      changes.url = transliterate(value).toLowerCase()
    }

    collectionDispatch(changes)
  }

  if(!thisCollection) {
    return null
  }

  return (
    <section className='collection-page__summary'>
      <div className='collection-page__summary__inputs-wrapper'>
        <MyInput myClassName={'grid-element'}
          name={'name'}
          required={false}
          onChange={nameChanger}
          value={thisCollection.name}
        >
          <h5 className="char-label__header header-font-s">Наименование</h5>
        </MyInput>

        <MyInput myClassName={'grid-element'}
          name={'url'}
          type={"nav"}
          required={false}
          onChange={collectionChanger}
          value={thisCollection.url}
          maxLength={45}
        >
          <h5 className="char-label__header header-font-s">URL</h5>
          <div className='possible-url text-font-s'
            onClick={urlToClipBoard}
          >
            /app/collections/<span>{thisCollection.url}</span>
          </div>
        </MyInput>

        <SelectInput<SortingItem> 
          className={'grid-element'}
          items={sorting || []}
          tabIndexLevel={2}
          selectedItem={sorting?.find(v => v.id === thisCollection.sorting_id)}
          selectHandler={(value: SortingItem) => collectionDispatch({ sorting_id: value.id })}
          inputProps={{ 
            children: <h5 className="char-label__header header-font-s">Сортировка товаров</h5> 
          }} 
        />

        <SelectInput<{}> 
          className={'grid-element'} // Сделать шаблоны
          items={[]}
          selectedItem={{}}
          selectHandler={() => {}}
          inputProps={{ 
            children: <h5 className="char-label__header header-font-s">Шаблон</h5> 
          }} 
        />
      </div>

      <div className='collection-page__summary__image'>
        <MyDropzone
          files={thisCollection.src
            ? [thisCollection.src]
            : []
          }
          optionalProps={{
            multiple: false,
            maxFiles: 1,
            disabled: thisCollection.newCollection
          }}
          accept={[...dropzoneAcceptAllVideoPreset, ...dropzoneAcceptAllImagePreset]}
          isLoading={loading}
          disabled={thisCollection?.newCollection}
          fileHandler={(...args) => {
            setLoading(true);
            const result = sendImage(...args)

            result && result
            .then(() => {
              setLoading(false);
            })
          }}
          contentRender={
            <DropzoneContent
              extractedImages={extractedImages}
              deleteImage={() => deleteImage('Collection')}
              fallBackLink={thisCollection.src?.src || null}
            />
          }
        />
      </div>

      <CollectionDescription
        description={thisCollection.description}
        descriptionHandler={(desc) => collectionDispatch({description: desc})} 
      />
    </section>
  );
}

const CollectionDescription = withDescription(({
  children
}) => {
  return (
    <div className='category-page__summary__description'>
      {children}
    </div>
  )
})

export default CollectionSummary;