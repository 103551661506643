import React from 'react';
import PaymentBody from "src/components/main-pages/orders/order-modules/payment/payment-body";
import PaymentHeader from "src/components/main-pages/orders/order-modules/payment/payment-header";
import PaymentBodyTestBill from "src/components/main-pages/orders/order-modules/payment/payment-body-test-bill";

function OrderPayment() {
  // const paidSum = priceWithPennies(order.paid_sum)
  // const successStatus = order.total && order.paid_sum >= order.total

  return (
    <section className='order-page__payment order-page__section'>
      <PaymentHeader/>

      <PaymentBody/>

      <PaymentBodyTestBill/>
      {/*test*/}

    </section>
  );
}

export default OrderPayment;