import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import {priceToPennies, toastUp, validateResponse} from 'src/scripts/functions';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import useMounted from '../../../../../../scripts/hooks/use-mounted';
import MyButton from '../../../../../_utility-components/button/button';
import { OrderDeliveryContext, Tariff } from '../delivery';
import { CdekCityAndDimensions } from './cdek-city-and-dimensions';
import { CdekTariffs } from './cdek-tariffs';
import { CdekAddressAndCost } from './cdek-address-and-cost';
import { OrderContext } from '../../../order-page';
import {batch} from 'react-redux';
import { OrderDelivery, CdekCalcTariffsBodyApi, CdekCalcTariffsRes, CdekCalcTariffDto, CdekDeliveryModeDto, CdekCityDto } from 'merchery-lib';
import useOrderDelivery from "../../../hooks/use-order-delivery";
import { useLoad } from 'src/scripts/hooks/use-load';

export const CdekDeliveryContext = React.createContext<{
  tariffsLoading: boolean,
  deliveryTariffs: CdekCalcTariffDto[] | null,
  setDeliveryTariffs: (tariffs: CdekCalcTariffDto[] | null) => void,
  modes: CdekDeliveryModeDto[],
  setModes: (modes: CdekDeliveryModeDto[]) => void,
  currentTariff: CdekCalcTariffDto | undefined,
  cities: CdekCityDto[],
  setCities: (cities: CdekCityDto[]) => void,
}>({
  tariffsLoading: false,
  deliveryTariffs: null,
  modes: [],
  setModes: () => {},
  setDeliveryTariffs: () => {},
  currentTariff: undefined,
  cities: [],
  setCities: () => {},
});

function Cdek () {
  const _isMounted = useMounted();
  const settings = useAppSelector(state => state.settings);
  const orderItems = useAppSelector(state => state.productItemsInContext);
  // const [cdekWidget, setCdekWidget] = useState<CdekWidget | null>(null)

  const match = useRouteMatch<{orderid: string}>();
  const orderId = +match.params.orderid;

  const cdekDeliveryTariffType = settings.find(setting =>
    setting.callname === 'cdek_delivery_type'
  )?.value as number | string | null;

  const cdekDeliveryCurrency = settings.find(setting =>
    setting.callname === 'cdek_currency_code'
  )?.value as number | string | null;

  const cdekFromCode = settings.find(setting =>
    setting.callname === 'cdek_from_location_city_code'
  )?.value as number | null;

  const [triggerSafe, setTriggerSafe] = useState(false);

  const {
    delivery,
    changeSelectedDelivery,
    safeHandler,
  } = useContext(OrderDeliveryContext);

  const {
    order
  } = useContext(OrderContext);

  const [, setLoad] = useLoad()

  const [tariffsLoading, setTariffsLoading] = useState(false);
  const [deliveryTariffs, setDeliveryTariffs] = useState<CdekCalcTariffDto[] | null>(null);
  const [modes, setModes] = useState<CdekDeliveryModeDto[]>([]);
  const [cities, setCities] = useState<CdekCityDto[]>([]);
  const currentDelivery = useOrderDelivery(order);

  const currentTariff = delivery && deliveryTariffs
    ? deliveryTariffs.find(tariff =>
        String(tariff.tariff_code) === delivery.tariff_id)
    : undefined;

  const currentTariffMode = useMemo(() =>
    modes.find(mode =>
      mode.id === currentTariff?.delivery_mode)
  , [currentTariff, modes])

  const deliveryIsRegistered = delivery?.registration_date

  useEffect(() => {
    const conditionsToGetTariffs = !Number.isNaN(orderId) && 
      delivery?.city &&
      delivery.weight &&
      delivery.length &&
      delivery.height &&
      delivery.width
      
    if(conditionsToGetTariffs && !deliveryIsRegistered) {
      calcTariffs()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  useEffect(() => {
    cdekDeliveryModesRequest()
  }, [])

  useEffect(() => {
    if(triggerSafe && delivery) {
      batch(() => {
        setTriggerSafe(false)
        safeHandler()
      })
    }
  }, [delivery, triggerSafe])

  useEffect(() => {
    setDeliveryTariffs(null)
  }, [
    delivery?.length, 
    delivery?.width, 
    delivery?.weight, 
    delivery?.height, 
  ])

  useEffect(() => {
    if(delivery?.city_code === null) {
      setDeliveryTariffs(null)
    }
  }, [ delivery?.city ])
  

  useEffect(() => {
    if(delivery &&
      delivery.city_code && (
        currentDelivery &&
        delivery.city_code !== currentDelivery.city_code
    )) {
      changeSelectedDelivery({
        point_address: null,
        point_id: null
      })
    }
  }, [delivery?.city_code]);

  const pointAddressOrClientAddressNotPresented = useMemo(() => {
    if(!delivery) {
      return true
    }

    if(!currentTariffMode) {
      return true
    }

    if(currentTariffMode.to_the_door && !delivery.address) {
      return true
    }

    if(!currentTariffMode.to_the_door && (!delivery.point_id || !delivery.point_address)) {
      return true
    }

    return false
  }, [modes, currentTariff, delivery])

  if(!delivery) {
    return null
  }

  const cdekDeliveryModesRequest = () => {
    mercheryFetch<CdekDeliveryModeDto[]>('integrations/cdek/modes', 'GET')
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      setModes(res.records)
    })
  }

  const calcTariffs = () => {
    if(
      !cdekDeliveryTariffType ||
      !cdekDeliveryCurrency ||
      !cdekFromCode ||
      !delivery.city_code ||
      !delivery.weight ||
      !delivery.length ||
      !delivery.width ||
      !delivery.height
    ) {
      return false;
    }

    const calcTariffsRequest: CdekCalcTariffsBodyApi = {
      type: Number(cdekDeliveryTariffType),
      currency: Number(cdekDeliveryCurrency),
      packages: [
        {
          weight: delivery.weight,
          length: delivery.length,
          width: delivery.width,
          height: delivery.height,
        }
      ],
      from_location: {
        code: Number(cdekFromCode),
      },
      to_location: {
        code: Number(delivery.city_code),
      },
    };

    setTariffsLoading(true)

    return mercheryFetch<CdekCalcTariffsRes>('integrations/cdek/calc-tariffs', 'POST', calcTariffsRequest)
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      const tariffs = res.records.tariff_codes
        .map(tariff => ({
          ...tariff,
          delivery_sum: priceToPennies(tariff.delivery_sum)
        }));
      setDeliveryTariffs(tariffs);

      const standardTariffs: Tariff[] = tariffs.map(tariff => ({
        id: tariff.tariff_code,
        price: +tariff.delivery_sum
      }));

      return standardTariffs;
    })
    .finally(() => {
      _isMounted.current && setTariffsLoading(false)
    })
  };

  const registerDelivery = async () => {
    try {
      if(!order?.client) {
        throw Error('Для регистрации необходима информация о клиенте')
      }

      if(!orderItems.length) {
        throw Error('Сначала необходимо добавить товары в заказ')
      }

      const body = {
        orderId: order.id,
        delivery: {
          ...delivery,
          id: delivery.newDelivery ? null : delivery.id
        },
        tariff: currentTariff
      };

      setLoad(true)

      const createRes = await mercheryFetch<OrderDelivery>(
        'integrations/cdek/order',
        'POST',
        body
      )

      if(!_isMounted.current || !validateResponse(createRes)) {
        return false
      }

      batch(() => {
        changeSelectedDelivery(createRes.records)
        setTriggerSafe(true);
      })
    } catch (error) {
      if(typeof error === 'string' || error instanceof Error) {
        toastUp(error)
      } 
      console.log(error)
    } finally {
      setLoad(false)
    }
  }

  const calcCostDisabled = !delivery.length ||
    !delivery.height ||
    !delivery.weight ||
    !delivery.width ||
    !delivery.city ||
    !delivery.city_code;
  
  const registerDeliveryDisabled = calcCostDisabled || !delivery.tariff_id || pointAddressOrClientAddressNotPresented;
    
  return (
    <CdekDeliveryContext.Provider value={{
      deliveryTariffs,
      tariffsLoading,
      modes,
      setModes,
      setDeliveryTariffs,
      currentTariff,
      cities, 
      setCities,
    }}>
      <div className={`op-delivery-cdek ${delivery.point_id ? 'sdek-widget' : ''}`}>
        <CdekCityAndDimensions />

        <MyButton
          className='blue-btn'
          disabled={calcCostDisabled}
          onClick={calcTariffs}
        >
          Рассчитать стоимость
        </MyButton>

        <CdekTariffs/>

        <CdekAddressAndCost/>
        
        {currentTariff ?
          <MyButton
            className='blue-btn'
            disabled={registerDeliveryDisabled}
            onClick={registerDelivery}
          >
            Зарегистрировать заказ
          </MyButton>
        : null}
      </div>
    </CdekDeliveryContext.Provider>
  );
}

export default Cdek