import React, { useContext } from 'react';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import MyInput from '../../../../_utility-components/input/index';
import SelectInput from '../../../../_utility-components/select-input/select-input';
import { RefundStartContext } from './refund-start';

function OrderRefundCause() {
  const {
    refundCreateData,
    setRefundCreateData, 
  } = useContext(RefundStartContext);
  const refundCauses = useAppSelector(state => state.refundCauses)
  
  return (
    <div className='refund-registration__cause refund-registration__popup-element'>
      <div className="inputs-row refund-registration__cause-type__wrapper">
        <h4 className='header-font-m'>Категория возврата</h4>
        <SelectInput 
          items={refundCauses} 
          selectedItem={refundCauses.find(cause => cause.id === refundCreateData.refund_cause_id)}
          valueLabelName={'name'}
          selectHandler={(cause) => 
            setRefundCreateData({
              ...refundCreateData, 
              refund_cause_id: cause.id
            })
          }
        />
      </div>

      <div className="inputs-row refund-registration__cause-description__wrapper">
        <h4 className='header-font-m'>Причина возврата</h4>
        <MyInput 
          value={refundCreateData.refund_cause_description || ''} 
          onChange={event => 
            setRefundCreateData({
              ...refundCreateData, 
              refund_cause_description: event.target.value
            })
          }/>
      </div>
    </div>
  );
}

export default OrderRefundCause;