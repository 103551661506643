import React, {useContext} from 'react';
import arrowLeft from '../../../../img/arrow-left.png';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import MyButton from '../../../_utility-components/button/button';
import MyInput from '../../../_utility-components/input/index';
import {priceToPennies, priceWithPennies} from "src/scripts/functions";
import { OrderPromocodeContext } from './promo';

export function NewPromo() {
  const tabIndex = useTabIndex(2)
  const {
    isPercent,
    popupClose,
    newPromoCode,
    promoSwitch,
    setCreateNewCodeState,
    createHandler,
    percentValue,
    currencyValue,
    setPercentValue,
    setCurrencyValue,
    setNewPromoCode,
  } = useContext(OrderPromocodeContext);

  const value = isPercent
    ? percentValue
    : priceWithPennies(currencyValue);

  const setValue = (newValue: number | string) =>
    isPercent
      ? setPercentValue(Number(newValue))
      : setCurrencyValue(priceToPennies(Number(newValue)));

  return (
    <>
      <div className="discount-promo-header promo">
        <MyButton
          tabIndex={tabIndex}
          removeDefaultClass
          className="cancel-create"
          onClick={() => setCreateNewCodeState(false)}
        >
          <img src={arrowLeft} alt="Назад" />
        </MyButton>
        <h2>Новый промокод</h2>
      </div>

      <form id='create-promo' className="discount-promo-set-discount-promo">
        <MyInput
          tabIndex={tabIndex}
          myClassName='promo-name'
          value={newPromoCode.code}
          onChange={(e) => setNewPromoCode({
            ...newPromoCode,
            code: e.target.value
          })}
          placeholder="Название"
          name="promo-name"
          withBind='confirm-promo'
          required
        />

        <div className="discount-promo-set-discount-promo-top promo">
          <MyInput
            tabIndex={tabIndex}
            placeholder="Номинал"
            name="promo-value"
            withBind='confirm-promo'
            value={value || 0}
            onChange={(e) => setValue(e.target.value)}
            required />

          <MyButton 
            tabIndex={tabIndex}
            className={`discount-by percent ${isPercent ? "blue-btn" : "white-btn"}`}
            onClick={promoSwitch}>
            %
          </MyButton>

          <MyButton
            tabIndex={tabIndex}
            className={`discount-by currency ${!isPercent ? "blue-btn" : "white-btn"}`}
            onClick={promoSwitch}>
            ₽
          </MyButton>
        </div>

        <div className="discount-promo-btns">
          <MyButton
            tabIndex={tabIndex}
            type="button"
            className="white-btn"
            onClick={popupClose}>
            Отменить
          </MyButton>

          <MyButton
            tabIndex={tabIndex}
            id="confirm-promo"
            type="button"
            className="blue-btn"
            onClick={createHandler}>
            Создать
          </MyButton>
        </div>
      </form>
    </>
  );
}
