import React from 'react';
import {ExtractedLinks} from "merchery-lib";
import {useTabIndex} from "../../scripts/hooks/use-tabindex";
import Picture from "./picture/picture";
import MyButton from "./button/button";
import {TabIndex} from "../../dto/master-dto/tabindex.dto";

export interface IDropzoneContent {
	tabIndex?: TabIndex
	extractedImages: ExtractedLinks | null,
	deleteImage: () => Promise<any>,
	fallBackLink: string | null,
  children?: React.JSX.Element | React.JSX.Element[]
}

function DropzoneContent ({
	extractedImages,
	deleteImage,
	fallBackLink,
	tabIndex,
  children,
}: IDropzoneContent){
	const defaultTabIndex = useTabIndex();

	return (
		<React.Fragment>
			<Picture
				images={extractedImages || fallBackLink}
			/>
    
      {children}

			<MyButton
				removeDefaultClass
				className="new-loaded_file-close-btn"
				tabIndex={tabIndex !== undefined ? tabIndex : defaultTabIndex}
				onClick={(e) => {
					e.stopPropagation();
					deleteImage();
				}}
				onKeyUp={(e) => e.key === 'Enter' ? deleteImage() : false}
			>
				<i className="icofont-close-line-circled"></i>
			</MyButton>
		</React.Fragment>
	)
}

export default DropzoneContent