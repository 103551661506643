import { Id } from "merchery-lib";

export type TariffNames = {
	ORDINARY_ONLINE_PARCEL: string,
	ORDINARY_PARCEL_CLASS_1: string,
	ORDINARY_PARCEL: string,
}

export const tariffNames: { [key in keyof TariffNames]: string } = {
	ORDINARY_PARCEL: 'посылка',
	ORDINARY_ONLINE_PARCEL: 'посылка онлайн',
	ORDINARY_PARCEL_CLASS_1: 'посылка 1 классом',
	// simple_letter: 'простое письмо',
	// reg_letter: 'заказное письмо',
	// val_letter: 'ценное письмо',
	// simple_parcel: 'простая бандероль',
	// reg_parcel: 'заказная бандероль',
	// val_parcel: 'ценная бандероль',
	// pkg: 'посылка',
	// letter_reg_1class: 'заказное письмо 1 класса',
	// letter_val_1class: 'ценное письмо 1 класса',
	// reg_parcel1class: 'заказная бандероль 1 класса',
	// val_parcel1class: 'ценная бандероль 1 класса',
	// pkg_1class: 'заказная посылка 1 класса',
	// pkg_val_1class: 'ценная посылка 1 класса',
}

export const typeCategoryMapping: {[key: Id]: {type: string, category: string}} = {
	ORDINARY_ONLINE_PARCEL: { type: 'ONLINE_PARCEL', category: 'ORDINARY' },
	ORDINARY_PARCEL_CLASS_1: { type: 'PARCEL_CLASS_1', category: 'ORDINARY' },
	// 'simple_letter': { type: 'LETTER', category: 'SIMPLE' },
	// 'reg_letter': { type: 'LETTER', category: 'ORDERED' },
	// 'val_letter': { type: 'LETTER', category: 'WITH_DECLARED_VALUE' },
	// 'letter_reg_1class': { type: 'LETTER_CLASS_1', category: 'ORDERED' },
	// 'letter_val_1class': { type: 'LETTER_CLASS_1', category: 'WITH_DECLARED_VALUE' },
	// 'simple_parcel': { type: 'BANDEROL', category: 'SIMPLE' },
	// 'reg_parcel': { type: 'BANDEROL', category: 'ORDERED' },
	// 'val_parcel': { type: 'BANDEROL', category: 'WITH_DECLARED_VALUE' },
	// 'reg_parcel1class': { type: 'BANDEROL_CLASS_1', category: 'ORDERED' },
	// 'val_parcel1class': { type: 'BANDEROL_CLASS_1', category: 'WITH_DECLARED_VALUE' },
	// 'pkg': { type: 'POSTAL_PARCEL', category: 'SIMPLE' },
	// 'pkg_1class': { type: 'PARCEL_CLASS_1', category: 'ORDERED' },
	// 'pkg_val_1class': { type: 'PARCEL_CLASS_1', category: 'WITH_DECLARED_VALUE' },
};

function transformTariffFormat (key: Id): {
	'mail-category': string,
	'mail-type': string,
} {
	const { type, category } = typeCategoryMapping[key];

	const transformedData = {
		'mail-category': category,
		'mail-type': type,
	};

	return transformedData;
}

export function reverseTransformTariffFormat ({ 'mail-category': category, 'mail-type': type }: {
	'mail-category': string,
	'mail-type': string,
}): string {
	const key = Object.keys(typeCategoryMapping).find((id) =>
		typeCategoryMapping[id].type === type &&
		typeCategoryMapping[id].category === category
	);

	if (!key) {
		throw new Error('No id found for the given mail-category and mail-type');
	}

	return key;
}


// export function breakPochtaTariff (tariff: PochtaEmsTariff): Tariff[] {
// 	return (
// 		Object.keys(tariffNames)
// 		.map((key) => ({
// 			id: key,
// 			price: tariff[key as keyof TariffNames],
// 		}))
// 	)
// }
//
// export const codeToMessage: { [key: number]: string } = {
// 	100: 'Расчет завершен успешно',
// 	101: 'Некорректное значение массы отправления',
// 	102: 'Некорректный индекс отправителя',
// 	103: 'Некорректный индекс получателя',
// 	104: 'Отправка в данное ОПС невозможна',
// 	105: 'Исчерпан лимит запросов'
// }
//
// export function PochtaTariffHandler (tariff: Tariff) {
// 	return (
// 		<div className="delivery-tariff-row-header">
// 			{tariffNames[tariff.id as keyof TariffNames]} {tariff.price} {ptsansCurrency('₽')}
// 		</div>
// 	);
// }

export interface PochtaEmsTariff {
	code: number;
	locality_from: string;
	locality_to: string;
	city_from: string;
	city_to: string;
	region_from: string;
	region_to: string;
	simple_letter: number;
	reg_letter: number;
	val_letter: number;
	simple_parcel: number; // use
	reg_parcel: number;
	val_parcel: number;
	pkg: number; // use
	letter_reg_1class: number;
	letter_val_1class: number;
	reg_parcel1class: number; // use
	val_parcel1class: number;
	pkg_1class: number; // use
	pkg_val_1class: number;
	cod: number;
}