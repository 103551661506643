import React from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from 'src/scripts/pre-type/use-selector'
import { Id } from "merchery-lib";
import HeaderSelectedRowsContextMenu from "./selected-rows-context-menu"
import HeaderCheckbox from "./table-header-checkbox"

const ProductsTableHeader = () => {
  const products = useAppSelector(state => state.products)
  const selectedProducts = useAppSelector(state => state.selectedProducts)
  const selectedVariants = useAppSelector(state => state.selectedVariants)
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

  const dispatch = useDispatch()
  const selectedProductsDispatch = (items: Id[]) => dispatch({ type: 'SELECTED_PRODUCTS', payload: items})

  const checkAllProducts = () => {
    const selectOrUnselectAll = selectedProducts.length !== products.length ? products.map(product => product.id) : []

    selectedProductsDispatch(selectOrUnselectAll)
  }

  const msClass = moySkladIntegrationOn ? 'moy-sklad-item' : '';
  const headerMenuClass = selectedProducts?.length || selectedVariants?.length ? 'header-menu' : '';
  const selectedClass = selectedProducts?.length && selectedProducts.length === products.length ? 'selected' : '';

  const checked = Boolean(products.length === selectedProducts.length)

  const className = `table-items-header table-item 
    ${msClass} 
    ${selectedClass} 
    ${headerMenuClass}
  `;

  return (
    <div className={className}>
      {selectedProducts?.length || selectedVariants?.length ? (
        <>
          <HeaderCheckbox 
            checked={checked}
            checkAllProducts={checkAllProducts}/> 
          
          <HeaderSelectedRowsContextMenu/>
        </>
      ) : (
        <>
          {!moySkladIntegrationOn ? 
            <HeaderCheckbox
              checked={false}
              checkAllProducts={checkAllProducts}
            /> 
          : null}
          <div className="op-image"><div>Фото</div></div>
          <div className="op-title"><div>Наименование</div></div>
          <div className="op-quantity"><div>Артикул</div></div>
          <div className="op-price right-align"><div>Остаток</div></div>
          <div className={`op-total right-align`}><div>Цена</div></div>
          {!moySkladIntegrationOn ? (
            <div className="op-config"><div><i className="icofont-gear-alt"></i></div></div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default ProductsTableHeader