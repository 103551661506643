import React, { useContext } from 'react';
import pochtaLogo from '../../../../../../img/ems-pochta.png';
import pickpointLogo from '../../../../../../img/pick-logo.png';
import sdekLogo from '../../../../../../img/sdek-logo.png';
import { useAppSelector } from '../../../../../../scripts/pre-type/use-selector';
import { OrderDeliveryContext } from '../delivery';

export function SelectedDeliveryImage() {
  const {
    delivery
  } = useContext(OrderDeliveryContext)
  const deliveries = useAppSelector(state => state.deliveryServices)
  if(!delivery) {
    return null
  }

  const image = (() => {
    switch(delivery.type) {
      case 2: return pochtaLogo;
      case 3: return pochtaLogo;
      case 7: return pickpointLogo;
      case 9: return sdekLogo;
      default: return null;
    }
  })()

  if(image) {
    return <img src={image} alt="logo" />
  }

  return <div>{deliveries.find(d => d.id === delivery.type)?.name || ''}</div>;
}
