import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import FallbackComponent from 'src/components/_utility-components/error-boundary';
import { Id, MSValueAsCheckboxOption } from 'merchery-lib';
import MyCheckbox from '../checkbox/index';

interface Props {
  handler: (id: Id) => void,
  selects: MSValueAsCheckboxOption[],
}

function MultiSelect({
  handler,
  selects,
}: Props) {
  return (
    <div className='multi-select'>
      {selects.map((value) => (
        <MyCheckbox
          key={value.name_id}
          condition={value.value}
          text={value.name}
          actionHandler={() => handler(value.name_id)}/>
      ))}
    </div>
  );
}

export default withErrorBoundary(MultiSelect, {FallbackComponent});