import React from "react";
import { useContext } from "react";
import { Id } from "react-toastify";
import useTaxForms from "../../../../../scripts/hooks/use-tax-forms";
import SelectInput from "../../../../_utility-components/select-input/select-input";
import SettingModuleGroup from "../../setting-module-group";
import { SettingsContext } from "../../settings-context-wrapper";
import { SiteSettingsContext } from "../site";
import TimezoneSelect from "./timezone-select";

export default function SiteMisc() {
  const taxForms = useTaxForms();
  const {
    taxForm,
    setTaxForm,
  } = useContext(SettingsContext);
  const siteTaxForm = taxForms && taxForms.find(form => form.id === taxForm);

  const taxFormSelect = (id: Id) => {
    setTaxForm(id)
  };

  return <SettingModuleGroup
    header={null}
    className={'site-settings__misc'}
  >
    {taxForms ? 
      <SelectInput 
        items={taxForms} 
        selectHandler={form => taxFormSelect(form.id)}
        valueLabelName={'name'}
        selectedItem={siteTaxForm || undefined}
        inputProps={{
          children: (
            <div className="header-font-m" style={{ marginBottom: '8px' }}>
              Система налогообложения
            </div>
          ),
        }}
      /> 
    : null}
    
    <TimezoneSelect/>
  </SettingModuleGroup>;
}