import React from 'react';
import { BodyCell } from 'src/components/_utility-components/common-table/body-cell';
import { SubscriptionDto } from 'merchery-lib';

interface Props {
  subscription: SubscriptionDto
}

function SubscriptionProductTitle({subscription}: Props) {
  return <BodyCell myClassName="tt-date">
    {subscription.productItem.name}
  </BodyCell>;
}

export default SubscriptionProductTitle;