import React, { useState } from 'react';
import Popup from 'src/components/_utility-components/popup';
import MyButton from 'src/components/_utility-components/button/button';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import triangleDown from '../../../../img/triangle-down.png';
import { Id, Admin } from "merchery-lib";
import useOrderFilters from "../hooks/use-order-filters";

function HeaderManagerFilter() {
  const [filters, filterChanger, cleanFilters] = useOrderFilters();
  const manager = useAppSelector(state => state.admin);
  const [popupOpened, setPopupOpened] = useState(false);
  const popupClose = () => setPopupOpened(false);
  const btnHandler = (id: Id | null) => 
    filterChanger({
      itemIds: [id], 
      filterName: 'admin_id'
    });

  if(!filters || !manager) {
    return null
  }

  const noManagerSelected = (
    filters.admin_id !== undefined && 
    filters.admin_id.find(m => m.id === null)?.selected) 
      ? 'active' 
      : '';
  
  return (
    <div className="filter-btn">
      <MyButton className="filter-btn-inner"
        onMouseDown={e => e.button === 0 && setPopupOpened(!popupOpened)}
        onKeyUp={e => e.key === 'Enter' && setPopupOpened(!popupOpened)}
      >
        Менеджеры <img alt="" src={triangleDown}/>
      </MyButton>

      {popupOpened ? (
        <Popup
          popupClose={popupClose}
          className={'manager admin-popup'}
        >
          <div className="popup-group">
            <ManagerBtn 
              manager={undefined} 
              btnHandler={btnHandler}
              className={noManagerSelected}
            />
            {manager.map(admin => {
              const thisManagerSelected = filters.admin_id && filters.admin_id.find(m => m.id === admin.id)?.selected ? 'active' : '';
              return <ManagerBtn
                key={admin.id}
                manager={admin}
                className={thisManagerSelected}
                btnHandler={btnHandler}
              />
            })}
          </div>

          <div className="popup-group">
            <MyButton
              className="popup-element general-popup-clean-btn"
              onClick={() => {
                cleanFilters('admin_id')
                popupClose()
              }}
            >
              <div>Очистить</div>
            </MyButton>
          </div>
        </Popup>
      ) : null}
    </div>
  );
}

interface ManagerBtnProps {
  manager: Admin | undefined;
  btnHandler: (id: Id | null) => void;
  className?: string;
  selected?: boolean
}

export function ManagerBtn({
  manager: argManager, 
  btnHandler,
  className,
  selected
}: ManagerBtnProps) {

  const nullAdmin = {
    id: null,
    name: 'Без менеджера', 
    color: 'white', 
  }

  const manager = argManager || nullAdmin

  return (
    <MyButton
      removeDefaultClass
      onClick={() => btnHandler(manager.id)} 
      className={`popup-element ${className || ''} ${selected ? 'active' : ''}`}
    >
      <div className="selected">
        <i className="icofont-check-alt"></i>
      </div>

      <div className='not-selected-char' 
        style={{
          backgroundColor: manager?.color || 'white', 
          ...(manager?.color === 'white' && {
            color: '#292D38'
          }),
          border: manager ? 'none' : '1px solid #e3e3e3'
        }}
      >
        {manager.name.charAt(0)}
      </div>
      <span>{manager.name}</span>
    </MyButton>
  )
}

export default HeaderManagerFilter;