import React from 'react';
import { Link } from 'react-router-dom';
import { mercheryFetch } from '../../scripts/fetchConstructor';
import { validateResponse } from '../../scripts/functions';
import history from '../../scripts/history';
import { useAppSelector } from '../../scripts/pre-type/use-selector';
import useMounted from '../../scripts/hooks/use-mounted';
import { usePopup } from '../../scripts/hooks/use-popup';
import MyButton from '../_utility-components/button/button';

export function HeaderAdminProfilePopup() {
  const _isMounted = useMounted();
  const adminId = localStorage.getItem('admin');
  const admins = useAppSelector(state => state.admin);
  const currentAdmin = adminId !== null && admins && admins.find((a) => a.id === +adminId);

  const { RenderButton, RenderPopup, openPopup } = usePopup();

  const logout = () => {
    mercheryFetch<true>('auth/logout', 'POST', {
      adminId: adminId
    })
    .then((res) => {
      if (_isMounted.current && validateResponse(res)) {
        localStorage.removeItem('jwt');
      }
    })
    .finally(() => {
      history.push('/sign-in');
    });
  };

  if (!currentAdmin) {
    return null;
  }

  return <div className="merchery-header-user">
    <RenderButton
      onClick={openPopup}
      className="merchery-header-user-container"
      removeDefaultClass
    >
      <div
        style={{ backgroundColor: currentAdmin.color }}
        className="user-circle">
        {currentAdmin?.name ? currentAdmin.name.charAt(0) : null}
      </div>
      <div className="user-name-and-post">
        <div className="user-name">{currentAdmin.name}</div>
        <div className="user-post">{currentAdmin.post}</div>
      </div>
    </RenderButton>

    <RenderPopup
      className={'admin-popup'}>
      <div className='popup-group'>
        <Link
          to="/app/orders"
          className='popup-element'
        >Профиль</Link>
        <MyButton
          removeDefaultClass
          className="sign-out-btn popup-element"
          onClick={logout}
        >Выйти</MyButton>
      </div>
    </RenderPopup>
  </div>;
}
