import React from 'react';
import MyButton from '../../../_utility-components/button/button';
import triangleDown from '../../../../img/triangle-down.png';
import { usePopup } from '../../../../scripts/hooks/use-popup';
import useClientFilters from "../../../../scripts/hooks/use-client-filters";

function ClientsFiltersElement() {
  const { RenderButton, RenderPopup} = usePopup()
  const [clientsFilters, updateFilters, cleanFilters] = useClientFilters();

  return (
    <div className="filter-btn">
      <RenderButton
        className="filter-btn-inner"
        removeDefaultClass
      >
        Группа <img alt="" src={triangleDown}/>
      </RenderButton>

      <RenderPopup 
        className={'status left-8px'}
      >
        <div className="popup-group">
          {clientsFilters?.group ?
            clientsFilters.group.map((gr) =>
              <MyButton
                key={gr.id}
                onClick={() =>
                  updateFilters({
                    itemIds: [gr.id],
                    filterName: 'group',
                  })
                }
                className={`popup-element ${gr.selected ? 'active' : ''}`}
              >
                <div className="checkbox">
                  <i className="icofont-check-alt"></i>
                </div>
                {gr.text}
              </MyButton>
            )
          : null}
        </div>

        <div className="popup-group">
          <MyButton 
            removeDefaultClass
            className="popup-element general-popup-clean-btn" 
            onClick={() => cleanFilters('group')}
          >
            <div>Очистить</div>
          </MyButton>
        </div>
      </RenderPopup>
    </div>
  );
}

export default ClientsFiltersElement;