import { Id, ExtendedProduct } from "merchery-lib";
import {useAppSelector} from "../../../../../scripts/pre-type/use-selector";
import React from "react";
import {Droppable} from "react-beautiful-dnd";
import ProductsTableRows from "./table-rows";

export default function SortedDroppableList ({
	droppableId,
  sortedProducts,
}: {
	droppableId: Id | null,
  sortedProducts: ExtendedProduct[]
}) {
	const searchInput = useAppSelector(state => state.searchInput)
	const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false);
	const productDragAndDropIsDisabled = !!(moySkladIntegrationOn || searchInput)

	return (
		<Droppable
			isDropDisabled={productDragAndDropIsDisabled}
			droppableId={`${droppableId}`}
			type={`PRODUCTS-${droppableId}`}
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.droppableProps}
				>
					<ProductsTableRows
						sortedProducts={sortedProducts}
					/>
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	)
}