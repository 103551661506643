/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useMemo} from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyButton from '../../../../../_utility-components/button/button';
import Popup from '../../../../../_utility-components/popup';
import {validateResponse} from "src/scripts/functions";
import {batch} from "react-redux";
import {
  OneCharContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/one-char-logic";
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";
import charEntitiesPaths
  from "src/components/main-pages/products/product-page-modules/characteristics-modules/char-entities-paths";
import useMounted from "src/scripts/hooks/use-mounted";

const CharGroupDeletePopup = () => {
  const _isMounted = useMounted()
  const {
    thisCharGroup,
    setToDelete,
  } = useContext(OneCharContext)

  const {
    charGroups,
    setCharGroups,
    initialCharGroups,
    setInitialCharGroups,
    patchReq,
  } = useContext(CharacteristicsContext)

  const groupName = thisCharGroup?.name;
  const categories = useAppSelector(state => state.categoriesAssociatedWithProduct);

  const categoryName = useMemo(() => 
    categories.find(c => c.id === thisCharGroup?.category_id)?.name
  , [thisCharGroup, categories]);

  const deleteCharGroup = async () => {
    if(!thisCharGroup) {
      return;
    }

    const res = await patchReq(charEntitiesPaths.charGroupsRoutePath, [{
      id: thisCharGroup.id,
      deleted: true
    }])

    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    batch(() => {
      setCharGroups(
        charGroups.filter(g =>
          g.id !== thisCharGroup.id)
      );
      setInitialCharGroups(
        initialCharGroups.filter(g =>
          g.id !== thisCharGroup.id)
      );
    })
  }

  const popupClose = () => setToDelete(false)

  const extendedSaveHandler = () => {
    deleteCharGroup();
    popupClose();
  };

  return (
    <Popup
      className={'confirm-popup fixed-on-center'}
      popupClose={popupClose}
      tabIndexDeep={3}
      withCloseBtn
      withBlackout
    >
      <h3 className='confirm-popup__h header-font-xl'>
        Подтвердите удаление шаблона
      </h3>

      <div className='confirm-popup__body'>
        <div className='confirm-popup__paragraph'>
          Шаблон группы характеристик «<b>{groupName}</b>» связан с категорией товаров «<b>{categoryName}</b>».
        </div>

        <div className='confirm-popup__paragraph'>
          Шаблон характеристик будет <b>удален</b> для всей категории товаров, 
          а значения характеристик будут удалены для всех товаров, 
          расположенных в категории «<b>{categoryName}</b>» и ее дочерних категориях.
        </div>

        <div className='confirm-popup__paragraph'>
          Вы уверены, что хотите продолжить?
        </div>
      </div>

      <div className='confirm-popup__btn-wrapper'>
        <MyButton className={'white-btn width-228'}
          onClick={popupClose}
        >
          Назад
        </MyButton>

        <MyButton className={`red-btn width-228`}
          onClick={extendedSaveHandler}
        >
          Да, сохранить изменения
        </MyButton>
      </div>
    </Popup>
  )
}

export default CharGroupDeletePopup;
