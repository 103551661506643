import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { batch } from 'react-redux';
import { Id, CharsLabelDto, CharsTypesDto } from 'merchery-lib';
import SelectInput from '../../../../../_utility-components/select-input/index';
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";

interface Props {
  labelChangeByField: (changes: Partial<CharsLabelDto>) => void;
  label: CharsLabelDto;
}

function CharLabelTypeSelect({
  label,
  labelChangeByField,
}: Props) {
  const {
    labelTypes,
    scopes,
    setScopes,
  } = useContext(CharacteristicsContext)
  const labelTypeId = useMemo(() => label.type_id, [label]);
  const getSelectedType = useCallback((typeId: Id) => labelTypes.find(type => type.id === typeId), [labelTypes]);
  const [selectedType, setSelectedType] = useState(getSelectedType(labelTypeId));

  useEffect(() => {
    if(selectedType && labelTypeId !== selectedType.id) {
      console.log(getSelectedType(labelTypeId))
      setSelectedType(getSelectedType(labelTypeId))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelTypeId, selectedType])

  const selectHandler = useCallback((type: CharsTypesDto) => {
    const newType = getSelectedType(type.id)

    if(!newType) {
      return false
    }

    const changedScopes = scopes.map((scope) => {
      if(scope.label_id === label.id && (newType.id === 4 || label.type_id === 4)) {
        return {
          ...scope,
          value: null,
          value_id: null,
        }
      }
      return scope
    })

    batch(() => {
      setSelectedType(newType)
      labelChangeByField({
        type_id: newType.id,
        type_name: newType.name,
      })
      setScopes(changedScopes)
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelTypes, labelChangeByField])

  if(!selectedType) {
    return <>
      {label.type_name}
    </>
  }
  
  return (
    <SelectInput
      className={'char-label_type-select my-select-input--tall-input'}
      items={labelTypes}
      selectedItem={selectedType}
      valueLabelName={'desc'}
      selectHandler={selectHandler}
    /> 
  );
}

export default CharLabelTypeSelect