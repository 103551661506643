import { useCallback } from 'react';
import {DraggableLocation, DropResult, ResponderProvided} from 'react-beautiful-dnd';
import {useAppSelector} from "src/scripts/pre-type/use-selector";

type DropResultDestinationIndex = Omit<
	DropResult, 'destination'> & {
	destination: Omit<DropResult['destination'], 'index'> & DraggableLocation & {index: number}
}

// Define a type for the callback function
type OnDragEndCallback<T> = (result: DropResultDestinationIndex, provided: ResponderProvided, newList: T[], removed: T) => void;

// Custom hook to handle drag end
export const useDragEndHandler = <T>(items: T[], callback: OnDragEndCallback<T>) => {
	const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

	return useCallback((result: DropResult, provided: ResponderProvided) => {
		if (moySkladIntegrationOn || result.destination?.index === undefined) {
			return;
		}

		const newList = [...items];
		const [removed] = newList.splice(result.source.index, 1);
		newList.splice(result.destination.index, 0, removed);

		callback(result as DropResultDestinationIndex, provided, newList, removed);
	}, [callback]);
};
