import React from 'react';
import { useAppSelector } from '../../scripts/pre-type/use-selector';

function BrightnessFilter() {
  const tabIndexLevel = useAppSelector(state => state.tabIndexLevel);
  return (
    <div 
      className="brightness-filter"
      style={{zIndex: tabIndexLevel}}
    ></div>
  );
}

export default BrightnessFilter;