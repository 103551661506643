import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { useTabIndex } from "../../../../scripts/hooks/use-tabindex";
import {ProductPageLocationState} from "src/components/main-pages/products/product";
import {ProductsContext} from "src/components/main-pages/products/products";

export const CreateProductPageLink = ({
  prevPage
}: {
  prevPage: string
}) => {
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)
  const tabIndex = useTabIndex()
  const {
    selectedCategory
  } = useContext(ProductsContext)

  const productPageLocationState: ProductPageLocationState = {
    prevPage: prevPage,
    categoryFilter: selectedCategory?.id
  }

  return (
    moySkladIntegrationOn ? 
      <div className='small-font'>
        Включена интеграция с Моим Складом, заказы формируются через МойСклад
      </div>
    : (
      <Link
        tabIndex={tabIndex} 
        to={{
          pathname: '/app/products/create-product', 
          state: productPageLocationState
        }} 
        className="products-create-btn text-font-m"
      >
        Создать товар
      </Link>
    )
  )
}