import React, {useContext} from 'react';
import MyButton from '../../../../_utility-components/button/button';
import { OrderContext } from '../../order-page';
import { OrderDeliveryContext } from './delivery';
import { OrderDeliveryChangeStateSwitcher } from './order-delivery-change-state-switcher';
import { OrderDeliveryStatus } from './order-delivery-status';

interface Props {
  changeDeliveryState: boolean;
  toInitial: () => void;
}

export function OrderDeliveryHeader({ 
  changeDeliveryState, 
  toInitial,
}: Props) {
  const {
    order,
  } = useContext(OrderContext);

  const {
    delivery,
    safeHandler,
  } = useContext(OrderDeliveryContext);

  if(!order) {
    return null
  }

  return <div className="op-delivery-top header-font-m sticky-header op-delivery_element-paddings">
    <span>Отгрузка и доставка</span>

    {changeDeliveryState && delivery
      ? (
        <div className="op-delivery-change-btns">
          {delivery.type ? <>
            {order.current_delivery_id ?
              <MyButton
                className="white-btn"
                onClick={toInitial}>
                Отменить
              </MyButton>
            : null}

            <MyButton
              id="merchery-btn"
              className="blue-btn"
              onClick={() => safeHandler()}
            >
              Сохранить
            </MyButton>
          </> : null}
        </div>
      )
    : !delivery?.type
      ? <OrderDeliveryChangeStateSwitcher />
      : <OrderDeliveryStatus/>
    }
  </div>;
}
