import React, { useState, useEffect, ChangeEvent, CSSProperties, FocusEvent, FormEvent, HTMLInputTypeAttribute, KeyboardEvent, MouseEvent, useRef, memo } from 'react';
import { Children } from '../../../dto/children';
import { TabIndex } from '../../../dto/master-dto/tabindex.dto';
import { removeMessage, uuidv4 } from '../../../scripts/functions';

import MyButton from '../button/button';

export interface MyInputProps {
  value: string | number,
  name?: string,
  placeholder?: string, 
  myClassName?: string, 
  placeholderClass?: string,
  disabledDefaultClass?: boolean,
  id?: string,
  widthOfValue?: true,
  defaultValue?: string | number, 
  required?: boolean, 
  pattern?: string,
  withFocus?: boolean, 
  withBlur?: boolean, 
  withBind?: string,
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onFocus?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onEnter?: (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onEscape?: (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onInput?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onCount?: OnCountHandler,
  onClick?: React.MouseEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onMouseDown?: (event: MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  messagetext?: string,
  autocomplete?: 'on' | 'off',
  readOnly?: boolean,
  type?: HTMLInputTypeAttribute | 'textarea',
  style?: CSSProperties,
  styleLabel?: CSSProperties,
  tabIndex?: TabIndex,
  min?: number,
  max? : number,
  noPlaceholder?: true,
  customArrow?: true,
  shortInput?: true,
  disabled?: boolean,
  children?: Children,
  innerChildren?: string | JSX.Element | React.ReactElement | null,
  maxLength?: number,
  title?: string,
  inputRef?: React.MutableRefObject<any> | ((instance: any | null) => void),
  actionDelay?: number,
  hasError?: boolean
  autoComplete?: string
}

function MyInput ({
  name, 
  placeholder, 
  myClassName = '', 
  placeholderClass = '',
  disabledDefaultClass,
  value,
  id,
  widthOfValue,
  defaultValue, 
  required = false, 
  withFocus = true, 
  withBlur = true, 
  withBind,
  onChange,
  onFocus,
  onBlur,
  onKeyUp,
  onKeyDown,
  onEnter,
  onEscape,
  onInput,
  onClick,
  onCount,
  messagetext = 'Некорректный ввод',
  readOnly,
  type = 'text',
  style,
  styleLabel,
  tabIndex = 0,
  min = undefined,
  max = undefined,
  noPlaceholder,
  customArrow,
  shortInput,
  disabled,
  children,
  innerChildren,
  maxLength,
  title,
  inputRef,
  actionDelay = 0,
  hasError,
  autoComplete = 'new-password',
  ...props
}: MyInputProps) {
  const noPlaceholderClass =noPlaceholder || !placeholder ? 'no-placeholder' : '';
  const customArrowClass = customArrow ? 'custom-number-input-wrapper' : '';
  const defaultClassName = !disabledDefaultClass ? 'merchery-label' : '';
  const inputPlaceholder = placeholder && noPlaceholder ? placeholder : ' ';
  const inputHeight38ClassName = shortInput ? 'merchery-label__input--height-38' : '';
  const placeholderClassName = `${placeholderClass} placeholder`;
  const inputWithPlaceholderClass = !noPlaceholder && placeholder ? `merchery-label__input--placeholder-gap` : '';

  const inputID = useRef(uuidv4());
  const inputEvent = useRef<ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>();
  const [intermediateValue, setIntermediateValue] = useState(value);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if(value != intermediateValue) {
      setIntermediateValue(value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const inputOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    if(withFocus) {
      const input = e.target;
      const parent = input.parentElement;
      if(parent?.classList.contains('has-error')) {
        removeMessage(parent)
      }
    }
    onFocus?.(e);
  }

  const inputOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    if(withBlur) {
      const input = e.target;

      if(!input.checkValidity()) {
        const parent = input.parentElement;
        if(parent) {
          parent.classList.add('has-error');
          const newMessage = !input.value ? 'Необходимо заполнить поле' : input.getAttribute('messagetext')
          newMessage && parent.setAttribute('messagetext', newMessage)
        }
      }
    }
    onBlur?.(e);
  }

  const inputOnKeyUp = (e: KeyboardEvent<HTMLInputElement> ) => {
    if(disabled) return e.preventDefault()
    if(withBind) {
      if(e.key === 'Enter') {
        const btn = document.getElementById(withBind && typeof withBind === 'string' ? withBind : 'merchery-btn');
        if(btn) btn.click();
      }
    }
    
    onKeyUp?.(e);

    if(e.key === 'Enter' && !!onEnter) onEnter(e);
    if(e.key === 'Escape' && !!onEscape) onEscape(e)
  }

  const inputOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if(disabled) return event.preventDefault();
    // if(type === 'number') {
    //   const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    //   const disallowedSymbols = ['!', '#', '$', '%', '(', ')'];
      
    //   const input = (event.target as HTMLInputElement).value;
    //   if (
    //     !/[\d\.]/.test(key) && 
    //     !event.ctrlKey && 
    //     event.keyCode !== 8 && 
    //     event.keyCode !== 46 &&
    //     disallowedSymbols.includes(key) &&
    //     (key === '.' && input.indexOf('.') >= 0)
    //   ) {
    //     event.preventDefault();
    //     return false;
    //   }
    // }
    onKeyDown?.(event);
  }

  const changer = <T extends HTMLInputElement | HTMLTextAreaElement>(e: ChangeEvent<T>) => {
    let value: number | string = e.currentTarget.value;

    if(disabled) {
      return e.preventDefault()
    }

    if(type === 'number') {
      if(value === '') {
        e.target.value = ''
        e.currentTarget.value = ''
        value = ''
      } else {
        const numValue = Number(value)

        const tooBig = max !== undefined && numValue > max;
        const tooSmall = min !== undefined && numValue < min;
  
        if(tooSmall || tooBig) {
          value = tooSmall ? min : tooBig ? max : value
          e.target.value = '' + value
        }
      }
    }

    if(type === 'nav') {
      e.target.value = e.target.value.replace(/\s+/g, '');
    }
    e.persist();

    inputEvent.current = e

    setIntermediateValue(value)

    let timeOutId = setTimeout(() => {
      if(inputEvent.current) {
        const e = inputEvent.current
        onInput?.(e)
        onChange?.(e)
      }
    }, actionDelay);
    return () => clearTimeout(timeOutId);
  }

  const basedProps = {
    name: name,
    ref: inputRef,
    title: title || '' + value,
    value: intermediateValue,
    disabled: !!disabled,
    readOnly: readOnly,
    tabIndex: tabIndex,
    className: 'merchery-label__input',
    defaultValue: defaultValue !== undefined ? defaultValue : undefined,
    required: required ? required : undefined,
    placeholder: inputPlaceholder,
    onChange: changer,
    onClick: onClick,
    autoComplete,
  }

  const labelClassName = `${defaultClassName} ${customArrowClass} ${noPlaceholderClass} ${myClassName} ${hasError ? 'has-error' : ''}`;
  const textAreaProps = {
    ...basedProps,
    className: `${basedProps.className} merchery-label__textarea`,
    value: basedProps.value || '',
  }

  return (
    <label 
      htmlFor={id || inputID.current}
      className={labelClassName} //@ts-ignore
      messagetext={messagetext}
      style={styleLabel}>

      {children}

      <div className={`merchery-label__inner`}>
        
        {type !== 'textarea' ? 
          <input 
            id={id || inputID.current}
            style={style}
            maxLength={maxLength}
            onFocus={inputOnFocus}
            onBlur={inputOnBlur}
            onKeyUp={inputOnKeyUp}
            onKeyDown={inputOnKeyDown}
            type={type}
            {...basedProps}
            className={ `${basedProps.className} ${inputHeight38ClassName} ${inputWithPlaceholderClass}`}
            {...props}
          />
        : <textarea {...textAreaProps}/>}

        {!noPlaceholder ?
          <div className={placeholderClassName}>
            {placeholder}
          </div>
        : null}

        {innerChildren}

        {customArrow && onCount && type === 'number' ?
          <NumInputArrows
            disabled={disabled}
            onCount={(num) => {
              // setIntermediateValue(num)
              onCount(num)
            }}
            max={max}
            value={+value}
            min={min}
          />
        : null}

      </div>

    </label>
  )
}

interface InputArrowsProps {
  disabled?: boolean
  onCount: OnCountHandler
  max?: number
  value: number
  min?: number
}

export type OnCountHandler = (count: number) => void

function NumInputArrows({
  disabled,
  onCount,
  max,
  value,
  min
}: InputArrowsProps) {
  const incHandler = () => {
    if(disabled) return onCount(value)
    return onCount(max !== undefined && value > max ? value : +value + 1)
  }

  const decHandler = () => {
    if(disabled) return onCount(value)
    return onCount(min !== undefined && value <= min ? 0 : +value - 1)
  }

  return (
    <>
      <MyButton
        className="custom-number-input-arrow inc-arrow"
        tabIndex={-1}
        removeDefaultClass
        disabled={disabled}
        onClick={incHandler}
      >
        <i className="icofont-simple-up"></i>
      </MyButton>

      <MyButton
        className="custom-number-input-arrow dec-arrow"
        tabIndex={-1}
        removeDefaultClass
        disabled={disabled}
        onClick={decHandler}
      >
        <i className="icofont-simple-down"></i>
      </MyButton>
    </>
  );
}

export default memo(MyInput);