import {Dispatch} from "react";
import { Id } from "merchery-lib";

export const updateDispatch =
	<E extends {id: Id}, Y extends string>
	(items: E[], dispatch: Dispatch<unknown>, type: Y ) =>
		<T extends {id: Id} = E>(changes: T | T[]) => {
			let payload: E[];

			if(Array.isArray(changes)) {
				payload	= items.map(item => {
					const foundChanged = changes.find(changedOne => changedOne.id === item.id);
					return foundChanged ? {...item, ...foundChanged} : item;
				})
			} else {
				payload	= items.map(item => item.id === changes.id ? ({...item, ...changes}) : item)
			}

			dispatch({ type: type, payload: payload })
		}

export const deleteDispatch =
	<E extends {id: Id}, Y extends string>
	(items: E[], dispatch: Dispatch<unknown>, type: Y ) =>
		(deletedIds: Id | Id[]) => {
			let payload: E[];

			if(Array.isArray(deletedIds)) {
				payload	= items.filter(item =>
					deletedIds.some(deletedOne =>
						deletedOne !== item.id
					)
				)
			} else {
				payload	= items.filter(item => item.id !== deletedIds)
			}
			dispatch({ type: type, payload: payload })
		}

export const createDispatch =
	<E extends {id: Id}, Y extends string>
	(items: E[], dispatch: Dispatch<unknown>, type: Y) =>
		(newItem: E | E[]) => {
			let payload: E[];

			if(Array.isArray(newItem)) {
				payload = [...items, ...newItem.filter(n => !items.some(item => item.id === n.id))]
			} else {
				if(items.some(order => order.id === newItem.id)) {
					return false
				}

				payload = [...items, newItem]
			}

			dispatch({ type: type, payload: payload })
		}
