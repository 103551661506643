/* eslint-disable */
import React, {  } from 'react';
// import screencast from 'src/img/video-test.webm';

const Playground = () => {

  return (
    <div>
      {/* <video src={String(screencast)} width={300} height={150} autoPlay={false} muted/> */}
    </div>
  );
}

export default Playground;