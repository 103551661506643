import flatMap from 'lodash/flatMap';
import omit from 'lodash/omit';
import { ExtendedProduct, Id, ProductOption, ProductsAttrValues, ProductVariantExtended } from "merchery-lib";
import { MutableRefObject } from "react";
import { useAppSelector } from '../pre-type/use-selector';
import { compareArrays, CompareResult } from '../functions';

export type WithOptionId<T> = T & {optionId: Id | null}

export function useProductComparison(initProduct: MutableRefObject<ExtendedProduct | undefined>) {
  const product = useAppSelector((state) => state.product);
  const options: ProductOption[] = useAppSelector((state) => state.productOptions);
  const initOptions: ProductOption[] = useAppSelector((state) => state.initOptions);
  const variants: ProductVariantExtended[] = useAppSelector((state) => state.productVariants);
  const initVariants: ProductVariantExtended[] = useAppSelector((state) => state.initVariants);
  const stories = useAppSelector((state) => state.productStories);
  const initStories = useAppSelector((state) => state.initStories);

  const compareProducts = () => product && initProduct.current && compareArrays({ 
    init: [omit(initProduct.current, ['src', 'relatedProducts', 'analogProducts'])], 
    actual: [omit(product, ['src', 'relatedProducts', 'analogProducts'])], 
  });

  const compareOptions = () => { 
    const comparedOptions = compareArrays({ 
      init: 
        initOptions
          .map(o => omit(o, ['values'])), 
      actual: 
        options
          .filter(option => !option.notSaved)
          .map(o => omit(o, ['values'])), 
    })

    return comparedOptions
  };

  const compareVariants = () => compareArrays({ 
    init: initVariants, 
    actual: variants, 
  });

  const compareOptionsValues = (): CompareResult<WithOptionId<ProductsAttrValues>> => {
    const initVals = flatMap(initOptions, option => 
      option.values.map(value => ({
        ...value, 
        optionId: option.notCreated 
          ? null 
          : option.id
      }))
    );

    const actualVals = flatMap(options, option => 
      option.values.map(value => ({
        ...value, 
        optionId: option.notCreated 
          ? null 
          : option.id
      }))
    );

    return compareArrays({ 
      init: initVals, 
      actual: actualVals, 
      leaveInitFields: ['optionId'], 
    });
  };

  const compareStories = () => {
    if(!stories) {
      return null
    }
    
    return {
      storiesChanges: compareArrays({ 
        init: [omit(initStories, ['items'])], 
        actual: [omit(stories, ['items'])], 
      }),
      itemsChanges: compareArrays({ 
        init: 
          initStories?.items 
            ? initStories.items.map(item => omit(item, ['image'])) 
            : [], 
        actual: 
          stories?.items 
            ? stories.items.map(item => omit(item, ['image'])) 
            : [], 
      })
    };
  }

  return { 
    compareProducts, 
    compareOptions, 
    compareVariants, 
    compareOptionsValues,
    compareStories
  };
}