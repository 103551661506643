import React from "react"
import { Id, ProductsAttrValues } from "merchery-lib";
import { usePopup } from "../../../../../scripts/hooks/use-popup"
import MyButton from "../../../../_utility-components/button/button"

interface Props {
  possibleValues: ProductsAttrValues[]
  addNewValue: (initialValue: string, id?: Id | undefined) => void
}

export function ProductOneOptionPossibleValues({
  possibleValues = [], 
  addNewValue
}: Props) {
  const visiblePart = possibleValues;
  const dropdownPart = possibleValues;

  const { RenderButton, RenderPopup, closePopup } = usePopup();
  
  return (
    <div className="product-page-option-possible-values">
      <div className="product-option-possible-value__visible-part">
        {visiblePart?.map((v) => 
          <MyButton
            key={v.id}
            onClick={() => addNewValue(v.value, v.id)}
            tabIndex={-1}
            className='product-page-option-possible-value product-page-option-possible-value--blue'
          >
            {v?.value || null}
          </MyButton>
        )}
      </div>
      
      {dropdownPart.length ? 
        <div className="possible-values-dropdown-list-wrapper">
          <RenderButton
            tabIndex={-1}
            className={'product-page-option-possible-value product-page-option-possible-value--blue possible-values-dropdown-list'}
          >
            <i className="icofont-simple-down"></i>
          </RenderButton> 
        </div>
      : null}
      
      <RenderPopup
        className={'product-option-possible-values'}
      >
        <div className="popup-group">
          {dropdownPart?.map((v) => 
            <MyButton 
              key={v.id}
              removeDefaultClass
              onClick={() => {
                closePopup()
                addNewValue(v.value, v.id)
              }}
              tabIndex={-1} 
              className='product-page-option-possible-value popup-element'
            >
              {v?.value || null}
            </MyButton>
          )}
        </div>
      </RenderPopup>
    </div>
  )
}