import React from 'react';
import { ptsansCurrency } from '../../../../scripts/functions';


export function TopSummary({
  header, mainText, bottomText, mainWithColor, withCurrency
}: {
  header: string;
  mainText: string;
  bottomText: string;
  mainWithColor?: true;
  withCurrency?: true;
}) {
  return (
    <div className='client-summary__item client-summary__grid-element'>
      <h4 className='header-font-s'>
        {header}
      </h4>
      
      <div className='main-item' 
        style={mainWithColor ? { color: '#5079E0' } : undefined}
      >
        {mainText}
      </div>

      <div className='text-font-l'>
        {bottomText} {withCurrency ? ptsansCurrency('₽', { opacity: .5 }) : ''}
      </div>
    </div>
  );
}
