/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useState } from 'react';
import dotsMenu from 'src/img/context-menu-dots.svg';
import MyButton from '../../../../../../_utility-components/button/button';
import Popup from '../../../../../../_utility-components/popup';

function OneCharDropDownMenu({
  setEditHandler,
  clearCharData
}: {
  setEditHandler: () => void,
  clearCharData: () => void,
}) {
  const [popupOpened, switchPopup] = useState(false);
  const closePopup = useCallback(() => switchPopup(!popupOpened), [popupOpened]);

  const clearHandler = () => {
    clearCharData()
    closePopup()
  };

  const startChangeHandler = () => {
    setEditHandler()
    closePopup()
  };

  return (
    <div className='drop-down-menu__wrapper'>
      <MyButton
        className={'drop-down-menu__open-btn'}
        onClick={closePopup}
      >
        <img src={String(dotsMenu)} alt={''}/>
      </MyButton>

      {popupOpened && 
        <Popup 
          popupClose={closePopup}
          className={'drop-down-menu drop-down-menu_position'}
          >
          <div className='popup-group'>
            <div 
              className='popup-element header-font-s' 
              onClick={startChangeHandler}>
              Изменить
            </div>
            <div 
              className='popup-element red-color header-font-s' 
              onClick={clearHandler}>
              Очистить значения
            </div>
          </div>
        </Popup>
      }
    </div>
  )
}

export default memo(OneCharDropDownMenu)