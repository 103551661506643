// Используется для создания унифицированных систем фильтров, требуется обертка вокруг хука

import filterChanger, {CommonFilters, FilterChangerProps} from "../utils/entity-filters";

export type CleanFilters<T,> = (filterName: keyof CommonFilters<T>) => void;
export type UpdateFilters<T,> = (...changes: FilterChangerProps<T>[]) => Promise<CommonFilters<T> | undefined>;

export default function useTableFilters<T, > (
	filters: CommonFilters<T> | undefined,
	dispatch: (filters: CommonFilters<T>) => void,
	initFilters: () => Promise<CommonFilters<T>>
) {

	const updateFilters: UpdateFilters<T> = async (...changes) => {
		let newFilters: CommonFilters<T> | undefined = filters ? {...filters} : undefined;

		if(!newFilters) {
			newFilters = await initFilters()
		}

		changes.forEach((oneFilterChange) => {
			if(newFilters) {
				const newLocalFilters = filterChanger(oneFilterChange, newFilters)
				newFilters = newLocalFilters
			}
		})

		dispatch(newFilters);
		return newFilters;
	};

	const cleanFilters: CleanFilters<T> = (filterName) => {
		const filter = filters && filters[filterName];
		if (!filter) {
			return false;
		}

		let ids = filter.map(item => item.id);

		updateFilters({
			itemIds: ids as (string | number)[],
			filterName,
			concreteSelected: false,
		});
	};

	return [updateFilters, cleanFilters] as const;
}
