import React, {createContext, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Route, Switch, useHistory, useLocation} from 'react-router';
import {mercheryFetch} from '../../../scripts/fetchConstructor';
import {validateResponse} from '../../../scripts/functions';
import {useAppSelector} from '../../../scripts/pre-type/use-selector';
import CategoriesFilterAndControl, {CategoryFilterChanger} from './categories-filter-and-control';
import CategoryPage from './category';
import {Category, Id} from "merchery-lib";
import {MainRouteChild} from "../main-page";

interface Props extends MainRouteChild {
}

export const CategoriesContext = createContext<{
	newCategories: Set<Id>,
	setNewCategories: (newCategories: Set<Id>) => void,
	selectedCategory: Category | undefined,
	setSelectedCategory: (category: Category | undefined) => void
}>({
	newCategories: new Set([]),
	setNewCategories: ()=> {},
	selectedCategory: undefined,
	setSelectedCategory: ()=> {}
})

function Categories (props: Props){
	const _isMounted = useRef(true);
	const categories = useAppSelector(state => state.categories);
	const [newCategories, setNewCategories] = useState<Set<Id>>(new Set([]));
	const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined);

	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		props.setCurrentPage('categories');

		mercheryFetch<Category[]>('category', 'GET')
		.then((res) => {
			if (!_isMounted.current || !validateResponse(res)) {
				return false
			}

			categoriesDispatch(res.records)
		})

		return () => {
			_isMounted.current = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (categories?.length) {
			const locationCategoryId = Number(location.pathname.split('/').at(-1));
			if (locationCategoryId !== selectedCategory?.id && !isNaN(locationCategoryId)) {
				const foundCategory = categories.find(c => c.id === locationCategoryId)
				foundCategory && setSelectedCategory(foundCategory)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories])

	const categoriesDispatch = useCallback((items: Category[]) => dispatch({
		type: 'CATEGORIES',
		payload: items
	}), [dispatch])

	const categoryHandler = useCallback<CategoryFilterChanger>((categoryId) => {
		const category = categories && categories.find(c => c.id === categoryId)
		if (category) {
			history.push('/app/categories/' + categoryId)
			setSelectedCategory(category)
		}
	}, [categories, history, setSelectedCategory])

	// useEffect(() => {
	//   console.log('useeffect history.push')
	//   if(selectedCategory) {
	//     const locationCategoryId = Number(location.pathname.split('/').at(-1));
	//     if(isNaN(locationCategoryId) || (locationCategoryId !== selectedCategory.id)) {
	//       history.push('/app/categories/' + selectedCategory.id)
	//     }
	//   }
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [selectedCategory])

	return (
		<CategoriesContext.Provider value={{
			newCategories,
			setNewCategories,
			selectedCategory,
			setSelectedCategory
		}}>
			<div className="categories">
				<div className={``}>
					<h1>Категории</h1>
				</div>

				<div className='categories__inner'>
					<CategoriesFilterAndControl
						filtersMod={false}
						withNoCategory={false}
						categoryHandler={categoryHandler}
					/>

					<Switch>
						<Route
							exact
							path={'/app/categories/:categoryid'}
							render={(routeProps) =>
								<CategoryPage
									{...props}
									{...routeProps} />
							}
						/>

						<Route
							render={(routeProps) =>
								<DefaultCategoryPage
									{...props}
									{...routeProps}
								/>
							}
						/>
					</Switch>
				</div>
			</div>
		</CategoriesContext.Provider>
	);
}

function DefaultCategoryPage (props: Props) {
	useEffect(() => {
		props.setCurrentPage('categories')
	}, []);

	return (
		<div>Выберите категорию</div>
	);
}

export default Categories;