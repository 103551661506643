import React, { LiHTMLAttributes, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { mercheryFetch } from '../../scripts/fetchConstructor';
import { validateResponse } from '../../scripts/functions';
import { useAppSelector } from '../../scripts/pre-type/use-selector';
import useMounted from '../../scripts/hooks/use-mounted';
import { useTabIndex } from '../../scripts/hooks/use-tabindex';
import { SettingsModuleDto } from '../main-pages/settings/dto/settings-module.dto';
import { NavBarPage } from './navbar-page';

interface Props {
  popupClose?: () => void,
  tabIndexLevel?: number
}

export interface PageItem {
  link: string;
  className: string;
  text: string;

  children?: JSX.Element;
  icon?: string;
  subPages?: PageItem[];
  hide?: boolean;
  liProps?: LiHTMLAttributes<HTMLLIElement>;
}

function SideBar ({
  tabIndexLevel = 1,
  popupClose,
}: Props) {
  const _isMounted = useMounted()
  const settingsModules = useAppSelector(state => state.settingsModules)
  const ordersCount = useAppSelector(state => state.ordersCount);
  const tabIndex = useTabIndex(tabIndexLevel);
  const dispatch = useDispatch() 
  const settingsModulesDispatch = (modules: SettingsModuleDto[]) => dispatch({ type: 'STORE_SETTINGS_MODULES', payload: modules })

  const pages: PageItem[] = [
    {
      link: 'orders', 
      className: 'orders',
      icon: 'cart', 
      text: 'Заказы',
      subPages: [
        {link: 'refunds', text: 'Возвраты', className: 'refunds'},
        {link: 'subscriptions', text: 'Подписки', className: 'subscriptions'},
      ], // @ts-ignore: Unreachable code error
      liProps: {orders: ordersCount},
      children: <div className="items-counter">{ordersCount}</div>,
    },
    {
      link: 'products', 
      className: 'products',
      icon: 'price', 
      text: 'Товары', 
      subPages: [
        {link: 'categories', text: 'Категории', className: 'categories'},
        {link: 'sets', text: 'Комплекты', className: 'sets'},
        {link: 'collections', text: 'Коллекции', className: 'collections'},
      ],
    },
    {
      link: 'clients', 
      className: 'clients',
      icon: 'users-alt-4', 
      text: 'Покупатели', 
    },
    {
      link: 'marketing', 
      className: 'marketing',
      icon: 'megaphone', 
      text: 'Маркетинг', 
      hide: true,
    },
    {
      link: 'content', 
      className: 'content',
      icon: 'comment', 
      text: 'Контент', 
      hide: true,
    },
    {
      link: 'analytics', 
      className: 'analytics',
      icon: 'chart-growth',
      text: 'Аналитика', 
      hide: true,
    },
    {
      link: 'integrations', 
      className: 'integrations',
      icon: 'site-map', 
      text: 'Интеграции', 
    },
    {
      link: 'settings', 
      className: 'settings',
      icon: 'settings-alt', 
      text: 'Настройки', 
      subPages: settingsModules.map((settingsModule) => (
        {
          link: settingsModule.front_link, 
          text: settingsModule.description, 
          className: settingsModule.front_class_name
        }
      ))
    },
  ];

  useEffect(() => {
    mercheryFetch<SettingsModuleDto[]>('settings/modules', 'GET')
    .then((res) => {
      _isMounted.current && 
      validateResponse(res) && 
      settingsModulesDispatch(res.records)
    })
  }, [])

  return (
    <nav className="merchery-navbar">
      <NavBarPage 
        pagesItems={pages} 
        tabIndex={tabIndex} 
        popupClose={popupClose}
      />
    </nav>
  );
}
export default SideBar