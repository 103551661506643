import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { batch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Id, OrderStatus, Order } from 'merchery-lib';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import MyButton from '../../../../_utility-components/button/button';
import Popup from '../../../../_utility-components/popup';

interface Props {
  order: Order,
  className?: string,
  btnClassName?: string,
  withArrow?: true,
  statusHandler: (statusId: Id) => void
}

function SetStatus({
  order,
  className, 
  btnClassName,
  withArrow,
  statusHandler,
}: Props) {
  const statuses = useAppSelector(state => state.orderStatus);
  const [openedConfirmPopup, setOpenedConfirmPopup] = useState(false);
  
  const { RenderButton, RenderPopup, closePopup } = usePopup()

  if(!statuses) {
    return <div></div>
  }

  const firstGroupStatuses = statuses.filter(os => os.id < 3);
  const selfGroupStatuses = statuses.filter(os => os.id >= 3);
  const orderStatusOfOrder = statuses.find(p => p.id === order.status);

  const statusBoxColor = orderStatusOfOrder && orderStatusOfOrder.type !== 'New' ? orderStatusOfOrder.color : '';
  const statusBoxClassName = orderStatusOfOrder && orderStatusOfOrder.type === 'New' ? 'grayColor' : '';

  const popupElementHandler = (status: OrderStatus) => {
    batch(() => {
      if(status.id === 3) {
        setOpenedConfirmPopup(true)
      } else {
        statusHandler(status.id)
      }
      closePopup();
    })
  }

  const cancelSettingsCompleted = () => {
    batch(() => {
      closePopup()
      setOpenedConfirmPopup(false)
    })
  }

  const confirmSettingsCompleted = () => {
    batch(() => {
      closePopup()
      setOpenedConfirmPopup(false)
      statusHandler(3)
    })
  } 

  const toolTipPlace = window.outerWidth > 1024 ? 
    (window.outerWidth > 1200 ? 'right' : 'top') 
  : (window.outerWidth < 501 ? 'top' : 'right');

  return (
    <div
      className={`${statusBoxClassName} ${className ? className : ''} select-btn`}
      style={{ 
        backgroundColor: statusBoxColor
      }}
    >
      <RenderButton
        removeDefaultClass
        className={`select-btn-inner ${btnClassName ? btnClassName : ''}`}
        disabled={orderStatusOfOrder?.id === 3}
        data-tip={orderStatusOfOrder?.id === 3 ? 'Нельзя отменить выполненный статус заказа' : ''}
        data-for={orderStatusOfOrder?.id === 3 ? `profit-status` : ''}
      >
        {orderStatusOfOrder?.name || ''} 
        {withArrow ? <i className='icofont-simple-down'/> : null}
      </RenderButton>

      <RenderPopup
        changingDirection={true} 
        className={'table-popup status'}
      >
        <div className="popup-group">
          {firstGroupStatuses.map((os) => 
            <StatusBtn status={os} key={os.id}
              btnHandler={popupElementHandler}
            />
          )}
        </div>
        {selfGroupStatuses.map((os) => 
          <div className="popup-group" key={os.id}>
            <StatusBtn 
              status={os}
              btnHandler={popupElementHandler}
            />
          </div>
        )}
      </RenderPopup>

      {orderStatusOfOrder?.id === 3 ? 
        <ReactTooltip
          id={`profit-status`}
          resizeHide={true}
          place={toolTipPlace}
          multiline={true}
          effect={"solid"}
          isCapture={true}
          className={'fff'}
        /> 
      : null}

      {openedConfirmPopup ? ReactDOM.createPortal(
        <Popup
          className={'confirm-window fixed-on-center row-gap-15'}
          popupClose={cancelSettingsCompleted}
          withBlackout
          tabIndexDeep={3}
          withCloseBtn
        >
          <h2>Подтверждение завершения заказа</h2>
          <span>После установки статуса «Выполнен» дальнейшее изменение статуса будет невозможно. Действительно завершить заказ?</span> 
      
          <div className="confirm-window-btn">
            <MyButton 
              className={'white-btn'} 
              onClick={cancelSettingsCompleted}
            >
              Отменить
            </MyButton>
            <MyButton 
              className="blue-btn" 
              onClick={confirmSettingsCompleted}
            >
              Завершить
            </MyButton>
          </div>
        </Popup>
      , document.body) : null}
    </div>
  );
}

interface StatusBtnProps {
  status: OrderStatus | undefined
  btnHandler: (status: OrderStatus) => void,
}

function StatusBtn ({
  status,
  btnHandler,
}: StatusBtnProps) {
  if(!status) {
    return null
  }
  const className = status.type === 'New' ? 'grayColor' : '';
  const bgColorStyle = status.type === 'New' ? '' : status.color;

  return (
    <MyButton className="popup-element"
              key={status.id}
              removeDefaultClass
              onClick={() => btnHandler(status)}
    >
      <span className={'status_filter-btn__text'}>
        {status.name}
        <div className={className} style={{backgroundColor: bgColorStyle}}></div>
      </span>
    </MyButton>
)
}

export default SetStatus;