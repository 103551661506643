import { ClientGroup, ExtendedClientDto } from "merchery-lib";

interface Props {
  client: ExtendedClientDto
  groups: ClientGroup[]
}

export const renameExportHeaders = ({
  client,
  groups
}: Props): any => {
  const group: string = (client.group && groups.find(group => 
    group.id === client.group)?.name) || '';

  return {
    'Email': client.email,
    'Имя': client.name,
    'Номер телефона': client.phone,
    'Группа клиента': group,
    "карта лояльности": client.loyalty?.loyalty_card || '',
  }
}