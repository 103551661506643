import React, {useContext, useState} from 'react';
import dotsMenu from 'src/img/context-menu-dots.svg';
import {usePopup} from "src/scripts/hooks/use-popup";
import MyButton from "src/components/_utility-components/button/button";
import {OrderDeliveryContext} from "../delivery";
import ReactDOM from "react-dom";
import {DeliveryNoteDeletePopup} from "./delivery-note-delete-popup";
import {batch} from "react-redux";
import ContextMenuItems
	from "src/components/main-pages/orders/order-modules/delivery/selected-delivery/context-menu-items";

export function SelectedDeliveryContextMenu () {
  const {
		RenderButton,
		RenderPopup,
		closePopup
	} = usePopup();

	const {
		delivery,
		setDeliverySelectStateOn,
		deleteHandler,
	} = useContext(OrderDeliveryContext);

	const [deletePopupOpened, setDeletePopupOpened] = useState(false);
	const disableDeletion = Boolean(!delivery || delivery.shipping_date !== null);

	const deliveryNoteCopyHandler = () => {
		if(!delivery?.note) {
			return false
		}

		navigator.clipboard.writeText(delivery.note)
		closePopup()
	}

	const showChangeBtn = !delivery?.note;
	const showNoteCopyBtn = delivery?.note;
	const showDeleteBtn = delivery && delivery.note;

	const rpostItems = ContextMenuItems();
	const thereIsAdditionalItems = rpostItems !== null

	return <div className={'op-delivery_context-menu'}>
    <RenderButton
			removeDefaultClass
			className={'op-delivery_context-menu_btn'}
		>
      <img
				src={String(dotsMenu)}
				alt={'context menu'}
			/>
    </RenderButton>

		<RenderPopup
			className='config-popup'
			popupName={'.config-popup'}
			tabIndexDeep={2}
			changingDirection={true}
		>
			{thereIsAdditionalItems ?
				<div className={'popup-group'}>
					{rpostItems}
				</div>
			: null}

			{showChangeBtn || showNoteCopyBtn ?
				<div className={'popup-group'}>
					{showChangeBtn ?
						<MyButton
							className='popup-element left-align'
							onClick={() => setDeliverySelectStateOn()}
						>
							Редактировать
						</MyButton>
					: null}

					{showNoteCopyBtn ?
						<MyButton
							className='popup-element left-align'
							onClick={deliveryNoteCopyHandler}
						>
							Копировать номер накладной
						</MyButton>
					: null}
				</div>
			: null}

			{showDeleteBtn ?
				<div className={'popup-group'}>
					<MyButton
						className='popup-element red-color left-align'
						onClick={() => {
							batch(() => {
								closePopup()
								setDeletePopupOpened(true)
							})
						}}
						disabled={disableDeletion}
					>
						<b>Удалить</b>
					</MyButton>
				</div>
			: null}
		</RenderPopup>

		{deletePopupOpened
			?	ReactDOM.createPortal(
					<DeliveryNoteDeletePopup
						popupClose={() => setDeletePopupOpened(false)}
						mainActionHandler={deleteHandler}
					/>
				, document.body)
			: null}
	</div>
}