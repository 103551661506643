import React, {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { batch, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import useInfiniteScrollShowMore from 'src/scripts/hooks/use-infinite-scroll-show-more';
import { Id, ExtendedProduct } from "merchery-lib";
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { uniqByKeepLast, validateResponse } from '../../../../scripts/functions';
import AddItemsLoader from '../../../_utility-components/loaders/add-items-loader';
import CommonTableLoader from '../../../_utility-components/loaders/common-table-loader';
import { ProductReorder, ProductsOrderingMode } from '../dto/products.dto';
import LoadMore from './load-more-btn';
import ProductsTableBody from './table-body/table-body';
import ProductsTableHeader from './table-header/table-header';
import useProductsFilters from "../hooks/use-products-filters";
import useInitFilters, { EntityGetter, entityGetterQuery } from "src/scripts/hooks/use-init-filters"; 
import {AppliedFilters} from "../../../_utility-components/applied-filters";
import ProductsSearchBar from "./search";
import {ProductFilters} from "../dto/products-filters.dto";
import {FilterChangerProps, FilterItem} from "../../../../scripts/utils/entity-filters";
import useProductsChangesListener from "../../../../ws-listeners/products.listener";
import PageIsOutOfDate from "../../../_utility-components/page-is-out-of-date/page-is-out-of-date";
import sortProducts from "../products.utils";
import MultiChangeCompletePopup from './multi-change-complete-popup';
import { ProductsContext } from '../products';

const productsItemsGetSize = 25;

export type MultiProductsActionCompletedParams = null | {
  filters: FilterChangerProps<ExtendedProduct>[],
  completeText: string,
}

export const ProductsTableContext = createContext<{
  sortedProducts: ExtendedProduct[],
  uniqueCategories: Id[],
  disableDraggable: boolean,
  multiProductsActionCompletedParams: MultiProductsActionCompletedParams,
  setMultiProductsActionCompletedParams: (params: MultiProductsActionCompletedParams) => void,
  applyFilters: (...changes: FilterChangerProps<ExtendedProduct>[]) => void,
  newProducts: Set<Id>
  setNewProducts: (newProducts: Set<Id>) => void,
  orderingMode: ProductsOrderingMode,
  setOrderingMode: (mode: ProductsOrderingMode) => void,
}>({
  sortedProducts: [],
  uniqueCategories: [],
  disableDraggable: false,
  multiProductsActionCompletedParams: null,
  setMultiProductsActionCompletedParams: () => {},
  applyFilters: () => {},
  newProducts: new Set([]),
  setNewProducts: () => {},
  orderingMode: 'category',
  setOrderingMode: () => {},
})

export default function ProductsTable() {
  const _isMounted = useRef(true);
  const {
    selectedCategory,
    setSelectedCategory
  } = useContext(ProductsContext)

  const products = useAppSelector(state => state.products);
  const categories = useAppSelector(state => state.categories || []);
  const selectedProducts = useAppSelector(state => state.selectedProducts);
  const currentProductsCount = useAppSelector(state => state.currentProductsCount);
  const allProductsCount = useAppSelector(state => state.allProductsCount);
  const searchInput = useAppSelector(state => state.searchInput);

  const uniqueCategories = useMemo(() => [...new Set(products.map(a => a.top))], [products]);

  const [productFilters, updateFilters] = useProductsFilters();

  useEffect(() => {
    const selectedTopFilter = productFilters?.top && productFilters?.top.find(category => category.selected)
    if(selectedTopFilter?.id !== selectedCategory?.id) {
      setSelectedCategory(categories.find(category => category.id === selectedTopFilter?.id) || undefined)
    }
  }, [productFilters])

  const [loading, setLoading] = useState(false);
  const [multiProductsActionCompletedParams, setMultiProductsActionCompletedParams] = useState<MultiProductsActionCompletedParams>(null);
  const [newProducts, setNewProducts] = useState<Set<Id>>(new Set([]));
  const [orderingMode, setOrderingMode] = useState<ProductsOrderingMode>('category');
  const additionalQueryParams = useMemo(() => ({
    orderingMode
  }), [orderingMode])
  const sortedProducts = sortProducts(products, orderingMode)

  const dispatch = useDispatch();
  const searchInputDispatch = (text: string) => dispatch({ type: 'PRODUCTS_SEARCH_INPUT_VALUE', payload: text });
  const productsDispatch = (items: ExtendedProduct[]) => dispatch({ type: 'PRODUCTS', payload: items });
  const selectedProductsDispatch = (ids: Id[]) => dispatch({ type: 'SELECTED_PRODUCTS', payload: ids });
  const productsCountDispatch = (count: number) => dispatch({ type: 'PRODUCTS_COUNT', payload: count });
  const allProductsCountDispatch = (count: number) => dispatch({ type: 'PRODUCTS_ALL_COUNT', payload: count });
  const reorderStatusDispatch = (bool: boolean) => dispatch({ type: 'REORDER_SAVING_iN_PROCESS', payload: bool });

  const [reorderChangesInQueue, applyRenderChanges] = useProductsChangesListener()

  const getProducts: EntityGetter<{
    clearCount?: boolean,
    orderingMode?: ProductsOrderingMode
  }> = ({
    filters, 
    clearCount,
    orderingMode
  }) => {
    const queryFilters = entityGetterQuery({
      ...(filters && {
        filters,
      }),
      ...(searchInput && {
        search: searchInput,
      }),
      ...(orderingMode && {
        orderingMode
      }),
      pageSize: productsItemsGetSize
    })

    setLoading(true)

    mercheryFetch<ExtendedProduct, true>(`products?${queryFilters}`, "GET")
    .then(res => {
      if(!_isMounted.current || !validateResponse<ExtendedProduct, true>(res)) {
        return false
      }

      const gotProducts = (clearCount 
        ? res.records.rows 
        : uniqByKeepLast<ExtendedProduct>([...sortedProducts, ...res.records.rows], (p) => (p.id))
      ) || [];

      const count = res.records.count;

      batch(() => {
        productsDispatch(gotProducts)
        selectedProductsDispatch(
          selectedProducts.filter(id =>
            gotProducts.some(product => product.id === id)
          )
        )
        productsCountDispatch(gotProducts?.length || 0)
        allProductsCountDispatch(count || 0)
      })
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const [ hasMore, showMore ] = useInfiniteScrollShowMore({
    getItems: getProducts,
    currentCount: currentProductsCount,
    getSize: productsItemsGetSize,
    allCount: allProductsCount,
  })

  useInitFilters({
    filters: productFilters,
    searchInput,
    setSearchInput: searchInputDispatch, 
    getEntity: (filterArgs) => getProducts({ ...filterArgs, clearCount: true, orderingMode }), 
    updateFilters ,
    additionalParams: additionalQueryParams,
    additionalParamsSetters: {
      setOrderingMode,
    }
  })

  const nonCategoryFiltersActive = useMemo(() => {
    if(!productFilters) {
      return false
    }
    const filtersCopy: ProductFilters = JSON.parse(JSON.stringify(productFilters));

    delete filtersCopy.top;

    const filtersValues = Object.values(filtersCopy) as (FilterItem[] | undefined)[]
    const isSelectedNonCategoryFilters = filtersValues.some(item =>
      item && item.some(value => value.selected)
    )
    return isSelectedNonCategoryFilters;
  }, [productFilters])

  const disableDraggable = useMemo((() => {
    return Boolean(nonCategoryFiltersActive || reorderChangesInQueue.length)
  }), [nonCategoryFiltersActive, reorderChangesInQueue])

  const reorderRequest = (requestOptions: ProductReorder) => {
    reorderStatusDispatch(true)

    mercheryFetch<ExtendedProduct[]>('products/reorder', 'PATCH', {
      ...requestOptions,
      filters: {},
    })
    .then(res => {
      if (!_isMounted.current || !validateResponse(res)) {
        throw Error;
      }

      const updatedProducts =
        [...sortedProducts]
        .map(product => 
          res.records.find(record => 
            record.id === product.id)
          || product
        )

      productsDispatch(updatedProducts);
    })
    .finally(() => {
      reorderStatusDispatch(false);
    });
  }

  const onDragStart = () => {
    hideVariantsWhileReordering()
  }

  const hideVariantsWhileReordering = () => {
    const elements = document.getElementsByClassName('product-variants-rows-wrapper') as HTMLCollectionOf<HTMLDivElement>;
    for(let i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none';
    }
  }

  const showVariantsAfterReordering = () => {
    const elements = document.getElementsByClassName('product-variants-rows-wrapper') as HTMLCollectionOf<HTMLDivElement>;
    for(let i = 0; i < elements.length; i++) {
      elements[i].style.removeProperty('display');
    }
  }

  const onDragEnd = (info: DropResult) => {
    showVariantsAfterReordering()

    const { destination, source } = info;
    const topFound: string | undefined = info.type.split('-').at(-1);

    if (!topFound ||
      !destination ||
      source.index === destination.index ||
      destination?.index === undefined
    ) {
      return;
    }

    let newList: ExtendedProduct[];
    const orderField: keyof ExtendedProduct = orderingMode === 'category' ? 'category_order' : 'main_page_order';

    if(orderingMode === 'category') {
      const top = +topFound;
      const productsByCategoryWithNewTop = sortedProducts.filter(p => p.top === top)
      newList = [...productsByCategoryWithNewTop];
    } else {
      newList = [...sortedProducts];
    }

    const [removed] = newList.splice(source.index, 1);
    newList.splice(destination.index, 0, removed);

    const withUpdatedOrder: ExtendedProduct[] = newList.map((item, index) => ({
      ...item, 
      [orderField]: index + 1
    }))
    
    const addChangedToAll =
      products.map(product =>
        withUpdatedOrder.find(sortedProduct =>
          product.id === sortedProduct.id
        ) || product
      )

    productsDispatch(addChangedToAll);

    const requestOptions: ProductReorder = {
      id: +info.draggableId,
      difference: destination.index - source.index,
      orderingMode: orderingMode,
    };
    
    reorderRequest(requestOptions)
  }

  const applyFilters = (...changes: FilterChangerProps<ExtendedProduct>[]) => {
    updateFilters(...changes)
  }

  return (
    <ProductsTableContext.Provider value={{
      sortedProducts,
      uniqueCategories,
      disableDraggable,
      multiProductsActionCompletedParams,
      setMultiProductsActionCompletedParams,
      applyFilters,
      newProducts,
      setNewProducts,
      orderingMode,
      setOrderingMode,
    }}>
      <div className='products-table__wrapper'>
        <DragDropContext
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        >
          <MultiChangeCompletePopup/>

          <ProductsSearchBar />

          <AppliedFilters<ExtendedProduct>
            filterNames={['top', 'show_date', 'brand']}
            filters={productFilters}
            updateFilters={updateFilters}
          />

          {loading ?
            <CommonTableLoader />
          : 
            <>
              <InfiniteScroll
                dataLength={products.length}
                next={showMore}
                hasMore={Boolean(hasMore)}
                loader={Array(productsItemsGetSize).map(() => <AddItemsLoader/>)}
                scrollableTarget={'table-items'}
              >
                {products.length ?
                  <div id='table-items' className="table-items">
                    <ProductsTableHeader/>

                    <ProductsTableBody/>
                  </div>
                :
                  <div>
                    Нет товаров с заданными критериями
                  </div>
                }
              </InfiniteScroll>

              <LoadMore
                showMoreProducts={showMore}
              />
            </>
          }
        </DragDropContext>

        {reorderChangesInQueue.length ?
          <PageIsOutOfDate
            condition={Boolean(reorderChangesInQueue.length)}
            actionHandler={applyRenderChanges}
          />
        : null}
      </div>
    </ProductsTableContext.Provider>
  )
}