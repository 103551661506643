import React, { MouseEvent } from 'react';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import MyButton from '../../../../_utility-components/button/button';
import { Order } from 'merchery-lib';

import {BodyCell} from "../../../../_utility-components/common-table/body-cell";

interface Props {
  orderCheckHandler: (e: MouseEvent, el: Order) => void, 
  order: Order
}

export function OrderRowIdAndCheck({
  order,
  orderCheckHandler
}: Props) {
  const status = useAppSelector(state => state.orderStatus)

  if(!status) {
    return <div></div>
  }

  const orderStatusOfOrder = status.find(p => p.id === order.status);
  const statusBoxColor = orderStatusOfOrder && orderStatusOfOrder.type !== 'New' ? orderStatusOfOrder.color : '' ;
  const statusBoxClassName = orderStatusOfOrder && orderStatusOfOrder.type === 'New' ? 'grayColor' : ''

  return <BodyCell myClassName="tt-id">
    <MyButton
      removeDefaultClass
      onClick={(e: MouseEvent) => {
        if (e.shiftKey) e.preventDefault();
        orderCheckHandler(e, order);
      }}
      className='tt-select-outside'
    >
      <div className="checkbox"><div></div></div>
    </MyButton>
    <div className={`status-and-id__wrapper`}>
      <div className={`${statusBoxClassName} status-indicator`}
        style={{ backgroundColor: statusBoxColor}}
      >
      </div>
      {order.id}
    </div>
  </BodyCell>;
}
