import { Order } from "merchery-lib";
import { useAppSelector } from "../../../../scripts/pre-type/use-selector";

export interface OrderDisabledCauses {
  disabledByStatus: boolean
}

export function useOrderDisabled (order: Order): [boolean, OrderDisabledCauses] {
  const statuses = useAppSelector(state => state.orderStatus);

  if(!statuses) {
    return [true, {disabledByStatus: false}]
  }

  const currentStatus = statuses.find(status => status.id === order.status);

  const disabledByStatus = currentStatus !== undefined ? currentStatus.type === 'Profit' : true;
  const disabledByDeletion = Boolean(order.deleted)
  const disabled = disabledByStatus || disabledByDeletion

  const result: [boolean, OrderDisabledCauses] = [disabled, {
    disabledByStatus
  }]
  
  return result
}