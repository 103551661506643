import React from 'react';
import MyButton from '../button/button';
import { arrayExport } from './export';

interface Props<T> {
  renameExportHeaders: (item: T) => {[name: string]: any},
  items: T[],
  text: string,
  className?: string,
} 

function ExportButton<T>({
  items,
  renameExportHeaders,
  text,
  className,
}: Props<T>) {
  const handler = async () => {
    arrayExport(items.map(item => renameExportHeaders(item)), 'Orders')
  }
  return (
    <MyButton 
      className={`hide-for-print ${className ? className : 'order-export-btn'}`} 
      onClick={handler}
    >
      <i className="icofont-download"></i> {text}
    </MyButton>
  );
}

export default ExportButton;