import React, { useEffect} from 'react';
import { MainRouteChild } from '../main-page';
import Header from './modules/header';
import ClientsTopPanel from './modules/popups/clients-top-panel';
import ClientsTable from './table';

interface Props extends MainRouteChild {
}

function Clients (props: Props) {

  useEffect(() => {
    props.setCurrentPage('clients');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='clients-page'>
      <Header />

      <ClientsTable />

      <ClientsTopPanel />
    </div>
  );
}

export default Clients;