import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { querify, uniqByKeepLast, validateResponse } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import { RefundDto } from 'merchery-lib';
import OneRefundSection from './refund-section';

function OrderRefunds() {
  const match = useRouteMatch<{orderid: string}>();
  const isNew = match.params.orderid === 'new';
  const orderId = +match.params.orderid;
  const _isMounted = useMounted();

  const refunds = useAppSelector(state => state.refunds)
  const thisOrderRefunds = refunds?.filter(refund => refund.order_id === orderId);

  const dispatch = useDispatch()
  const setRefunds = (orderRefunds: RefundDto[]) => dispatch({type: 'ORDERS_REFUNDS', payload: uniqByKeepLast([...(refunds || []), ...orderRefunds], (r) => r.id) })

  useEffect(() => {
    if(!isNew) {
      const query = querify({
        filters: { order_id: orderId }
      });

      mercheryFetch<RefundDto[]>(`orders/refund?${query}`, 'GET')
      .then((res) => {
        if(_isMounted.current && validateResponse(res)) {
          setRefunds(res.records)
        }
      })

    }
  }, [])

  if(!thisOrderRefunds?.length) {
    return null
  }

  return (
    <div className='order-page__refunds'>

      {thisOrderRefunds.map((refund) => 
        <OneRefundSection 
          key={refund.id}
          refund={refund}      
        />
      )}
      
    </div>
  );
}

export default OrderRefunds;