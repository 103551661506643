import React, { memo, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import useRowFocus from 'src/scripts/hooks/use-row-focus';
import { Id, ExtendedClientDto } from 'merchery-lib';
import { mercheryFetch } from '../../../scripts/fetchConstructor';
import { priceWithPennies, ptsansCurrency, querify, validateResponse } from '../../../scripts/functions';
import { useAppSelector } from '../../../scripts/pre-type/use-selector';
import useMounted from '../../../scripts/hooks/use-mounted';
import useRowClick from '../../../scripts/hooks/use-row-click';
import { useShiftSelect } from '../../../scripts/hooks/use-shift-select';
import useSortableData, { SortableDataConfig } from '../../../scripts/hooks/use-sortable-data';
import { useTabIndex } from '../../../scripts/hooks/use-tabindex';
import MyTable from '../../_utility-components/common-table/table';
import CommonTableLoader from '../../_utility-components/loaders/common-table-loader';
import { ClientsFilters } from './dto/client-filters.dto';
import { ClientRowIdAndCheck } from './modules/check-and-id-cell';
import ClientRowConfig from './modules/popups/client-row-config';
import useInfiniteScrollShowMore from "../../../scripts/hooks/use-infinite-scroll-show-more";
import InfiniteScroll from "react-infinite-scroll-component";
import useClientFilters from "../../../scripts/hooks/use-client-filters";
import FiltersAndActions from "./modules/filters-and-actions";
import {AppliedFilters} from "../../_utility-components/applied-filters";
import HeaderCell from "../../_utility-components/common-table/header-cell";
import {BodyCell} from "../../_utility-components/common-table/body-cell";
import useInitFilters from 'src/scripts/hooks/use-init-filters';
import OrdersLoader from 'src/components/_utility-components/loaders/orders-loader';

export interface ClientsGetParams {
  filters?: {[p: string]: (string | number | null)[] | undefined},
  search?: string
  page?: number
}

const clientsGetSize = 40;

function ClientsTable() {
  const _isMounted = useMounted()
  const clients = useAppSelector(state => state.clients);
  const searchInput = useAppSelector(state => state.clientSearchInput);
  const selectedClientsIds = useAppSelector(state => state.selectedClientsIds)

  const [clientsFilters, updateFilters] = useClientFilters()
  const tabIndex = useTabIndex()

  const [loading, setLoading] = useState(false)
  const [allClientsCount, setAllClientsCount] = useState(0);
  const [currentClientsCount, setCurrentClientsCount] = useState(0);

  const dispatch = useDispatch();
  const setSearchText = (search: string) => dispatch({
    type: 'CLIENTS_SEARCH_INPUT_VALUE',
    payload: search
  });
  const clientsDispatch = (clients: ExtendedClientDto[]) => dispatch({type: 'CLIENTS', payload: clients});
  const setSelectedClientsIds = (ids: Id[]) => dispatch({ type: 'CLIENTS_SELECTED', payload: ids })


  const getClientsWithFilters = ({
    filters,
    search,
    page,
  }: ClientsGetParams = {}) => {
    const nullFilters: (keyof ClientsFilters)[] = [];

    const noNullFilters = filters &&
      Object.entries(filters)
        .reduce((acc, [key, values]) => {
          if (Array.isArray(values)) {
            const withNull = values.some(value => value === null);
            if(withNull) {
              nullFilters.push(key as keyof ClientsFilters);
            }
            acc[key] = values.filter(value => value !== null);
          } else {
            acc[key] = values;
          }
          return acc;
        }, {} as {[p: string]: (string | number | null)[] | undefined});

    const query = querify({
      filters: {
        ...noNullFilters,
      },
      ...(search && {search}),
      ...(nullFilters.length > 0 && {nullFilters}),
      pagination: {
        size: clientsGetSize,
        page,
      },
    });

    setLoading(true)

    mercheryFetch<ExtendedClientDto, true>(`client?${query}`, 'GET')
      .then((res) => {
        if(!_isMounted.current || !validateResponse<ExtendedClientDto, true>(res)) {
          return false
        }

        const gotClients = res.records.rows;
        const allClientsCount = res.records.count;

        batch(() => {
          clientsDispatch(gotClients);
          setAllClientsCount(allClientsCount);
          setCurrentClientsCount(allClientsCount);
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [ hasMore, showMore ] = useInfiniteScrollShowMore({
    getItems: getClientsWithFilters,
    currentCount: currentClientsCount,
    getSize: clientsGetSize,
    allCount: allClientsCount
  })

  useInitFilters(
    { filters: clientsFilters, searchInput, setSearchInput: setSearchText, getEntity: getClientsWithFilters, updateFilters }  )

  const defaultSorting = {
    key: "id",
    direction: "descending"
  }
  const location = useLocation<{config: SortableDataConfig<ExtendedClientDto>}>()

  const [
    sortedClients,
    requestSort,
    config
  ] = useSortableData((clients || []), location.state?.config || defaultSorting);

  const linkProps = (id: Id) => ({
    to: `/app/clients/${id}`,
    state: { config: config }
  })

  const { focusedItem, focusHandler } = useRowFocus<ExtendedClientDto>(null);

  const [rowClick, setActiveRowId] = useRowClick(linkProps, [
    '.clients-table__email-cell', '.config-popup-wrapper', '.id-and-check-wrapper .select-outside', '.clients-delete-popup'
  ])

  const [selectedIds, handleSelect, selectAll] = useShiftSelect(sortedClients)

  useEffect(() => {
    if(selectedClientsIds.length !== selectedIds.length) {
      selectAll(selectedClientsIds)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientsIds])

  const rowCheckHandler = (client: ExtendedClientDto) => {
    const newCalculatedSelected = handleSelect(client)
    setSelectedClientsIds(newCalculatedSelected)
  }

  return (
    <div className='clients-table__wrapper'>

      <FiltersAndActions
      />

      <AppliedFilters<ExtendedClientDto>
        filterNames={['group']}
        filters={clientsFilters}
        updateFilters={updateFilters}
      />

      <div className={'clients-table__items__wrapper'}>
        {clients && !loading ?
          <InfiniteScroll
            dataLength={clients.length}
            next={showMore}
            hasMore={hasMore}
            loader={ Array(10).map(() => <OrdersLoader/>) }
            scrollableTarget={<>{window}</>}
          >
            {clients.length ?
              <MyTable id="clients-table" myClassName={'clients-table'}>
                <div className='table__header clients-table__cells-template'>
                  <HeaderCell children={'Номер'}
                              label={'id'}
                              config={config}
                              className={'padding-left-18'}
                              handler={requestSort}
                  />

                  <HeaderCell children={'покупатель'}
                              label={'name'}
                              config={config}
                              handler={requestSort}
                  />

                  <HeaderCell children={'телефон'}
                              label={'phone'}
                              config={config}
                              handler={requestSort}
                  />

                  <HeaderCell children={'email'}
                              label={'email'}
                              config={config}
                              handler={requestSort}
                  />

                  <HeaderCell children={'Заказы'}
                              className="center-align"
                              label={'ordersCount'}
                              config={config}
                              handler={requestSort}
                  />

                  <HeaderCell children={'оборот'}
                              className="right-align"
                              label={'ordersTotal'}
                              config={config}
                              handler={requestSort}
                  />

                  <HeaderCell children={'возвраты'}
                              className="right-align"
                              label={'refundTotal'}
                              config={config}
                              handler={requestSort}
                  />

                  <HeaderCell children={'выкуп'}
                              className="center-align"
                              label={'buyout'}
                              config={config}
                              handler={requestSort}
                  />
                  <div></div>
                </div>

                <div className='table__inner'>
                  {sortedClients.map((el) => {
                    const focused = focusedItem?.id === el.id ? 'focused' : '';// eslint-disable-next-line
                    const active = selectedClientsIds.some(s => s == el.id) ? 'active' : '';

                    return (
                      <div
                        className={active + ' table__row clients-table__cells-template ' + focused}
                        key={el.id}
                        onFocus={() => focusHandler(el.id, clients)}
                        tabIndex={tabIndex}
                        onMouseUp={(e) => rowClick(e, el.id)}
                        onMouseDown={() => _isMounted.current && setActiveRowId(el.id)}
                        onKeyUp={(e) => e.key === 'Enter' ? rowClick(e, el.id) : null}
                      >
                        <ClientRowIdAndCheck
                          clientCheckHandler={rowCheckHandler}
                          client={el}
                        />
                        <BodyCell myClassName='left-align'>
                          {el.name}
                        </BodyCell>
                        <BodyCell myClassName='left-align'>
                          {el.phone}
                        </BodyCell>
                        <BodyCell myClassName='clients-table__email-cell left-align'>
                          {el.email}
                        </BodyCell>
                        <BodyCell myClassName='center-align'>
                          {el.ordersCount}
                        </BodyCell>
                        <BodyCell myClassName='right-align'>
                          {priceWithPennies(el.ordersTotal)} {ptsansCurrency('₽')}
                        </BodyCell>
                        <BodyCell myClassName='right-align'>
                          {el.refundTotal} {ptsansCurrency('₽')}
                        </BodyCell>
                        <BodyCell myClassName='center-align'>
                          {el.buyout}%
                        </BodyCell>
                        <ClientRowConfig linkProps={linkProps} client={el}/>
                      </div>
                    )
                  })}
                </div>
              </MyTable>
              : <div>
                Клиентов не найдено
              </div>}
          </InfiniteScroll>
          : <CommonTableLoader />}

      </div>
    </div>
  );
}

export default memo(ClientsTable);

// export function extendClient(input: undefined | null): undefined | null;
// export function extendClient(input: ExtendedClientDto): ExtendedClientDto;
// export function extendClient(input: ExtendedClientDto[]): ExtendedClientDto[];
// export function extendClient<T extends undefined | ExtendedClientDto | ExtendedClientDto[]>(input: T): undefined | null | ExtendedClientDto | ExtendedClientDto[] {
//   if(!input) 
//     return input

//   if (Array.isArray(input)) {
//       return input.map(client => ({
//           ...client,
//           ordersCount: null, // Assuming default value, replace with actual logic to calculate ordersCount
//           refundsCount: null, // Assuming default value, replace with actual logic to calculate refundsCount
//       })) as ExtendedClientDto[];
//   } else {
//       return {
//           ...input,
//           ordersCount: null, // Assuming default value, replace with actual logic to calculate ordersCount
//           refundsCount: null, // Assuming default value, replace with actual logic to calculate refundsCount
//       } as ExtendedClientDto;
//   }
// }