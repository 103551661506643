import React from 'react';
import SiteFileStorage from './site-settings/file-storage';
import SiteMisc from './site-settings/misc';
import SiteOwnerData from './site-settings/owner';
import SiteRequisites from './site-settings/requsites';
import SiteTariff from './site-settings/tariff';

export const SiteSettingsContext = React.createContext<{
}>({
});

function SiteSettings() {
  return (
    <SiteSettingsContext.Provider value={{
    }}>
      <div className='site-settings setting-module'>
        <SiteOwnerData/>
  
        <SiteRequisites/>

        <SiteTariff/>

        <SiteFileStorage/>

        <SiteMisc/>
      </div>
    </SiteSettingsContext.Provider>
  );
}

export default SiteSettings;