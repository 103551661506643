import {ProductsOrderingMode} from "./dto/products.dto";
import { ExtendedProduct } from 'merchery-lib';

export default function sortProducts (products: ExtendedProduct[], orderingMode: ProductsOrderingMode) {
  let sortedProducts: ExtendedProduct[];
  const orderField: keyof ExtendedProduct = orderingMode === 'category' ? 'category_order' : 'main_page_order'

  if(orderingMode === 'category') {
    sortedProducts = [...products].sort((a, b) => {
      if (a.top === null || b.top === null) {
        return a.top === null ? (b.top === null ? a.category_order - b.category_order : -1) : 1;
      }
      return +a.top - +b.top || a.category_order - b.category_order;
    })
  } else {
    sortedProducts = [...products].sort((a,b) => a[orderField] - b[orderField])
  }

  return sortedProducts 
}