import React from 'react';

function RefundDelivery() {
  return (
    <div className='refund-delivery'>
      <div className='refund-delivery__row refund-received'>
        <div className='status'>заказ получен</div>
        <div className='date'>22.06.2023</div>
      </div>
      <div className='refund-delivery__row refund-sent'>
        <div className='status'>возврат отправлен</div>
        <div className='date'>26.06.2023</div>
      </div>
      <div className='refund-delivery__row return-duration'>
        <div className='status'>срок возврата</div>
        <div className='date'>4 дня</div>
      </div>
      <div className='refund-delivery__row arrived-in-store'>
        <div className='status'>поступил в магазин</div>
        <div className='date'>28.06.2023</div>
      </div>
      <div className='refund-delivery__row processed'>
        <div className='status'>проведен</div>
        <div className='date'>30.06.2023</div>
      </div>
    </div>
  );
}

export default RefundDelivery;