import React from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { ExtendedProduct, ProductVariantExtended } from 'merchery-lib';
import { itemIsVariant } from './row';

function OpTitle({
  product
}: {
  product: ExtendedProduct | ProductVariantExtended
}) {
  const brands = useAppSelector(state => state.brands || [])
  const isVariant = itemIsVariant(product)

  const currentBrand = !isVariant ?
    brands.find(b => b.id === product.brand) || null
    : null;

  const title = isVariant ?
    product.attributes?.map(a => a.value)?.join(' / ')
    : product.name;

  return (
    <div className="op-title">
      <div className='products-op-title-inner'>
        <div>{title}</div>
        {!isVariant && currentBrand && (
          <div className='products-op-title-brand'>
            {currentBrand?.name || ''}
          </div> 
        )}
      </div>
    </div>
  );
}

export default OpTitle;