import React, { useEffect, useState } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OpenCalendarIcon from '../../../img/calendar.svg'
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy')


interface MyDateProps extends Omit<ReactDatePickerProps, 'selected' | 'value' | 'onChange' | 'dateFormat' | 'wrapperClassName' | 'className' | 'customInput' | "popperModifiers"> {
  value: Date | string | number | null;
  onChange: (date: number) => void
  withWrapper?: boolean,
  disablePast?: boolean
}

export const MyDatePicker = ({ 
  value, 
  onChange, 
  withWrapper = true,
  disablePast = false,
  ...rest
}: MyDateProps) => {
  const timeIntervals = 15;
  const [
    intermediateValue,
    setIntermediateValue
  ] = useState(
    typeof value === 'string' || typeof value === 'number' ?
      new Date(value)
    : value
  );

  useEffect(() =>{
    if(intermediateValue) {
      onChange(new Date(intermediateValue).getTime())
    }
  }, [intermediateValue])

  useEffect(() => {
    if(!intermediateValue || !value || new Date(value).getTime() !== new Date(intermediateValue).getTime()) {
      setIntermediateValue(typeof value === 'string' || typeof value === 'number' ? new Date(value) : value)
    }
  }, [value])

  const disablePastTimes = (selectedDate: Date | null) => {
    const currentDate = new Date();
    const selectedDateTime = new Date(selectedDate || currentDate);

    if (selectedDateTime.toDateString() === currentDate.toDateString()) {
      return {
        minTime: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes()),
        maxTime: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59),
      };
    }

    return {};
  };

  const disablePastDatePickerOptions: false | Partial<ReactDatePickerProps> = disablePast && {
    showTimeSelect: true,
    minDate: new Date(),
    timeIntervals: timeIntervals,
    onChange: (date) => {
      if (date && isToday(date)) {
        const now = new Date();
        const minutes = now.getMinutes();
        const remainder = minutes % timeIntervals;
        if (remainder >= (timeIntervals / 2) && isTimeInPast(date, now)) {
          now.setMinutes(minutes + timeIntervals - remainder);

          date.setHours(now.getHours(), now.getMinutes());
        }
      }
      date && onChange(date.getTime())
    },
    ...(disablePastTimes(intermediateValue))
  }

  const datePicker = 
    <DatePicker
      selected={intermediateValue}
      onChange={(date) => date && onChange(date.getTime())}
      dateFormat="dd.MM.yyyy"
      wrapperClassName={'merchery-label no-placeholder'}
      className={'merchery-label__input'}
      popperClassName={'merchery-date-picker--picker'}
      popperModifiers={[
        {
          name: 'arrow',
          options: { padding: 24, },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ]}
      customInput={
        <MaskedInput
          pipe={autoCorrectedDatePipe}
          mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
          keepCharPositions= {true}
          guide = {true}
        />
      }
      {...(disablePastDatePickerOptions && disablePastDatePickerOptions)}
      {...rest}
    />

  if(!withWrapper) {
    return datePicker
  }
  return (
    <label className="merchery-date-picker">
      {datePicker}
      <div className="merchery-date-picker__open-icon" >
        <img
          src={String(OpenCalendarIcon)}
          alt={'open calendar'}
        />
      </div>
    </label>
  )
}

function isToday(date: Date) {
  const today = new Date();
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
}
function isTimeInPast (date: Date, now: Date) {
  return date.getHours() < now.getHours() || 
    (date.getHours() === now.getHours() && date.getMinutes() < now.getMinutes());
}