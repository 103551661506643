import React, { useContext } from 'react';
import { Id, ExtendedProduct } from 'merchery-lib';
import {mercheryFetch} from "../../../../../scripts/fetchConstructor";
import {validateResponse} from "../../../../../scripts/functions";
import {batch, useDispatch} from "react-redux";
import {useAppSelector} from "../../../../../scripts/pre-type/use-selector";
import useMounted from "src/scripts/hooks/use-mounted";
import sortProducts from "../../products.utils";
import MultiProductsChangePopup, {MultiProductsChangeHandler} from "./multi-products-change-popup";
import FolderMove from "../../../../../img/folder-move.svg";
import { ProductsTableContext } from '../table';
import { useLoad } from 'src/scripts/hooks/use-load';

function MultiCategoryReplace () {
	const _isMounted = useMounted();
	const products = useAppSelector(state => state.products)
	const selectedProductsIds = useAppSelector(state => state.selectedProducts);
	const [, setLoad] = useLoad()
  
	const dispatch = useDispatch()
	const productsDispatch = (products: ExtendedProduct[]) => dispatch({type: 'PRODUCTS', payload: products});
	const selectedIdsDispatch = (selected: Id[]) => dispatch({type: 'SELECTED_PRODUCTS', payload: selected});
  const {
    orderingMode,
    setMultiProductsActionCompletedParams,
  } = useContext(ProductsTableContext);

	const onChange: MultiProductsChangeHandler = (activeVisibilityStatus, value) => {
		const changes: Partial<ExtendedProduct> = { }

		if(activeVisibilityStatus.id !== 3) {
			changes.show_date = activeVisibilityStatus.id === 1 ? new Date().getTime() : null
		}

    setLoad(true)

		mercheryFetch<ExtendedProduct[]>('products/replace-category', 'PATCH', {
			categoryId: value.top,
			products: selectedProductsIds,
			otherChanges: changes,
		})
			.then((res) => {
				if(!_isMounted.current || !validateResponse(res)) {
					return false
				}

				const changedProducts =
          [...products]
          .map(product => {
            const thisChangedProduct = res.records.find (
              updated =>
                updated.id === product.id
            );

            return thisChangedProduct
              ? thisChangedProduct
              : product;
          })

        const sortedProducts = sortProducts(changedProducts, orderingMode)

				batch(() => {
					selectedIdsDispatch([])
					productsDispatch(sortedProducts)
          setMultiProductsActionCompletedParams({
			      completeText: 'Товары перемещены',
            filters: [{
              itemIds: value.top === undefined ? value.top : [value.top],
              filterName: 'top',
              cleanPrev: true,
              concreteSelected: true,
              cleanOthers: true
            }]
              // top: value.top === undefined ? value.top : [value.top],
            ,
          })
				})
			})
      .finally(() => {
        setLoad(false)
      })
	}
  
	return (
		<MultiProductsChangePopup
			onChange={onChange}
			btnText={<>
				<img src={String(FolderMove)} alt={''}/>
				Изменить категорию
			</>}
			children={null}
		/>
	);
}

export default MultiCategoryReplace;