import React, { ReactNode, useCallback, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import tinymce from 'tinymce';
import { scrollPageTo } from '../../../scripts/functions';
import ProductEditor from '../products/product-page-modules/description-editor';

let a = tinymce.isOpera;
interface Props {
  description: string | null,
  descriptionHandler: (description: string) => void
}

interface WrappedComponentProps { 
  descriptionEditing: boolean, 
  handleEditorChange: (desc: string) => void,
  descriptionEditSwitch: () => void,
  children: ReactNode
}

export const withDescription = (WrappedComponent: React.FC<WrappedComponentProps>) => {
  return function EnhancedComponent({ descriptionHandler, description, ...props }: Props) {
    const [descriptionEditing, setDescriptionEditing] = useState(true);

    const handleEditorChange = useCallback((desc: string) => {
      descriptionHandler(desc);
    }, [descriptionHandler]);

    const descriptionEditSwitch = useCallback(() => {
      setDescriptionEditing(!descriptionEditing);
      if (!descriptionEditing) scrollPageTo('.inputs-row.description');
    }, [descriptionEditing, setDescriptionEditing]);

    return (
      <WrappedComponent
        {...props}
        descriptionEditing={descriptionEditing}
        handleEditorChange={handleEditorChange}
        descriptionEditSwitch={descriptionEditSwitch}
      >
        <div className={`inputs-row description ${!descriptionEditing ? 'display-none' : ''}`}>
          <div style={{display: 'flex', gap: 8, alignContent: 'center'}}>
            <h4 className="header-font-s">Описание</h4>
            {/* {!moySkladIntegrationOn ?
                !descriptionEditing ? (
                  <MyButton
                    className='product-page-description-edit-btn edit-btn'
                    tabIndex={tabIndex} removeDefaultClass
                    onClick={descriptionEditSwitch} 
                  >
                    <i className='icofont-ui-edit'></i>
                  </MyButton>
                ) : (
                  <MyButton 
                    className='product-page-description-edit-btn edit-btn'
                    tabIndex={tabIndex} removeDefaultClass
                    onClick={descriptionEditSwitch} 
                  >
                    <i className="icofont-not-allowed"></i>
                  </MyButton>
                )
              : null
            } */}
          </div>
          {descriptionEditing ?
            <ProductEditor
              handleEditorChange={handleEditorChange}
              description={description || ''}
            />
          :
            <div className="description-text">{ReactHtmlParser(description || '')}</div>
          }
        </div>
      </WrappedComponent>
    );
  };
};