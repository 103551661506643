import React, {memo, useContext} from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { usePopup } from '../../../../../../../scripts/hooks/use-popup';
import CategoriesFilterAndControl, { CategoryFilterChanger } from '../../../../../categories/categories-filter-and-control';
import ArrowBown from '../../../../../../../img/arrow-down.png';
import {
  OneCharContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/one-char-logic";
import { CharGroupsDto } from 'merchery-lib';

interface Props {
  charGroupsDispatch: (changes: Partial<CharGroupsDto>) => void,
}

function CategoryBindingSelect({
  charGroupsDispatch,
}: Props) {
  const {
    thisCharGroup,
  } = useContext(OneCharContext)
  const categories = useAppSelector(state => state.categories || []);

  const { RenderButton, RenderPopup, closePopup } = usePopup()

  const categoryHandler: CategoryFilterChanger = (categoryId) => {
    charGroupsDispatch({category_id: categoryId})
    closePopup()
  }

  const selectedCategory = categories.find(category =>
    category.id === thisCharGroup?.category_id
  ) || null

  return (
    <div className='characteristic-header__category--input height-48'>
      <RenderButton
        className='input-like-button height-48'
      >
        {selectedCategory?.name || ''}

        <img src={String(ArrowBown)} alt=''/>
      </RenderButton>


      <RenderPopup
        className={'my-select-options'}
      >
        <CategoriesFilterAndControl
          categoryHandler={categoryHandler} 
          filtersMod={true}
          withNoCategory={false}
          noCategoryOptionText={'Для всех категорий'}
        />
      </RenderPopup>
    </div>
  )
}

export default memo(CategoryBindingSelect)