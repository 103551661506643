import React from "react"
import { Id, ProductsAttrValues, ProductAttr, ProductVariantExtended } from "merchery-lib";
import { useAppSelector } from "../../../../scripts/pre-type/use-selector"
import SelectInput from "../../../_utility-components/select-input/select-input"

export interface ProductItemAttributesRequirment {
  id: Id,
  attributes?: ProductAttr[] 
}

export interface ProductItemAttributesProps extends ProductItemAttributesRequirment {
  attrValueHandler?: (item: ProductsAttrValues, name_id: Id) => void
  variant?: ProductVariantExtended | null
}

export interface ProductItemAttrSelect {
  attrValueHandler: (item: ProductsAttrValues, name_id: Id) => void
  variant: ProductVariantExtended | null
}

export function ProductItemAttributes (props: ProductItemAttributesProps) {
  return props.attrValueHandler && props.variant !== undefined && props.attributes !== undefined ? (
    <ProductItemSelectAttributes
      attrValueHandler={props.attrValueHandler}
      variant={props.variant}
      attributes={props.attributes}
      {...props}
      />
  ) : (
    <ProductItemShowAttributes
      {...props}
      />
  )
}

function ProductItemShowAttributes ({attributes}: ProductItemAttributesProps) {
  if(!attributes) {
    return null
  }

  const attrs =
    Array.isArray(attributes) ? 
      attributes.map(attr => `${attr.attr_name}: ${attr.value}`).join(', ')
    : attributes

  return (
    <div className='row-item__attributes text-font-xs'>
      {attrs}
    </div>
  )
}

function ProductItemSelectAttributes ({
  id,
  attrValueHandler,
  variant
}: Required<ProductItemAttributesProps>) {
  const productOptions = useAppSelector(state => state.productOptions);
  const thisProductOptions = productOptions.filter(option => option.product_id === id);

  if(!thisProductOptions.length || !variant) {
    return null
  }
  
  return (
    <div className='row-item__attributes row-item__attributes--select text-font-xs'>
      {thisProductOptions.map(option => {
        const {name_id} = option
        if(!name_id) {
          return null
        }

        const variantValueId = variant?.attributes.find(attr => attr.attr === name_id)?.value_id
        const selectedValue = option.values.find(value => value.id === variantValueId)

        return <div className='row-item__attributes__attr' key={option.id}>
          {`${option.title}: `} 

          <SelectInput 
            inputProps={{
              myClassName: 'row-item__attributes__attr__value',
              widthOfValue: true
            }}
            tabIndexLevel={2}
            items={option.values} 
            valueLabelName={'value'}
            selectedItem={selectedValue}
            selectHandler={value => attrValueHandler(value, name_id)}
            />
        </div>
      })}
    </div>
  )
}