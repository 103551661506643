import React from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { MoySkladPrice, ProductVariantExtended, ExtendedProduct } from 'merchery-lib';
import { priceToPennies, priceWithPennies } from '../../../../../../scripts/functions';
import { useTabIndex } from '../../../../../../scripts/hooks/use-tabindex';
import MSProductsPrices from '../../../../../integrations-modules/moysklad/ms-products-currency';
import MSProductsRemains from '../../../../../integrations-modules/moysklad/ms-products-remains';
import MyInput from '../../../../../_utility-components/input/index';
import { MyInputProps } from '../../../../../_utility-components/input/input';
import { itemIsVariant } from './row';

interface InlineOpCellProps <T extends ExtendedProduct | ProductVariantExtended>{
  product: T,
  changes: Partial<T>,
  setChanges: (ch: Partial<T>) => void,
  confirmInlineChange: (label: keyof T) => void
}

function OpRemains <T extends ExtendedProduct | ProductVariantExtended>({
  product,
  changes,
  setChanges,
  confirmInlineChange,
}: InlineOpCellProps<T>) {
  const tabIndex = useTabIndex(1)
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

  const isVariant = itemIsVariant(product)
  const hasVariants = !isVariant && product.variants.length

  if(hasVariants) {
    return null
  }

  if(moySkladIntegrationOn) { // @ts-ignore: Unreachable code error
    const uom = product.uom
    return <div className={`op-price`}>
      <div> 
        <MSProductsRemains uom={uom} remain={product.remain} />
      </div>
    </div>
  }

  const value = changes.remain !== undefined ? changes.remain : product.remain

  const inputProps: MyInputProps = {
    name: "remain",
    placeholder: "шт",
    disabled: moySkladIntegrationOn,
    myClassName: 'inline-input placeholder--animation-off',
    messagetext: '',
    withBlur: false,
    onChange: (e) => {
      setChanges({
        ...changes,
        id: product.id, 
        remain: +e.target.value,
      })
    },
    value: value,
    onEnter: (e) => (e.target as HTMLElement)?.blur(),
    onBlur: () => confirmInlineChange('remain'),
    type: "number",
    // min: 0,
    style: {paddingRight: 28},
    tabIndex
  }

  return (
    <div className={"op-price"}>
      <div>
        <MyInput {...inputProps}/> 
      </div>
    </div>
  )
}

function OpTotal <T extends ExtendedProduct | ProductVariantExtended>({
  product,
  changes,
  setChanges,
  confirmInlineChange,
}: InlineOpCellProps<T>) {
  const currentLabel = 'price'
  const tabIndex = useTabIndex(1)
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

  const isVariant = itemIsVariant(product)
  const hasVariants = !isVariant && product.variants.length

  if(hasVariants || (changes.price !== undefined && typeof changes.price !== 'number')) {
    return null
  }

  const price = product.price

  if(moySkladIntegrationOn && typeof price !== 'number') {
    return <div className={`op-total ${moySkladIntegrationOn ? 'op-ms-cell' : ''}`}>
      <div>
        <MSProductsPrices prices={(price as MoySkladPrice[])} />
      </div>
    </div>
  }

  const value = priceWithPennies(changes.price || (price as number)) as number;

  const inputProps: MyInputProps = {
    name: "price",
    placeholder: "₽",
    disabled: moySkladIntegrationOn,
    myClassName: 'inline-input placeholder--animation-off',
    messagetext: '',
    // withBind: false,
    withBlur: false,
    onChange: (e) => {
      setChanges({
        ...changes,
        id: product.id, 
        price: priceToPennies(+e.target.value) as number,
      })
    },
    value: value,
    onEnter: (e) => (e.target as HTMLElement)?.blur(),
    onBlur: () => confirmInlineChange(currentLabel),
    type: "number",
    min: 0,
    tabIndex
  }
  return (
    <div className={`op-total ${moySkladIntegrationOn ? 'op-ms-cell' : ''}`}>
      <div>
        <MyInput {...inputProps}/> 
      </div>
    </div>
  );
}

function productHasShowDateField(product: ExtendedProduct | ProductVariantExtended): product is ExtendedProduct {
  return (product as ExtendedProduct).show_date !== undefined
}

export {
  OpTotal,
  OpRemains
};