import React, {createContext, useContext, useEffect, useState} from 'react';
import {usePopup} from "src/scripts/hooks/use-popup";
import MyButton from "src/components/_utility-components/button/button";
import PaymentModes from "src/components/main-pages/orders/order-modules/payment/dev/payment-modes";
import {PaymentModeDto} from "src/components/main-pages/orders/order-modules/payment/dto/payment-mode.dto";
import usePaymentModes from "src/components/main-pages/orders/order-modules/payment/dev/use-payment-modes";
import {mercheryFetch} from "src/scripts/fetchConstructor";
import useMounted from "src/scripts/hooks/use-mounted";
import {uuidv4, validateResponse} from "src/scripts/functions";
import {OrderContext} from "src/components/main-pages/orders/order-page";
import {useAppSelector} from "src/scripts/pre-type/use-selector";
import {useLoad} from "src/scripts/hooks/use-load";
import { RegisterPaymentRequestBody, RegisteredPaymentMethod, RegisteredPaymentRes } from 'merchery-lib';

export const RegisterPaymentContext = createContext<{
	selectedPaymentMode: PaymentModeDto | undefined,
	setSelectedPaymentMode: (mode: PaymentModeDto | undefined) => void
	paymentModes: PaymentModeDto[] | null,
}>({
	paymentModes: null,
	selectedPaymentMode: undefined,
	setSelectedPaymentMode: () => {}
})

function PaymentPayLikeClient () {
	const _isMounted = useMounted();
	const paymentModes = usePaymentModes();
	const [, setLoad] = useLoad();

	const items = useAppSelector(state => state.productItemsInContext)

	const [selectedPaymentMode, setSelectedPaymentMode] = useState<PaymentModeDto | undefined>(
		paymentModes?.[0] || undefined
	);
	const [idempotenceKey, setIdempotenceKey] = useState(uuidv4());

	const {
		order
	} = useContext(OrderContext)

	const {
		RenderButton,
		RenderPopup,
	} = usePopup();

	useEffect(() => {
		setIdempotenceKey(uuidv4())
	}, [order, items]) // update when order changed

	const payLikeClientHandler = async () => {
		if(!selectedPaymentMode || !order) {
			return;
		}

		const body: RegisterPaymentRequestBody = {
			orderUUID: order.UUID,
		};

		setLoad(true)

		const res = await mercheryFetch<RegisteredPaymentRes>('payment', 'POST', body, {
			additionalHeaders: {
				'Idempotence-Key': idempotenceKey
			}
		})

		_isMounted.current &&
			setLoad(false)

		if(!_isMounted.current || !validateResponse(res)) {
			return false
		}

		if(res.records.paymethodId === 4 && (res.records.paymethod as RegisteredPaymentMethod['YooPayment']).confirmation?.type === 'redirect') {
      const yookassa = (res.records.paymethod as RegisteredPaymentMethod['YooPayment']);

      if(yookassa.confirmation?.type === 'redirect') {
        window.location.href = yookassa.confirmation.confirmation_url
      }
		}
	};

	return (
		<RegisterPaymentContext.Provider value={{
			paymentModes,
			selectedPaymentMode,
			setSelectedPaymentMode
		}}>
			<div>
				<RenderButton
					className={'text-font-m blue-btn'}
				>
					Оплитить заказ как клиент
				</RenderButton>

				<RenderPopup
					withBlackout
					withCloseBtn
					tabIndexDeep={2}
				>
					<PaymentModes/>

					<MyButton
						className={'blue-btn'}
						onClick={payLikeClientHandler}
					>
						Оплатить
					</MyButton>
				</RenderPopup>
			</div>
		</RegisterPaymentContext.Provider>
	);
}

export default PaymentPayLikeClient;