import React, { useEffect, useMemo, useState } from 'react';
import { ColorChangeHandler, SketchPicker } from 'react-color';
import MyInput from './input';
import Popup from './popup';
import { batch } from 'react-redux';
import { addAlphaToHex, extractAlphaAndHexWithoutAlpha } from 'src/scripts/functions';
import { PresetColor } from 'react-color/lib/components/sketch/Sketch';

interface IProps {
  className?: string,
  colorValue: string | undefined | null,
  onChange: (value: string) => void
}

function removeSharpFromHex (hex: string) {
  return hex.includes('#') ? hex.replace('#', '') : hex
}

function addSharpToHex (hex: string) {
  return !hex.includes('#') ? '#' + hex : hex
}

const presetColors: PresetColor[] = [
  { color: '#000', title: 'black' },
  { color: '#4A5267', title: 'dark' },
  { color: '#9b9b9b', title: 'grey' },
  { color: '#fff', title: 'white' },
];

function ColorPickerInput({
  className,
  colorValue,
  onChange
}: IProps) {
  const [color, setColor] = useState<string>(colorValue || '');
  const { alpha: extractedAlpha, hexColor: extractedHex } = useMemo(() => 
    extractAlphaAndHexWithoutAlpha(color)
  , [color]);

  const [pickerOpened, setPickerOpened] = useState<boolean>(false);
  const [alpha, setAlpha] = useState<number>(extractedAlpha);

  useEffect(() => {
    if(colorValue !== color) {
      setColor(colorValue || '')
    }
  }, [colorValue])

  useEffect(() => {
    if(colorValue !== color && color) {
      onChange(color)
    }
  }, [color, alpha])

  const handleChange: ColorChangeHandler = (newColor, event) => {
    const isTransparent = newColor.hex === 'transparent' || newColor.rgb.a === 0

    if(isTransparent) 
      return false;

    const hexWithOutSharp = removeSharpFromHex(newColor.hex)
    const newColorAlpha = newColor.rgb.a || 1;
    const hexWithAlpha = addAlphaToHex(hexWithOutSharp, newColorAlpha)

    console.log('newColor', newColor)
    console.log('color', color)
    console.log('isTransparent', isTransparent)
    console.log('hexWithOutSharp', hexWithOutSharp)
    console.log('hexWithAlpha', hexWithAlpha)
    console.log('alpha', alpha)
    console.log('newColorAlpha', newColorAlpha)

    batch(() => {
      if(hexWithAlpha !== color) {
        setColor(hexWithAlpha)
      }
  
      if(alpha !== newColorAlpha) {
        setAlpha(newColorAlpha)
      }
    })
  }

  function inputOnInputHandler () {
    !pickerOpened && setPickerOpened(true)
  }

  function inputOnFocusHandler () {
    setPickerOpened(!pickerOpened)
  }

  return <div className={`merchery-color-picker ${className}`}>
    <MyInput
      myClassName={className}
      name={'color'}
      value={color || ''}
      placeholder='4A5267'
      noPlaceholder
      maxLength={8}
      onChange={e => setColor(e.target.value)}
      onInput={inputOnInputHandler}
      onFocus={inputOnFocusHandler}
    >
      <h4 className="header-font-s">Цвет (hex-код)</h4>
      <p className='product-stories-item__color-prefix'>#</p>
    </MyInput>

    {pickerOpened ? 
      <Popup
        className={''}
        popupClose={() => setPickerOpened(false)}
				changingDirection={true}
        tabIndexDeep={2}
      >
        <SketchPicker
          onChange={ handleChange }
          color={ color }
          presetColors={ presetColors }
        />
      </Popup>
    : null}
  </div>
}

export default ColorPickerInput;