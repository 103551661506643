import React, {memo, useContext, useMemo} from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { getTextWidth } from '../../../../../../scripts/functions';
import MyButton from '../../../../../_utility-components/button/button';
import SelectInput from '../../../../../_utility-components/select-input/index';
import CategoryBindingSelect from './char-header-modules/category-binding-select';
import OneCharDropDownMenu from './char-header-modules/drop-down-menu';
import HeaderTitle from './char-header-modules/title';
import {
  OneCharContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/one-char-logic";
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";
import { CharGroupsDto } from 'merchery-lib';

function OneCharHeader() {
  const pageTopPanelShown = useAppSelector(state => state.topPanelShown);
  const {
    thisCharGroup,
    selectOptions: selectItems,
    selectedOption: selectedItem,
    char,
    clearDeletedCharData,
    setCharScope,
    setToDelete,
  } = useContext(OneCharContext)

  const {
    page,
    charEditingId,
    charGroups,
    setCharGroups,
    setCharEditingId,
  } = useContext(CharacteristicsContext)

  const charEditing = charEditingId === thisCharGroup?.id

  const selectOptionFont = '400 14px Open Sans';
  const charsTop = document.getElementById('chars-top');

  const headerStyle = useMemo(() => {
    const charsTopHeight = charsTop?.clientHeight;
    const charsTopTopRuleValue = charsTop?.style?.top;
    const charHeaderTopRuleValue = charsTopHeight ? charsTopHeight + (pageTopPanelShown && charsTopTopRuleValue ? +charsTopTopRuleValue.slice(0, charsTopTopRuleValue.length - 2) : -1) : 0;
  
    return {
      top: charHeaderTopRuleValue + 'px'
    }
  }, [charsTop, pageTopPanelShown]);

  const charGroupsDispatch = (changes: Partial<CharGroupsDto>) => {
    setCharGroups(
      charGroups.map(group =>
        group.id !== thisCharGroup?.id ? group : ({
          ...group,
          ...changes
        })
      )
    )
  }

  const selectWidth = useMemo(() => {
    const textWidth = selectItems.map(({text}) => getTextWidth(text, selectOptionFont));
    const largestWidth = Math.max.apply(Math, textWidth);
    const paddingsPlusBorders = 12 + 32 + 1 + 1;
    return largestWidth + paddingsPlusBorders;
  }, [selectItems])

  const clearCharData = () => char && clearDeletedCharData(char);
  const setEditHandler = () =>
    setCharEditingId(charEditingId === null && thisCharGroup
      ? thisCharGroup.id
      : null
    );

  return (
    <div className='characteristic-header__wrapper'
      style={headerStyle}
    >
      {charEditing ?
        <h4 className='header-font-m side-padding-24'>
          {thisCharGroup?.newGroup
            ? 'Новая группа характеристик'
            : 'Группа характеристик'}
        </h4>
      : null}

      <div className='characteristic-header side-padding-24'>
        <HeaderTitle
          charEditing={charEditing}
          charGroupsDispatch={charGroupsDispatch}
        />

        {charEditing && page === 'product' ?
          <CategoryBindingSelect
            charGroupsDispatch={charGroupsDispatch}
          />
        : null}

        <div className={'characteristic-header__right-part ' + (page === 'product' ? 'flex-gap-18' : 'flex-gap-24')}>
          {charEditing && page === 'product' ?
            <SelectInput
              inputProps={{
                style: {
                  width: selectWidth,
                  maxWidth: '100%',
                },
              }}
              items={selectItems}
              selectedItem={selectedItem}
              className={'char-header__select-input'}
              selectHandler={value => setCharScope(value.id)}
            /> 
          : null}

          {charEditingId === null && page === 'product' ?
            <OneCharDropDownMenu
              setEditHandler={setEditHandler}
              clearCharData={clearCharData}
            />
          : null}

          {!charEditing && page === 'categories' ?
            <React.Fragment>
              <MyButton
                disabled={charEditingId !== null}
                onClick={setEditHandler}
                removeDefaultClass
              >
                <i className='icofont-ui-edit'></i>
              </MyButton>

              <MyButton
                disabled={charEditingId !== null}
                onClick={() => setToDelete(true)}
                removeDefaultClass
              >
                <i className='icofont-trash'></i>
              </MyButton>
            </React.Fragment>
          : null}
        </div>
      </div>
    
      {charEditing ? (
        <div className='side-padding-24 text-font-s'>
          Блок характеристик будет применен ко всем товарам выбранной категории
        </div>
      ) : null}
    </div>
  );
}

export default memo(OneCharHeader);