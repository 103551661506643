import React, {ReactNode} from "react";

export function BodyCell ({
	myClassName,
	children
}: {
	myClassName?: string,
	children: ReactNode | ReactNode[]
}) {
	return (
		<div className={`table__cell ${myClassName ? myClassName : ''}`}>
			{children}
		</div>
	)
}