import {useContext, useEffect, useState} from "react";
import {WebSocketContext} from "../scripts/web.socket";
import {useAppSelector} from "../scripts/pre-type/use-selector";
import {batch, useDispatch} from "react-redux";
import {updateDispatch} from "./dispatches";
import {
  WsResponse, 
  CategoryChangeWs, 
  CreateCategoriesWsDto, 
  DeleteCategoriesWsDto, 
  ReorderCategoriesWsDto, 
  UpdateCategoriesWsDto,
  Category
} from "merchery-lib";
import { 
	createCategory,
	deleteCategory, 
  reorderCategory,
	updateCategory,
} from "src/constants/ws-events";
import {CategoriesContext} from "../components/main-pages/categories/categories";

const categoriesDispatchType = 'CATEGORIES';

function useCategoriesChangesListener () {
	const {
		lastMessage,
	} = useContext(WebSocketContext);
	const {
		newCategories,
    setNewCategories
	} = useContext(CategoriesContext);

	const categories = useAppSelector(state => state.categories);

	const [changesInQueue, setChangesInQueue] = useState<(ReorderCategoriesWsDto | CreateCategoriesWsDto | DeleteCategoriesWsDto)[]>([]);

	const dispatch = useDispatch()

	const reorderCategoriesDispatch = (categories: Category[]) =>
		dispatch({ type: categoriesDispatchType, payload: categories })

	const updateCategoriesDispatch = categories && updateDispatch(categories, dispatch, categoriesDispatchType);

	const applyRenderChanges = (changes?: typeof changesInQueue) => {
    if(!categories) {
      return false
    }

		let reorderedCategories: Category[] = [...categories];
    const newCategoriesShallowCopy = new Set(newCategories)

		if(!changes?.length) {
			changes = changesInQueue
		}

		changes.forEach((data) => {
			if('category' in data) {
				if(reorderedCategories.some(cat => cat.UUID === data.category.UUID)) {
					reorderedCategories = reorderedCategories.map(cat => 
            cat.UUID === data.category.UUID 
              ? data.category 
              : cat
          )
				} else {
					reorderedCategories.push(data.category)
				}
        newCategoriesShallowCopy.add(data.category.id)
			} else if('changes' in data) {
				data.changes.forEach((categoryReorderWs) => {
					const categoryIndex = reorderedCategories.findIndex(category => 
            category.id === categoryReorderWs.id);

					if (categoryIndex !== -1) {
						reorderedCategories[categoryIndex] = {
							...reorderedCategories[categoryIndex],
							...categoryReorderWs
						}
					}
				})
			} else {
				reorderedCategories = reorderedCategories.filter(
					category => Array.isArray(data.id)
						? !data.id.some(id => category.id === id)
						: category.id !== data.id
				)
			}
		})

		batch(() => {
      setNewCategories(newCategoriesShallowCopy)
      reorderCategoriesDispatch(reorderedCategories)
			setChangesInQueue([]);
		})
	}

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<ReorderCategoriesWsDto>;

			if (message.event === reorderCategory &&
				message.data.changes &&
				message.data.initiatorId
			) {
				const currentAdminId = localStorage.getItem('admin')
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<UpdateCategoriesWsDto>;
			const changes = message.data.changes;

			if (message.event === updateCategory &&
				message.data.changes
			) {
        updateCategoriesDispatch &&
				  updateCategoriesDispatch<CategoryChangeWs>(changes)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<DeleteCategoriesWsDto>;
			const currentAdminId = localStorage.getItem('admin')

			if (message.event === deleteCategory &&
				message.data.id &&
				message.data.initiatorId
			) {
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<CreateCategoriesWsDto>;
			const currentAdminId = localStorage.getItem('admin')

			if (message.event === createCategory &&
				message.data.category &&
				message.data.initiatorId
			) {
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	return [changesInQueue, applyRenderChanges] as const;
}

export default useCategoriesChangesListener