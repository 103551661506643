import React from "react"
import MyButton from "../../../../_utility-components/button/button"
import MyCheckbox from "../../../../_utility-components/checkbox/my-checkbox"

const HeaderCheckbox = ({
  checked,
  checkAllProducts
}: {
  checked: boolean,
  checkAllProducts: () => void
}) => (
  <div
    className="op-check hide-for-print" 
  >
    <MyCheckbox condition={checked} actionHandler={checkAllProducts}    
    />
    {/* <MyButton 
      onClick={checkAllProducts}
      removeDefaultClass
    >
      <div className="checkbox"><i className='icofont-check-alt'></i></div>
    </MyButton> */}
  </div>
)

export default HeaderCheckbox