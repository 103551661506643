import React, { useContext, useEffect } from 'react';
import { MainRouteChild } from '../../main-page';
import SubscriptionsTable from './table';
import PerformMailingBtn from './perform-mailing-btn';
import { SubscriptionContext } from './subscription-context';
import SearchInput from 'src/components/_utility-components/search-input';
import withSubscriptionContext from './subscription-context';

interface SubscriptionsPageProps extends MainRouteChild {
}

function Subscriptions(props: SubscriptionsPageProps) {
  const {
    searchInput,
    setSearchInput
  } = useContext(SubscriptionContext)

  useEffect(() => {
    props.setCurrentPage('subscriptions');
  }, [])

  return (
    <div className='subscriptions-page'>
      <h1>Подписки</h1>

      <PerformMailingBtn/>

      <SearchInput
        id='subscription-search'
        searchInput={searchInput}
        applySearch={setSearchInput}/>

      <SubscriptionsTable/>
    </div>
  );
}

export default withSubscriptionContext(Subscriptions);