import React, {useCallback, useContext} from 'react';
import {batch} from 'react-redux';
import { uuidv4 } from '../../../../../scripts/functions';
import MyButton from '../../../../_utility-components/button/button';
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";
import { Id, CharGroupsDto } from 'merchery-lib';

interface Props {
  categoryId: Id,
}
function CharsBottom({
  categoryId,
}: Props) {
  const {
    charEditingId,
    setCharEditingId,
    charGroups,
    setCharGroups
  } = useContext(CharacteristicsContext)

  const addNewCharGroups = useCallback(() => {
    if(!categoryId) {
      return false
    }
    
    const newCharGroup: CharGroupsDto = {
      id: uuidv4(),
      category_id: categoryId,
      name: '',
      newGroup: true,
    }

    batch(() => {
      setCharEditingId(newCharGroup.id)
      setCharGroups( [...charGroups, newCharGroup])
    })
  } 
  , [charGroups, categoryId])

  return (
    <div className='characteristics-bottom side-padding-24'>
      <div className='characteristics-bottom__left-side'>
        <MyButton className={'characteristics-bottom__btn header-font-m'}
          onClick={addNewCharGroups}
          removeDefaultClass
          disabled={charEditingId !== null}
        >
          <i className="icofont-plus"></i> Добавить группу характеристик
        </MyButton>
      </div>

      {/* <div
        className='characteristics-bottom__right-side'>
        <MyButton className={'characteristics-bottom__btn header-font-m'}>
          Сохранить шаблон
        </MyButton>
      </div> */}
    </div>
  );
}

export default CharsBottom;