import React, {useContext} from 'react';
import MyButton from '../../../../_utility-components/button/button';
import { OrderContext } from '../../order-page';
import { OrderDeliveryContext } from './delivery';

export function OrderDeliveryChangeStateSwitcher() {
  const {
    order,
    orderDeleted,
    isOrderUnchangeable
  } = useContext(OrderContext);
  const {
    setDeliverySelectStateOn,
  } = useContext(OrderDeliveryContext);

  if(!order || orderDeleted || isOrderUnchangeable) {
    return null
  }

  return (
    <MyButton
      className="op-delivery-top-edit-btn hide-for-print"
      removeDefaultClass
      onClick={() => setDeliverySelectStateOn()}
    >
      <i className="icofont-ui-edit"></i>
    </MyButton>
  )
}
