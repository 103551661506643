import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/scripts/pre-type/use-selector";
import { WebSocketContext } from "src/scripts/web.socket";
import { createDispatch, deleteDispatch, updateDispatch } from "./dispatches";
import { 
  CreatePromocodesWsDto,
  DeletePromocodesWsDto,
  PromocodeChange, 
  UpdatePromocodesWsDto,
  WsResponse,
} from "merchery-lib";
import { 
  createPromocode,
  deletePromocode,
  updatePromocode
} from "src/constants/ws-events";

export default function usePromocodesWsListener () {
	const {
		lastMessage,
	} = useContext(WebSocketContext);

  const promocodes = useAppSelector(state => state.promocodes);
	const dispatch = useDispatch();
  const promocodesDispatchType = 'ORDERS_PROMOCODES'

	const updateItemsDispatch = updateDispatch(promocodes, dispatch, promocodesDispatchType);
	const deleteItemsDispatch = deleteDispatch(promocodes, dispatch, promocodesDispatchType);
	const createItemsDispatch = createDispatch(promocodes, dispatch, promocodesDispatchType);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<UpdatePromocodesWsDto>;

			if (message.event === updatePromocode &&
				message.data.changes
			) {
				const changes = message.data.changes;

				updateItemsDispatch<PromocodeChange>(changes)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<DeletePromocodesWsDto>;

			if (message.event === deletePromocode &&
				message.data.id
			) {
				const ids = message.data.id;

				deleteItemsDispatch(ids)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<CreatePromocodesWsDto>;

			if (message.event === createPromocode &&
				message.data.promocode
			) {
				const promocode = message.data.promocode;

				createItemsDispatch(promocode)
			}
		}
	}, [lastMessage]);
}