import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { Id } from 'merchery-lib';
import {printPage, scrollPageTo} from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import MyButton from '../../../_utility-components/button/button';
import { OrderContext } from '../order-page';
import SetManager from './popups/set-manager';
import SetStatus from './popups/set-status';
import RefundOrder from './refund-modules/refund-start';
import SendLetter from "./popups/send-letter";

function OrderChangeBar() {
  const {
    order,
    orderChange,
  } = useContext(OrderContext)

  const refunds = useAppSelector(state => state.refunds)
  if(!order) {
    return null
  }
  
  const statusHandler = (statusId: Id) => {
    orderChange({
      id: order.id, 
      status: statusId,
    })
  };

  const managerHandler = (managerId: Id | null) => orderChange({
    id: order.id,
    admin_id: managerId
  });
  const setCompleteOrderStatus = () => orderChange({
    id: order.id,
    status: 3
  });

  return (
    <div className="order-page-change-bar">
      <div className='order-page-change-bar__left-inner'>
        <SetStatus 
          className='status-select'
          btnClassName={'btn-clickable-zone'} 
          withArrow
          order={order} 
          statusHandler={statusHandler}/>

        {/* <SetPaid 
          withArrow
          order={order} 
          paidHandler={paidHandler}/> */}
        
        {+order.status !== 3 ? (
          <MyButton 
            removeDefaultClass
            className='square-btn done-btn hide-for-print' 
            data-tip={"Завершить заказ"} 
            data-for={'order-status'} 
            onClick={setCompleteOrderStatus}
          >
            <i className='icofont-check-alt'></i>

            <ReactTooltip 
              id={"order-status"} 
              resizeHide={true} 
              place={'top'} 
              multiline={true} 
              effect={"solid"} 
              isCapture={true}
            />
          </MyButton>
        ) : null}

        {refunds && refunds.some((refund) => refund.order_id === order.id) ? 
          <MyButton 
            removeDefaultClass
            className={`order-page-change-bar__refund-status header-font-xxs`}
            data-tip={`Заказ возвращен`} 
            data-for={'refund-status'} 
            onClick={() => {
              scrollPageTo('.order-page__refunds')
            }}
          >
            <div className='refund-status__inner'>
              Возвращен
            </div>

            <ReactTooltip 
              id={"refund-status"} 
              resizeHide={true} 
              place={'top'} 
              multiline={true} 
              effect={"solid"} 
              isCapture={true}
            />
          </MyButton>
        : null}

        <SetManager 
          className='add-manager-btn' 
          admin_id={order.admin_id} 
          changeManager={managerHandler}
        />
      </div>


      <div className='order-page-change-bar__right-inner'>
        {/* <MyButton 
          removeDefaultClass
          className="clipboards-btn"
        >
          <div className="clipboards"></div>
        </MyButton> */}

        <RefundOrder
          order={order}
        />

        <div className="order-page-buttons hide-for-print">
          <MyButton
            removeDefaultClass
            className='order__external-btn'
            onClick={printPage}
          >
            <i className="icofont-print"></i>Печать
          </MyButton>

          {order.client ?
            <SendLetter
              headerText={<h2>
                Отправить клиенту <strong>{order.client.name} письмо о заказе {order.id}?</strong>
              </h2>}
            />
          : null}
        </div>
      </div>
    </div>
  );
}

export default OrderChangeBar;