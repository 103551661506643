import { toastUp } from './functions';
import staticValues from '../data/static-values.json';
import history from './history.js';
import { Id, MyResponse, VideoCreate } from 'merchery-lib';

export type GetResourceResponse<T, ByRows extends boolean, Options> = 
  Options extends { externalUrl: true }
    ? T
    : MyResponse<T, ByRows>;

// export type MercheryFetch = <T, ByRows extends boolean = false>(
//   url: string,
//   method: string,
//   data?: {},
//   options?: {
//     externalUrl?: boolean,
//     withoutToastUp?: boolean,
//     additionalHeaders?: {}
//   }
// ) => Promise<GetResourceResponse<T, ByRows, typeof options>>

const _apiBase = staticValues[process.env.NODE_ENV === 'development' ? 'dev_values' : 'prod_values'].admin_api;

async function mercheryFetch<T>(
  url: string, method: string, data: {}, options: {
    externalUrl: true
  }
): Promise<T>
async function mercheryFetch<T, ByRows extends boolean = false>(
  url: string, method: string, data?: {}, options?: {}
): Promise<MyResponse<T, ByRows>>
async function mercheryFetch<T, ByRows extends boolean = false>(
  url: string,
  method: string,
  data?: {},
  options?: {
    externalUrl?: boolean, 
    withoutToastUp?: boolean, 
    additionalHeaders?: {}
  }
): Promise<GetResourceResponse<T, ByRows, typeof options>> {
  try {
    let res: Response;

    const headers = options?.externalUrl ? {
      'Content-type': 'application/json',
      'x-custom-lang': 'ru'
    } : {
      'Content-type': 'application/json',
      'x-custom-lang': 'ru',
      'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
      ...options?.additionalHeaders
    }

    const collectedUrl = options?.externalUrl ? url : _apiBase + url;
    const fetchOptions = {
      method: method,
      headers: headers,
      ...(method !== 'GET' && {
        body: JSON.stringify(data)
      })
    }

    res = await fetch(collectedUrl, fetchOptions);

    if(res.status === 401 && !options?.externalUrl) {
      await fetch(_apiBase + 'auth/relogin', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'x-custom-lang': 'ru'
        },
        credentials: 'include'
      })
      .then(res => res.json())
      .catch(error => {
        console.log(error)
        return false
      })
      .then(async loginRes => {
        if(loginRes.success && loginRes.records) {
          const access_token = loginRes.records.jwt;
          localStorage.setItem('jwt', access_token);

          const newHeaders = {
            'Content-type': 'application/json',
            'x-custom-lang': 'ru',
            'Authorization': 'Bearer ' + access_token,
            ...options?.additionalHeaders
          }

          res = await fetch(collectedUrl, {
            ...fetchOptions, 
            headers: newHeaders
          });
          
        } else {
          history.push('/sign-in/');
        }

      })
    }

    return res.json()
    .then((response) => {
      if(!response.success) {
        if(response?.message && !options?.withoutToastUp) {
          toastUp(response?.message)
        }
        return response
      }

      return response
    })
  } catch (error: unknown) {
    if(error instanceof Error) {
      console.log(error)
      toastUp(error?.message || error)
    }
    throw error
  }
}

async function login (data: {}): Promise<MyResponse> {
  return fetch(_apiBase + 'auth/login', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'x-custom-lang': 'ru',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  })
  .then(res => res.json())
  .catch(error => {
    console.log(error)
    return false
  })
}



async function mercheryUploadVideos (filesToAdd: VideoCreate[], module_id: Id, module: string) {
  const headers: HeadersInit = {
    'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
    'x-custom-lang': 'ru',
  }

  const data = new FormData();

  data.append('module_id', String(module_id));
  data.append('module', module);

  for (const [index, videoData] of filesToAdd.entries()) {
    data.append('videos', videoData.image, videoData.image.name);
    data.append(`metadata[${index}][imageName]`, videoData.image.name)

    if(videoData.main !== undefined) 
      data.append(`metadata[${index}][main]`, String(videoData.main))
    
    if(videoData.order !== undefined) 
      data.append(`metadata[${index}][order]`, String(videoData.order))
  }
  
  const request = fetch(_apiBase + 'images/create/video', {
    method: 'POST',
    headers,
    credentials: 'include',
    body: data,
  })

  return await request
  .then(res => res.json())
}

export {
  mercheryFetch,
  login,
  mercheryUploadVideos,
}