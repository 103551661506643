import React from 'react';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { validateResponse } from 'src/scripts/functions';
import { useLoad } from 'src/scripts/hooks/use-load';
import useMounted from 'src/scripts/hooks/use-mounted';
import MyButton from './button/button';
import RefuncIcon from '../../img/refund-icon.svg'

function RestoreButton<T extends any>({
  restoreUrlPath,
  dispatchHandler
}: {
  restoreUrlPath: string,
  dispatchHandler: (records: T) => any
}) {
  const _isMounted = useMounted()
  const[, setLoad] = useLoad()

  const restoreRequest = () => {
    setLoad(true)
    
    return mercheryFetch<T>(restoreUrlPath, 'PATCH')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      return dispatchHandler(res.records)
    })
    .finally(() => {
      setLoad(false)
    })
  }

  return (
    <MyButton
      style={{ opacity: .8, color: '#4a5267' }}
      className='wide-header-font-xs'
      onClick={restoreRequest}
    >
      <img src={String(RefuncIcon)} alt={''}/>
      Восстановить
    </MyButton>
  );
}

export default RestoreButton;