import React from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

const AddItemsLoader = (props: IContentLoaderProps) => (
  <ContentLoader
    speed={2}
    width={800}
    height={97}
    viewBox="0 0 800 97"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    preserveAspectRatio="none"
    {...props}
  >
    <rect x="27" y="40" rx="0" ry="0" width="16" height="16" /> 
    <rect x="72" y="20" rx="0" ry="0" width="48" height="56" /> 
    <rect x="138" y="40" rx="0" ry="0" width="270" height="18" /> 
    <rect x="565" y="40" rx="0" ry="0" width="22" height="16" /> 
    <rect x="615" y="32" rx="0" ry="0" width="48" height="31" /> 
    <rect x="670" y="40" rx="0" ry="0" width="20" height="16" /> 
    <rect x="718" y="40" rx="0" ry="0" width="55" height="16" /> 
  </ContentLoader>
)

export default AddItemsLoader;