import React, { useState } from 'react';
import { Id, Order } from 'merchery-lib';
import { num2str } from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import MyButton from '../../../_utility-components/button/button';
import ExportButton from '../../../_utility-components/export-btn/export-btn';
import TopPagePanel from '../../../_utility-components/top-page-panel';
import { ChangeOrderHandler } from '../orders-modules/table';
import { renameExportHeaders } from '../utils/rename-export-headers';
import OrdersDeletePopupRender from './delete-popup';
import { SetMassManager } from './manager-multi-rows-popup';
import { SetMassStatus } from './status-multi-rows-popup';
import {MultiChanger} from "../../../../scripts/utils/entity-filters";

interface Props {
  deleteOrder: () => void
  clearSelectedOrders: () => void,
  selectedOrders: Id[],
  changeOrder: ChangeOrderHandler
}

const filtersRename: {
  [key: string]: string 
} = {
  'manager': 'admin_id',
  'status': 'status'
};

const SelectedOrdersMenu = ({
  deleteOrder,
  clearSelectedOrders,
  selectedOrders,
  changeOrder,
}: Props) => {
  const orders = useAppSelector(state => state.orders);
  const statuses = useAppSelector(state => state.orderStatus);
  const deliveries = useAppSelector(state => state.deliveryServices);
  const paymethod = useAppSelector(state => state.paymethod);
  const admins = useAppSelector(state => state.admin);

  const [deletePopupOpened, setDeletePopupOpened] = useState(false);
  const countText = num2str(selectedOrders.length, ['заказ выбран', 'заказа выбрано', 'заказов выбрано']);

  const multiChange: MultiChanger<Order> = async (filterChangerProps) => {
    if(!filterChangerProps.filterName) {
      return undefined
    }

    const renamedOrderField = filtersRename[filterChangerProps.filterName] || filterChangerProps.filterName;

    changeOrder(
      selectedOrders.flatMap(so => filterChangerProps.itemIds && filterChangerProps.filterName ? ({
        id: so,
        [renamedOrderField]: filterChangerProps.itemIds[0]
      }) : [])
    )
  }

  return (
    <div className="selected-top-page-menu">
      <TopPagePanel topPanelOpened={true} className={'selected-top-page-menu-inner'}>
        <div className="left">
          <MyButton 
            className="uncheck-all-items selected-top-page-menu-btn header-font-s"
            onClick={clearSelectedOrders}
          >
            {countText} 
            <div><div></div></div>
          </MyButton>
          
          <SetMassStatus multiChange={multiChange}/>
          
          <SetMassManager multiChange={multiChange}/>

          <div>
            <ExportButton<Order>
              text={'Выгрузить'}
              className={'selected-top-page-menu-btn header-font-s'}
              items={orders.filter(o =>
                selectedOrders.some(so => so === o.id)
              )}
              renameExportHeaders={order => renameExportHeaders({
                order,
                statuses,
                deliveries,
                paymethod,
                admins,
                // paidStatuses,
                // ordersCount
              })}
            />
          </div>
        </div>
        
        <MyButton 
          className="right header-font-s"
          onClick={() => setDeletePopupOpened(true)}
        >
          <div><i className="icofont-trash"></i> Удалить</div>
        </MyButton>

        {deletePopupOpened ? 
          <OrdersDeletePopupRender 
            popupClose={() => setDeletePopupOpened(false)} 
            selectedOrders={selectedOrders} 
            mainActionHandler={deleteOrder}
          />
        : null} 

      </TopPagePanel>
    </div>
  );
};
export default SelectedOrdersMenu;
