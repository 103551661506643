// Универсальный спиннер для состояния загрузки/отправки

import React from 'react';
import Svg from '../../../img/app-loader.svg'

interface Props {
  className?: string
  width?: number
  height?: number
}

function AppLoader(props: Props): React.ReactElement {
  const {
    className='load-spinner',
    width,
    height
  } = props;

  return (
    <div className={className} onClick={(e) => {e.stopPropagation()}}>
      <img width={width} height={height} src={String(Svg)} alt="app loader" />
    </div>
  );
}

export default AppLoader;

export function AppLoaderWrapper (props: Props) {

  return (
    <div className='app-loader-wrapper'>
      <AppLoader {...props}/>
    </div>
  )
}