import draggable from 'src/img/draggable.svg';
import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

interface Props extends DraggableProvidedDragHandleProps {
  className?: string
}

function Grabber(props: Props) {
  const {
    className,
    ...draggableProps
  } = props;

  return (
    <div
      {...draggableProps}
      tabIndex={-1}
      className={`grab-zone ${className}`}
    >
      <img src={String(draggable)} alt=""/>
    </div>
  );
}

export default Grabber;