import React, { ReactNode, useState } from 'react';
import { SubscriptionDto } from 'merchery-lib';

const SubscriptionContext = React.createContext<{
  searchInput: string,
  setSearchInput: (value: string) => void,
  subscriptions: SubscriptionDto[],
  setSubscriptions: (subs: SubscriptionDto[]) => void
}>({
  searchInput: '',
  setSearchInput: (value: string) => {},
  subscriptions: [],
  setSubscriptions: (subs: SubscriptionDto[]) => {}
})

const withSubscriptionContext = <P extends {}>(Component: React.ComponentType<P>) => {
  return (props: P) => {
    const [searchInput, setSearchInput] = useState<string>('');
    const [subscriptions, setSubscriptions] = useState<SubscriptionDto[]>([]);

    return (
      <SubscriptionContext.Provider value={{ 
        searchInput, setSearchInput,
        subscriptions, setSubscriptions,
      }}>
        <Component {...props} />
      </SubscriptionContext.Provider>
    );
  };
};

export default withSubscriptionContext;

export {
  SubscriptionContext
}