import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id, ExtendedProduct } from 'merchery-lib';
import { gramToKg, kgToGram } from '../../../../scripts/functions';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import MyCheckbox from '../../../_utility-components/checkbox/index';
import MyInput from '../../../_utility-components/input/index';
import { ProductInputHandler } from '../product';
import ProductsCharacteristics from './characteristics-modules/product-characteristics';
import ProductUnitsSelect from './units-select';
import {TabIndex} from "../../../../dto/master-dto/tabindex.dto";

interface Props {
  inputHandler: ProductInputHandler
  isCreatePage: boolean;
}

function WeightInput ({
  tabIndex,
  inputHandler
}: {
  tabIndex: TabIndex,
  inputHandler: ProductInputHandler
}) {
  const product = useAppSelector(state => state.product);
  const additionalOptions = useAppSelector(state => state.additionalOptions);

  if(!product || additionalOptions) {
    return null
  }

  return <div className={`inputs-row weight`}>
    <h4 className="header-font-s">Вес, гр (100гр по ум.)</h4>
    <MyInput
      type={"number"}
      value={gramToKg(product.weight)}
      required={false}
      tabIndex={tabIndex}
      shortInput={true}
      noPlaceholder
      min={0}
      maxLength={2}
      onChange={e => inputHandler('weight', kgToGram(Number(e.target.value)))}
    />
  </div>;
}

function RemainInput ({
  tabIndex,
  inputHandler,
  productDispatchByLabel,
}: {
  tabIndex: TabIndex,
  inputHandler: ProductInputHandler,
  productDispatchByLabel: (label: keyof ExtendedProduct, value: any) => undefined | {payload: ExtendedProduct, type: string}
}) {
  const product = useAppSelector(state => state.product);
  const additionalOptions = useAppSelector(state => state.additionalOptions);

  if(!product || additionalOptions) {
    return null
  }

  return <div className={`inputs-row remain`}>
    <h4 className="header-font-s">Остаток на складе</h4>
    <MyInput
      type={"number"}
      value={product.remain}
      required={false}
      tabIndex={tabIndex}
      shortInput={true}
      maxLength={16}
      onChange={e => inputHandler('remain', Number(e.target.value))}
      onCount={num => productDispatchByLabel('remain', num)}
    />
  </div>;
}

function ProductProperties({
  inputHandler,
  isCreatePage,
}: Props) {
  const product = useAppSelector(state => state.product);
  const tabIndex = useTabIndex();
  const dispatch = useDispatch();

  const productDispatch = (item: ExtendedProduct) => dispatch({type: 'PRODUCT_ITEM', payload: item});
  const productDispatchByLabel = (label: keyof ExtendedProduct, value: any) => 
    product && productDispatch({
      ...product, 
      [label]: value 
    });

  const moySkladIntegration = useAppSelector( state => state.integrations?.find(s => s.code === 'moy_sklad') )
  const moySkladIntTurnedOn = useMemo(() => moySkladIntegration?.turned_on, [moySkladIntegration])

  // useEffect(() => {
    // if(product) {
    //   setLocalProduct(product)
    // }
  // }, [product?.ship_in_packages, product?.price_per_pack, product?.measure_id]);

  const prevUnit = useRef<Id | null>(null);

  useEffect(() => {
    if(product) {
      const conditionForFocus = 
        product.measure_id === 2 &&
        prevUnit.current !== null &&
        prevUnit.current !== product.measure_id;
  
      if(conditionForFocus) {
        (document.querySelector('.package-amount.inputs-row .merchery-label__input') as HTMLElement)?.focus()
      }
      prevUnit.current = product.measure_id
    }
  }, [product?.measure_id]);

  if(!product) {
    return null
  }

  return (
    <div id='propserties' className='product-page-element product-page-properties-and-characteristics'>
      <div className='product-page-properties side-padding-24'>
        <h3 className="product-page-h3 section-title">Свойства</h3>

        <WeightInput
          inputHandler={inputHandler}
          tabIndex={tabIndex}
        />

        <RemainInput
          inputHandler={inputHandler}
          tabIndex={tabIndex}
          productDispatchByLabel={productDispatchByLabel}
        />

        <div className={`inputs-row unit-of-measurement`}>
          <h4
            className="header-font-s"
            style={{
              textWrap: 'nowrap',
              overflow: 'hidden',
            }}
          >
            Единица измерения
          </h4>

          <ProductUnitsSelect
            tabIndexLevel={2}
            currentUnitId={product.measure_id}
            selectHandler={unit => productDispatchByLabel('measure_id', unit.id || product.measure_id)}
            shortInput={true}
          />
        </div>

        {product.measure_id === 2 ?
          <>
            <div className={`inputs-row package-amount`}>
              <h4 className="header-font-s">Кол-во в упаковке</h4>
              <MyInput
                type={'number'}
                value={product.package_amount || ''}
                required={false}
                tabIndex={tabIndex}
                shortInput={true}
                maxLength={32}
                min={0}
                customArrow={true}
                onChange={e => inputHandler('package_amount', Number(e.target.value))}
                onCount={num => productDispatchByLabel('package_amount', num)}
                />
            </div>

            <MyCheckbox
              className='ship_in_packages checkbox-wrapper'
              text='Отгружать кратно упаковке'
              actionHandler={() => inputHandler('ship_in_packages', !product.ship_in_packages)}
              condition={Boolean(product.ship_in_packages)}/>
          
            <MyCheckbox
              className='price_per_pack checkbox-wrapper'
              text='Отображать цену за упаковку'
              actionHandler={() => inputHandler('price_per_pack', !product.price_per_pack)}
              condition={!!product.price_per_pack}/>
          </>
        : null}
      </div>

      {!moySkladIntTurnedOn &&
        <ProductsCharacteristics
          isCreatePage={isCreatePage}
          page={'product'}
          productCategory={product.top}
        />
      }
    </div>
  );
}

export default memo(ProductProperties);