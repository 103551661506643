import React, { MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLoad } from 'src/scripts/hooks/use-load';
import { Id, SubscriptionDto } from 'merchery-lib';
import useInfiniteScrollShowMore from 'src/scripts/hooks/use-infinite-scroll-show-more';
import useInitFilters, { EntityGetter, entityGetterQuery } from 'src/scripts/hooks/use-init-filters';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import OrdersLoader from 'src/components/_utility-components/loaders/orders-loader';
import CommonTableLoader from 'src/components/_utility-components/loaders/common-table-loader';
import useMounted from 'src/scripts/hooks/use-mounted';
import useSortableData from 'src/scripts/hooks/use-sortable-data';
import MyTable from 'src/components/_utility-components/common-table/table';
import SubscriptionsTableHeader from './subscriptions-table-header';
import { TableBody } from 'src/components/_utility-components/common-table/table-body';
import useRowFocus, { activeCounter } from 'src/scripts/hooks/use-row-focus';
import { BodyRow } from 'src/components/_utility-components/common-table/body-row';
import { useTabIndex } from 'src/scripts/hooks/use-tabindex';
import useRowClick from 'src/scripts/hooks/use-row-click';
import { SubscriptionRowIdAndCheck } from './table-columns/id-and-check';
import { batch } from 'react-redux';
import SubscriptionProductTitle from './table-columns/product-title';
import { SubscriptionContext } from './subscription-context';
import { validateResponse } from 'src/scripts/functions';
import SubscriptionConfig from './table-columns/config';
import SubscriptionDate from './table-columns/date';
import SubscriptionClientEmail from './table-columns/client-email';
import SubscriptionAttributes from './table-columns/attributes';
import SubscriptionRemain from './table-columns/remain';
import SelectedSubsMenu from './table-columns/selected-subs-menu';

const subsGetSize = 40;

function SubscriptionsTable() {
  const _isMounted = useMounted();
  const [load, setLoad] = useLoad();
  const tabIndex = useTabIndex();
  const {
    searchInput,
    setSearchInput,
    subscriptions, 
    setSubscriptions
  } = useContext(SubscriptionContext);

  const [selectedSubs, setSelectedSubs] = useState<Id[]>([]);
  const [allSubsCount, setAllSubsCount] = useState(0);
  const [currentSubsCount, setCurrentSubsCount] = useState(0);
  const [shownSubs, setShownSubs] = useState<Id[]>([]);

  const defaultSorting = {
    key: "id",
    direction: "descending",
  } as const;

  const initialCount = {
    prev: 0,
    next: 40
  };
  const [count,] = useState(initialCount);

  const currentlyShownSubs = useMemo(() => 
    subscriptions.filter(o =>
      shownSubs.some(shownId => o.id === shownId))
  , [subscriptions, shownSubs]);
  const {focusedItem, focusHandler, prevChecked, setPrevChecked} = useRowFocus<SubscriptionDto>(null);

  const [
    sortedSubs,
    requestSort,
    config
  ] = useSortableData<SubscriptionDto>(
    [...(currentlyShownSubs || [])],
    defaultSorting
  );

  const [rowClick, setActiveRowId] = useRowClick(
    undefined,
    // ['.tt-select-outside', '.tt-config', '.tt-status', '.tt-manager', '.fixed-on-center', '.client-info-popup-open-btn', '.client-info-popup']
  );
  const getSubs: EntityGetter = () => {
    const queryFilters = entityGetterQuery({
      search: searchInput,
      pageSize: subsGetSize
    })

    setLoad(true)

    mercheryFetch<SubscriptionDto, true>(`orders/subscriptions?${queryFilters}`, 'GET')
    .then(async (res) => {
      if (!_isMounted.current || !validateResponse<SubscriptionDto, true>(res)) {
        return false
      }

      const gotSubs = res.records.rows;

      const allOrdersCount = res.records.count;
      const shownSubs = gotSubs.slice(count.prev, count.next) || [];

      batch(() => {
        setShownSubs(shownSubs.map(order => order.id));
        setSubscriptions(gotSubs);
        setAllSubsCount(allOrdersCount);
        setCurrentSubsCount(allOrdersCount);
      })

      return res
    })
    .finally(() => {
      setLoad(false)
    })
  }

  useEffect(() => {
    getSubs({})
  }, [])


  useInitFilters({
    filters: undefined,
    searchInput,
    setSearchInput: setSearchInput,
    getEntity: getSubs,
    updateFilters: () => new Promise<undefined>((resolve, reject) => {}),
  })

  const [hasMore, showMore] = useInfiniteScrollShowMore({
    getItems: getSubs,
    currentCount: currentSubsCount,
    getSize: subsGetSize,
    allCount: allSubsCount,
  })

  const subscriptionActiveCounter = useCallback((e: MouseEvent, itemId: Id) => {
    const result = activeCounter(subscriptions, selectedSubs, prevChecked)(e, itemId)
    if(!result) {
      return;
    }

    const [newSelectedItems, isMultiSelect] = result;

    if(!isMultiSelect) {
      batch(() => {
        setSelectedSubs(newSelectedItems)
        setPrevChecked(focusedItem?.id)
      })
    } else {
      setSelectedSubs(newSelectedItems)
    }
  }
  , [subscriptions, selectedSubs, prevChecked])

  const checkHandler: (e: React.MouseEvent, el: SubscriptionDto) => void = 
    useCallback((e, el) => {
      subscriptionActiveCounter(e, el.id);

      if(!e.shiftKey) 
        focusHandler(el.id, subscriptions)
    }, [subscriptionActiveCounter, subscriptions, focusHandler])

  const clearSelected = () => setSelectedSubs([]);

  const deleteHandler = useCallback((id?: Id) => {
    setLoad(true)

    const idsToDelete = id ? [id] : selectedSubs;

    mercheryFetch<boolean>('orders/subscriptions', 'DELETE', {
      id: idsToDelete
    })
    .then((res) => {
      if (!_isMounted.current || !validateResponse(res) || !res.records) {
        return false
      }

      batch(() => {
        clearSelected()
        setSubscriptions(
          subscriptions.filter(sub => 
            !idsToDelete.some(id => id === sub.id))
        )
      })
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      setLoad(false)
    })
  }, [setLoad, subscriptions, selectedSubs, setSubscriptions])
  
  return (
    <div>
      {selectedSubs.length ?
        <SelectedSubsMenu
          deleteHandler={deleteHandler}
          clearSelected={clearSelected}
          selectedSubs={selectedSubs}
        />
      : null}

      {load ?
        <CommonTableLoader/>
      :
        <InfiniteScroll
          dataLength={subscriptions.length}
          next={showMore}
          hasMore={hasMore}
          loader={ Array(10).map(() => <OrdersLoader/>) }
          scrollableTarget={<>{window}</>}
        >
          {sortedSubs.length ?
            <MyTable id={"subs-table" } myClassName={!sortedSubs.length ? 'hide-table' : ''}>
              <SubscriptionsTableHeader
                config={config}
                requestSort={requestSort}
              />

              <TableBody>
                {sortedSubs.map((subscription) => {
                  const focused = focusedItem && focusedItem.id === subscription.id ? 'focused' : '';
                  const active = selectedSubs.some(s => s === subscription.id) ? 'active' : '';

                  return (
                    <BodyRow
                      key={subscription.id} // @ts-ignore: Unreachable code error
                      orderid={subscription.id}
                      className={`subscriptions-table__cells-template ${active} ${focused}`}
                      onFocus={() => focusHandler(subscription.id, subscriptions)}
                      tabIndex={tabIndex}
                      onMouseUp={(e) => rowClick(e, subscription.id)}
                      onMouseDown={() => setActiveRowId(subscription.id)}
                      onKeyUp={(e) => e.key === 'Enter' ? rowClick(e, subscription.id) : null}
                    >
                      <SubscriptionRowIdAndCheck handler={checkHandler} subscription={subscription} />
                      <SubscriptionProductTitle subscription={subscription} />
                      <SubscriptionAttributes subscription={subscription} />
                      <SubscriptionRemain subscription={subscription} />
                      <SubscriptionClientEmail subscription={subscription} />
                      <SubscriptionDate subscription={subscription} />
                      <SubscriptionConfig subscription={subscription} deleteHandler={deleteHandler} />
                    </BodyRow>
                  )
                })}
              </TableBody>
            </MyTable>
          : 'Подписок не найдено'}
        </InfiniteScroll>
      }
    </div>
  );
}

export default SubscriptionsTable;
