import React from 'react';
import { TabIndex } from '../../../../dto/master-dto/tabindex.dto';
import MyCheckbox from '../../../_utility-components/checkbox/my-checkbox';

export interface ProductItemCheckboxProps <T, >{
  withInlineChange?: true
  selectHandler?: (product: T) => void,
  item: T,
  disabled: boolean,
  selected: boolean,
  tabIndex: TabIndex,
}

function ProductItemCheckBox <T>(props: ProductItemCheckboxProps<T>) {
  const {
    selectHandler,
    disabled,
    tabIndex,
    item,
    selected,
  } = props;
  const actionHandler = () => selectHandler?.(item)

  return (
    <div>
      <MyCheckbox 
        tabIndex={tabIndex}
        condition={!!selected} 
        disabled={disabled}
        actionHandler={actionHandler}/>
    </div>
  );
}

export default ProductItemCheckBox;