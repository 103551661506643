import React, {useEffect, useState} from 'react';
import MyButton from "../button/button";

function PageIsOutOfDate ({
	condition,
	actionHandler
}: {
	condition: boolean,
	actionHandler: () => void
}) {
	const [show, setShow] = useState<boolean>(condition);

	if(!show) {
		return null
	}

	return (
		<div
			className={'page-is-out-of-date'}
		>
			<h4 className={'header-font-xl'}>Страница устарела</h4>

			<div className={'text-font-m'}>
				Данная страница изменена другим пользователем,
				пожалуйста, <b>обновите страницу</b>,
				чтобы получить ее актуальную версию.
			</div>

			<MyButton
				className={'page-is-out-of-date_confirm-btn white-btn header-font-m'}
				onClick={actionHandler}
			>
				Обновить страницу
			</MyButton>

			<MyButton
				removeDefaultClass={true}
				onClick={() => setShow(false)}
				className={'page-is-out-of-date_close-btn'}
			>
				<i className="icofont-close"></i>
			</MyButton>
		</div>
	);
}

export default PageIsOutOfDate;