import {ProductInputHandler} from "src/components/main-pages/products/product";
import {useAppSelector} from "src/scripts/pre-type/use-selector";
import VatValueSelect from "src/components/main-pages/products/product-page-modules/vat-value-select";
import React from "react";

export function ProductVat ({
	inputHandler,
}: {
	inputHandler: ProductInputHandler
}) {
	const settings = useAppSelector(state => state.settings)
	const siteTaxForm = settings.find(setting => setting.callname === 'tax_form')?.value as number | undefined;

	if (siteTaxForm !== 1) {
		return null
	}

	return <div className={`inputs-row tax-form`}>
		<h4 className="header-font-s">Налог</h4>
		<VatValueSelect inputHandler={inputHandler}/>
	</div>
}