import MyButton from "../../_utility-components/button/button";
import MenuIcon from "../../../img/context-menu-dots.svg";
import {Link, useLocation} from "react-router-dom";
import React, {useContext, useState} from "react";
import {usePopup} from "src/scripts/hooks/use-popup";
import {Category, Id } from "merchery-lib";
import {batch, useDispatch} from "react-redux";
import ConfirmPopup from "../../_utility-components/confirm-popup";
import MyCheckbox from "../../_utility-components/checkbox";
import {mercheryFetch} from "src/scripts/fetchConstructor";
import {validateResponse} from "src/scripts/functions";
import {useAppSelector} from "src/scripts/pre-type/use-selector";
import useMounted from "../../../scripts/hooks/use-mounted";
import {CategoriesContext} from "./categories";

export function CategoryItemMenu ({
	category,
	closePopupParent,
	addCategory,
	nameRef,
	filtersMod,
	disabledConfig,
}: {
	closePopupParent?: () => void,
	category: Category,
	addCategory?: (top: Id) => void,
	nameRef:  React.MutableRefObject<HTMLInputElement | null>
	filtersMod?: boolean,
	disabledConfig?: boolean,
}) {
	const _isMounted = useMounted();
	const categories = useAppSelector(state => state.categories)
	const [deleteProductsWithCategory, setDeleteProductsWithCategory] = useState(false);
	const [popupOpened, setPopupOpened] = useState(false);

	const location = useLocation();
	const categoriesPage = location.pathname.includes('categories')

	const {
		RenderButton,
		RenderPopup,
		closePopup,
	} = usePopup();

	const {
		setSelectedCategory
	} = useContext(CategoriesContext);

	const dispatch = useDispatch();
	const categoriesDispatch = (categories: Category[]) =>
		dispatch({type: 'CATEGORIES', payload: categories});

	const setInlineNameEditing = (id: Id | null) =>
		dispatch({ type: 'INLINE_CHANGING_CATEGORY', payload: id});

	const deleteHandler = () => setPopupOpened(true);

	const deleteCategory = async () => {
		if(!categories) {
			return false
		}

		const res = await mercheryFetch<number[]>('category', 'DELETE', {
			filters: {
				id: category.id
			},
			parameters: {
				deleteProducts: deleteProductsWithCategory
			}
		})

		if(!_isMounted.current || !validateResponse(res)) {
			return;
		}

		batch(() => {
			const updatedCategories = categories.filter(c =>
				!res.records.some(deletedId => deletedId === c.id)
			)

			categoriesDispatch(updatedCategories)
			closePopup()
			setSelectedCategory(undefined)
		})
	}

	if(!addCategory || filtersMod || category.newCategory || disabledConfig) {
		return null
	}

  return <>
    <RenderButton
      className="category-item__context-menu-btn"
    >
      <img src={String(MenuIcon)} alt={"context-menu"}/>
    </RenderButton>

		<RenderPopup
			className={"category-menu"}
			popupName={".category-menu"}
			changingDirection
			tabIndexDeep={2}
			{...(closePopupParent && {
			 popupParent: ".category-tree-popup",
			 popupParentClose: closePopupParent
			})}
		>
			<div className="popup-group">
				<MyButton
					className={"popup-element header-font-s"}
					onClick={() => {
						closePopup()
						addCategory(category.id)
					}}
				>
					<i className="icofont-plus"></i>
					Добавить подкатегорию
				</MyButton>

				<Link
					to={`/app/categories/${category.id}`}
					className={"popup-element header-font-s"}
					onClick={() => {
						closePopup();
						closePopupParent && closePopupParent()
						setSelectedCategory(category)
					}}
				>
					<i className="icofont-settings-alt"></i>
					Настроить категорию
				</Link>

				{categoriesPage ? null :
					<MyButton
						className={"popup-element header-font-s"}
						removeDefaultClass
						onClick={() => {
							setInlineNameEditing(category.id);
							closePopup();
							setTimeout(() => {
								nameRef.current?.focus()
							}, .04)
						}}
					>
						<i className="icofont-ui-edit"></i>
						Переименовать
					</MyButton>
				}
			</div>

			<div className="popup-group">
				<MyButton
					removeDefaultClass
					className={"popup-element header-font-s red-color"}
					onClick={deleteHandler}
				>
					<i className="icofont-trash"></i>
					Удалить
				</MyButton>
			</div>
		</RenderPopup>

		{popupOpened ?
			<ConfirmPopup
				confirmHandler={deleteCategory}
				deletion={true}
				popupClose={() => setPopupOpened(false)}
				popupParent={".category-menu"}
				tabIndexDeep={3}
				children={<>
					<div className='confirm-popup__paragraph header-font-s'>
						Удаление категории
					</div>

					<div className='confirm-popup__paragraph'>
						Категория {category.name} будет удалена
					</div>

					<MyCheckbox
						className={'text-font-xs'}
						condition={deleteProductsWithCategory}
						actionHandler={() => setDeleteProductsWithCategory(!deleteProductsWithCategory)}
						text={'Удалить товары категории?'}
					/>

					<div className='confirm-popup__paragraph'>
						Вы уверены, что хотите продолжить?
					</div>
				</>}
			/>
		: null}
  </>;
}