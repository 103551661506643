import React from 'react';
import { SetDto } from "merchery-lib";
import { num2str } from 'src/scripts/functions';
import MyButton from '../../../../_utility-components/button/button';

export interface SetItemShowProductsProps {
  products_ids?: SetDto['products_ids'],
  showedProducts: boolean,
  showHandler: () => void,
  tabindex?: number,
  disabled: boolean,
  noButton?: boolean
}

function SetItemShowProducts(props: SetItemShowProductsProps) {
  const { products_ids, showedProducts, showHandler, disabled, tabindex, noButton } = props;
  const text = num2str(products_ids?.length || 0, ["товар", 'товара', 'товаров']);

  if(noButton) {
    return (
      <div>
        <div className='show-products__btn'>
          {text} 
        </div>
      </div>
    )
  }

  return (
    <div>
      <MyButton
        tabIndex={tabindex}
        disabled={disabled}
        className={`show-products__btn clickable ${showedProducts ? '' : 'show-products__btn--shown'}`}
        onClick={showHandler}
      >
        {text}
        <i className="icofont-simple-down"></i>
      </MyButton>
    </div>
  );
}

export default SetItemShowProducts;