import React, { useContext } from 'react';
import { priceWithPennies, ptsansCurrency } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import CurrencyAndPrice from '../../../../_utility-components/currency-and-price/currency-and-price';
import { ExtendedClientDto, Order } from 'merchery-lib';
import { OrderContext } from '../../order-page';

function OrderTotalPrice() {
  const {
    order
  } = useContext(OrderContext);

  const { RenderButton, RenderPopup, closePopup } = usePopup();

  if(!order) {
    return null
  }

  const client = order.client
  const discount = order.percentage_discount ? order.discount : order.discount ? order.discount / 100 : 0;
  const total = order.total ? priceWithPennies(order.total) : 0;
  const price = order.price ? priceWithPennies(order.price) : 0;

  const curAndPrice = <CurrencyAndPrice cur='RUB' balance={total}/>

  return (
    <div className="order-total-price">
      <RenderButton
        className="order-total-price-container header-font-s" 
        onBlur={closePopup}
      >
        <div>
          К оплате: 
          <div>
            {curAndPrice}
          </div>
        </div>
      </RenderButton>

      <RenderPopup
        className={'order-total-price-popup'}>
        <div>
          {price !== total ? <>
            <TotalIndicator 
              price={price}/>

            <Separator/>
          </> : null}
          
          <ClientDiscountIndicator client={client}/>
          
          {client?.discount && (order.promo_code || discount) ? 
            <Separator/> 
          : null}

          {discount ? 
            <OrderDiscountIndicator 
              order={order}/> 
          : null}
          
          {discount && order.promo_code ? 
            <Separator/> 
          : null}

          {order.promo_code ? 
            <CouponIndicator 
              order={order}/> 
          : null}

          {price !== total ? 
            <Equel/> 
          : null}

          <div>
            <div className="title-div">итого</div>
            <div className="text-div">{curAndPrice}</div>
          </div>
        </div>
      </RenderPopup>
    </div>
  );
}

export default OrderTotalPrice;

function Equel() {
  return <div className="sub-text">=</div>;
}

function CouponIndicator({order}: {order: Order}) {
  const promocodes = useAppSelector(state => state.promocodes);
  const currentPromoCode = promocodes.find(p => p.id === order.promo_code);

  if(!currentPromoCode) {
    return null
  }

  return <div>
    <div className="title-div">купон</div>
    <div className="text-div">
      {currentPromoCode.price} {currentPromoCode.isPercent ? '%' : ''}
    </div>
  </div>;
}

function OrderDiscountIndicator({order}: {order: Order}) {
  return <div>
    <div className="title-div">Скидка</div>
    <div className="text-div">
      {order.percentage_discount ? order.discount : priceWithPennies(order.discount)} {order.percentage_discount ? '%' : ptsansCurrency('₽')}
    </div>
  </div>;
}

function ClientDiscountIndicator({client}: {client: ExtendedClientDto | null}) {
  if(!client || !client.discount) {
    return null
  }
  return <div>
    <div className="title-div">Скидка клиента</div>
    <div className="text-div">{client.discount} %</div>
  </div>;
}

function Separator() {
  return <div className="sub-text">–</div>;
}

function TotalIndicator({price}: {price: number | string}) {
  return <div>
    <div className="title-div">Товары</div>
    <div className="text-div">
      <CurrencyAndPrice cur='RUB' balance={price}/>
    </div>
  </div>;
}
