import React, {ReactNode} from "react";

export function HeaderRow ({
	children,
	className,
}: {
	children: ReactNode | ReactNode[],
	className?: string,
}) {
	return (
		<div className={`table__header ${className || ''}`}>
			{children}
		</div>
	)
}