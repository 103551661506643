import React, {useContext} from 'react';
import {priceWithPennies, ptsansCurrency} from '../../../../scripts/functions';
import MyButton from '../../../_utility-components/button/button';
import {OrderPromocodeContext} from "src/components/main-pages/orders/order-modules/promo";

export function PromocodeStatus() {
  const {
    createCodeHandler,
    currentPromocode,
  } = useContext(OrderPromocodeContext);

  const value = currentPromocode.isPercent
    ? currentPromocode.price
    : priceWithPennies(currentPromocode.price);

  return (
    <>
      <div className={value ? '' : 'no-code'}>
        {value ?
          <>
            Номинал: {value}
            &nbsp;
            {currentPromocode.isPercent
              ? '%'
              : ptsansCurrency('₽')
            }
          </>
          : 'Код не существует'}
      </div>

      <MyButton
        removeDefaultClass
        className="promo-create-btn"
        onClick={createCodeHandler}
      >
        {value
          ? 'Очистить'
          : 'Создать код'
        }
      </MyButton>
    </>
  );
}
