import React from 'react';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import ClientOrder from './client-order';
import { ExtendedClientDto } from 'merchery-lib';

interface Props {
  client: ExtendedClientDto
}

function ClientHistory({
  client
}: Props) {
  const orders = useAppSelector(state => state.orders);
  
  return (
    <div className='client-order-history'>
      <h3 className='header-font-xl'>История заказов</h3>

      <div className='client-orders__wrapper'>
        {orders.length ? 
          orders
          .filter(order => order.client_id === client.id)
          .map((order, index) => (
            <ClientOrder
              key={order.id}
              index={index}
              order={order}
            />
          ))
        : <div>
          Заказы отсутствуют
        </div>}
      </div>
    </div>
  );
}

export default ClientHistory;