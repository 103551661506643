import {useContext, useEffect, useState} from "react";
import {batch, useDispatch} from "react-redux";
import { useAppSelector } from "src/scripts/pre-type/use-selector";
import { WebSocketContext } from "src/scripts/web.socket";
import { updateDispatch } from "./dispatches";
import {SetsContext} from "../components/main-pages/sets/sets";
import { 
  CollectionChangeWs,
	CreateSetsWsDto,
	DeleteSetsWsDto,
	ReorderSetsWsDto,
	UpdateSetsWsDto,
  WsResponse,
  SetDto,
} from "merchery-lib";
import { 
  createSets, 
  deleteSets, 
  reorderSets, 
  updateSets
} from "src/constants/ws-events";

export default function useSetsWsListener () {
	const {
		lastMessage,
	} = useContext(WebSocketContext);
	const {
		newSets,
		setNewSets
	} = useContext(SetsContext);

  const sets = useAppSelector(state => state.sets);
	const dispatch = useDispatch();
  const setsDispatchType = 'SETS'
	const [changesInQueue, setChangesInQueue] = useState<(CreateSetsWsDto | DeleteSetsWsDto | ReorderSetsWsDto)[]>([]);

	const reorderSetsDispatch = (sets: SetDto[]) =>
		dispatch({ type: setsDispatchType, payload: sets })
	const updateItemsDispatch = sets && updateDispatch(sets, dispatch, setsDispatchType);

	const applyRenderChanges = (changes?: typeof changesInQueue) => {
		if(!sets) {
			return false
		}

		let reorderedSets: SetDto[] = [...sets];
		const newSetsShallowCopy = new Set(newSets)

		if(!changes?.length) {
			changes = changesInQueue
		}

		changes.forEach((data) => {
			if('set' in data) {
				reorderedSets.push(data.set)
				newSetsShallowCopy.add(data.set.id)
			} else if('changes' in data) {
				data.changes.forEach((setReorderWs) => {
					const setIndex = reorderedSets.findIndex(set => set.id === setReorderWs.id);

					if (setIndex !== -1) {
						reorderedSets[setIndex] = {
							...reorderedSets[setIndex],
							...setReorderWs
						}
					}
				})
			} else {
				reorderedSets = reorderedSets.filter(
					set => Array.isArray(data.id)
						? !data.id.some(id => set.id === id)
						: set.id !== data.id
				)
			}
		})

		batch(() => {
			setNewSets(newSetsShallowCopy)
			reorderSetsDispatch(reorderedSets)
			setChangesInQueue([]);
		})
	}

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<ReorderSetsWsDto>;

			if (message.event === reorderSets &&
				message.data.changes &&
				message.data.initiatorId
			) {
				const currentAdminId = localStorage.getItem('admin')
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<UpdateSetsWsDto>;
			const changes = message.data.changes;

			if (message.event === updateSets &&
				message.data.changes
			) {
				updateItemsDispatch &&
					updateItemsDispatch<CollectionChangeWs>(changes)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<DeleteSetsWsDto>;
			const currentAdminId = localStorage.getItem('admin')

			if (message.event === deleteSets &&
				message.data.id &&
				message.data.initiatorId
			) {
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<CreateSetsWsDto>;
			const currentAdminId = localStorage.getItem('admin')

			if (message.event === createSets &&
				message.data.set &&
				message.data.initiatorId
			) {
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	return [changesInQueue, applyRenderChanges] as const;
}