import React, { useEffect, useState } from 'react';
import Sidebar from '../navbar/navbar';
import { usePopup } from '../../scripts/hooks/use-popup';
import ReactDOM from 'react-dom';

export function HeaderSidebarPopup() {
  const tabIndexLevel = 3
  const { RenderButton, RenderPopup, closePopup, openPopup } = usePopup();
  const [headerInner, setHeaderInner] = useState<Element | null>(null);

  useEffect(() => {
    const header = document.querySelector('.header-sidebar-popup-wrapper') as Element | null;
    if (header) {
      setHeaderInner(header);
    }
  }, []);

  if(!headerInner) {
    return null
  }

  return (
    <>
      {ReactDOM.createPortal(
        <RenderButton
          onClick={openPopup}
          removeDefaultClass 
          className="nav-menu-btn"
        >
          <i className="icofont-navigation-menu"></i>
        </RenderButton>
      , headerInner)}

      <RenderPopup
        className={'sidebar-popup'}
        tabIndexDeep={tabIndexLevel}
      >
        <Sidebar
          popupClose={closePopup}
          tabIndexLevel={tabIndexLevel}
        />
      </RenderPopup>
    </>
  ) 
}
