import React from 'react';
import { num2str } from '../../../../../../scripts/functions';
import MyButton from '../../../../../_utility-components/button/button';
import { ExtendedProduct } from 'merchery-lib';

function VariantsShowMoreBtn({
  product,
  showVariants,
  showVariantsHandler,
}: {
  product: ExtendedProduct,
  showVariants: boolean,
  showVariantsHandler: () => void
}) {
  const formattedVariantWord = num2str(product.variants.length, ['вариант', 'варианта', 'вариантов'])
  const btnTitle = showVariants ? 'Скрыть варианты товара' : 'Показать варианты товара'
  return (
    <div className="op-show-more">
      <MyButton
        className={`icon-wrapper ${showVariants ? '' : 'hided-subcategories'}`}
        onClick={showVariantsHandler}
        title={btnTitle}
      >
        {formattedVariantWord} товара<i className="icofont-simple-down"></i>
      </MyButton>
    </div>
  );
}

export default VariantsShowMoreBtn;