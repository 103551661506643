import React from 'react';

function PaymentHeader () {
	return (
		<div className='order-page__payment__header'>

			<h3 className='header-font-m'>Оплата</h3>

			{/* <div className={`header-font-m order-page__payment-sum ${successStatus ? 'success' : ''}`}>
          {paidSum} {ptsansCurrency('₽')}
        </div> */}
		</div>
	);
}

export default PaymentHeader;