import { ExtendedProductStories } from 'merchery-lib';
import qs from 'qs';
import React, { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { validateResponse } from 'src/scripts/functions';
import useMounted from 'src/scripts/hooks/use-mounted';
import useRouteId from 'src/scripts/hooks/use-route-id';
import ProductStoriesBody from './stories-modules/stories-body';
import ProductStoriesInputs from './stories-modules/stories-inputs';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';

function ProductStories() {
  const _isMounted = useMounted()
  const productid = useRouteId('productid')
  const stories = useAppSelector(state => state.productStories)

  const dispatch = useDispatch()
  const storiesDispatch = (stories: ExtendedProductStories) => dispatch({
    type: 'PRODUCT_STORIES',
    payload: stories
  })
  const initStoriesDispatch = (stories: ExtendedProductStories) => dispatch({
    type: 'PRODUCT_INIT_STORIES',
    payload: stories
  })

  useEffect(() => {
    getStories()

    return () => {
      batch(() => {
        storiesDispatch(null)
        initStoriesDispatch(null)
      })
    }
  }, [])

  async function getStories () {
    if(!productid) {
      return false
    }
    const params = qs.stringify({
      product_id: productid,
    }, {arrayFormat: 'comma'})

    const res = await mercheryFetch<ExtendedProductStories>(`products-stories/get-one?${params}`, 'GET', {})

    if(!_isMounted.current || !validateResponse(res)) 
      return false

    const stories = res.records

    batch(() => {
      storiesDispatch(stories)
      initStoriesDispatch(stories)
    })

    return res
  }

  if(!productid) {
    return null
  }

  return (
    <div id='stories' className='product-page-element product-page__stories'>
      <div className='product-page__stories-header'>
        <h3 className='product-page-h3'>Серия Stories</h3>

        <ProductStoriesInputs storiesDispatch={storiesDispatch}/>
      </div>

      <ProductStoriesBody/>
    </div>
  );
}

export default ProductStories;