import React from 'react';
import MyInput from '../../../_utility-components/input/index';

export interface ProductItemCountRequirment {
  count?: number | undefined,
  remain?: number | null,
  disabled: boolean,
}

export type ProductItemCountProps = ProductItemCountRequirment & {
  counter?: (num: number) => void,
  customArrow?: true,
  hideUnit?: true,
  width?: number
  min?: number
}

function ProductItemCount(props: ProductItemCountProps) {
  const {
    count,
    counter,
    disabled,
    customArrow,
    hideUnit,
    width,
    min
  } = props;

  const handler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = +event.target.value

    counter?.(value)
  }

  return (
    <div className='row-item__count'>
      {counter && count !== undefined ? 
        <MyInput
          disabled={disabled}
          myClassName='add-items-count'
          type={'number'}
          required={false}
          style={{
            width: `${width || 52}px`
          }}
          value={count}
          {...(min !== undefined && {
            min
          })}
          // min={0}
          // {...(preventMax && {
          //   max: remain
          // })}
          customArrow={customArrow}
          {...(customArrow && {onCount: counter})}
          onChange={handler}
        /> 
      : count}

      {!hideUnit ? 
        <div className='row-item__count-unit'>шт </div>
      : null}
    </div>
  );
}

export default ProductItemCount;