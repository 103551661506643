import React, { useContext, useMemo } from 'react';
import SelectInput from '../../../../_utility-components/select-input/select-input';
import { SettingsContext } from '../../settings-context-wrapper';

function TimezoneSelect() {
  const {
    timezoneOffset,
    setTimezoneOffset,
  } = useContext(SettingsContext)
  
  const selectedOffset = useMemo(() => 
    selectOptionsSortedByOffest.find(option => option.offset === timezoneOffset)
  , [timezoneOffset])

  return (
    <SelectInput 
      items={selectOptionsSortedByOffest}
      selectedItem={selectedOffset} 
      idLabelName={'offset'}
      selectHandler={option => setTimezoneOffset(option.offset)}
      inputProps={{
        children: (
          <div className="header-font-m" style={{ marginBottom: '8px' }}>
            Таймзона
          </div>
        ),
      }}
    />
  );
}

export default TimezoneSelect;

let russianTimeZones = [
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr'
] as const;

let russianTimeZoneNames = {
  'Europe/Kaliningrad': 'Калининград',
  'Europe/Moscow': 'Москва',
  'Europe/Simferopol': 'Симферополь',
  'Europe/Kirov': 'Киров',
  'Europe/Astrakhan': 'Астрахань',
  'Europe/Volgograd': 'Волгоград',
  'Europe/Saratov': 'Саратов',
  'Europe/Ulyanovsk': 'Ульяновск',
  'Europe/Samara': 'Самара',
  'Asia/Yekaterinburg': 'Екатеринбург',
  'Asia/Omsk': 'Омск',
  'Asia/Novosibirsk': 'Новосибирск',
  'Asia/Barnaul': 'Барнаул',
  'Asia/Tomsk': 'Томск',
  'Asia/Novokuznetsk': 'Новокузнецк',
  'Asia/Krasnoyarsk': 'Красноярск',
  'Asia/Irkutsk': 'Иркутск',
  'Asia/Chita': 'Чита',
  'Asia/Yakutsk': 'Якутск',
  'Asia/Khandyga': 'Хандыга',
  'Asia/Vladivostok': 'Владивосток',
  'Asia/Ust-Nera': 'Усть-Нера',
  'Asia/Magadan': 'Магадан',
  'Asia/Sakhalin': 'Сахалин', 
  'Asia/Srednekolymsk': 'Среднеколымск', 
  'Asia/Kamchatka': 'Камчатка', 
  'Asia/Anadyr': 'Анадырь' 
}

function getOffset(timeZone: string, date = new Date()) {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  const offset = (tzDate.getTime() - utcDate.getTime()) / (1000 * 60 * 60);
  return offset;
}

let selectOptions = russianTimeZones.reduce((options, zone) => {
  const offset = getOffset(zone);
  const localizedName = russianTimeZoneNames[zone];
  
  const existingOption = options.find(option => option.offset === offset);
  if (existingOption) {
    existingOption.text += `, ${localizedName}`;
  } else {
    options.push({
      offset: offset,
      text: `(GMT${offset >= 0 ? '+' : ''}${offset}) ${localizedName}`
    });
  }
  
  return options;
}, [] as {offset: number, text: string}[]);

let selectOptionsSortedByOffest = selectOptions.sort((a, b) => a.offset - b.offset)