import {useEffect, useMemo, useRef} from 'react';
import {CommonFilters, FilterChanger, FilterItem} from "../utils/entity-filters";
import qs from "qs";
// @ts-ignore
import queryString from "query-string";
import { Id } from "merchery-lib";
import {useHistory, useLocation} from "react-router-dom";
import {PaginationArgs} from "./use-infinite-scroll-show-more";
import { batch } from 'react-redux';
import { SettersFor } from '../utils/setter-for';
import { capitalizeFirstChar } from '../utils/capitalize-first-char';

export type FiltersArgs = {
	filters?: { [p: string]: (string | number | null)[] | undefined },
	search?: string
};

export type ParsedURLFilters<T> = {
  search?: string | string[];
  nulls?: keyof CommonFilters<T> | (keyof CommonFilters<T>)[];
} & { 
  [p in keyof CommonFilters<T> ]: (Id | null)[]
}

export type EntityGetter<T = {}> = (args: FiltersArgs & T) => void;

export function entityGetterQuery<T> (params: FiltersArgs & PaginationArgs) {
	const nullFilters: (keyof CommonFilters<T>)[] = [];

	const {
		filters, search,
		page, pageSize,
    ...restParams
	} = params;

	const noNullFilters = filters && 
    Object.entries(filters)
    .reduce((acc, [key, values]) => {
      if (Array.isArray(values)) {
        const withNull = values.some(value => value === null);
        if (withNull) {
          nullFilters.push(key as keyof CommonFilters<T>);
        }
        acc[key] = values.filter(value => value !== null);
      } else {
        acc[key] = values;
      }
      return acc;
    }, {} as { [p: string]: (string | number | null)[] | undefined });


	const queryFilters = qs.stringify({
    ...restParams,
		filters: {
			...noNullFilters,
			complete: true,
		},
		...(search && {search}),
		...(nullFilters.length > 0 && {nullFilters}),
		pagination: {
			size: pageSize,
			page: page,
		},
	}, {arrayFormat: 'comma'});

	return queryFilters
}

function useInitFilters<T, Y extends {}> ({ 
  filters,
  searchInput,
  setSearchInput,
  getEntity,
  updateFilters,
  additionalParams,
  additionalParamsSetters,
}: { 
  filters: CommonFilters<T> | undefined; 
  searchInput: string; 
  setSearchInput: (str: string) => void; 
  getEntity: EntityGetter; 
  updateFilters: FilterChanger<T>;
  additionalParams?: Y
  additionalParamsSetters?: SettersFor<Y>
}) {
	const notFirstRender = useRef(false);

	const history = useHistory();
	const location = useLocation();

	const plainedFilters = useMemo(() => {
		try {
			if (!filters) {
				return undefined
			}

			const filtersEntries: [key: string, value: FilterItem[] | undefined][] = Object.entries(filters)

			return Object.fromEntries(
				filtersEntries
					.filter(([, value]) =>
						value && value.filter(v => v.selected).length
					)
					.map(([key, value]) =>
						[key, value ? value.flatMap(item => item.selected ? item.id : []) : value]
					)
			)
		} catch (error) {
			return undefined
		}
	}, [filters]);

	useEffect(() => {
		const locationSearch = location.search.replace('?', '')
		const parsed = queryString.parse(locationSearch, {arrayFormat: 'comma', parseNumbers: true}) as ParsedURLFilters<T>;
		const search = parsed['search'] as string | undefined;

    if(additionalParamsSetters && additionalParams) {
      for (const key in parsed) {
        const setterName = `set${capitalizeFirstChar(key)}`;
        if (setterName in additionalParamsSetters &&
          key in additionalParams &&
          key in parsed
        ) {
          const setter = additionalParamsSetters[setterName as keyof typeof additionalParamsSetters] as (value: unknown) => void
          const value = parsed[key as keyof typeof parsed];
          
          setter(value);
        }
      }
    }

		let nulls = parsed['nulls']

		if(nulls) {
			delete parsed['nulls']
			nulls = Array.isArray(nulls) ? nulls : [nulls];

			nulls.forEach((filterName) => {
				// @ts-ignore
				parsed[filterName] = parsed[filterName]
					? Array.isArray(parsed[filterName])
						? [...parsed[filterName], null]
						: [parsed[filterName], null]
					: [null]
			})
		}

		if(parsed['search']) {
			delete parsed['search']
		}

		const filtersFromUrl = Object.entries(parsed)
			.map(([key, value]) => ({
				itemIds: (Array.isArray(value) ? value : [value]) as (Id | null)[],
				filterName: key as keyof CommonFilters<T>,
				cleanPrev: true,
				concreteSelected: true
			}));

		batch(() => {
			if(search) {
				setSearchInput(search)
			}

			if(filtersFromUrl.length) {
				updateFilters(...filtersFromUrl)
			} else {
				updateFilters({
					itemIds: undefined,
					filterName: undefined,
					concreteSelected: false,
					cleanPrev: true,
					cleanOthers: true
				})
			}
		})
	}, []);

	useEffect(() => {
		// if (plainedFilters || searchInput) {
			if (notFirstRender.current) {
				getEntity({
					filters: plainedFilters,
					...(searchInput && {search: searchInput}),
          ...(additionalParams && additionalParams)
				})
			}

			notFirstRender.current = true;
      const nullFilters: (keyof CommonFilters<T>)[] = [];

      const noNullFilters = plainedFilters && 
        Object.entries(plainedFilters)
        .reduce((acc, [key, values]) => {
          if (Array.isArray(values)) {
            const withNull = values.some(value => value === null);
            if (withNull) {
              nullFilters.push(key as keyof CommonFilters<T>);
            }
            acc[key] = values.filter(value => value !== null);
          } else {
            acc[key] = values;
          }
          return acc;
        }, {} as { [p: string]: (string | number | null)[] | undefined });
    
			const paramsWithoutPaginationOptions = qs.stringify({
				...noNullFilters,
        ...(nullFilters && {nulls: nullFilters}),
				...(searchInput && {search: searchInput}),
        ...(additionalParams && additionalParams),
			}, {arrayFormat: 'comma'});

			history.replace({
				pathname: location.pathname,
				search: '?' + paramsWithoutPaginationOptions
			})
		// }
	}, [plainedFilters, searchInput, additionalParams])

}

export default useInitFilters;