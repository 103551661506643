import React from "react";
import { withErrorBoundary } from "react-error-boundary";
import { Id } from "react-toastify";
import { useAppSelector } from "src/scripts/pre-type/use-selector";
import { useTabIndex } from "src/scripts/hooks/use-tabindex";
import FallbackComponent from "../../../_utility-components/error-boundary";
import { ExtendedProduct } from "merchery-lib"
import { ProductRow } from "../../products/products-items/product-items";
import { productLikeFields } from "../../products/products-items/product-items-utils";

function ProductToBeAddedInSet ({ 
  product,
  canBeAddedSelected,
  setCanBeAddedSelected,
}: {
  product: ExtendedProduct,
  canBeAddedSelected: ExtendedProduct[],
  setCanBeAddedSelected: (items: ExtendedProduct[]) => void,
}) {
  const showRemain = useAppSelector(state => state.settings.find(setting => setting.callname === 'remainder'))?.value
  const items = useAppSelector(state => state.productItemsInContext);
  const tabIndex = useTabIndex(2);

  const productPredicate: <T extends {id: Id},>(
    value: T,
    index: number,
    obj: T[]
  ) => unknown =
    arrayItem =>
      arrayItem.id === product.id;

  const addSelected = (productFromHandlerRow: ExtendedProduct) => {
    const productToBeAdded = productFromHandlerRow || product;
    const selected = canBeAddedSelected.some(item => item.id === productToBeAdded.id)

    if(selected) {
      removeSelected()
      return false
    } 
    
    setCanBeAddedSelected([
      ...canBeAddedSelected,
      productFromHandlerRow
    ])
  }

  const removeSelected = () => {
    setCanBeAddedSelected(
      canBeAddedSelected.filter(item => item.id !== product.id)
    )
  }

  const canBeAddedSelectedProduct = canBeAddedSelected?.find(productPredicate)

  const externalIdForLabels = !canBeAddedSelectedProduct
    ? product.external_id
    : canBeAddedSelectedProduct.external_id;

  const remainForLabels = !canBeAddedSelectedProduct
    ? product.remain
    : canBeAddedSelectedProduct.remain;

  const alreadyAdded = items.some(productPredicate)
  const disabled = !showRemain || alreadyAdded;

  const shownProductFields = [
    'checkbox',
    'src',
    'name',
    {
      key: 'external_id',
      options: {
        remain: remainForLabels,
        external_id: externalIdForLabels
      }
    },
    'price'
  ] as const;

  return (
    <ProductRow
      item={product}
      shownFields={shownProductFields} 
      className={`row-item collections__add-items-rows--template`}
      changer={undefined}
      selectHandler={addSelected}
      disabled={disabled}
      tabIndex={tabIndex}
      componentsFields={productLikeFields}
      selected={canBeAddedSelectedProduct !== undefined || alreadyAdded}
    />
  )
}

export default withErrorBoundary(ProductToBeAddedInSet, {FallbackComponent: FallbackComponent})