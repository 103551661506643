// Define the layer on which the component can use the tab key.
// This is typically used to disable the tab key for the page when a popup is shown.

import { useMemo } from 'react';
import { useAppSelector } from '../pre-type/use-selector';

export function useTabIndex(level: number = 1) {
  const tabIndexLevel = useAppSelector(state => state.tabIndexLevel);
  const load = useAppSelector(state => state.load);

  const tabIndex = useMemo(() => {
    return tabIndexLevel === level && !load ? 0 : -1;
  }, [tabIndexLevel, level, load]);

  return tabIndex;
}
