import React, { MouseEvent } from 'react';
import MyButton from '../../../../_utility-components/button/button';
import { SubscriptionDto } from 'merchery-lib';
import {BodyCell} from "../../../../_utility-components/common-table/body-cell";

interface Props {
  handler: (e: MouseEvent, el: SubscriptionDto) => void, 
  subscription: SubscriptionDto
}

export function SubscriptionRowIdAndCheck({
  subscription,
  handler
}: Props) {
  return <BodyCell myClassName="tt-id">
    <MyButton
      removeDefaultClass
      onClick={(e: MouseEvent) => {
        if (e.shiftKey) e.preventDefault();
        handler(e, subscription);
      }}
      className='tt-select-outside'
    >
      <div className="checkbox"><div></div></div>
    </MyButton>
    
    <div className={`status-and-id__wrapper`}>
      {subscription.id}
    </div>
  </BodyCell>;
}
