import React, { useContext } from 'react';
import { batch, useDispatch } from 'react-redux';
import { mercheryFetch } from '../../../../../scripts/fetchConstructor';
import { calcPrice, EntityWithPrice, num2str, numWithSep, ptsansCurrency, validateResponse } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import useMounted from '../../../../../scripts/hooks/use-mounted';
import { useTabIndex } from '../../../../../scripts/hooks/use-tabindex';
import MyButton from '../../../../_utility-components/button/button';
import { RefundStartContext } from './refund-start';
import { Order, RefundDto } from 'merchery-lib';

function isCalcPriceItem<T>(item: T): item is T & EntityWithPrice {
  return typeof item === 'object' && item !== null && 'price' in item && 'count' in item;
}

export function OrderItemsToRefundFooter({
  closePopup,
  order,
}: {
  closePopup: () => void,
  order: Order
}) {
  const _isMounted = useMounted()
  const tabIndex = useTabIndex(3) 
  const { 
    selectedItems, 
    setSelectedItems,
    refundCreateData,
    setRefundCreateData,
  } = useContext(RefundStartContext)
  const refunds = useAppSelector(state => state.refunds)

  const dispatch = useDispatch()
  const newRefundDispatch = (refund: RefundDto) => dispatch({ type: 'ORDERS_REFUNDS', payload: [...(refunds || []), refund] })

  const cancelHandler = () => {
    setSelectedItems([])
    closePopup()
  }

  const confirmHandler = async () => {
    await addItemsToRefund()
    .then(res => {
      if(res === true) {
        closePopup();
      }
    }) 
  }

  const addItemsToRefund = async (): Promise<boolean> => { 
    const preparedForUploadItems = selectedItems.map((item) => ({
      id: item.id,
      count: item.refundCount
    }))

    const res = await mercheryFetch<RefundDto>(`orders/refund/create`, "POST", {
      toCreate: {
        order_id: order.id,
        refund_total: selectedItems.reduce((total, item) => total + item.refundTotal, 0),
        client_id: order.client_id,
        returned_items: preparedForUploadItems,
        ...refundCreateData,
      }
    })

    if(!_isMounted.current || !validateResponse(res))  {
      return false
    }

    batch(() => {
      setRefundCreateData({})
      setSelectedItems([])
      newRefundDispatch(res.records)
    })

    return true
  };

  const summ = selectedItems.every(isCalcPriceItem) ? ' на сумму ' + numWithSep(calcPrice(selectedItems.map(item => ({price: item.price, count: item.refundCount})))) : '';

  const selectedItemsText = 
    selectedItems.length ? 
      num2str(
        selectedItems.length, 
        ['товар', 'товара', 'товаров'], 
        ['Выбран', 'Выбрано', 'Выбрано']
      ) + summ
    : 'Выберите товар для возврата';

  return <div className="refund-registration__footer refund-registration__popup-element">

    <div className="text-div">
      {selectedItemsText}
      &nbsp;
      {summ && selectedItems.length ? ptsansCurrency('₽') : null}
    </div>

    <div className="items-in-order-btns">
      <MyButton
        tabIndex={tabIndex}
        className="white-btn header-font-m"
        onClick={cancelHandler}
      >
        Отменить
      </MyButton>

      <MyButton
        tabIndex={tabIndex}
        className="blue-btn header-font-m"
        onClick={confirmHandler}
        disabled={!selectedItems.length}
      >
        Добавить
      </MyButton>
    </div>
  </div>;
}
