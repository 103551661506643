import React, { useState, useCallback } from 'react';
import MyButton from '../../components/_utility-components/button/button';
import Popup, { PopupProps } from '../../components/_utility-components/popup';

type UsePopupReturnType = {
  isOpen: boolean;
  openPopup: () => void;
  closePopup: () => void;
  RenderPopup: (popupProps: Omit<PopupProps, 'popupClose'>) => JSX.Element | null;
  RenderButton: (buttonProps: React.ComponentProps<typeof MyButton>) => JSX.Element;
};

export function usePopup(initialState: boolean = false): UsePopupReturnType {
  const [isOpen, setIsOpen] = useState(initialState);

  const openPopup = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closePopup = useCallback(() => {
    setIsOpen(false);
  }, []);

  const RenderPopup = useCallback(
    (popupProps: Omit<PopupProps, 'popupClose'>) => {
      if (!isOpen) return null;

      return <Popup 
        {...popupProps} 
        popupClose={closePopup} 
      />;
    },
    [isOpen, closePopup]
  );

  const RenderButton = useCallback(
    (buttonProps: React.ComponentProps<typeof MyButton>) => {
      const handler = !isOpen ? openPopup : closePopup
      return <MyButton
        {...buttonProps}
        {...(buttonProps.onClick && {
          onClick: buttonProps.onClick
        })}
        onMouseDown={e => e.button === 0 && handler()}
        onKeyUp={e => e.key === 'Enter' && handler()} />
    },
    [closePopup, isOpen, openPopup]
  );

  return { isOpen, openPopup, closePopup, RenderPopup, RenderButton };
}
