import React from 'react';
import { Id } from 'merchery-lib';
import MyButton from '../../../_utility-components/button/button';
import Popup from '../../../_utility-components/popup';

interface Props {
  popupClose: () => void,
  mainActionHandler: () => void
}

const SubscriptionsDeletePopup = ({
  popupClose, mainActionHandler,
}: Props) => {
  const headerText = 'Подтвердите удаление подписок'

  return (
    <Popup
      className={'confirm-window fixed-on-center order-delete-popup'}
      popupClose={popupClose}
      withBlackout
      withCloseBtn
      tabIndexDeep={3}
    >
      <h2>{headerText}</h2>

      <div className="confirm-window-btn">
        <MyButton 
          className={'white-btn'} 
          onClick={popupClose}
        >
          Отменить
        </MyButton>
        <MyButton 
          className="red-btn" 
          onClick={() => {
            mainActionHandler()
            popupClose()
          }}
        >
          Удалить
        </MyButton>
      </div>
    </Popup>
  )
}

export default SubscriptionsDeletePopup