import React from 'react';
import { createCurrencySymbol, currencyFont, numWithSep, priceWithPennies } from '../../../scripts/functions';

export interface CurrencyAndPriceProps {
  cur: string, 
  balance: number | any,
  withPennies?: boolean
}

function CurrencyAndPrice({
  cur, 
  balance,
  withPennies,
}: CurrencyAndPriceProps) {
  let leftPlace, rightPlace;

  if(typeof balance !== 'number') return null;

  const currency = currencyFont(createCurrencySymbol(cur))
  const price = withPennies ? numWithSep(priceWithPennies(balance)) : numWithSep(balance)

  if(cur === "USD" || cur === "EUR") {
    leftPlace = currency
    rightPlace = price
  } else {
    leftPlace = price
    rightPlace = currency
  }
  
  return (
    <>{leftPlace} {rightPlace}</>
  );
}

export default CurrencyAndPrice;