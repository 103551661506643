import React, {ReactNode} from "react";

export function BodyRow ({
	children,
	className,
	...restProps
}: {
	children: ReactNode | ReactNode[],
	className?: string,
} & React.HTMLProps<HTMLDivElement>) {
	return (
		<div
			className={`table__row ${className || ''}`}
			{...restProps}
		>
			{children}
		</div>
	)
}