import React, { memo, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Id, Promocode } from 'merchery-lib';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import {priceWithPennies, ptsansCurrency, uuidv4, validateResponse} from '../../../../scripts/functions';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import { useLoad } from '../../../../scripts/hooks/use-load';
import useMounted from '../../../../scripts/hooks/use-mounted';
import { usePopup } from '../../../../scripts/hooks/use-popup';
import useRouteId from '../../../../scripts/hooks/use-route-id';
import { OrderChangeHandler } from '../order-page';
import { NewPromo } from './new-promo';
import { PromoEdit } from './promo-edit';

export type CurrentPromoCode = Omit<Promocode, 'id'> & {id: Id | null}

const defaultPromocode: CurrentPromoCode = {
  id: null,
  code: '',
  price: 0,
  used: false,
  multi: false,
  isPercent: false,
}

export const OrderPromocodeContext = React.createContext<{
  isPercent: boolean
  popupClose: () => void
  newPromoCode: CurrentPromoCode
  setNewPromoCode: (promo: CurrentPromoCode) => void
  setPromo: () => void,
  createNewCodeState: boolean,
  setCreateNewCodeState: (value: boolean) => void
  promoSwitch: () => void,
  createHandler: () => void,
  currentPromocode: CurrentPromoCode,
  setCurrentPromocode: (promoCode: CurrentPromoCode) => void,
  createCodeHandler: () => void,
  percentValue: number,
  setPercentValue: (value: number) => void,
  currencyValue: number,
  setCurrencyValue: (value: number) => void,
}>({
  isPercent: true,
  popupClose: () => {},
  newPromoCode: defaultPromocode,
  setNewPromoCode: () => {},
  setPromo: () => {},
  createNewCodeState: false,
  setCreateNewCodeState: () => {},
  promoSwitch: () => {},
  createHandler: () => {},
  currentPromocode: defaultPromocode,
  setCurrentPromocode: () => {},
  createCodeHandler: () => {},
  percentValue: defaultPromocode.price,
  setPercentValue: () => {},
  currencyValue: defaultPromocode.price,
  setCurrencyValue: () => {},
})

interface Props {
  changeData: OrderChangeHandler
}

function Promo ({
  changeData,
}: Props) {
  const _isMounted = useMounted();
  const orders = useAppSelector(state => state.orders);
  const orderId = useRouteId('orderid');
  const order = orders.find(o => o.id === orderId);
  const promoList = useAppSelector(state => state.promocodes);
  const initPromocode = order && promoList.find(promo => promo.id === order.promo_code);
  const [createNewCodeState, setCreateNewCodeState] = useState<boolean>(false)

  const {RenderButton, RenderPopup, openPopup, closePopup, isOpen} = usePopup();
  const [, setLoad] = useLoad();
  const [isPercent, setIsPercent] = useState(false);

  const [newPromoCode, setNewPromoCode] = useState<CurrentPromoCode>(defaultPromocode);
  const [currentPromocode, setCurrentPromocode] = useState<CurrentPromoCode>(initPromocode || newPromoCode);

  const [percentValue, setPercentValue] = useState<number>(currentPromocode.isPercent
    ? currentPromocode.price
    : defaultPromocode.price);

  const [currencyValue, setCurrencyValue] = useState<number>(currentPromocode.isPercent
    ? defaultPromocode.price
    : currentPromocode.price);

  useEffect(() => {
    setCurrentPromocode(initPromocode || newPromoCode)
  }, [initPromocode])

  useEffect(() => {
    if(!isOpen && initPromocode) {
      setCurrentPromocode(initPromocode || newPromoCode)
    }
  }, [isOpen])

  const dispatch = useDispatch();
  const promocodesDispatch = (promocodes: Promocode[]) =>
    dispatch({ type: 'ORDERS_PROMOCODES', payload: promocodes })

  // useEffect(() => {
  //   setCreateNewCodeState(!currentPromocode)
  // }, [])

  useEffect(() => {
    const newValue = isPercent
      ? percentValue
      : currencyValue;

    if(createNewCodeState) {
      setNewPromoCode({
        ...newPromoCode,
        price: newValue,
        isPercent,
      })
    }
  }, [createNewCodeState, percentValue, currencyValue, isPercent])

  useEffect(() => {
    if(!Number.isNaN(orderId)) {
      getPromocodes()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  useEffect(() => {
    if(currentPromocode) {
      if(!initPromocode && currentPromocode.code) {
        setCurrentPromocode(newPromoCode)
      } else if(initPromocode && initPromocode.code !== currentPromocode.code) {
        setCurrentPromocode(initPromocode)
      }
    }
  }, [initPromocode])

  useEffect(() => {
    if(initPromocode?.code !== currentPromocode?.code) {
      const foundCode = currentPromocode && promoList.find(promo => promo.code === currentPromocode.code)

      if(foundCode) {
        setCurrentPromocode(foundCode)
        setIsPercent(foundCode.isPercent)
      } else {
        setCurrentPromocode({
          ...newPromoCode,
          id: null,
          code: currentPromocode?.code
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPromocode?.code])

  const getPromocodes = () => {
    mercheryFetch<Promocode[]>('promocode', 'GET', {})
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false;
      }

      promocodesDispatch(res.records)
    })
  };

  const checkValidity = () => {
    let pattern = new RegExp("^[0-9]+$");
    return pattern.test('' + newPromoCode.price);
  }

  const createHandler = async () => {
    if(!checkValidity()) return false
    
    const newCode = await createNewPromo({
      ...newPromoCode,
      isPercent: isPercent
    })

    newCode && batch(() => {
      setNewPromoCode({
        id: uuidv4(),
        code: '',
        price: 0,
        used: false,
        multi: false,
        isPercent: false,
      })
      setCreateNewCodeState(false)

      setCurrentPromocode(newCode)
      setIsPercent(newCode.isPercent)
    })

    newCode && setPromo(newCode.id);
  };

  const createNewPromo = async (data: {
    code: string
    price: number
    isPercent: boolean
    multi?: boolean
  }) => {
    setLoad(true)

    return mercheryFetch<Promocode>('promocode/create', 'POST', data)
    .then(async (res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false;
      }

      promocodesDispatch(
        [...promoList, res.records]
        .sort((a,b) => +b.id - +a.id)
      )

      return res.records
    })
    .finally(() => {
      setLoad(false)
    })
  };

  const promoSwitch = () => setIsPercent(!isPercent)

  const setPromo = (codeId: Id | null) => {
    changeData({promo_code: codeId}, true)
    .then(res => {
      if(_isMounted.current && res === true) {
        closePopup();
      }
    })
  }

  const createCodeHandler = () => {
    if (currentPromocode?.price) {
      setCurrentPromocode(newPromoCode)
      return false;
    }

    batch(() => {
      setNewPromoCode({
        ...newPromoCode,
        code: currentPromocode?.code || ''
      });
      setCreateNewCodeState(true);
    })
  }

  const openBtnClass = `white-blue-btn hide-for-print discount-promo ${order?.promo_code ? '' : ' disabled'}`;

  return (
    <OrderPromocodeContext.Provider value={{
      isPercent,
      popupClose: closePopup,
      newPromoCode,
      setNewPromoCode,
      setPromo: () => {setPromo(currentPromocode?.id || null)},
      createNewCodeState,
      setCreateNewCodeState,
      promoSwitch,
      createHandler,
      currentPromocode,
      setCurrentPromocode,
      createCodeHandler,
      percentValue,
      setPercentValue,
      currencyValue,
      setCurrencyValue,
    }}>
      <RenderButton
        className={openBtnClass}
        onClick={openPopup}
      >
        {order?.promo_code && initPromocode ? 
          <>
            Промокод 
            <span>
              <span style={{fontWeight: 600}}>
                {initPromocode.code}
              </span>
              :
            </span>
            <span style={{
              display: 'inline-flex',
              alignItems: 'center',
              columnGap: '4px'
            }}>
              {initPromocode.isPercent
                ? initPromocode.price
                : priceWithPennies(initPromocode.price)}

              {initPromocode.isPercent
                ? '%'
                : ptsansCurrency('₽')}
            </span>
          </>
        : 'Применить код'}
      </RenderButton>

      <RenderPopup
        className={'fixed-on-center promo'}
        withBlackout
        withCloseBtn
        tabIndexDeep={2}
      >
        {!createNewCodeState
          ? <PromoEdit />
          : <NewPromo />
        }
      </RenderPopup>
    </OrderPromocodeContext.Provider>
  )
}

export default memo(Promo)