import React from 'react';
import {useAppSelector} from "../../../../scripts/pre-type/use-selector";
import SearchInput from "../../../_utility-components/search-input";
import ClientsFiltersElement from "./filter-element";
import {ExtendedClientDto, Id} from "merchery-lib";
import {useDispatch} from "react-redux";
import {useTabIndex} from "../../../../scripts/hooks/use-tabindex";
import ExportButton from "../../../_utility-components/export-btn/export-btn";
import {CommonFiltersLoader} from "../../../_utility-components/loaders/common-table-loader";

function FiltersAndActions () {
	const clients = useAppSelector(state => state.clients);
	const searchInput = useAppSelector(state => state.clientSearchInput);
	const selectedClientsIds = useAppSelector(state => state.selectedClientsIds);

	const tabIndex = useTabIndex();

	const dispatch = useDispatch();
	const searchInputDispatch = (text: string) => dispatch({ type: 'CLIENTS_SEARCH_INPUT_VALUE', payload: text});

	if(!clients) {
		return <CommonFiltersLoader />
	}

	return <div className='clients-page__filters-and-export'>
		<div className={`clients-search-bar search-state`}>
			<SearchInput
				searchInput={searchInput}
				applySearch={searchInputDispatch}
				tabIndex={tabIndex}
			/>

			<ClientsFiltersElement />
		</div>


		<ExportButton<ExtendedClientDto>
			text={'Экспорт'}
			className={'selected-top-page-menu-btn header-font-s order-export-btn'}
			items={clients.filter(o =>
				selectedClientsIds.some(so => so === o.id)
			)}
			renameExportHeaders={renameExportHeaders}
		/>
	</div>
}

const renameExportHeaders = (client: ExtendedClientDto) => {
	return {
		"Id": client.id,
		"Email": client.email,
		"Имя": client.name,
		"Группа": client.group,
		"Телефон": client.phone,
		"Адрес": client.address,
		"Скидка": client.discount,
		"Оборот": client.ordersTotal,
		"Заказы": client.ordersCount,
		"Возвраты": client.refundTotal,
		"Выкуп": client.buyout,
	}
}

export default FiltersAndActions;
