import { CdekCityDto, OrderDelivery } from 'merchery-lib';
import QueryString from 'qs';
import React, { useContext, useEffect } from 'react';
import HtmlParser from 'react-html-parser';
import { batch } from 'react-redux';
import MyButton from 'src/components/_utility-components/button/button';
import MyInput from 'src/components/_utility-components/input/index';
import Popup from 'src/components/_utility-components/popup';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { validateResponse } from 'src/scripts/functions';
import useMounted from 'src/scripts/hooks/use-mounted';
import { usePossibleValues } from 'src/scripts/hooks/use-possible-values';
import { OrderDeliveryContext } from '../delivery';
import { DeliveryDimensions } from "../delivery-dimensions-presets";
import { CdekDeliveryContext } from './cdek';

export function CdekCityAndDimensions() {
  const {
    setSearch,
    search,
    setShowData,
    dataLoading,
    data,
    showData
  } = usePossibleValues<CdekCityDto, 'city'>({
    urlPath: 'integrations/cdek/cities',
    textField: 'city',
    params: {
      pagination: {
        page: 0,
        size: 10,
      }
    }
  });
  const _isMounted = useMounted()

  const {
    delivery,
    changeSelectedDelivery,
    itemsWeight,
  } = useContext(OrderDeliveryContext);

  const {
    setCities
  } = useContext(CdekDeliveryContext)

  useEffect(() => {
    if(delivery?.city_code) {
      const query = QueryString.stringify({
        filters: {
          code: delivery?.city_code
        }
      })

      mercheryFetch<CdekCityDto[]>(`integrations/cdek/cities?${query}`, 'GET')
      .then((res) => {
        if(!_isMounted.current || !validateResponse(res)) {
          return false
        }
        setCities(res.records)
      })
    }
  }, [])

  useEffect(() => {
    setCities(data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    label: keyof OrderDelivery
  ) => {
    const value = e.target.value;
    changeSelectedDelivery({[label]: value})
  };

  const cityInputOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;

    batch(() => {
      changeSelectedDelivery({
        city: value,
        city_code: null
      })
      setSearch(event.target.value)
    })
  }

  const cityHandler = (city: CdekCityDto) => {
    batch(() => {
      changeSelectedDelivery({
        city: city.city,
        city_code: String(city.code)
      })
      setShowData(false)
    })
  };

  const returnItemsWeight = () => {
    changeSelectedDelivery({weight: itemsWeight})
  }

  return (
    <div className="op-delivery-cdek__city-dimensions">
      <div className="op-delivery-cdek__city-dimensions__column op-delivery-cdek__city-dimensions__column--city-column">
        <h4 className='wide-text-font-xxs'>
          ГОРОД
        </h4>

        <MyInput
          id="delivery-city"
          name="delivery-city"
          myClassName='delivery-city-input'
          placeholder="Город получателя"
          value={delivery?.city || ''}
          onChange={cityInputOnChangeHandler}
        />

        {showData ? (
          <Popup
            className={'possible-values-popup op-delivery-cdek__city-dimensions__possible-cities'}
            popupClose={() => setShowData(false)}
            changingDirection={true}
          >
            <div className='possible-values-wrapper popup-group'>
              {dataLoading ?
                <div className='popup-element'>
                  Загрузка...
                </div>
              : !data.length ?
                <div className='popup-element'>
                  Ничего не найдено
                </div>
              :
                <CityPopup
                  data={data}
                  search={search}
                  cityHandler={cityHandler}
                />
              }
            </div>
          </Popup>
        ) : null}
      </div>

      <div className="op-delivery-cdek__city-dimensions__column">
        <DeliveryDimensions />
      </div>

      <div className='op-delivery-cdek__city-dimensions__column'>
        <h4></h4>
        <MyInput
          id="delivery-weight"
          name="delivery-weight"
          myClassName='delivery-weight-input'
          placeholder="Вес, гр"
          type={'number'}
          min={0}
          value={delivery?.weight || ''}
          onChange={e => handleChange(e, 'weight')}
          innerChildren={
            delivery?.weight &&
            delivery.weight !== itemsWeight
              ? <i className="icofont-refresh merchery-input__icon"
                   onClick={returnItemsWeight}></i>
              : null
          }
        />
      </div>
    </div>
  )
}

function CityPopup ({ data, search, cityHandler }: {
  data: CdekCityDto[],
  search: string,
  cityHandler: (city: CdekCityDto) => void
}) {
  const highlightMatch = (cityName: string) => {
    const index = cityName.toLowerCase().indexOf(search.toLowerCase());
    if (index !== -1) {
      const beforeMatch = cityName.substring(0, index);
      const match = cityName.substring(index, index + search.length);
      const afterMatch = cityName.substring(index + search.length);

      return `${beforeMatch}<strong>${match}</strong>${afterMatch}`;
    }
    return cityName;
  };

  return (
    <div className='possible-values-wrapper popup-group'>
      {data.map((n) => (
        <MyButton
          key={n.id}
          removeDefaultClass
          onClick={() => cityHandler(n)}
          className='popup-element'
        >
          {HtmlParser(highlightMatch(n.city))}, {n.region}, {n.country}
        </MyButton>
      ))}
    </div>
  );
}