import React, { useEffect, useState } from 'react';
import { Id, SubscriptionDto } from 'merchery-lib';
import { BodyCell } from 'src/components/_utility-components/common-table/body-cell';
import { usePopup } from 'src/scripts/hooks/use-popup';
import MenuIcon from 'src/img/context-menu-dots.svg';
import ReactDOM from 'react-dom';
import SubscriptionsDeletePopup from '../delete-popup';
import useMounted from 'src/scripts/hooks/use-mounted';

interface Props {
  subscription: SubscriptionDto,
  deleteHandler: (id: Id) => void
}

function SubscriptionConfig({
  subscription,
  deleteHandler
}: Props) {
  const { RenderButton, RenderPopup, isOpen, closePopup } = usePopup()
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);

  useEffect(() => {
    if(deletePopupOpened && isOpen) {
      closePopup()
    }
  }, [deletePopupOpened])

  return (
    <BodyCell myClassName="tt-config">
      <div className='config-popup-wrapper'>
        <RenderButton
          removeDefaultClass
        >
          <img src={String(MenuIcon)} alt={'context-menu'}/>
        </RenderButton>
        <RenderPopup
          className='config-popup'
        >
          <div className='popup-element'
            onClick={() => setDeletePopupOpened(true)}
          >
            Удалить
          </div>
        </RenderPopup>

        {deletePopupOpened ?
          ReactDOM.createPortal(
            <SubscriptionsDeletePopup
              popupClose={() => setDeletePopupOpened(false)}
              mainActionHandler={() => deleteHandler(subscription.id)}
            />
          , document.body)
        : null}
      </div>
    </BodyCell>
  );
}

export default SubscriptionConfig;