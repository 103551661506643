import React, { useState } from 'react';
import { Id, Order } from 'merchery-lib';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import MyButton from '../../../_utility-components/button/button';
import Popup from '../../../_utility-components/popup';
import { ManagerBtn } from '../orders-modules/header-manager-filter';
import {MultiChanger} from "../../../../scripts/utils/entity-filters";

export function SetMassManager({multiChange}: {
  multiChange: MultiChanger<Order>
}) {
  const [popupOpened, setPopupOpened] = useState(false);

  return <div>
    <MyButton
      className="selected-top-page-menu-btn header-font-s"
      onMouseDown={() => setPopupOpened(true)}
    >
      <i className="icofont-user"></i>
      <span>Назначить</span>
    </MyButton>

    {popupOpened ?
      <ManagerMultiRowsPopup
        popupClose={() => setPopupOpened(false)}
        multiChange={multiChange}
      />
    : null}

  </div>;
}

interface ManagerMultiRowsPopupProps {
  popupClose: () => void,
  multiChange: MultiChanger<Order>
}

const ManagerMultiRowsPopup = ({popupClose, multiChange}: ManagerMultiRowsPopupProps) => {
  const admins = useAppSelector(state => state.admin)

  if(!admins) {
    return null
  }

  const btnHandler = (id: Id | null) => 
    multiChange({
      itemIds: [id], 
      filterName: 'admin_id', 
      concreteSelected: true
    });

  return (
    <Popup
      popupClose={popupClose}
      className={'manager selected-row'}
    >
      <div className="popup-group">
        <ManagerBtn
          manager={undefined}
          btnHandler={btnHandler}
        />

        {admins.map((admin) => (
          <ManagerBtn key={admin.id} 
            manager={admin} 
            btnHandler={btnHandler}
          />
        ))}
      </div>
    </Popup>
  );
};

export default ManagerMultiRowsPopup;