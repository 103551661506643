import React from 'react';
import { usePopup } from 'src/scripts/hooks/use-popup';
import MyButton from 'src/components/_utility-components/button/button';
import { RefundDto } from 'merchery-lib';

interface Props {
  refund: RefundDto,
  className?: string,
  btnClassName?: string,
  withArrow?: true,
  tabIndex?: number,
  statusHandler: (status: string) => void,
  disabled?: boolean
}

interface RefundStatus {
  id: number,
  code: string,
  name: string,
  color: null | string
}

export const refundStatuses: RefundStatus[] = [{
  id: 1,
  code: 'pending',
  name: 'Ожидание',
  color: null
}, {
  id: 2,
  code: 'success',
  name: 'Завершен',
  color: '#7a48cf'
}, {
  id: 3,
  code: 'cancel',
  name: 'Отменен',
  color: '#eb0248'
}]

function SetStatus({
  refund,
  className, 
  btnClassName,
  withArrow,
  tabIndex,
  statusHandler,
  disabled,
}: Props) {
  const { RenderButton, RenderPopup, closePopup } = usePopup()
  const refundStatus = refundStatuses.find(p => p.code === refund.status);

  const popupElementHandler = (status: RefundStatus) => {
    if(disabled) {
      return false
    }
    statusHandler(status.code)
    closePopup();
  }

  return (
    <div className={`${className ? className : ''} select-btn`} >
      <RenderButton
        {...(tabIndex !== undefined && {
          tabIndex: tabIndex,
        })}
        disabled={disabled}
        removeDefaultClass
        style={{
          backgroundColor: refundStatus ? refundStatus.color || '#E4E4E4' : '#E4E4E4',
          color: refundStatus?.color ? '#FFF' : '',
        }}
        className={`select-btn-inner ${btnClassName ? btnClassName : ''}`}
      >
        {refundStatus?.name || ''} 
        {withArrow ? <i className='icofont-simple-down'/> : null}
      </RenderButton>

      <RenderPopup
        changingDirection={true} 
        className={'table-popup status'}
      >
        {refundStatuses.map((os) =>
          <div className="popup-group" key={os.id}>
            <StatusBtn 
              status={os}
              btnHandler={popupElementHandler}
            />
          </div>
        )}
      </RenderPopup>
    </div>
  );
}

interface StatusBtnProps {
  status: RefundStatus | undefined
  btnHandler: (status: RefundStatus) => void,
}

function StatusBtn ({
  status,
  btnHandler,
}: StatusBtnProps) {
  if(!status) {
    return null
  }
  const bgColorStyle = status.color ? status.color : '#E4E4E4';

  return (
    <MyButton className="popup-element" 
      key={status.id} 
      removeDefaultClass
      onClick={() => btnHandler(status)}
    >
      <span className={'status_filter-btn__text'}>
        {status.name} 
        <div style={{backgroundColor: bgColorStyle}}></div>
      </span>
    </MyButton>
  )
}

export default SetStatus;