import React, {useContext} from 'react';
import { memo } from 'react';
import { Id, ProductsAttrValues } from 'merchery-lib';
import MySwitcher from '../../../../_utility-components/switcher/switcher';
import {
  getCurrentOptionValue,
  OneCharContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/one-char-logic";

function CharOptionSwitcher() {
  const {
    selectedOption,
    selectedOptionValue,
    setSelectedOptionValue,
  } = useContext(OneCharContext)
  const items = selectedOption.values.filter(value => value !== undefined) as ProductsAttrValues[]

  const selectScopeValueHandler = (valueId: Id) =>
    setSelectedOptionValue(
      getCurrentOptionValue({
        valueId: valueId,
        selectedOption
      })
    )

  if(items.some(item => item === null) || !selectedOptionValue?.id) {
    return null
  }

  return (
    <div
      className='characteristic-options-switcher__wrapper side-padding-16'>

      {items.length ? 
        <MySwitcher<ProductsAttrValues>
          items={items}
          className={'characteristic-options-switcher'}
          textField={'value'}
          selectedItemId={selectedOptionValue.id}
          itemHandler={selectScopeValueHandler}
        />
      : null}
    </div>
  );
}

export default memo(CharOptionSwitcher);