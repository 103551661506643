/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id, UnitOfMeasurement } from 'merchery-lib';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { MyInputProps } from '../../../_utility-components/input/input';
import SelectInput from '../../../_utility-components/select-input/index';
import { validateResponse } from 'src/scripts/functions';

interface Props extends Partial<MyInputProps> {
  selectHandler: (unit: UnitOfMeasurement) => void,
  currentUnitId: Id | null,
  tabIndexLevel?: number
}

function ProductUnitsSelect({
  selectHandler,
  currentUnitId,
  tabIndexLevel,
  ...inputProps
}: Props) {
  const units = useAppSelector(state => state.units);
  const selectedUnit = useMemo(() => units.find(unit => unit.id === currentUnitId), [units, currentUnitId])
  const dispatch = useDispatch();
 
  const unitsType = 'UNITS_OF_MEASUREMENT'

  const setUnits = (value: UnitOfMeasurement[]) => dispatch({type: unitsType, payload: value});


  const createUnit = async (name: string) => {
    return mercheryFetch<UnitOfMeasurement>('unit-of-measurement', 'POST', {
      name: name,
    })
    .then((res) => {
      if(validateResponse(res)) {
        setUnits([...units, res.records])
        selectHandler(res.records)
      }
    })
  }
  
  return (
    <SelectInput
      className='char-label_unit-select'
      items={units}
      valueLabelName={'name'}
      selectedItem={selectedUnit}
      selectHandler={selectHandler}
      searchable={true}
      inputProps={inputProps}
      {...(tabIndexLevel !== undefined && {tabIndexLevel})}
    >
      {(selectContext) => {
        const hasCompleteMatch = selectContext.shownItems.some(i => i.name.toLowerCase() === selectContext.searchText.toLowerCase());
        const elementClassName = `${selectContext.elementProps.className} ${selectContext.focused(selectContext.shownItems.length)} char-label_unit-select`;
        
        return (
          !hasCompleteMatch && selectContext.searchText ? 
            <div 
              {...selectContext.groupProps}>
              <div 
                {...selectContext.elementProps}
                className={elementClassName}
                onClick={() => createUnit(selectContext.searchText)}
              >
                Создать "{selectContext.searchText}"
              </div>
            </div>
          : <></>
        )
      }}
    </SelectInput> 
  )
}

export default ProductUnitsSelect;