import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { mercheryFetch } from '../fetchConstructor';
import { validateResponse } from '../functions';
import { useAppSelector } from '../pre-type/use-selector';
import useMounted from './use-mounted';
import { VatValue } from 'merchery-lib';

function useVatValues() {
  const _isMounted = useMounted()
  const vatValues = useAppSelector(state => state.vatValues);
  const dispatch = useDispatch()

  const vatValuesDispatch = (items: VatValue[]) =>  dispatch({ type: 'VAT_VALUES', payload: items})

  useEffect(() => {
    if(vatValues) {
      return;
    }

    mercheryFetch<VatValue[]>('static-data/vat-values', 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }

      vatValuesDispatch(res.records)
    })
  }, [])

  return vatValues
}

export default useVatValues;