import { Id } from "merchery-lib"

export type CommonFilters<T> = {
	[K in keyof T]?: FilterItem[];
}

export interface FilterItem {
	id: Id | null
	selected: boolean
	text: string
	type: any
}

const filterChanger = <T>({
	itemIds,
	filterName,
	cleanPrev = false,
	concreteSelected,
	cleanOthers,
}: FilterChangerProps<T>, filters: CommonFilters<T>) => {
	function createFiltersObject(filterName: keyof T | undefined, filters: CommonFilters<T>): CommonFilters<T> {
		const keys = Object.keys(filters) as (keyof T)[];

		const result = Object.fromEntries(
			keys.map((key) => {
				const value = filters[key];
				return [
					key,
					key === filterName || !value ?
						value
						: value.map(f => ({	...f, selected: false }) )
				] as const;
			})
		)

		return result as CommonFilters<T>
	}

	if(!filters) {
		return undefined
	}

	let sf: CommonFilters<T> = {...filters};

	if(cleanOthers) {
		sf = createFiltersObject(filterName, sf);
	}

	if(filterName && sf[filterName]) {
		let filter = sf[filterName] as FilterItem[]

		if(cleanPrev) filter = filter.map(f => ({...f, selected: false}))

		if(!itemIds || itemIds[0] === undefined) {
			filter = filter
				.map((filter) => ({...filter, selected: false}))
		} else {
			filter = filter.map(
				(filter) => (
					!itemIds.some(itemId => filter.id === itemId) ?
						filter : {
							...filter,
							selected: concreteSelected !== undefined ? concreteSelected : !filter.selected
						}
				)
			)
		}

		sf[filterName] = filter

	}
	if (!filterName) {
		for (const [, items] of Object.entries(sf)) {
			items && (items as FilterItem[]).forEach((filter: FilterItem) => {
				filter.selected = concreteSelected !== undefined ? concreteSelected : !filter.selected
			})
		}
	}

	return sf
};


export interface FilterChangerProps<T> {
	itemIds: (Id | null)[] | undefined;
	filterName: (keyof T) | undefined;
	cleanPrev?: boolean | undefined;
	concreteSelected?: boolean,
	cleanOthers?: true,
}

export type FilterChanger<T> = (...props: FilterChangerProps<T>[]) => Promise<CommonFilters<T> | undefined>

export type MultiChanger<T> = FilterChanger<T>

export default filterChanger