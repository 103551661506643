import React, {useContext} from "react";
import { batch } from "react-redux";
import MyInput from "src/components/_utility-components/input";
import { Id, MultiSelectDto, MultiSelectValueName } from "merchery-lib";
import { uuidv4 } from "../../../../../../../scripts/functions";
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";

function MultiSelectAddNew ({
  labelId,
  categoryId,
}: {
  labelId: Id,
  categoryId: Id
}) {
  const {
    multiSelects,
    setMultiSelects,
    multiSelectNames: names,
    setMultiSelectNames,
  } = useContext(CharacteristicsContext)
  const thisMultiSelect = multiSelects.find(ms => ms.label_id === labelId)
  // const newValueNamePrevState = useRef('');
  // const [newValue, setNewValue] = useState('');

  // useEffect(() => { // add new value && clear create input 
  //   let timeOutId = setTimeout(() => {
  //     newValueNamePrevState.current = newValue
  //     if(newValue && newValue === newValueNamePrevState.current) {
  //       addNewValue(newValue)
  //       setNewValue('')
  //     }
  //     return false
  //   }, 200);
  //   return () => clearTimeout(timeOutId);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [newValue]);

  const setMultiSelect = (value: MultiSelectDto) => setMultiSelects(
    multiSelects.map(ms => ms.id !== value.id ? ms : value)
  )
  const addNewValue = (initialValue: string) => {
    if(!thisMultiSelect) {
      return false
    }

    const newName: MultiSelectValueName = {
      id: uuidv4(),
      name: initialValue,
      newName: true,
      category: categoryId,
    }

    const updatedMultiSelect = {
      ...thisMultiSelect, 
      names: [
        ...thisMultiSelect.names, 
        newName.id
      ]
    }

    batch(() => {
      setMultiSelect(updatedMultiSelect)
      setMultiSelectNames([...names, newName])
    })
  }

  return (
    <div className='product-page-additional-options-one-option-values-blank-value char-label_sub-label'>
      <MyInput
        placeholder={'Добавить еще одно значение'}
        myClassName={'product-page-option-values'}
        value={''}
        onFocus={() => addNewValue('')}
        // value={newValue}
        // onInput={(e) => setNewValue(e.target.value)}
        required={false}
      />
    </div>
  )
}

export default MultiSelectAddNew