import React from 'react';
import { Prompt } from 'react-router';
import { TabIndex } from '../../../../dto/master-dto/tabindex.dto';
import MyButton from '../../../_utility-components/button/button';
import TopPagePanel from '../../../_utility-components/top-page-panel';

interface Props {
  hasChanges: boolean,
  promptWhen: boolean,
  tabIndex: TabIndex,
  isNew: boolean,
  cancelBtnHandler: () => void,
  createCollection: () => void,
  updateCollection: () => void,
}

function CollectionTopPanel({
  hasChanges,
  promptWhen,
  tabIndex,
  isNew,
  cancelBtnHandler,
  createCollection,
  updateCollection,
}: Props) {

  return (
    <TopPagePanel 
      topPanelOpened={hasChanges}
      fixed={true}
    >
      <Prompt
        when={promptWhen}
        message='Остались несохраненные изменения, покинуть страницу?'
      />
      
      <div className="left">
        <div className="text-div">
          {isNew ? 
            'Несохраненная коллекция' 
          : `Несохраненные изменения`}
        </div>
      </div>

      <div className="right">
        <MyButton
          tabIndex={tabIndex}
          id={'product-cancel-btn'}
          className="dark-btn"
          onClick={cancelBtnHandler}
        >
          Отменить
        </MyButton>

        <MyButton
          tabIndex={tabIndex}
          id={'product-confirm-btn'}
          className="blue-btn"
          onClick={isNew ? createCollection : updateCollection}
        >
          {isNew ? 
            'Создать коллекцию' 
          : 'Сохранить'}
        </MyButton>
      </div>
    </TopPagePanel>
  );
}

export default CollectionTopPanel;