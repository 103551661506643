import qs from 'qs';
import React, { useContext } from 'react';
import { useEffect, useMemo, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { batch } from 'react-redux';
import { Id, ProductsAttrValues } from 'merchery-lib';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { uuidv4, validateResponse } from "src/scripts/functions";
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import useMounted from 'src/scripts/hooks/use-mounted';
import MyInput from "../../../../_utility-components/input/index";
import { ProductOneOptionPossibleValues } from "./possible-value";
import {useDragEndHandler} from "src/scripts/hooks/use-drag-end-handler-react-beautiful-dnd";
import ProductOneOptionOneValue from './one-value';
import { OneOptionContext } from '../one-option';

export function ProductOneOptionValues() {
  const {
    // edit,
    option,
    changeOption,
    needCallPossibleValues,
    setNeedCallPossibleValues
  } = useContext(OneOptionContext);
  const [iniitalPossibleValues, setIniitalPossibleValues] = useState<ProductsAttrValues[]>([]);
  const [newValue, setNewValue] = useState<string>('');
  const _isMounted = useMounted()
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => 
    s.code === 'moy_sklad')?.turned_on || false
  )

  const values = useMemo(() => 
    option ? [...option.values] : []
  , [option?.values])

  const possibleValues = useMemo(() => {
    if(!iniitalPossibleValues.length) {
      return []
    }
    if(!values.length) {
      return iniitalPossibleValues
    }

    return iniitalPossibleValues
      .filter(r => 
        !values
        .some(v => v?.value === r?.value)
      )
  }, [iniitalPossibleValues, values])

  useEffect(() => {
    if(option?.notSaved) {
      getAttributeValue()
    }
  }, [option?.notSaved])

  useEffect(() => {
    let timeOutId = setTimeout(() => 
      newValue 
        ? addNewValue(newValue) 
        : false
    , 10);
    return () => clearTimeout(timeOutId);
  }, [newValue]);

  useEffect(() => {
    if(needCallPossibleValues) {
      getAttributeValue()
      setNeedCallPossibleValues(false)
    }
  }, [needCallPossibleValues])

  const onDragEnd = useDragEndHandler<ProductsAttrValues>(
    values,
    (result, provided, newList, removed) => {
      if (result.type.includes("value") && result.source.index !== result.destination.index) {
        changeOption({
          values: newList.map((item, index) => ({
            ...item,
            order: index + 1
          }))
        });
      }
    }
  )

  const addNewValue = (initialValue: string, id: Id | undefined = undefined) => {
    if(!option) {
      return false
    }
    const maxOrder = Math.max.apply(null, values.map((v) => v.order))
    const valueOrder = (maxOrder < 1 ? values.length : maxOrder) + 1

    const newValue: ProductsAttrValues = {
      id: id || uuidv4(),
      name_id: option?.name_id || '',
      order: valueOrder,
      value: initialValue || '',
      description: '',
      ...(id === undefined && {
        newValue: true
      })
    }

    batch(() => {
      setNewValue('')
      changeOption({
        values: [
          ...values, 
          newValue,
        ]
      })
    })
    
    setTimeout(() => {
      const inputElement = document.querySelector(`.product-page-additional-options-one-option-values-input-wrapper[id='option-value-${option.order}-${valueOrder}'] .merchery-label__input`) as HTMLElement | null;
      inputElement?.focus()
    }, 0.4)
  }

  const getAttributeValue = () => {
    if(option?.newName) {
      return false
    }
    const params = qs.stringify({
      name_id: option?.name_id || '',
    }, {arrayFormat: 'comma'})
    
    mercheryFetch<ProductsAttrValues[]>(`products-attributes/get_attr_values?${params}`, 'GET', {})
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) 
        return false

      setIniitalPossibleValues(res.records)
    })
  }

  if(!option?.notSaved) {
    return null
  }
  
  const onDragStart = () => {
    if(moySkladIntegrationOn) return false
    const element = document?.activeElement as HTMLElement | null
    element?.blur()
  }

  if(!option) {
    return null;
  }

  return (
    <div className='product-page-additional-options-one-option-values-wrapper'>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
      >
        <div className='product-page-additional-options-one-option-values'>

          <div className='product-page-additional-options-one-option-values-h-wrapper header-font-s'>
            <div className='option-values-section-title'>
              Значения опции и комментарии
            </div>

            {possibleValues?.length ? (
              <ProductOneOptionPossibleValues
                possibleValues={possibleValues}
                addNewValue={addNewValue}
              />
            ) : null}
          </div>

          <div className='product-page-additional-options-one-option-values-inputs'>
            <Droppable 
              type={`values`} 
              droppableId={`${option.order}-value`}
            >
              {(provided, snapshot) => (
                <div 
                  className={`product-page-additional-options-one-option-values-inputs-wrapper`}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {values
                  .sort((a,b) => a.order - b.order)
                  .map((value, index) => 
                    <ProductOneOptionOneValue
                      key={value.id}
                      value={value}
                      index={index} 
                      />
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <div className='product-page-additional-options-one-option-values-blank-value'>
              <MyInput
                placeholder={'Добавить еще одно значение'}
                myClassName={'product-page-option-values'}
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                required={false}
              />
            </div>
          </div>
        </div>
      </DragDropContext>
    </div>
  )
}