import React from 'react';
import { Prompt } from 'react-router-dom';
import useUnload from '../../../scripts/hooks/use-unload';
import MyButton from '../../_utility-components/button/button';
import TopPagePanel from '../../_utility-components/top-page-panel';
import { Setting } from './dto/settings.dto';

function SettingsTopPanel({
  changes,
  cancelBtnHandler,
  saveChanges
}: {
  changes: {[keyof in Setting['callname']]: Setting['value']},
  cancelBtnHandler: () => void,
  saveChanges: () => void
}) {
  const topPanelOpened = Boolean(
    Object.keys(changes)
      .length
  )

  useUnload(e => {
    e.preventDefault();
    e.returnValue = '';
  }, topPanelOpened);

  return (
    <TopPagePanel
      fixed
      topPanelOpened={topPanelOpened}
    >
      <Prompt
        when={topPanelOpened}
        message='Остались несохраненные изменения, покинуть страницу?'
      />

      <div className="left">
        <div className="text-div">
          Несохраненные изменения
        </div>
      </div>

      <div className="right">
        <MyButton
          id={'product-cancel-btn'}
          className="dark-btn"
          onClick={cancelBtnHandler}
        >
          {'Отменить'}
        </MyButton>

        <MyButton
          id={'product-confirm-btn'}
          className="blue-btn"
          onClick={saveChanges}
        >
          Сохранить
        </MyButton>
      </div>
      
    </TopPagePanel>
  );
}

export default SettingsTopPanel;