import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import MyButton from './button/button';

function FallbackComponent ({error, resetErrorBoundary}: FallbackProps) {
  return <div className='error-boundary'>
    <h1 style={{textAlign: 'center'}}>Произошла критическая ошибка в этом модуле</h1>

    {error ? 
      <h2 style={{textAlign: 'center'}}>Ошибка: {error?.message}</h2>
    : null}

    <MyButton onClick={() => window.location.reload()}
      removeDefaultClass
    >
      Перезагрузить страницу
    </MyButton>
    
    <MyButton onClick={resetErrorBoundary}
      removeDefaultClass
    >
      Перемонтировать модуль
    </MyButton>
  </div>
}

export default FallbackComponent