import React from 'react';
import PaymentIcon from "src/img/module-kassa.svg";

function Paymethod () {
	return (
		<div className='order-page__paymethod'>
			<div className='order-page__paymethod__icon'>
				<img src={String(PaymentIcon)} alt={'payment'}/>
			</div>

			<div className='order-page__paymethod__name text-font-xs'>
				Модульбанк: интернет-эквайринг
			</div>
		</div>
	);
}

export default Paymethod;