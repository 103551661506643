import React, { ComponentType } from 'react';
import AppLoader from '../components/_utility-components/loaders/app-loader';

interface WithLoaderProps {
  condition: unknown;
}

export function withLoader<T extends ComponentType<any>>(WrappedComponent: T) {
  const WithLoader: React.FC<WithLoaderProps & React.ComponentProps<T>> = (props) => {
    const condition = props.condition
    return condition ? <WrappedComponent {...props as React.ComponentProps<T>} /> : <AppLoader />;
  };

  return WithLoader;
}
