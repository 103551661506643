import React, { useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import FallbackComponent from 'src/components/_utility-components/error-boundary';
import Cdek from './cdek/cdek';
import CourierDelivery from './courier-delivery';
import { OrderDeliveryContext } from './delivery';
import { DeliveryItemHandler } from './delivery-item-handler';
import RussianPostEms from './russian-post/russian-post-ems';

export function DeliveriesToSelect() {
  const {
    delivery,
  } = useContext(OrderDeliveryContext);
  const deliveries = useAppSelector(state => state.deliveryServices);

  return <div className="op-delivery-change">
    {deliveries
      .filter(d => d.show)
      .map((d) => {
        const active = delivery?.type === +d.id;

        return (
          <div
            key={+d.id}
            className={`op-delivery-item ${active ? 'active' : ''}`}
          >
            <DeliveryItemHandler
              delivery={d}
            />

            <ErrorBoundary FallbackComponent={FallbackComponent}>
              {active ? (
                <div className="op-delivery-item-main">
                  {+d.id === 1 ?
                    <CourierDelivery />
                  : null}

                  {+d.id === 2 ?
                    <RussianPostEms />
                  : null}

                  {+d.id === 9 ?
                    <Cdek />
                  : null}
                </div>
              ) : null}
            </ErrorBoundary>
          </div>
        );
      }
    )}
  </div>;
}
