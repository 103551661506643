import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ImageExtended, SortingItem, SetDto, ImageDeleteResponse, MyResponse, ImageCreate, VideoCreate } from "merchery-lib";
import {mercheryFetch, mercheryUploadVideos} from '../../../scripts/fetchConstructor';
import { addMessage, getObjectsDiffsByFields, querify, toastUp, uuidv4, validateResponse } from '../../../scripts/functions';
import { useAppSelector } from '../../../scripts/pre-type/use-selector';
import { useTabIndex } from '../../../scripts/hooks/use-tabindex';
import { NotFoundLocalApp } from '../../_utility-components/not-found';
import PageLoading from '../../_utility-components/page-loading';
import SeoSection from '../categories/seo';
import { MainRouteChild } from '../main-page';
import SetSide from './modules/set-side';
import ProductsInSet from './modules/products-in-set';
import SetSummary from './modules/summary';
import SetTopPanel from './modules/top-panel';
import useMounted from '../../../scripts/hooks/use-mounted';
import useUnload from '../../../scripts/hooks/use-unload';
import MyButton from '../../_utility-components/button/button';
import { DropzoneFileHandler, postBase64 } from 'src/components/_utility-components/dropzone';

export type SetImageModules = 'Set' | 'OgSet'

interface Props extends MainRouteChild {

}

function SetPage(props: Props) {
  const _isMounted = useMounted();

  const match = useRouteMatch<{id: string}>();
  const isNew = match.params.id === 'new';
  const currentId = +match.params.id;
  const history = useHistory();

  const tabIndex = useTabIndex(1);

  const sets = useAppSelector(state => state.sets);
  const thisSet = useMemo(() => 
    !sets 
      ? undefined
      : sets.find(set => isNew 
        ? set.newSet === true 
        : set.id === +currentId
      )
  , [sets, currentId, isNew]);

  const labelsToFindDiffs: (keyof SetDto)[] = [
    'name', 'url', 'sorting_id', 'template_id', 'description', 
    'seo_description','og_title', 'seo_title', 'og_description',
    'show_date'
  ]

  const isCurrentSet = useCallback((set: SetDto): boolean =>  
    isNew ? 
      set.newSet === true 
      : set.id === +currentId
  , [currentId, isNew]);

  const [initSet, setInitSet] = useState(thisSet);
  const [loaded, setLoaded] = useState(!!thisSet);

  const setsChanges = useMemo(() => {
    if(!thisSet) {
      return {}
    }
    
    return getObjectsDiffsByFields<SetDto>(thisSet, initSet, labelsToFindDiffs)
  }, [initSet, labelsToFindDiffs, thisSet]);

  const hasChanges = useMemo(() => 
    !!Object.keys(setsChanges).length
  , [setsChanges]);

  const dispatch = useDispatch();

  const sortingDispatch = (items: SortingItem[]) => 
    dispatch({type: 'SORTING_ITEMS', payload: items});

  const setsDispatch = (sets: SetDto[]) => 
    dispatch({type: 'SETS', payload: sets});

  const thisSetDispatch = useCallback((changedSet: Partial<SetDto>) => {
    if(thisSet && sets) {
      setsDispatch(
        sets.map(c => isCurrentSet(c) 
          ? {
            ...thisSet, 
            ...changedSet
          } 
          : c)
      )
    }
  }, [sets, isCurrentSet, thisSet]);

  const setChanger = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name: labelName, value} = e.target;
    thisSetDispatch({[labelName]: value})
  };

  useUnload(e => {
    e.preventDefault();
    e.returnValue = '';
  }, hasChanges);
  
  useEffect(() => {
    props.setCurrentPage('sets');

    if(!isNew) {
      getSet()
    } else if(!thisSet) {
      addSet()
    }

    getSorting()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const getSorting = () => {
    mercheryFetch<SortingItem[]>('sorting', 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      sortingDispatch(res.records)
    })
  }

  const getSet = () => {
    if(isNew) {
      setLoaded(true)
      return false;
    }
    
    const query = querify({
      filters: {id: currentId}
    });

    mercheryFetch<SetDto[]>(`sets/extend?${query}`, 'GET')
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res) || !res.records[0]) return false

      batch(() => {
        setLoaded(true)
        setInitSet(res.records[0])
        setsDispatch(
          sets?.length 
            ? sets.map(c => c.id !== 
              res.records[0].id 
                ? c 
                : res.records[0]) 
            : res.records
        )
      })
    })
  }

  const deleteImage = useCallback(async (module: SetImageModules) => {
    const fieldName: keyof SetDto = module === 'Set' ? 'src' : 'og_src';
    if(!thisSet) return false;

    return mercheryFetch<ImageDeleteResponse>('images', 'DELETE', {
      filters: {
        module_id: thisSet.id,
        module: module
      }
    })
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      thisSetDispatch({[fieldName]: undefined})
    })
  }, [thisSet])

  const sendImage: (module: SetImageModules) => DropzoneFileHandler = (module: SetImageModules) => async (newFiles, dropzoneElement) => {
    const isOgImage = module === 'OgSet';
    const fieldName: keyof SetDto = isOgImage ? 'og_src' : 'src';
    const file = newFiles.at(0);

    if(!thisSet || !file) {
      return undefined
    }

    if(thisSet[fieldName]) {
      await deleteImage(module)
    }

    thisSetDispatch({[fieldName]: {...file}})

    const isVideo = file.type.includes('video');
    const fileURL = URL.createObjectURL(file)

    const imageProps = {
      imageName: file.name,
      order: 1,
      src: fileURL,
      newFile: true,
      videoSource: null,
      videoLink: isVideo ? fileURL : null,
    }

    if(isVideo) {
      const videoFiles: VideoCreate[] = [{
        ...imageProps,
        image: file,
      }]

      await mercheryUploadVideos(videoFiles, thisSet.id, module)
      .then((res: MyResponse<ImageExtended[]>) => {
        if(_isMounted.current && validateResponse(res)) {
          const createdImage = res.records.at(0);
          createdImage && 
            thisSetDispatch({[fieldName]: createdImage})
        }
  
        if(res && 'message' in res) {
          toastUp(res.message);
  
          dropzoneElement &&
            addMessage(dropzoneElement as Element, res.message);
        }
  
        return res
      })
    } else {
      const imageFiles: ImageCreate[] = [{
        ...imageProps,
        image: String(await postBase64(file)),
      }]

      const requestBody =  {
        module_id: thisSet.id,
        newImages: imageFiles,
        cropSizes: ['original', 'medium', 'small'],
        module
      }
  
      await mercheryFetch<ImageExtended[]>('images/create', 'POST', requestBody, {
        withoutToastUp: true
      })
      .then((res) => {
        if(res && 'message' in res) {
          toastUp(res.message);
  
          dropzoneElement &&
            addMessage(dropzoneElement as Element, res.message);
        }
        if(!_isMounted.current || !validateResponse(res)) {
          initSet && thisSetDispatch(initSet)
          return res;
        }
  
        const createdImage = res.records.at(0);
        createdImage &&
          thisSetDispatch({[fieldName]: createdImage})
  
        return res
      })
    }

    // thisSetDispatch({[fieldName]: {...newImage}})

  }
  
  const updateSet = useCallback(async () => {
    if(setsChanges && Object.keys(setsChanges).length && thisSet) {
      await mercheryFetch<SetDto[]>('sets', 'PATCH', {
        toChange: [{
          id: thisSet.id, 
          ...setsChanges
        }]
      })
      .then(res => {
        if(!_isMounted.current) return false;
        if(!validateResponse(res)) {
          toastUp(res.message)

          initSet && thisSetDispatch(initSet)
          return false
        } 

        const upddatedSet = res.records.find(c => c.id === thisSet.id);
        upddatedSet && batch(() => {
          thisSetDispatch(upddatedSet)
          setInitSet(upddatedSet)
        })
      })
    }

    if(setsChanges.og_src) {
      await deleteImage('OgSet')
    }
  }, [setsChanges, deleteImage, initSet, thisSet])

  const createSet = () => {
    mercheryFetch<SetDto>('sets', 'POST', {
      ...thisSet,
    })
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) return false;

      const setsWithoutNew = (sets && sets.filter(c => !c.newSet)) || []

      batch(() => {
        setsDispatch([...setsWithoutNew, res.records])
        setInitSet(res.records)
      })
      return res.records.id
    })
    .then((id) => {
      if(id) {
        history.replace('/app/sets/' + id)
      }
    })
  }

  const addSet = () => {
    if(!sets) {
      return false
    }
    
    const arrayOfOrders = [...sets.map((c) => c.order), 0];
    const maxOrder = Math.max.apply(null, arrayOfOrders);
    const newOrder = maxOrder + 1;

    const newSet: SetDto = {
      id: uuidv4(),
      name: '',
      url: '',
      order: newOrder,
      show_date: null,
      sorting_id: 1,
      template_id: null,
      description: null,
      seo_title: null,
      seo_description: null,
      og_title: null,
      og_description: null,
      products_ids: [],
      products: [],
      newSet: true
    }

    batch(() => {
      setLoaded(true)
      setsDispatch([...sets, newSet])
      setInitSet(undefined)
    })
  }

  const toClients = useMemo(() => ({
    pathname: '/app/sets',
    state: {
      fromSet: +currentId,
    }
  }), [currentId]);

  const cancelBtnHandler = useCallback(() => {
    if(initSet) {
      thisSetDispatch(initSet)
    }
    
    if(isNew) {
      history.push(toClients)
    }

  }, [history, initSet, isNew, thisSetDispatch, toClients])

  return (
    <PageLoading
      loaded={loaded}
    >
      {thisSet ? 
        <div className='collection-page'>
          <div className="collection-page__header">
            <MyButton 
              tabIndex={0} 
              removeDefaultClass
              onClick={() => {
                history.goBack()
              }}
              className="to-products-btn"
            >
              <i className="icofont-simple-left"></i>
            </MyButton>

            <h1>{thisSet.name || 'Новый комплект'}</h1>
          </div>

          <SetSummary
            setChanger={setChanger}
            setDispatch={thisSetDispatch}
            sendImage={sendImage('Set')}
            deleteImage={deleteImage}
            thisSet={thisSet}
          />

          <ProductsInSet 
            set={thisSet}
            setDispatch={thisSetDispatch} 
            isNew={isNew}
          />

          <SeoSection
            changer={setChanger}
            sendImage={sendImage('OgSet')}
            deleteImage={() => deleteImage('OgSet')}
            dispatch={thisSetDispatch}
            item={thisSet}
            disabled={thisSet.newSet}
          />

          <SetSide 
            set={thisSet}
            setDispatch={thisSetDispatch}
          />

          <SetTopPanel hasChanges={hasChanges} 
            promptWhen={_isMounted.current} 
            tabIndex={tabIndex} 
            isNew={isNew} 
            cancelBtnHandler={cancelBtnHandler} 
            createSet={createSet} 
            updateSet={updateSet}          
          />
        </div>
      : <NotFoundLocalApp
        optionalMessage={'Такого комплекта не существует'}
      />}
    </PageLoading>
  );
}

export default SetPage;