import React from "react";
import { Draggable } from "react-beautiful-dnd";
import Grabber from "src/components/_utility-components/grabber";
import TrashBtn from "../../../../../../_utility-components/trash-btn/index";
import { MultiSelectValueName } from 'merchery-lib';
import MultiSelectNameEditing from "./value-name";

interface Props {
  msName: MultiSelectValueName,
  deleteName: () => void,
  index: number
}

function MultiSelectTemplateEditingRow ({
  msName,
  deleteName,
  index
}: Props) {
  return (
    <Draggable
      draggableId={`value-${msName.id}`}
      index={index}
    >
      {(provided, snapshot) =>
        <div
          className={'char-label--editing multiselect-item char-label_sub-label'}// @ts-ignore: Unreachable code error
          valueid={`value-${msName.id}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {provided.dragHandleProps ? 
            <Grabber {...provided.dragHandleProps} /> 
          : null}

          <MultiSelectNameEditing msName={msName} deleteHandler={deleteName} />
          
          <TrashBtn handler={deleteName} />
        </div>
      }
    </Draggable>

  )
}

export default MultiSelectTemplateEditingRow