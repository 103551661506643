import React, { useContext } from 'react';
import { OrderDeliveryContext } from '../delivery';
import {priceWithPennies} from "src/scripts/functions";

export function SelectedDeliveryAddressAndCost() {
  const {
    delivery
  } = useContext(OrderDeliveryContext)

  if(!delivery) {
    return null
  }

  const registered = Boolean(delivery.note || delivery.delivery_service_uuid);
  const isPvz = delivery.point_id && delivery.point_address;

  const isDeliveryCDEK = delivery.type === 9;

  const costUI = !registered 
    ? '—'
    : isDeliveryCDEK 
      ? delivery.register_cost
      : priceWithPennies(delivery.register_cost);

  return <div className="op-delivery-address op-delivery_element-paddings">
    <div>Стоимость</div>

    {isPvz ?
      <div>
        <span>{delivery.point_id}</span>
        {delivery.city} {delivery.point_address}
      </div>
    :
      <div>
        <span>
          {delivery.type !== 1 ? delivery.point_id : null} {delivery.city}
        </span>
        &nbsp;
        {delivery.address}
      </div>
    }

    <div>
      <b>{costUI} ₽</b>
    </div>
  </div>;
}
