import React from 'react';

const UploadImage = ({
  color,
  height = 16,
  width = 16,
}: {
  color?: string,
  height?: number,
  width?: number,
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5475_1129)">
      <path d="M22.125 14.6553V21.1709C22.125 21.6878 21.7044 22.1084 21.1875 22.1084H2.8125C2.29556 22.1084 1.875 21.6878 1.875 21.1709V14.6553H0V21.1709C0 22.7217 1.26169 23.9834 2.8125 23.9834H21.1875C22.7383 23.9834 24 22.7217 24 21.1709V14.6553H22.125Z" fill={color ? color : "#5079E0"}/>
      <path d="M11.9996 0.0166016L6.17383 5.84241L7.49964 7.16823L11.0621 3.60573V18.2174H12.9371V3.60573L16.4996 7.16823L17.8255 5.84241L11.9996 0.0166016Z" fill={color ? color : "#5079E0"}/>
    </g>
    <defs>
      <clipPath id="clip0_5475_1129">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default UploadImage;
