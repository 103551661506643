import React from 'react';
import MaskedInput from 'react-text-mask';
import MyInput, { MyInputProps } from "../input/input";

interface MyMaskedInputProps extends MyInputProps {
	mask: (string | RegExp)[];
}

const MyMaskedInput = ({
	value,
	onChange,
	mask,
	inputRef,
	...props
}: MyMaskedInputProps) => {
	return (
		<MaskedInput
			mask={mask}
			guide={false}
			value={value || ''}
			onChange={onChange}
			render={(ref, {defaultValue, ...maskedProps}) => {
				return (
					<MyInput
						{...props}
						{...maskedProps}
						value={value}
						inputRef={(input: HTMLInputElement) => {
							ref(input);
						}}
					/>
				)
			}}
		/>
	);
};

export default MyMaskedInput;
