import React, { useEffect, useMemo, useRef, useState } from 'react';
import useMounted from 'src/scripts/hooks/use-mounted';
import { PossibleValuesPopup, usePossibleValues } from 'src/scripts/hooks/use-possible-values';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { ExtendedProduct, AnalogProduct } from 'merchery-lib';
import MyInput from 'src/components/_utility-components/input';
import { batch, useDispatch } from 'react-redux';
import Bubbles from 'src/components/_utility-components/bubbles';
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { validateResponse } from 'src/scripts/functions';
import FindProductsInCatalog from './find-products-in-catalog';
import isEqual from 'lodash/isEqual';

function AnalogProducts() {
  const _isMounted = useMounted();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const product = useAppSelector(state => state.product);

  const dispatch = useDispatch();
  const changeProduct = (changes: Partial<ExtendedProduct>) => 
    dispatch({ type: 'PRODUCT_ITEM', payload: {...product, ...changes}})

  const [analogProducts, setAnalogProducts] = useState<AnalogProduct[]>(product?.analogProducts || [])

  const {setSearch, search, setShowData, dataLoading, data, showData} = usePossibleValues<ExtendedProduct>({
    urlPath: 'products',
  });

  useEffect(() => {
    if(product?.analogProducts) {
      const isLocalHasDifference = product.analogProducts && !isEqual(analogProducts, product.analogProducts)
      if(isLocalHasDifference) {
        setAnalogProducts(product.analogProducts)
      }
    }
  }, [product])

  const notIncludedYetData = useMemo(() => {
    const removedIncludedAndThisProduct = data.filter(possibleProduct => 
      possibleProduct.id !== product?.id &&
      !analogProducts.some(added => 
        added.id === possibleProduct.id))

    return removedIncludedAndThisProduct
  }, [analogProducts, data, product])

  const addAnalogProduct = async (analogProduct: ExtendedProduct | ExtendedProduct[]) => {
    if(!analogProduct || !product) {
      return false
    }

    const analogIds = 
      Array.isArray(analogProduct) 
        ? analogProduct.map(item => item.id) 
        : [analogProduct.id]
    
    const res = await mercheryFetch<AnalogProduct[]>('products/add-analog-product', 'PATCH', {
      product_id: product.id,
      analog_product_id: analogIds,
    })

    batch(() => {
      if(_isMounted.current && validateResponse(res)) {
        setSearch('')
        setShowData(false)
        changeProduct({
          analogProducts: res.records
        })
      }
    })

    return true
  };

  const removeAnalogProductFromProduct = async (analogProduct: AnalogProduct) => {
    if(!analogProduct || !product) {
      return false
    }

    const res = await mercheryFetch<AnalogProduct[]>('products/remove-analog-product', 'PATCH', {
      product_id: product.id,
      analog_product_id: analogProduct.id,
    })

    
    if(_isMounted.current && res && validateResponse(res)) {
      changeProduct({
        analogProducts: res.records
      })
    }
  };

  if(!product || product.newProduct) {
    return null
  }

  return (
    <div id='analog-products' className='product-page-element product-page__analog-products'>
      <h3 className='product-page-h3'>Похожие товары</h3>
      
      <div className='product-page-element__find-products'>
        <MyInput
          inputRef={inputRef}
          value={search}
          myClassName='product-page-element__find-products__search-input'
          placeholder='поиск по наименованию'
          onChange={(e) => setSearch(e.target.value)}
        />

        <FindProductsInCatalog handler={addAnalogProduct}/>
      </div>
      
      <PossibleValuesPopup<ExtendedProduct>
        clickHandler={addAnalogProduct}
        dataLoading={dataLoading}
        search={search}
        data={notIncludedYetData}
        showData={showData}
        setShowData={setShowData}
        className='related-products__possible-values'
        changingDirection={false}
      />

      <Bubbles<AnalogProduct>
        deleteHandler={removeAnalogProductFromProduct}
        items={analogProducts}
        linkConfig={{
          pathname: (item) => `/app/products/${item.id}`
        }}
      />
    </div>
  );
}

export default AnalogProducts;