import { getISOByParam } from 'iso-country-currency'
import React from 'react'
import { MoySkladPrice } from 'merchery-lib'

export default function MsProductsPrices ({prices}: {prices: MoySkladPrice[]}) { 
  return (
    <>
      {
        Array.isArray(prices) ? prices.map((p) => {
          let {value, currency} = p
          value = typeof value === 'number' ? value / 100 : value
          const currencyIsoCode = currency.isoCode
          const regionIso = getISOByParam('currency', currency.isoCode)
          const localCurrency = new Intl.NumberFormat(`ru-${regionIso}`, {style: 'currency', currency: currencyIsoCode})
          const formattedLocalCurrency = localCurrency.format(value)

          return formattedLocalCurrency
        }).join('; ') : null
      }
    </>
  )
}