import React from 'react';
import {dateToRuLocale} from "src/scripts/utils/date-to-locale";

interface DeliveryDateRowProps {
	status: string;
	date: Date | null;
}

const DeliveryDateRow: React.FC<DeliveryDateRowProps> = ({ status, date }) => {
	return (
		<div className='refund-delivery__row refund-received'>
			<div className='status'>{status}</div>
				<div className='date'>{date ? dateToRuLocale(date) : ''}</div>
			</div>
	);
};

export default DeliveryDateRow;
