import {MouseEvent} from "react";
import {useAppSelector} from "../pre-type/use-selector";

export default function useUrlToClipBoard (pathname: unknown) {
	return (e: MouseEvent) => {
		const domain = useAppSelector(state => state.settings.find(s => s.callname === 'site_front_domain'));

		e.preventDefault();
		if(domain && typeof pathname === 'string') {
			navigator.clipboard.writeText(domain.value + '/app/collections/' + pathname);
		}
	}
}