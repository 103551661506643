import React from 'react';
import MyButton from '../../../_utility-components/button/button';
import { ProductFilters } from '../dto/products-filters.dto';
import { Id } from 'merchery-lib';
import useProductsFilters from "../hooks/use-products-filters";

export const FilterSelectElement = ({
  text, num, statusName,
}: {
  text: string;
  num: Id;
  statusName: keyof ProductFilters;
}) => {
  const [productFilters, updateFilters, ] = useProductsFilters();

  if (!productFilters) {
    return null;
  }

  const thisFilter = productFilters[statusName]
  const thisElement = thisFilter && thisFilter.find(f => f.id == num);
  const activeClassName = thisElement?.selected ? 'active' : '';

  return (
    <MyButton
      tabIndexLevel={3}
      removeDefaultClass
      onClick={() => updateFilters({
        itemIds: [num],
        filterName: statusName
      })}
      className={`popup-element ${activeClassName}`}
    >
      <div className="checkbox">
        <i className="icofont-check-alt"></i>
      </div>
      <span>{text}</span>
    </MyButton>
  );
};
