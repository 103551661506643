import React, {memo, useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import { Id, CharGroupsDto, CharsLabelDto, ProductsAttrValues } from 'merchery-lib';
import CharGroupDeletePopup from './char-modules/confirm-popup';
import CharFooter from './char-modules/footer';
import OneCharHeader from './char-modules/header';
import Labels from './char-modules/labels';
import CharOptionSwitcher from './char-option-switcher';
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";
import {
  OneCharContext, withOneCharLogic
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/one-char-logic";
import {batch, useDispatch} from "react-redux";
import {useAppSelector} from "src/scripts/pre-type/use-selector";

export interface OneCharProps {
  thisCharGroup: CharGroupsDto | null
}

function OneChar(props: OneCharProps) {
  const newAddedLabels = useRef<CharsLabelDto[]>([]);

  const {
    thisCharIsEditing,
    selectedOption,
    toDelete,
    charLabels,
    thisCharGroup,
  } = useContext(OneCharContext);

  const {
    scopes,
    setScopes,
  } = useContext(CharacteristicsContext);

  const allLabels = useAppSelector(state => state.labels);
  const labelsReducerType = 'CHARS_LABELS';
  const dispatch = useDispatch();
  const setLabels = (value: CharsLabelDto[]) =>
    dispatch({type: labelsReducerType, payload: value})

  useEffect(() => {
    const newLabels = charLabels.filter(l => l.newLabel)

    if(newLabels.length > newAddedLabels.current.length && thisCharGroup) {
      const lastNewAddedLabel = newLabels[newLabels.length - 1]
      const newLabelSelector = `[valueid='group-${thisCharGroup.id}_label-${lastNewAddedLabel.order}'] .char-label__header--editing .merchery-label__input`
      const newLabelInDOM = document.querySelector<HTMLInputElement>(newLabelSelector)
      newLabelInDOM?.focus()
      newAddedLabels.current = newLabels
    }
  }, [charLabels])

  const charClassName = useMemo(() =>
    `characteristic ${thisCharIsEditing ? `characteristic--editing` : ''}`
  , [thisCharIsEditing]);

  const removeLabelAndDispatch = useCallback((labelForRemove: CharsLabelDto) => {
    batch(() => {
      newAddedLabels.current = newAddedLabels.current.filter(label => label.id !== labelForRemove.id)
      setLabels(allLabels.filter(label => label.id !== labelForRemove.id))
      if(labelForRemove.newLabel) {
        setScopes(scopes.filter(scope => scope.label_id !== labelForRemove.id))
      }
    })
  }, [allLabels, scopes, setLabels, setScopes]);

  const showOptionsSwitcher = !thisCharIsEditing &&
    selectedOption?.values?.length;

  return (
    <div className={charClassName}>
      <OneCharHeader />
      
      {showOptionsSwitcher ? 
        <CharOptionSwitcher />
      : null}
      
      <Labels
        removeLabelAndDispatch={removeLabelAndDispatch}
      />

      {thisCharIsEditing ? (
        <CharFooter />
      ) : null}

      {toDelete ? 
        <CharGroupDeletePopup />
      : null}
    </div>
  );
}

export interface SelectOption {
  id: Id | null
  text: string
  values: (ProductsAttrValues | undefined)[]
}

const OneCharWithLogic = withOneCharLogic(OneChar);

export default memo(OneCharWithLogic);