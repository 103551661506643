import React, { useEffect, useState } from 'react';
import { ExtendedProductStoriesItem, NonNullStories } from 'merchery-lib';
import Grabber from 'src/components/_utility-components/grabber';
import TrashBtn from 'src/components/_utility-components/trash-btn';
import MyInput from 'src/components/_utility-components/input';
import { useDispatch } from 'react-redux';
import ProductStoriesItemImage from './stories-item-image';
import { DraggableProvided } from 'react-beautiful-dnd';
import ColorPickerInput from 'src/components/_utility-components/color-picker-input';

interface IProps {
  item: ExtendedProductStoriesItem,
  stories: NonNullStories,
  dragHandleProps: DraggableProvided['dragHandleProps'],
}

function StoriesItem({ item: thisItem, stories, dragHandleProps }: IProps) {
  const [name, setName] = useState<string | null>(thisItem.name);
  const [color, setColor] = useState<string | null>(thisItem.color);
  const [description, setDescription] = useState<string | null>(thisItem.description);

  const dispatch = useDispatch()
  const itemDispatch = (changes: Partial<ExtendedProductStoriesItem>) => 
    dispatch({ 
      type: 'PRODUCT_STORIES', 
      payload: {
        ...stories,
        items: stories.items.map(arrayItem => 
          arrayItem.id === thisItem.id ? {
            ...thisItem,
            ...changes,
          } : arrayItem)
      }
    });

  useEffect(() => {
    const changes = {
      ...(name !== thisItem.name && { name }),
      ...(color !== thisItem.color && { color }),
      ...(description !== thisItem.description && { description }),
    }

    if(Object.values(changes).length)
      itemDispatch(changes)

  }, [name, color, description])

  useEffect(() => {
    if(thisItem.name !== name)
      setName(thisItem.name)

    if(thisItem.color !== color)
      setColor(thisItem.color)

    if(thisItem.description !== description)
      setDescription(thisItem.description)

  }, [thisItem])
    
  const deleteItemHandler = () => {
    const itemsWithoutRemoved = 
      [...stories.items]
        .sort((a, b) => a.order - b.order) 
        .filter(arrayItem => 
          thisItem.id !== arrayItem.id)
        .map(item => {
          if (item.order > thisItem.order) {
            return {...item, order: item.order - 1 };
          }
          return item;
        });

    dispatch({ 
      type: 'PRODUCT_STORIES', 
      payload: {
        ...stories,
        items: itemsWithoutRemoved
      }
    });
  }

  return (
    <>
      {dragHandleProps ? 
        <Grabber 
          className='product-stories-item__grabber' 
          {...dragHandleProps} /> 
      : null}

      <ProductStoriesItemImage 
        item={thisItem} 
        itemDispatch={itemDispatch}
      />
      
      <MyInput 
        myClassName={'product-stories-item__input product-stories-item__name'}
        name={'name'}
        onChange={e => setName(e.target.value)}
        value={name || ''}
      >
        <h4 className="header-font-s">Заголовок</h4>
      </MyInput>

      <ColorPickerInput 
        className={'product-stories-item__input product-stories-item__color'}
        onChange={setColor}
        colorValue={color}
      />

      <MyInput 
        myClassName={'product-stories-item__input product-stories-item__description'}
        name={'description'}
        onChange={(e) => setDescription(e.target.value)}
        value={description || ''}
        type='textarea'
      >
        <h4 className="header-font-s">Текст</h4>
      </MyInput>

      <TrashBtn 
        className='product-stories-item__delete-btn'
        handler={deleteItemHandler}
      />
    </>
  );
}

export default StoriesItem;