import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import ActualizeButton from 'src/components/_utility-components/actualize-button';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { SortableDataConfig } from '../../../scripts/hooks/use-sortable-data';
import RestoreButton from '../../_utility-components/restore-button';
import { ExtendedClientDto } from 'merchery-lib';


export function ClientHeader() {
  const match = useRouteMatch<{id: string}>();
  const currentClientId = +match.params.id;
  const clients = useAppSelector(state => state.clients);
  const currentClient = useMemo(() => clients && clients.find(c => c.id === currentClientId), [clients, currentClientId]);
  const location = useLocation<{config: SortableDataConfig<ExtendedClientDto>}>()

  const dispatch = useDispatch();
  const clientsDispatch = (clients: ExtendedClientDto[]) => dispatch({type: 'CLIENTS', payload: clients});

  const thisClientDispatch = (client: ExtendedClientDto) => clientsDispatch(clients?.length ? clients.map(c => c.id !== client.id ? c : client) : [client])

  const toClients = useMemo(() => ({
    pathname: '/app/clients',
    state: { 
      fromClient: currentClientId,
      config: location?.state?.config
    }
  }), [currentClientId, location])

  const dispatchHandler = (client: ExtendedClientDto) => {
    thisClientDispatch(client)
  }

  if(!currentClient) {
    return null
  }

  return <div className="client-page__header">
    <div className='client-page__header__left-inner'>
      <Link
        tabIndex={0}
        to={toClients}
        className="to-products-btn"
      >
        <i className="icofont-simple-left"></i>
      </Link>

      <h1>{currentClient.name}</h1>

      {currentClient.deletedAt ? 
        <div 
          className='wide-text-font-xxs' 
          style={{
            padding: '4px 25px',
            background: '#d75a5a',
            textTransform: 'uppercase',
            borderRadius: '4px',
            color: 'white'
          }}
        >
          удален
        </div>
      : null}
    </div>

    <div className='client-page__header__right-inner'>
      {currentClient.deletedAt ?
        <RestoreButton<ExtendedClientDto>
          restoreUrlPath={`client/restore/${currentClientId}`}
          dispatchHandler={dispatchHandler} />
      : 
        <ActualizeButton<ExtendedClientDto>
          actualizeUrlPath={`client/actualize/${currentClientId}`}
          dispatchHandler={dispatchHandler} />
      }
    </div>
  </div>;
}
