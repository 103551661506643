import React, {ReactNode, useEffect, useMemo, useState,} from 'react';
import ReactDOM from "react-dom";
import SelectProductCategory from "../../product-page-modules/select-product-category";
import MyCheckbox from "../../../../_utility-components/checkbox";
import MyButton from "../../../../_utility-components/button/button";
import {usePopup} from "src/scripts/hooks/use-popup";
import {useAppSelector} from "../../../../../scripts/pre-type/use-selector";
import {useLoad} from "src/scripts/hooks/use-load";
import { Id } from 'merchery-lib';

export const visibilityOptions = [
	{
		id: 1,
		text: 'Видимый',
	},
	{
		id: 2,
		text: 'Скрытый',
	},
	{
		id: 3,
		text: 'Сохранить текущий'
	}
];

export type MultiProductsChangeHandler = (
	activeVisibilityStatus: {id: Id, text: string},
	value: {top?: Id | undefined},
	callback: (state: boolean) => void,
) => void

export default function MultiProductsChangePopup ({
	children,
	onChange,
	btnText,
}: {
	onChange: MultiProductsChangeHandler
	children: ReactNode | ReactNode[] | null,
	btnText: ReactNode,
}) {
	const products = useAppSelector(state => state.products)
	const selectedProductsIds = useAppSelector(state => state.selectedProducts);
	const {
		RenderPopup, RenderButton, closePopup, isOpen,
	} = usePopup();
	const [, setLoad] = useLoad();

	const selectedProducts = useMemo(() =>
		products.filter( p =>
			selectedProductsIds.some(
				id =>	p.id === id
			)
		)
	, [products, selectedProductsIds])

	const isValueTheSame = useMemo(() =>
		selectedProducts.every(
			item =>
				item.top === selectedProducts[0].top
		)
	, [selectedProducts])

	const averageElement = useMemo(() => ({
		top: selectedProducts?.length && isValueTheSame ? selectedProducts?.[0].top : undefined
	}), [isValueTheSame, selectedProducts])

	const [value, setValue] = useState<{top?: Id | undefined }>(averageElement)
	const [activeVisibilityStatus, setActiveVisibilityStatus] = useState(visibilityOptions[2]);
	const onChangeCallback = (state: boolean) => {
		setLoad(false)
	}

	const saveHandler = () => {
		setLoad(true)
		onChange(activeVisibilityStatus, value, onChangeCallback)
	}

	useEffect(() => {
		setValue(averageElement)
	}, [averageElement])

	const popupClassName = `confirm-popup fixed-on-center products_replace-and-copy_popup`;

	return (
		<>
			<RenderButton
				removeDefaultClass={true}
				className="flex-center blue-color header-font-s"
				style={{gap: '4px'}}
			>
				{btnText}
			</RenderButton>

			{ReactDOM.createPortal(
				<RenderPopup
					className={popupClassName}
					tabIndexDeep={3}
					withCloseBtn
				>
          <div className='products_replace-and-copy_popup_inner'>
            <h2 className={'header-font-xl'}>Перемещение товаров</h2>

            <div className={'products_replace-and-copy_popup_body'}>
              <SelectProductCategory
                selectedCategoryId={value.top}
                topChangeHandler={setValue}
                tabIndexLevel={3}
                headerText={'Категория для переноса'}
              />

              {children}

              <div>
                <h3 className={'header-font-m'} style={{marginBottom: '12px'}}>
                  Установить статус
                </h3>

                {visibilityOptions.map((option) => {
                  const isActive = activeVisibilityStatus.id === option.id;
                  return <MyCheckbox
                    condition={isActive}
                    key={option.id}
                    stylePreset={'inner-point'}
                    isRadio={true}
                    actionHandler={() => setActiveVisibilityStatus(option)}
                    text={option.text}
                  />
                })}
              </div>

              <div>
                Обратите внимание, что все характеристики товаров, отсутствующие в целевой категории,
                <span className={'red-color'}> будут удалены</span>.
              </div>
            </div>
          </div>

					<div className="flex-wrapper confirm-popup_footer--with-btns">
						<MyButton
							className={'white-btn header-font-s'}
							onClick={closePopup}
						>
							Отменить
						</MyButton>

            <MyButton
              removeDefaultClass
              className="blue-btn header-font-s"
              onClick={saveHandler}
            >
              Сохранить
            </MyButton>
					</div>
				</RenderPopup>
			, document.body)}
		</>
	);
}