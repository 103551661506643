import { ProductOption } from 'merchery-lib';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toastUp } from '../../../../scripts/functions';
import MyButton from '../../../_utility-components/button/button';
import { withDraggableParentTag } from './one-option-modules/conditiaonal-draggable';
import { ProductOneOptionHeader } from './one-option-modules/header';
import { ProductOneOptionValues } from './one-option-modules/values';
import { UpdateOptionsHandler } from './options';

export interface OneOptionProps {
  elementIndex: number,
  option: ProductOption,
  updateOption: UpdateOptionsHandler,
};

export const OneOptionContext = React.createContext<{
  edit: boolean;
  setEdit: (value: boolean) => void,
  option: ProductOption | null;
  changeOption: (changes: Partial<ProductOption>) => void;
  warning: boolean;
  setWarning: (value: boolean) => void;
  needCallPossibleValues: boolean;
  setNeedCallPossibleValues: (value: boolean) => void;
  updateOption: UpdateOptionsHandler
}>({
  edit: false,
  setEdit: () => {},
  option: null,
  changeOption: () => {},
  warning: false,
  setWarning: () => {},
  needCallPossibleValues: false,
  setNeedCallPossibleValues: () => {},
  updateOption: () => false
})

function ProductOneOption({
  elementIndex,
  option, 
  updateOption,
}: OneOptionProps) {
  const [warning, setWarning] = useState(false);
  const [edit, setEdit] = useState<boolean>(Boolean(!option.title))
  const [previewOption, setPreviewOption] = useState<ProductOption>(option);
  const [needCallPossibleValues, setNeedCallPossibleValues] = useState(false)

  useEffect(() => {
    if(warning) {
      setTimeout(() => {
        setWarning(false)
      }, 8000);
    }
  }, [warning])

  useEffect(() => {
    if(!edit) {
      updateOption(option.id, {...previewOption, notSaved: false})
    }
  }, [edit])

  useEffect(() => {
    setPreviewOption(option)
  }, [option])

  const changeOption = (changes: Partial<ProductOption>) => {
    setPreviewOption({...previewOption, ...changes})
  }

  const className = `product-page-additional-options-one-option ${
    warning 
      ? 'product-page-additional-options-one-option--warning' 
      : ''
  }`;

  return (
    <OneOptionContext.Provider value={{
      edit,
      setEdit,
      changeOption,
      setNeedCallPossibleValues,
      needCallPossibleValues,
      option: previewOption,
      warning,
      setWarning,
      updateOption,
    }}>
      <ConditionalDraggable
        isDraggable={!option.notSaved}
        dragClassName={className}
        dragProps={{
          draggableId: '' + option.order,
          isDragDisabled: option.notSaved || edit,
          index: elementIndex
        }}
      />
    </OneOptionContext.Provider>
  );
}

const ConditionalDraggable = withDraggableParentTag(({
  provided,
}) => {
  const {
    option,
    edit,
    setEdit,
    setWarning,
    warning,
    updateOption,
  } = useContext(OneOptionContext);
  const titleInput = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if(!option?.title) {
      setTimeout(() => {
        titleInput.current?.focus()
      }, 0)
    }
    return () => {}
  }, []);

  const deleteOption = () => option && updateOption(option.id);

  const saveOptions = () => {
    if(!option) {
      return false
    }

    const noTitle = !option.title;
    const noValues = !option.values?.length;
    const duplicates = ( new Set(option.values.map(v => v.value)) ).size !== option.values.length;
    const emptyValues = option.values.some((v) => !v?.value);

    if(noTitle || noValues || duplicates || emptyValues) {
      toastUp(
        noTitle ? 'Название обязательно' : 
        noValues ? 'Опция без значений недопостима' : 
        duplicates ? 'Повторяющиеся значения недопустимы' : 
        'Пустые значения недопостимы'
      )

      if(noTitle) {
        titleInput.current?.focus()
      } else if(noValues) {
        (document.querySelector(`.product-page-additional-options-one-option-values-blank-value .merchery-label__input`) as HTMLElement)?.focus()
      }

      setWarning(true)
      return false
    }

    if(warning) setWarning(false)
    // updateOption(option.id, {...option, notSaved: false})
    setEdit(false)
  }

  return (
    <>
      <ProductOneOptionHeader
        titleInput={titleInput}
        deleteOption={deleteOption}
        dragProvided={provided}/>

      <ProductOneOptionValues/>

      {edit ? (
        <div className='one-option__save-btn__wrapper'>
          <MyButton
            className={'white-btn'}
            onClick={saveOptions}
          >
            Применить
          </MyButton>
        </div>
      ) : null}
    </>
  )
})

export default ProductOneOption;