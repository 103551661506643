import React from 'react';
import {useAppSelector} from 'src/scripts/pre-type/use-selector';
import {priceToPennies, priceWithPennies} from 'src/scripts/functions';
import {useTabIndex} from 'src/scripts/hooks/use-tabindex';
import MsProductsPrices from '../../../integrations-modules/moysklad/ms-products-currency';
import MyInput from '../../../_utility-components/input/index';
import { ExtendedProduct } from "merchery-lib";
import {ProductInputHandler} from '../product';
import {SwitchHeaders} from './labels';
import {ProductVat} from "src/components/main-pages/products/product-page-modules/product-vat";

function ProductMainLabels({
  inputHandler,
}: {
  inputHandler: ProductInputHandler
}) {
  const tabIndex = useTabIndex()
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)
  const product = useAppSelector(state => state.product);

  if(!product) {
    return null
  }

  const priceOldOrDiscount = product.price_old_or_discount

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, label: keyof ExtendedProduct, type?: string) => {
    if(label === 'price') {
      const typedValue = priceToPennies(Number(e.target.value)) as number
      inputHandler(label, typedValue)
      return false
    }

    let typedValue = type === 'number' ? Number(e.target.value) : e.target.value
    inputHandler(label, typedValue)
  }

  const discountVariants = [
    'price_old',
    'discount'
  ] as const;

  const labelsClass = moySkladIntegrationOn
    ? 'ms-product-main-label'
    : 'product-page-main-labels';

  const className = `product-page-element ${labelsClass} top-bottom_paddings side-padding-24`;

  return (
    <div className={className}>
      <div className={`inputs-row name`}>
        <h4 className="header-font-s">Название товара</h4>
        <MyInput
          value={product.name}
          required
          noPlaceholder
          tabIndex={tabIndex}
          shortInput={true}
          min={0}
          maxLength={256}
          onChange={(e) => onChangeHandler(e, 'name')}
        />
      </div>

      <div className={`inputs-row price`}>
        <h4 className="header-font-s">Цена товара</h4>
        {typeof product.price === 'number' || typeof product.price === 'string' ?
          <MyInput
            value={priceWithPennies(product.price) || 0}
            maxLength={22}
            myClassName='placeholder--animation-off'
            placeholder={'₽'}
            tabIndex={tabIndex}
            required={false}
            shortInput={true}
            min={0}
            placeholderClass={'currency-pt-sans placeholder--pos-right-16'}
            type={'number'}
            onChange={(e) => onChangeHandler(e, 'price', 'number')}
          />
          : <MsProductsPrices prices={product.price}/>}
      </div>

      <div className={`inputs-row discount`}>
        <SwitchHeaders
          switchHeaderHandler={(discountVariant: "discount" | "price_old") => inputHandler('price_old_or_discount', discountVariant)}
          price_old_or_discount={product.price_old_or_discount}
        />
        <MyInput
          value={product[product.price_old_or_discount] !== null ? product[product.price_old_or_discount] : ''}
          maxLength={22}
          tabIndex={tabIndex}
          shortInput={true}
          required={false}
          type={'number'}
          myClassName='placeholder--animation-off'
          placeholderClass={'currency-pt-sans placeholder--pos-right-16'}
          placeholder={priceOldOrDiscount === discountVariants[0] ? '₽' : '%'}
          min={0}
          onChange={(e) => onChangeHandler(e, 'discount', 'number')}
        />
      </div>

      <div className={`inputs-row purchase-price`}>
        <h4 className="header-font-s">Закупочная цена</h4>
        <MyInput
          value={product.purchase_price !== null ? product.purchase_price : ''}
          maxLength={22}
          myClassName='placeholder--animation-off'
          tabIndex={tabIndex}
          shortInput={true}
          min={0}
          placeholder={'₽'}
          placeholderClass={'currency-pt-sans placeholder--pos-right-16'}
          required={false}
          type={'number'}
          onChange={(e) => onChangeHandler(e, 'purchase_price', 'number')}
        />
      </div>

      <ProductVat inputHandler={inputHandler}/>
    </div>
  );
}

export default ProductMainLabels;