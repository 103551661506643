import React, { memo, useEffect, useState } from 'react';
import { priceToPennies, priceWithPennies } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import useRouteId from '../../../../../scripts/hooks/use-route-id';
import MyButton from '../../../../_utility-components/button/button';
import MyInput from '../../../../_utility-components/input/index';
import { OrderChangeHandler } from '../../order-page';
import {batch} from "react-redux";

interface Props {
  changeData: OrderChangeHandler
}

function Discount ({
  changeData,
}: Props) {
  const {RenderButton, RenderPopup, closePopup} = usePopup();

  const orders = useAppSelector(state => state.orders)
  const orderId = useRouteId('orderid')
  const order = orders.find(o => o.id === orderId);

  const [isPercent, setIsPercent] = useState<boolean>(order?.percentage_discount || true);
  const [initialDiscount, setInitialDiscount] = useState(order?.discount || null)
  const [discount, setDiscount] = useState<number | null>(initialDiscount);

  const percentValues = [3, 5, 7, 10];
  const rubleValues = [30000, 50000, 100000, 200000];
  const shownValues = isPercent
    ? percentValues
    : rubleValues;

  useEffect(() => {
    if(order && order?.discount !== discount) {
      setDiscount(order.discount)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.discount])

  useEffect(() => {
    if(order && order?.percentage_discount !== isPercent) {
      setIsPercent(order.percentage_discount || false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.percentage_discount])

  const discountSwitch = () => {
    setDiscount(null);
    setIsPercent(!isPercent)
  };

  const discountInputChange = (value: number | null) => {
    if(!order || !order.total) {
      return false
    }

    const maxValue = isPercent ? 100 : priceWithPennies(order.total) || 0;

    if(value !== null) {
      value = +value > maxValue ? maxValue : value;
    }

    setDiscount(isPercent ? value : priceToPennies(value))
  }

  const saveHandler = () => {
    changeData({
      discount,
      ...(order && isPercent !== order.percentage_discount && {
        percentage_discount: isPercent,
      })
    })
    .then((bool) => {
      if(bool) {
        batch(() => {
          setInitialDiscount(discount)
          closePopup()
        })
      }
    });
  };

  return (
    <div className='order-discount__popup'>
      <RenderButton
        className={`white-blue-btn hide-for-print discount-promo ${!initialDiscount ? 'disabled' : ''}`}>

        {initialDiscount
          ? <>Скидка {isPercent ? initialDiscount : priceWithPennies(initialDiscount)} {isPercent ? '%': ''}</>
          : 'Сделать скидку'}
      </RenderButton>

      <RenderPopup
        className={'fixed-on-center discount'}
        withBlackout
        withCloseBtn
        tabIndexDeep={2}
      >
        <div className="discount-promo-header discount">
          <h2>Скидка на заказ</h2>
        </div>

        <div className="discount-promo-set-discount-promo">
          <div className="discount-promo-set-discount-promo-top">
            <MyInput 
              onChange={(e) => discountInputChange(+e.target.value || null)} 
              value={!discount
                ? ""
                : isPercent
                  ? discount
                  : priceWithPennies(discount)
              }
              name="discount" 
              placeholder="Размер скидки" 
              withFocus={false} 
              withBlur={false} 
            />

            <MyButton 
              className={`discount-by percent ${isPercent ? "blue-btn" : "white-btn"}`} 
              onClick={discountSwitch}
            >
              %
            </MyButton>

            <MyButton 
              className={`discount-by currency ${!isPercent ? "blue-btn" : "white-btn"}`} 
              onClick={discountSwitch}
            >
              ₽
            </MyButton>
          </div>

          <div className="discount-promo-set-discount-promo-bottom discount">
            {shownValues.map((num) => 
              <MyButton 
                key={num}
                value={num} 
                className={`set-discount-btn ${discount && num === discount ? 'active' : ''}`} 
                onClick={() => setDiscount(num)}>
                {isPercent
                  ? num
                  : priceWithPennies(num)
                }
              </MyButton>
            )}

            <MyButton 
              className="discount-clean" 
              onClick={() => setDiscount(null)}>
              Удалить скидку
            </MyButton>
          </div>
        </div>
        <div className="discount-promo-btns">
          <MyButton 
            className="white-btn" 
            onClick={closePopup}>
            Отменить
          </MyButton>
          
          <MyButton 
            id="confirm-discount" 
            className="blue-btn" 
            onClick={saveHandler}
          >
            Сохранить
          </MyButton>
        </div> 
      </RenderPopup>
    </div>
  );
}

export default memo(Discount)