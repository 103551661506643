import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyCheckbox from '../../../../../_utility-components/checkbox/my-checkbox';
import DragElement from './drag-element';

function CellCheckbox({
  productCheckHandler,
  provided,
  checked,
}: {
  checked: boolean,
  provided?: DraggableProvided
  productCheckHandler: () => void
}) {
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

  return (
    <div className="op-check hide-for-print">
      {!moySkladIntegrationOn && provided ? (
        <DragElement provided={provided}/>
      ) : null} 

      <MyCheckbox condition={checked} 
        actionHandler={(e) => {
          // if(e.shiftKey) {
          //   e.preventDefault()
          //   return false
          // }
          productCheckHandler()
        }}        
      />
    </div>
  );
}

export default CellCheckbox;