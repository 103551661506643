import React from 'react';
import { Link } from 'react-router-dom';
import history from '../../scripts/history';
import { useAppSelector } from '../../scripts/pre-type/use-selector';
import { PageItem } from './navbar';

export interface NavBarPageProps {
  pagesItems: PageItem[];
  tabIndex: number;
  popupClose: (() => void) | undefined;
  parentItem?: PageItem
}

export function NavBarPage({ 
  pagesItems, 
  tabIndex, 
  popupClose,
  parentItem
}: NavBarPageProps) {
  const prefix = useAppSelector(state => state.staticValues.router_link_prefix || '')
  const currentPage = useAppSelector(state => state.currentPage);

  return <ul>
    {pagesItems.map((page => {
      if (page.hide || (parentItem && page.link === parentItem.link)) {
        return null;
      }

      const pageOrSubPageActive = page?.subPages?.length && [
        page.link,
        ...page.subPages.map(p => p.link)
      ]
      .some(link =>
        history.location.pathname.includes(prefix + '/' + link)
      );

      const isActive = `${prefix}/${page.link}` === currentPage
      // const isActive = `${prefix}/${page.link}` === currentPage || (page.subPages && page.subPages.some(subPage => `${prefix}/${page.link}/${subPage.link}` === currentPage));

      const activeClass = isActive ? 'active' : '';

      return (
        <li key={page.link}>
          <Link
            tabIndex={tabIndex}
            className={`merchery-navbar-item-${page.className} ${activeClass}`}
            to={`/app/${page.link}`}
            onClick={() => popupClose?.()}
          >
            <i className={`icofont-${page.icon}`}></i>
            {page.text}
            {page?.children || null}
          </Link>

          {page.subPages && (pageOrSubPageActive || isActive) ?
            <NavBarPage
              parentItem={page}
              tabIndex={tabIndex}
              pagesItems={page.subPages}
              popupClose={popupClose}
            />
          : null}
        </li>
      );
    }))}
  </ul>
}
