import React, {useContext} from 'react';
import { batch, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id, Category } from "merchery-lib";
import triangleDown from '../../../../img/triangle-down.png';
import { usePopup } from 'src/scripts/hooks/use-popup';
import { useTabIndex } from 'src/scripts/hooks/use-tabindex';
import SearchInput from '../../../_utility-components/search-input/index';
import CategoriesFilterAndControl, { CategoryFilterChanger } from '../../categories/categories-filter-and-control';
import { ProductFilters } from '../dto/products-filters.dto';
import { FiltersPopup } from './filters';
import useProductsFilters from "../hooks/use-products-filters";
import MyButton from 'src/components/_utility-components/button/button';
import { ProductsTableContext } from './table';
import {ProductsContext} from "src/components/main-pages/products/products";

const ProductsSearchBar = () => {
  const tabIndex = useTabIndex()
  const searchInput = useAppSelector(state => state.searchInput);

  const dispatch = useDispatch()
  const searchInputDispatch = (text: string) => dispatch({ type: 'PRODUCTS_SEARCH_INPUT_VALUE', payload: text})

  return (
    <div className={`search-and-filters-bar search-state`}>
      <SearchInput
        searchInput={searchInput}
        applySearch={searchInputDispatch}
        tabIndex={tabIndex}
      />
      
      <SearchBarButtons />
    </div>
  )
}

// interface ReplaceAndCopyPopupProps {
//   popupOpened: number
//   popupClose: () => void
//   categoriesAsOptions: []
// }

// const ReplaceAndCopyPopup = ({
//   popupOpened,
//   popupClose,
//   categoriesAsOptions,
// }: ReplaceAndCopyPopupProps) => {
//   const tabIndexLevel = useAppSelector(state => state.tabIndexLevel)
//   const load = useAppSelector(state => state.load)

//   const [categoryToCopy, setCategoryToCopy] = useState({})
//   const tabIndex = getTabIndex( tabIndexLevel, load )

//   if(popupOpened !== 3 || popupOpened !== 7) {
//     return null
//   }
//   return (
//     <Popup 
//       popupClose={popupClose} 
//       className={'fixed-on-center client-edit replace-copy-popup'} 
//       blackout
//       withCloseBtn
//       isForm={'client-edit'}
//       children={(
//         <ValidFuncConsumer>
//           {({checkValidity}) => 
//             <>
//               <div className="client-edit-header">{popupOpened !== 7 ? 'Копирование' : 'Перемещение'} в</div>
//               <Select
//                 isSearchable={false}
//                 options={categoriesAsOptions} 
//                 value={categoryToCopy}
//                 onChange={setCategoryToCopy}
//                 styles={customStylesSelect}
//                 className="my-select"
//                 classNamePrefix="my-select"
//               />
//               <div className='client-edit-footer'>
//                 <MyButton 
//                   tabIndex={tabIndex} 
//                   className="white-btn" 
//                   onClick={popupClose}
//                 >
//                   Отменить
//                 </MyButton>
//                 <MyButton 
//                   tabIndex={tabIndex} 
//                   id="confirm-edit" 
//                   className="blue-btn" 
//                   onClick={() => {}}
//                 >
//                   Сохранить
//                 </MyButton>
//               </div> 
//             </>
//           }
//         </ValidFuncConsumer>
//       )}
//     />
//   )
// }

const SearchBarButtons = () => {
  const [productFilters] = useProductsFilters();
  const categories = useAppSelector(state => state.categories || []);
  const {
    setSelectedCategory,
  } = useContext(ProductsContext);

  const dispatch = useDispatch()
  const selectedFiltersDispatch = (item: ProductFilters) =>
    dispatch({ type: 'PRODUCTS_SELECTED_FILTERS', payload: item})

  const categoryFilterHandler: CategoryFilterChanger = (categoryId) => {
    if(!productFilters) return false

    let changedSelectedFilters: ProductFilters = {...productFilters};

    if(categoryId === undefined) {
      const topFilter = changedSelectedFilters['top']
      changedSelectedFilters = {
        ...changedSelectedFilters,
        top: topFilter ? topFilter.map(filter => ({...filter, selected: false})) : undefined
      }
    } 

    const category = categoryId !== undefined ? categories.find(t => t.id === categoryId) : undefined

    if(category) {
      const subCategoriesIds = extractSubTopicIds(category, categories)
      const itemIds = [categoryId, ...subCategoriesIds]

      if(!itemIds.length) 
        return false;

      const topFilter = changedSelectedFilters['top'];

      changedSelectedFilters = {
        ...changedSelectedFilters,
        top: topFilter
          ? topFilter
            .map(filter => ({
              ...filter,
              selected: itemIds.some(itemId => filter.id === itemId)
            }))
          : undefined
      }
    }
    
    batch(() => {
      selectedFiltersDispatch(changedSelectedFilters)
      setSelectedCategory(category)
    })
  }

  return (
    <div className="search-open-bar-btns products-search-btns">
      <SelectCategoryPopup
        // initCategoryId={productFilters?.top?.find(category => category.selected)?.id}
        categoryFilterHandler={categoryFilterHandler}
      />

      <FiltersPopup
      />

      {/* <ReplaceAndCopyPopup
        popupOpened={popupOpened}
        popupClose={popupClose}
      /> */}
    </div>
  )
}

export const extractSubTopicIds = (element: Category, objArray: Category[]): number[] => {
  const a = f(element, [], objArray)
  return a.splice(1, a.length)
}

const f = (obj: Category, array: number[] = [], objArray: Category[]): number[] => {
  let newArray: number[] = [...array, +obj.id]

  for (const subObj of objArray.filter(c => c.top === obj.id)) {
    newArray = f(subObj, newArray, objArray)
  }

  return newArray
}

export default ProductsSearchBar

function SelectCategoryPopup({
  categoryFilterHandler,
  // initCategoryId,
}: {
  categoryFilterHandler: CategoryFilterChanger,
  // initCategoryId: Id | null | undefined
}) {
  const {
    orderingMode,
    setOrderingMode,
  } = useContext(ProductsTableContext)
  const {
    selectedCategory,
  } = useContext(ProductsContext)
  const {RenderButton, RenderPopup, closePopup} = usePopup()
  const tabIndex = useTabIndex();

  const mainPageOrderingHandler = () => {
    batch(() => {
      setOrderingMode('mainPage')
      categoryFilterHandler(undefined)
      closePopup()
    })
  }

  const categoryFilterHandlerExtended: CategoryFilterChanger = (categoryId) => {
    batch(() => {
      categoryFilterHandler(categoryId)
      setOrderingMode('category')
      closePopup()
    })
  }

  return <div className="filter-btn">
    <RenderButton
      className="filter-btn-inner"
      removeDefaultClass
    >
      {orderingMode === 'category'
        ? selectedCategory?.name || 'Без фильтра по категориям'
        : orderingMode === 'mainPage'
          ? 'Товары на главной'
          : ''
      }
      <img alt="" src={triangleDown}/>
    </RenderButton>

    <RenderPopup
      tabIndexDeep={3}
      className={'select-category category-tree-popup'}
      popupName={'.category-tree-popup'}
    >
      <CategoriesFilterAndControl
        categoryHandler={categoryFilterHandlerExtended}
        closePopupParent={closePopup}
        filtersMod={true} 
        withNoCategory={true}
        additionalFilters={
          <div className='categories-tree__single-option'>
            <MyButton className="categories-tree__selected-item"
              tabIndex={tabIndex}
              onClick={mainPageOrderingHandler}
            >
              Товары на главной странице
            </MyButton>
          </div>
        }/>
    </RenderPopup>
  </div>;
}
