import React, { } from 'react';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import useOrderDelivery from '../../hooks/use-order-delivery';
import {BodyCell} from "src/components/_utility-components/common-table/body-cell";
import {Order} from "merchery-lib";

interface Props {
  order: Order
}

export function OrderRowDelivery({
  order,
}: Props) {
  const delivery = useOrderDelivery(order)
  const deliveryServices = useAppSelector(state => state.deliveryServices)
  // const [popupOpened, setPopupOpened] = useState(false);

  // const pickpointInfoHandler = (e: React.KeyboardEvent | React.MouseEvent) => {
  //   setPopupOpened(!popupOpened)
  //   e.stopPropagation();
  // };
  
  const thisDeliveryService = delivery && deliveryServices.find(del => del.id === delivery.type);

  return <BodyCell myClassName="tt-delivery">
    {thisDeliveryService ? 
      <div className='tt-delivery-popup-wrapper'>
        {thisDeliveryService.name}
        {delivery.note ? `: ${delivery.note}` : null}
      </div>
    :
      <div className='tt-delivery-popup-wrapper'>
        Не выбрана
      </div>
    }

    {/* {order.pickpoint_id ?
        <MyButton
          tabIndex={tabIndex}
          removeDefaultClass
          onMouseUp={(e) => e.button === 0 && pickpointInfoHandler(e)}
          onKeyUp={(e) => e.key === 'Enter' && pickpointInfoHandler(e)}
        >
          <span>Самовывоз</span>
        </MyButton> */}
        {/* {popupOpened ? 
          <PickpointInfo order={order} 
            popupClose={() => setPopupOpened(false)}
          />
        : null} */}
    {/* //   </div> */}
    {/* // : `${order.delivery_city || ''} ${order.client.address || ''}`} */}
  </BodyCell>;
}
