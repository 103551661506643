import React from 'react';
import PaymentPayLikeClient from "src/components/main-pages/orders/order-modules/payment/dev/pay-like-client";

function PaymentBodyTestBill () {
	return (
		<div className='order-page__payment__body'>
			<PaymentPayLikeClient/>
		</div>
	);
}

export default PaymentBodyTestBill;