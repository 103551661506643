import React, {memo, useCallback, useContext} from 'react';
import { Droppable } from 'react-beautiful-dnd';
import {useDispatch} from 'react-redux';
import { CharsLabelDto, Id } from 'merchery-lib';
import { uuidv4 } from '../../../../../../scripts/functions';
import { useAppSelector } from '../../../../../../scripts/pre-type/use-selector';
import MyInput from '../../../../../_utility-components/input/index';
import CharLabel from '../char-label';
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";
import {
  OneCharContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/one-char-logic";

interface Props {
  removeLabelAndDispatch: (labelForRemove: CharsLabelDto) => void,
}

function Labels({
  removeLabelAndDispatch,
}: Props) {
  const {
    thisCharGroup: group,
    charLabels: labels
  } = useContext(OneCharContext)

  const {
    page,
    charEditingId,
  } = useContext(CharacteristicsContext)

  const allLabels = useAppSelector( state => state.labels);
  const dispatch = useDispatch()
  const labelsReducerType = 'CHARS_LABELS';
  const setLabels = (value: CharsLabelDto[]) =>
    dispatch({type: labelsReducerType, payload: value});

  const addNewValue = (initialValue: string = '', id?: Id) => {
    if(!group) {
      return null
    }

    const arrayOfOrders = [...labels.map((v) => v.order), 0]
    const maxOrder = Math.max.apply(null, arrayOfOrders);
    const newOrder = maxOrder + 1;

    const newLabel: CharsLabelDto = {
      id: id || uuidv4(),
      char_group: group.id,
      name: initialValue,
      order: newOrder,
      type_id: 2,
      type_name: 'string',
      unit: null,
      unit_id: null,
      newLabel: true,
    }
    setLabels([...allLabels, newLabel])
  }

  const labelsDispatch = useCallback((changedLabel: CharsLabelDto) =>
    setLabels(
      allLabels.map(label =>
        label.id !== changedLabel.id 
          ? label 
          : changedLabel
      )
    )
  , [allLabels])

  if(!group) {
    return null
  }

  const wrapperClassName = `characteristic-labels ${page === 'product' || charEditingId ? 'flex-gap-16' : 'category-page__characteristic-labels'}`;
  const headerClassName = `header-font-s side-padding-24 characteristic-labels__header`;

  const labelsRender = labels.map((label) =>
    <CharLabel
      key={label.order}
      labelsDispatch={labelsDispatch}
      removeLabelAndDispatch={removeLabelAndDispatch}
      label={label}
    />
  )

  if(page === 'categories' && !charEditingId) {
    return (
      <div className={wrapperClassName}>
        {labelsRender}
      </div>
    )
  }

  return (
    <Droppable 
      type={`groupLabel-${group.id}`} 
      droppableId={`char_group-${group.id}`}
    >
      {(provided, snapshot) => 
        <div 
          className={wrapperClassName}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {charEditingId ?
            <h5 className={headerClassName}>Характеристики</h5> 
          : null}

          {labelsRender}
          {provided.placeholder}

          {charEditingId ?
            <div className='char-label side-padding-24'>
              <MyInput
                placeholder={'Добавить характеристику'}
                value={''}
                onFocus={() => addNewValue()}
              />
            </div>
          : null}
        </div>
      }
    </Droppable>
  )
}

export default memo(Labels)