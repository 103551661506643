import React, {useContext} from 'react';
import MyCheckbox from "src/components/_utility-components/checkbox";
import {RegisterPaymentContext} from "src/components/main-pages/orders/order-modules/payment/dev/pay-like-client";

function PaymentModes () {
	const {
		paymentModes,
		selectedPaymentMode,
		setSelectedPaymentMode
	} = useContext(RegisterPaymentContext)

	if(!paymentModes) {
		return null
	}

	return (
		<div>
			{paymentModes.map(mode =>
				<MyCheckbox
					key={mode.id}
					text={mode.desc}
					condition={selectedPaymentMode?.code === mode.code}
					actionHandler={() => setSelectedPaymentMode(mode)}
				/>
			)}
		</div>
	);
}

export default PaymentModes;