import { useRef, useEffect } from 'react';

const useUnload = (fn: (...args: any[]) => void, hasChanges: boolean) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args: any[]) => {
      if(hasChanges) {
        cb.current?.(...args)
      }
    };

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, [hasChanges]);

  // const [isBackNavigationAttempted, setIsBackNavigationAttempted] = useState(false);

  // useEffect(() => {
  //   const handlePopState = (event: PopStateEvent) => {
  //     if (hasChanges && !isBackNavigationAttempted) {
  //       event.preventDefault();
  //       setIsBackNavigationAttempted(true);
  //       window.history.pushState(null, document.title, window.location.href);
  //     } else if (isBackNavigationAttempted) {
  //       setIsBackNavigationAttempted(false);
  //     }
  //   };

  //   window.addEventListener('popstate', handlePopState);

  //   return () => window.removeEventListener('popstate', handlePopState);
  // }, [hasChanges, isBackNavigationAttempted]);
};

export default useUnload;
