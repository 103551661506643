import React from 'react';
import MyButton from 'src/components/_utility-components/button/button';

interface IProps {
  addNewHandler: () => void
}

function AddNewStoriesItem({ addNewHandler }: IProps) {
  return (
    <MyButton
      className={"header-font-l product-page__stories-add-item"}
      onClick={addNewHandler}
    >
      <i className="icofont-plus"></i>
      Добавить историю
    </MyButton>
  );
}

export default AddNewStoriesItem;