import React from 'react';

interface Props {
  style?: React.CSSProperties;
}

function VisualSeparatorLine (props: Props) {
  return <div className='visual-separator-line' style={props.style}></div>
}

export default VisualSeparatorLine;