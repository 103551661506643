function MSProductsRemains({
  uom,
  remain 
}: {
  uom: any,
  remain: any,
}) {
  return uom?.name ? `${remain} ${uom.name}` : remain
}

export default MSProductsRemains;