import React, {  } from 'react';
import NoImage from '../../../img/no-image.png';
import {ExtractedLinks} from "merchery-lib"; 

interface PictureProps {
  images: ExtractedLinks | string | null
  className?: string
}

function Picture({ images, className }: PictureProps) {
  return (
    <picture className="my-picture">
      {typeof images !== "string" && images ? (
				<>
					<source srcSet={images["svg"]} type="image/svg+html"/>
					<source srcSet={images["png"]} type="image/png"/>
					<source srcSet={images["jpeg"]} type="image/jpeg"/>
					<source srcSet={images["webp"]} type="image/webp"/>
				</>
			) : null}

			<img
				className={`my-picture__img ${className || ''}`}
				src={
          !images 
            ? String(NoImage)
            : typeof images !== "string" 
              ? images.default
              : images
				}
				alt="not found"
			/>
    </picture>
  );
}

export default Picture;