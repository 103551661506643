import { useRouteMatch } from "react-router";

function useRouteId<T extends string>(key: T) {
  const match = useRouteMatch<{[key in T]: string}>()

  const id = match?.params?.[key]
    ? Number(match.params[key])
    : undefined;

  return id
}

export default useRouteId;