import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/scripts/pre-type/use-selector";
import { WebSocketContext } from "src/scripts/web.socket";
import { createDispatch, deleteDispatch, updateDispatch } from "./dispatches";
import {
	CreateRefundsWsDto,
	DeleteRefundsWsDto,
	RefundChangeWs,
	UpdateRefundsWsDto,
  WsResponse
} from "merchery-lib";
import { 
  createRefund, 
  deleteRefund, 
  updateRefund,
} from "src/constants/ws-events";

export default function useRefundsWsListener () {
	const {
		lastMessage,
	} = useContext(WebSocketContext);

  const refunds = useAppSelector(state => state.refunds);
	const dispatch = useDispatch();
  const refundsDispatchType = 'ORDERS_REFUNDS'

	const updateItemsDispatch = refunds && updateDispatch(refunds, dispatch, refundsDispatchType);
	const deleteItemsDispatch = refunds && deleteDispatch(refunds, dispatch, refundsDispatchType);
	const createItemsDispatch = refunds && createDispatch(refunds, dispatch, refundsDispatchType);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<UpdateRefundsWsDto>;

			if (message.event === updateRefund &&
				message.data.changes
			) {
				const changes = message.data.changes;

				updateItemsDispatch &&
					updateItemsDispatch<RefundChangeWs>(changes)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<DeleteRefundsWsDto>;

			if (message.event === deleteRefund &&
				message.data.id
			) {
				const ids = message.data.id;

				deleteItemsDispatch &&
					deleteItemsDispatch(ids)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<CreateRefundsWsDto>;

			if (message.event === createRefund &&
				message.data.refund
			) {
				const refund = message.data.refund;

				createItemsDispatch &&
					createItemsDispatch(refund)
			}
		}
	}, [lastMessage]);
}