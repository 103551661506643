import React, {useContext} from 'react';
import MyButton from '../../../_utility-components/button/button';
import MyInput from '../../../_utility-components/input/index';
import { PromocodeStatus } from './promocode-status';
import {OrderPromocodeContext} from "src/components/main-pages/orders/order-modules/promo";

export function PromoEdit() {
  const {
    popupClose,
    currentPromocode,
    setCreateNewCodeState,
    setCurrentPromocode,
    setPromo,
  } = useContext(OrderPromocodeContext)

  return <>
    <div className="discount-promo-header promo">
      <h2>Промокод</h2>
    </div>

    <div className="discount-promo-set-discount-promo">
      <div className="discount-promo-set-discount-promo-top promo">
        <MyInput
          placeholder="Введите действительный промокод"
          onChange={(e) => setCurrentPromocode({
            ...currentPromocode,
            code: e.target.value
          })}
          value={currentPromocode?.code || ''}
          name="promo"
          withFocus={false}
          withBlur={false}
          withBind='confirm-discount'
        />
      </div>

      <div className="discount-promo-set-discount-promo-bottom promo">
        {!currentPromocode?.code ?
          <MyButton
            removeDefaultClass
            className="promo-create-btn"
            onClick={() => setCreateNewCodeState(true)}>
            Создать новый
          </MyButton>
        :
          <PromocodeStatus
          />
        }
      </div>
    </div>
    <div className="discount-promo-btns">
      <MyButton
        className="white-btn"
        onClick={popupClose}>
        Отменить
      </MyButton>

      <MyButton
        id="confirm-discount"
        className="blue-btn"
        onClick={setPromo}>
        Сохранить
      </MyButton>
    </div>
  </>;
}
