// Универсальный спиннер для состояния загрузки/отправки

import React from 'react';
import Svg from '../../../img/app-loader.svg'

interface Props {
  className?: string
  width?: number | string
  height?: number | string
}

function LocalLoader(props: Props) {
  const {
    className='load-spinner',
    width,
    height
  } = props;

  return (
    <div style={{
        ...(width && {
          width
        }),
        ...(height && {
          height
        }),
      }}
      className={className} 
      onClick={(e) => {e.stopPropagation()}}
    >
      <img style={{
        width: '100%',
        height: '100%',
      }} src={String(Svg)} alt="local loader" />
    </div>
  );
}

export default LocalLoader;