import React from 'react';
import SortingArrowSvg from '../../../img/sorting-arrow.svg';

interface Props {
  label: string,
  currentSortingLabel: string | undefined,
  direction: 'ascending' | 'descending' | undefined,
  handler: (direction: 'ascending' | 'descending') => void
}

function SortingArrows({
  direction,
  label,
  currentSortingLabel,
  handler,
}: Props) {
  const byThisLabelSorting = label === currentSortingLabel;
  
  return (
    <div className={`sorting-arrows ${byThisLabelSorting ? 'active' : ''}`}>
      <img src={String(SortingArrowSvg)} 
        onClick={() => handler('ascending')}
        alt={'sort up'}
        className={`sort-arrow ascending-arrow ${direction === 'ascending' && byThisLabelSorting ? 'active' : ''}`}
        />
        
      <img src={String(SortingArrowSvg)}
        onClick={() => handler('descending')}
        alt={'sort down'}
        className={`sort-arrow descending-arrow ${direction === 'descending' && byThisLabelSorting ? 'active' : ''}`}
        />
    </div>
  );
}

export default SortingArrows;