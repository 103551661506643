if ( !window.indexedDB ) window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
if ( !window.IDBTransaction ) window.IDBTransaction = window.webkitIDBTransaction || window.msIDBTransaction;
if ( !window.IDBKeyRange ) window.IDBKeyRange = window.webkitIDBKeyRange || window.msIDBKeyRange;
if ( !window.indexedDB ) throw new Error( 'IndexedDB is not awailable' );

// const DB_NAME = 'objectStore';
// const OBJECT_STORE_NAME = 'objectStore';

function openDatabasePromise( keyPath, DB_NAME ) {
  return new Promise( ( resolve, reject ) => {
    const dbOpenRequest = window.indexedDB.open( DB_NAME, 1 );

    dbOpenRequest.onblocked = () => {
      reject( 'Требуется обновление структуры базы данных, хранимой в вашем браузере, ' +
        'но браузер уведомил о блокировке базы данных.' );
    };

    dbOpenRequest.onerror = err => {
      console.log( 'Unable to open indexedDB ' + DB_NAME );
      console.log( err );
      reject( 'Невозможно открыть базу данных, либо при её открытии произошла неисправимая ошибка.' +
       ( err.message ? 'Техническая информация: ' + err.message : '' ) );
    };

    dbOpenRequest.onupgradeneeded = event => {
      const db = event.target.result;
      try {
        db.deleteObjectStore( DB_NAME );
      } catch ( err ) {  }
      db.createObjectStore( DB_NAME, { keyPath } );
    };

    dbOpenRequest.onsuccess = () => {
      // console.info( 'Successfully open indexedDB connection to ' + DB_NAME );
      resolve( dbOpenRequest.result );
    };

    dbOpenRequest.onerror = reject;
  } );
}

function wrap( methodName ) {
  return function() {
    const [ objectStore, ...etc ] = arguments;
    return new Promise( ( resolve, reject ) => {
      const request = objectStore[ methodName ]( ...etc );
      request.onsuccess = () => resolve( request.result );
      request.onerror = reject;
    } );
  };
}

const deletePromise = wrap( 'delete' );
const getAllPromise = wrap( 'getAll' );
const getPromise = wrap( 'get' );
const putPromise = wrap( 'put' );

export default class IndexedDbRepository {
  constructor( keyPath, DB_NAME ) {
    this.error = null;
    this.keyPath = keyPath;
    this.listeners = new Set();
    this.stamp = 0;
    this.dbName = DB_NAME

    this.openDatabasePromise = this._openDatabase( keyPath );
  }

  async _openDatabase( keyPath ) {
    try {
      this.dbConnection = await openDatabasePromise( keyPath, this.dbName );
    } catch ( error ) {
      this.error = error;
      throw error;
    }
  }

  async _tx( txMode, callback ) {
    await this.openDatabasePromise; 
    try {
      const transaction = this.dbConnection.transaction( [ this.dbName ], txMode );
      const objectStore = transaction.objectStore( this.dbName );
      return await callback( objectStore );
    } finally {if ( txMode === 'readwrite' ) this.onChange()}
  }

  async findAll() {
    return this._tx( 'readonly', objectStore => getAllPromise( objectStore ) );
  }

  async findById( key ) {
    return this._tx( 'readonly', objectStore => getPromise( objectStore, key ) );
  }

  async deleteById( key ) {
    return this._tx( 'readwrite', objectStore => deletePromise( objectStore, key ) );
  }

  async save( item ) {
    return this._tx( 'readwrite', objectStore => putPromise( objectStore, item ) );
  }

  async multiSave( items ) {
    return items.forEach(async (i) => {
      await this.save(i)
    })
  }

  async deleteAll() {
    return await this.findAll()
    .then(res => res.forEach(async (i) => {await this.deleteById(i[this.keyPath])}))
  }

  addListener( listener ) {
    this.listeners.add( listener );
  }
  onChange() {
    this.stamp++;
    this.listeners.forEach( listener => listener( this.stamp ) );
  }
  removeListener( listener ) {
    this.listeners.delete( listener );
  }
}