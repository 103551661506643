import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { openInNewTab } from '../../scripts/functions';
import MyButton from '../_utility-components/button/button';
import PasswordRecoveryForm from './password-recovery-form';
import SignInForm from './sign-in-form';
import PasswordRecoveryPassword from './password-recovery-password'
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { extractImages } from 'src/scripts/utils/extractImages';
import Picture from '../_utility-components/picture/picture';

function SignIn () {
  const settings = useAppSelector(state => state.settings)
  const siteLogo = useAppSelector(state => state.siteLogo)
  const title = settings.find(setting => setting.callname === 'site_name_display')?.value as string | null
  const extractedImages = useMemo(() => siteLogo && extractImages(siteLogo, 'medium'), [])

  return (
    <div className="merchery-inner">
      <div className={`app-logo ${siteLogo ? 'logo-picture' : 'logo-title'}`} >
        {siteLogo ?
          <Picture
            images={extractedImages || siteLogo?.src} 
          />
        : title}
      </div> 

      <div className="sign-in-forms-wrapper">
        <Switch>
          <Route exact path="/sign-in" component={SignInForm}/>
          <Route path="/sign-in/password-recovery" component={PasswordRecoveryForm}/>
          <Route path="/sign-in/new-password" component={PasswordRecoveryPassword}/>
          <Redirect to="/sign-in/"/>
        </Switch>
      </div>

      <div className="powered-by-merchery">
        <span>Работает на </span>
        <MyButton 
          onClick={() => openInNewTab('https://merchery.ru')}>
          Merchery
        </MyButton>
      </div>
    </div>
  );
}

export default SignIn