import { useEffect, RefObject, useRef } from 'react';

type Handler = () => void;

function useTabBlur(inputRef: RefObject<HTMLInputElement>, onTabBlur: Handler) {
  const lastKeyDownTarget = useRef<EventTarget | null>(null);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      lastKeyDownTarget.current = event.target;
    }

    const onBlur = (event: FocusEvent) => {
      if (lastKeyDownTarget.current === event.target && event.target === inputRef.current) {
        onTabBlur()
      }
    }

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('blur', onBlur, true);

    // Cleanup function
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('blur', onBlur, true);
    }
  }, [onTabBlur, inputRef]); // Re-run the effect when lastDownTarget changes
}

export default useTabBlur;
