import React from 'react';
import { Id } from "merchery-lib";
import {CommonFilters} from "src/scripts/utils/entity-filters";
import {UpdateFilters} from "src/scripts/hooks/use-table-filters";

interface AppliedFiltersProps<T> {
  filterNames: (keyof T)[]
  filters: CommonFilters<T> | undefined,
  updateFilters: UpdateFilters<T>,
}

export function AppliedFilters<T, >({
  filterNames,
  filters,
  updateFilters,
}: AppliedFiltersProps<T>) {

  return (
    <div className="applied-filters">
      {filterNames.map((filterName) => (
        <AppliedGroup<T>
          key={filterName as string}
          filterName={filterName}
          filters={filters}
          updateFilters={updateFilters}
        />
      ))}
    </div>
  )
}

export function AppliedGroup<T> ({
  filterName,
  filters,
  updateFilters
}: {
  filters: CommonFilters<T> | undefined,
  updateFilters: UpdateFilters<T>,
  filterName: keyof T,
}) {
  const closeHandler = (id: Id | null) => updateFilters({
    itemIds: [id],
    filterName,
  })

  const filter = filters?.[filterName]

  if(!filter) {
    return null
  }

  return <>
    {filter.map((el) =>
      !el.selected
        ? null
        : <div key={el.id}>
            {el.text}
            <i className="icofont-close"
               onClick={() => closeHandler(el.id)}
            ></i>
          </div>
    )}
  </>
}

