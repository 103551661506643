import React from 'react';
import { useDispatch } from 'react-redux';
import { Id, Order } from 'merchery-lib';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { useOrderDisabled } from '../../hooks/use-order-disabled';
import MyCheckbox from '../../../../_utility-components/checkbox/my-checkbox';

function ItemsListHeaderCells({order}: {order: Order}) {
  const items = useAppSelector(state => state.productItemsInContext);
  const selectedItems = useAppSelector(state => state.selectedProductItemsInContext);
  const dispatch = useDispatch();
  const setSelectedItems = (ids: Id[]) => dispatch({ type: 'PRODUCT_ITEMS_IN_CONTEXT_SELECTED', payload: ids})
  const [disabled] = useOrderDisabled(order)

  const checkboxHandler = () => setSelectedItems(selectedItems.length === items.length ? [] : items.map(item => item.id))
  const allSelected = Boolean(items.length && selectedItems.length && selectedItems.length === items.length);

  return (
    <>
      <MyCheckbox 
        condition={allSelected} 
        disabled={disabled}
        actionHandler={checkboxHandler}/>
      {/* <div className="op-check hide-for-print" 
        onClick={checkboxHandler}
        onKeyUp={(e) => e.key === 'Enter' && checkboxHandler()}
      >
        <div className="checkbox"><i className='icofont-check-alt'></i></div>
      </div> */}
      <div className="op-image center-align"><div>Фото</div></div>
      <div className="op-title"><div>Название</div></div>
      <div className="right-align"><div>цена</div></div>
      <div className="center-align"><div>Вес, гр</div></div>
      <div className="right-align"><div>кол-во</div></div>
      <div className="right-align"><div>Сумма</div></div>
    </>
  );
}

export default ItemsListHeaderCells;