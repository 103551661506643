import React, { useState } from 'react';
import MyButton from 'src/components/_utility-components/button/button';
import withAddItemPopup from 'src/components/main-pages/orders/order-page-popups/add-item-popup';
import AnalogAndRelatedProductToBeAdded from './analog-and-related-row-to-be-added';
import { ExtendedProduct } from 'merchery-lib';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';

const AddAnalogAndRelatedItemsPopup = withAddItemPopup(AnalogAndRelatedProductToBeAdded)

interface Props {
  handler: (items: ExtendedProduct[]) => Promise<boolean>
}

function FindProductsInCatalog({
  handler,
}: Props) {
  const [addItemPopupOpen, setAddItemPopupOpen] = useState(false);
  const product = useAppSelector(state => state.product);

  if(!product) {
    return null
  }

  return (
    <>
      <MyButton
        className='white-blue-btn text-font-m height-48'
        onClick={() => setAddItemPopupOpen(true)}
      >
        Выбрать в каталоге...
      </MyButton>

      {addItemPopupOpen ?
        <AddAnalogAndRelatedItemsPopup
          tabIndexDeep={3}
          popupClose={() => setAddItemPopupOpen(false)}
          addItems={handler} 
          filters={{}}
          productsFilterPredicate={value => value.id !== product.id}
          withTotalSum={true}
          />
      : null}
    </>
  );
}

export default FindProductsInCatalog;