/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyInput from "../../../_utility-components/input/index";
import ProductUnitsSelect from "./units-select";

// interface Props {
//   hide: boolean,
//   switchHeaders?: true,
//   labelName: keyof ExtendedProduct | (keyof ExtendedProduct)[],
//   inputHandler: <T extends keyof ExtendedProduct>(label: T, value: ExtendedProduct[T]) => void
// }

// function ProductLabels ({
//   header,
//   rowClassName,
//   labelName,
//   required,
//   switchHeaders,
//   hide,
//   type,
//   inputHandler,
//   tabIndex,
//   select,
//   ...rest
// }: Props) {
//   const product = useAppSelector(state => state.product)

//   if(!product) {
//     return null
//   }

//   const label: keyof ExtendedProduct | string | undefined = product?.price_old_or_discount || labelName;

//   const productLabel = product?.[label]
//   const [value, setValue] = useState(productLabel)

//   const switchHeaderHandler = useCallback((discountVariant: "discount" | "price_old") => inputHandler('price_old_or_discount', discountVariant), [inputHandler])

//   const onChangeHandler = useCallback((e) => {
//     const typedValue = type === 'number' ? Number(e.target.value) : e.target.value
//     setValue(typedValue)
//   }, [setValue])

//   const inputValue = useMemo(() => value || value === 0 ? value : '', [value])

//   useEffect(() => {
//     let timeOutId = setTimeout(() => {// eslint-disable-next-line
//       productLabel != value && inputHandler(label, value)
//     }, 50);
//     return () => clearTimeout(timeOutId)
//   }, [value]);

//   useEffect(() => {
//     if(productLabel !== value) {
//       setValue(productLabel)
//     }
//   }, [productLabel])

//   if(hide || !label || !product) {
//     return null
//   }

//   return (
//     <div className={`inputs-row ${rowClassName || ''}`}>
//       {switchHeaders && product?.price_old_or_discount ?
//         <SwitchHeaders 
//           switchHeaderHandler={switchHeaderHandler}
//           price_old_or_discount={product.price_old_or_discount}
//         />
//       : 
//         <h4 className="header-font-s">{header}</h4>
//       }

//       {select ? (
//         <ProductUnitsSelect
//           currentUnitId={value}
//           selectHandler={select.optionHandler}
//           shortInput={true}
//           />
//       ) : (
//         <MyInput
//           {...rest}
//           type={type}
//           value={inputValue}
//           required={required || false}
//           tabIndex={tabIndex}
//           shortInput={true}
//           min={0}
//           onChange={onChangeHandler}
//         />
//       )}
//     </div>
//   )
// }

export function SwitchHeaders ({
  switchHeaderHandler,
  price_old_or_discount
}: {
  price_old_or_discount: string,
  switchHeaderHandler: (str: "discount" | "price_old") => void
}) {
  return (
    <div className={'switch-headers-wrapper'}>
      <div className={`switch-headers clickable ${price_old_or_discount === 'price_old' ? 'active' : ''}`} 
        onClick={() => switchHeaderHandler('price_old')}
      >
        <h4 className="header-font-s">{'Старая цена'}</h4>
      </div>

      <div className={`switch-headers clickable ${price_old_or_discount === 'discount' ? 'active' : ''}`} 
        onClick={() => switchHeaderHandler('discount')}
      >
        <h4 className="header-font-s">{'Скидка'}</h4>
      </div>
    </div>
  )
}

// export default ProductLabels