import { ImageExtended, ImageFile, ExtractedLinks } from "merchery-lib"
 
type Img = ImageFile | ImageExtended;

export function extractImages <T extends Img>(img: T | undefined | null, scale: string, predicate?: (value: T) => unknown): ExtractedLinks | null {
  if(!img) return null;

  if(imageIsFile(img)) {
    return {
      default: img.src, 
      ...(predicate !== undefined && {
        searched: predicate(img)
      })
    }
  }
  
  const validatedResize = img.resizes.filter(r => r.scale === scale);

  const webp = validatedResize.find(thumb => thumb.format === 'webp')?.src
	const jpeg = validatedResize.find(thumb => thumb.format === 'jpeg')?.src
	const svg = validatedResize.find(thumb => thumb.format === 'svg')?.src
	const png = validatedResize.find(thumb => thumb.format === 'png')?.src

  const result: ExtractedLinks = {
    default: img.src,
    webp,
    jpeg,
		png,
		svg,
    ...(predicate !== undefined && {
      searched: predicate(img)
    })
  }

  return result
}

function imageIsFile(item: ImageFile | ImageExtended): item is ImageFile {
  return (item as ImageFile).newFile
}