import {useContext, useEffect} from 'react';
import {WebSocketContext} from "../scripts/web.socket";
import {useDispatch} from "react-redux";
import { NewOrdersCount, WsResponse } from 'merchery-lib';
import { 
  newOrdersCount
} from "src/constants/ws-events";

function useNewOrdersCountWsListener () {
	const {
		lastMessage,
	} = useContext(WebSocketContext);

	const dispatch = useDispatch()
	const ordersCountDispatch = (count: number) => 
    dispatch({ type: 'ORDERS_COUNT', payload: count })

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<NewOrdersCount>;

			if (message.event === newOrdersCount &&
				message.data.count !== undefined
			) {
				const newCount = message.data.count;
				ordersCountDispatch(newCount)
			}
		}
	}, [lastMessage]);
}

export default useNewOrdersCountWsListener;