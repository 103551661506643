import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { configureStore } from '@reduxjs/toolkit';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { Route } from 'react-router-dom';
import App from './components/app/app';
import FallbackComponent from './components/_utility-components/error-boundary';
import history from './scripts/history';
import reducer from './reducers/reducer';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const store = configureStore({ reducer })
 
ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
    >
      <Router history={history}>
        <Route path="/" component={App}/>
      </Router>
    </ErrorBoundary>
  </Provider>
  , document.getElementById('root')
);

export {
  store
};