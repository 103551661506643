import React from 'react';
import withRowComponents, { RowProps } from 'src/scripts/with-row-components';
import { ProductVariantExtended, OrderItemExtended, ExtendedProduct } from 'merchery-lib';

const ProductItemRow: React.FC<RowProps<OrderItemExtended>> = withRowComponents<RowProps<OrderItemExtended>>(() => <></>);

const ProductRow: React.FC<RowProps<ExtendedProduct>> = withRowComponents<RowProps<ExtendedProduct>>(() => <></>);

const ProductVariantRow: React.FC<RowProps<ProductVariantExtended>> = withRowComponents<RowProps<ProductVariantExtended>>(() => <></>);

export {
  ProductItemRow,
  ProductRow,
  ProductVariantRow
};

