/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode } from 'react';
import MyButton from './button/button';
import Popup, {PopupProps} from './popup';
import {createPortal} from "react-dom";

interface Props extends Partial<PopupProps> {
  confirmHandler: () => void,
  popupClose: () => void,
  deletion?: boolean,
  children?: ReactNode | ReactNode[]
}

const ConfirmPopup = ({ 
  confirmHandler,
  popupClose,
  deletion = false,
  children,
  ...restProps
}: Props) => {

  return (createPortal(
    <Popup
      tabIndexDeep={3}
      className={'confirm-popup fixed-on-center'}
      popupClose={popupClose}
      withCloseBtn
      withBlackout
      {...restProps}
    >
      <h3 className='confirm-popup__h header-font-xl'>
        {deletion ?
          'Подтвердите удаление'
          : 'Подтвердите сохранение'}
      </h3>

      <div className='confirm-popup__body'>
        {children}
      </div>

      <div className='confirm-popup__btn-wrapper'>
        <MyButton className={'white-btn width-228'}
                  onClick={popupClose}
        >
          Назад
        </MyButton>

        <MyButton className={`${deletion ? 'red-btn' : 'blue-btn'} width-228`}
                  onClick={confirmHandler}
        >
          {deletion ? 'Удалить' : 'Сохранить'}
        </MyButton>
      </div>
    </Popup>
    , document.body)
  )
}

export default ConfirmPopup;
