import React from 'react';

interface SetProductItemExternalIdProps {
  external_id?: string | null | undefined
}

function SetProductItemExternalId(props: SetProductItemExternalIdProps) {
  const { external_id } = props;

  return (
    <div className='row-item__external-id'>
      {external_id ? 
        <div>{external_id}</div> 
      : null}
    </div>
  );
}

export default SetProductItemExternalId;