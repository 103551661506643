import React from "react";
import MyButton from "../../../../_utility-components/button/button";
import { useAppSelector } from "src/scripts/pre-type/use-selector";
import { RefundFilterItem, RefundFilters } from "../dto/refund-filters.dto";

export type FilterTabRules = [ Array<number>, (keyof RefundFilters) | undefined ]

interface FilterTabProps {
  handler: (rules: FilterTabRules) => void,
  text: string,
  rules: FilterTabRules
}

export const FilterTab = ({
  text,
  handler,
  rules
}: FilterTabProps) => {
  const refundFilters = useAppSelector(state => state.refundFilters);

  if(!refundFilters) {
    return null
  }

  let isActive = rules[1] && refundFilters[rules[1]] ? 
      !!(refundFilters[rules[1]].filter((f) => rules[0].some(filterId => filterId === f.id && f.selected)).length)
    : Object.entries<RefundFilterItem[]>(refundFilters).every((s) => s[1].every((r) => !r.selected));

  return (
    <MyButton className={isActive ? "active-status" : ''} 
      removeDefaultClass
      onClick={() => handler(rules)}
    >
      {text}
    </MyButton>
  )
}