import React from 'react';
import MyButton from 'src/components/_utility-components/button/button';
import PaymentIcon from 'src/img/module-kassa.svg'

function PaymentBodyPaid() {
  return (
    <div className='order-page__payment__body'>
      <MyButton
        className='order-page__check header-font-xs'
        removeDefaultClass
      >
        Чек по операции
      </MyButton>
    </div>
  );
}

export default PaymentBodyPaid;