import React from 'react';
import { SubscriptionDto } from 'merchery-lib';
import { BodyCell } from 'src/components/_utility-components/common-table/body-cell';

interface Props {
  subscription: SubscriptionDto
}

function SubscriptionClientEmail({
  subscription
}: Props) {
  return (
    <BodyCell myClassName='center-align'>
      {subscription.email}
    </BodyCell>
  );
}

export default SubscriptionClientEmail;