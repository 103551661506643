import React, { useContext } from 'react';
import { OrderDeliveryContext } from '../delivery';
import ReactTooltip from "react-tooltip";
import DeliveryDateRow from "src/components/main-pages/orders/order-modules/delivery/selected-delivery/footer-date-row";

export function SelectedDeliveryFooter() {
  const {
    delivery
  } = useContext(OrderDeliveryContext);

  if(!delivery || !(delivery.note && delivery.delivery_service_uuid)) {
    return null
  }

  const deliveryNoteCopyHandler = () => {
    if(!delivery?.note) {
      return false
    }

    navigator.clipboard.writeText(delivery.note)
  }

  return <div className='op-delivery-dates op-delivery_element-paddings'>
    <div className='op-delivery-dates__left-inner'>
      {delivery.registration_date && delivery.shipping_date === null && (
        <DeliveryDateRow
          status="дата регистрации"
          date={delivery.registration_date}
        />
      )}

      {delivery.shipping_date && (
        <DeliveryDateRow
          status="дата отгрузки"
          date={delivery.shipping_date}
        />
      )}

      {delivery.receiving_date && (
        <DeliveryDateRow
          status="дата получения"
          date={delivery.receiving_date}
        />
      )}
    </div>

    <div className='op-delivery-dates__right-inner'>
      <div className='refund-delivery__row delivery-note'>
        <div className='status'>накладная</div>

        <div className='delivery-note-number'
             data-tip={"Скопировать накладную"}
             data-for={'note-number-copy'}
             onClick={deliveryNoteCopyHandler}
        >
          {delivery.note}

          <ReactTooltip
            id={"note-number-copy"}
            resizeHide={true}
            place={'top'}
            multiline={true}
            effect={"solid"}
            isCapture={true}
          />
        </div>
      </div>
    </div>
  </div>;
}
