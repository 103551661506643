import qs from 'qs';
import React, { useCallback, useContext, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { batch, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id, ExtendedProduct, ProductOption, ProductVariantExtended } from 'merchery-lib';
import { mercheryFetch } from '../../../../scripts/fetchConstructor';
import { uuidv4, validateResponse } from '../../../../scripts/functions';
import useMounted from '../../../../scripts/hooks/use-mounted';
import useRouteId from '../../../../scripts/hooks/use-route-id';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import MyButton from '../../../_utility-components/button/button';
import MyCheckbox from '../../../_utility-components/checkbox/index';
import MyInput from '../../../_utility-components/input/index';
import VisualSeparatorLine from '../../../_utility-components/visual-separator-line';
import { ProductVariantFilter } from '../dto/variants.dto';
import { ProductInputHandler, ProductPageContext } from '../product';
import ProductOneOption from './one-option';
import {useDragEndHandler} from "src/scripts/hooks/use-drag-end-handler-react-beautiful-dnd";

interface Props {
  isCreate: boolean,
  inputHandler: ProductInputHandler,
}

export type UpdateOptionsHandler = 
  (id: Id, option?: ProductOption) => boolean;

function ProductOptions({
  isCreate,
  inputHandler,
}: Props) {
  const _isMounted = useMounted()
  const product = useAppSelector(state => state.product)
  const tabIndex = useTabIndex()
  const options = useAppSelector(state => state.productOptions)
  const initOptions = useAppSelector(state => state.initOptions)
  const initVariants = useAppSelector(state => state.initVariants)
  const additionalOptions = useAppSelector(state => state.additionalOptions)
  const moySkladIntegrationOn = useAppSelector(state => state.integrations?.find(s => s.code === 'moy_sklad')?.turned_on || false)

  const { setOptionsLoaded } = useContext(ProductPageContext);
  
  const dispatch = useDispatch()
  const optionsDispatch = (options: ProductOption[]) => 
    dispatch({ type: 'PRODUCTS_OPTIONS', payload: options })
  const initOptionsDispatch = (options: ProductOption[]) => 
    dispatch({ type: 'PRODUCTS_INIT_OPTIONS', payload: options })

  const variantsDispatch = (variants: ProductVariantExtended[]) => 
    dispatch({ type: 'PRODUCTS_VARIANTS', payload: variants })
  const initVariantsDispatch = (variants: ProductVariantExtended[]) => 
    dispatch({ type: 'PRODUCTS_INIT_VARIANTS', payload: variants })

  const additionalOptionsDispatch = (additionalOptions: boolean) => 
    dispatch({ type: 'PRODUCT_ADDITIONAL_OPTIONS_ACTIVE', payload: additionalOptions })

  const selectedVariantAttributesDispatch = (attrs: Id[]) => 
    dispatch({ type: 'SELECTED_PRODUCTS_VARIANTS_ATTRIBUTES', payload: attrs })
  const selectedVariantFiltersDispatch = (filters: ProductVariantFilter[]) => 
    dispatch({ type: 'SELECTED_PRODUCTS_VARIANTS_FILTERS', payload: filters })

  const productid = useRouteId('productid')

  useEffect(() => {
    if(!isCreate) {
      getOptions()
    } else {
      batch(() => {
        setOptionsLoaded(true)
        additionalOptionsDispatch(false)

        initOptionsDispatch([])
        optionsDispatch([])

        variantsDispatch([])
        initVariantsDispatch([])
        
        selectedVariantAttributesDispatch([])
        selectedVariantFiltersDispatch([])
      })
    }
  }, [])

  const getOptions = async () => {
    const params = qs.stringify({
      product_id: productid,
    }, {arrayFormat: 'comma'})

    const res = await mercheryFetch<ProductOption[]>(`products-options?${params}`, 'GET', {})

    if(!_isMounted.current || !validateResponse(res)) 
      return false

    const options = res.records

    batch(() => {
      setOptionsLoaded(true)
      initOptionsDispatch(options)
      optionsDispatch(options)
      additionalOptionsDispatch(!!options.length)
    })

    return res
  }

  const updateOption: UpdateOptionsHandler = useCallback((id: Id, option?: ProductOption) => {
    if(moySkladIntegrationOn) 
      return false

    const shallowOptions = 
      option !== undefined 
        ? options.map(op => op.id === id ? option : op) 
        : options.filter(op => op.id !== id);

    batch(() => {
      optionsDispatch(shallowOptions)
      if(shallowOptions.length === 0) {
        additionalOptionsDispatch(false)
      }
    })

    return true
  }, [options, ])

  const addBlankOption = () => {
    if(!productid) {
      return false
    }
    const lastOrder = options.length ? options.reduce((a,b) => a.order > b.order ? a : b).order : 0;

    const newOption: ProductOption = {
      id: uuidv4(),
      product_id: productid,
      name_id: uuidv4(),
      title: '',
      values: [],
      order: lastOrder + 1,
      notCreated: true,
      notSaved: true,
      newName: true
    }

    optionsDispatch([
      ...options, 
      newOption
    ])
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, label: keyof ExtendedProduct, type?: string) => {
    const typedValue = type === 'number'
      ? Number(e.target.value)
      : e.target.value;
    inputHandler(label, typedValue)
  }

  const additionalOptionsHandler = () => {
    if(moySkladIntegrationOn) return false;

    batch(() => {
      additionalOptionsDispatch(!additionalOptions)

      if(!options.length || additionalOptions) {
        optionsDispatch([])
        variantsDispatch([])
        selectedVariantAttributesDispatch([])
        selectedVariantFiltersDispatch([])
      }

      if(additionalOptions) {
        optionsDispatch([])
        initVariantsDispatch([])
      }

      if(!additionalOptions && initOptions.length) {
        optionsDispatch([...initOptions])
        variantsDispatch([...initVariants])
      }
    })
  }

  const onDragStart = () => {
    if(moySkladIntegrationOn) return false
    const element = document?.activeElement as HTMLElement | null
    element?.blur()
  }

  const onDragEnd = useDragEndHandler<ProductOption>(
    options,
    (result, provided, newList, removed) => {
      if (result.type.includes("option")) {
        const updatedOrders = newList.map((item, index) => ({ ...item, order: index + 1 }));

        optionsDispatch(updatedOrders);
      }
    }
  )
  
  if(!product) {
    return null
  }
  
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
    >
      <div id='options' className="product-page-element product-page-additional-options">
        <div className="product-page-additional-options-header side-padding-24">
          <h3 className="product-page-h3">Опции</h3>
        </div>

        <div className='product-page-additional-options-labels side-padding-24'>
          <div className={`inputs-row vendor-code`}>
            <h4 className="header-font-s">Артикул</h4>
            <MyInput
              noPlaceholder
              value={product.external_id || ''}
              tabIndex={tabIndex}
              shortInput={true}
              maxLength={32}
              min={0}
              required={false}
              onChange={(e) => onChangeHandler(e, 'external_id')}
            />
          </div>
          <div className={`inputs-row barcode`}>
            <h4 className="header-font-s">Штрихкод</h4>
            <MyInput
              noPlaceholder
              value={product.barcode || ''}
              tabIndex={tabIndex}
              shortInput={true}
              maxLength={32}
              required={false}
              min={0}
              onChange={(e) => onChangeHandler(e, 'barcode')}
            />
          </div>
        </div>

        <div className='side-padding-24'>
          <MyCheckbox
            className='additional-options-switcher text-font-m'
            text='Товар имеет дополнительные опции (цвет, размер и т.д.)'
            actionHandler={additionalOptionsHandler}
            tabIndex={tabIndex}
            condition={additionalOptions}
          />
        </div>

        {additionalOptions ? <>
          {options.length ? (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Droppable type={`option`} droppableId={`option`}>
                {(provided, snapshot) => (
                  <div 
                    className={`product-page-additional-options-options-wrapper`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {options
                      .sort((a, b) => a.order - b.order)
                      .map((o, index) => 
                        <ProductOneOption 
                          key={o.order}
                          option={o}
                          elementIndex={index}
                          updateOption={updateOption} 
                          />
                      )
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ) : <VisualSeparatorLine/>}

          <MyButton
            removeDefaultClass
            className='product-page-additional-options-add-option'
            onClick={addBlankOption}
          >
            <i className="icofont-plus"></i> Добавить опцию
          </MyButton>
        </> : null}
      </div>
    </DragDropContext>
  );
}
export default ProductOptions;