import React, { useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { batch } from 'react-redux';
import { RowItemChangerActions } from 'src/scripts/with-row-components';
import { Id, ExtendedProduct, SetDto } from "merchery-lib";
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { validateResponse } from 'src/scripts/functions';
import MyButton from 'src/components/_utility-components/button/button';
import ConfirmPopup from 'src/components/_utility-components/confirm-popup';
import withAddItemPopup from '../../orders/order-page-popups/add-item-popup';
import { withDraggableParentTag } from '../../products/product-page-modules/one-option-modules/conditiaonal-draggable';
import { ProductRow } from '../../products/products-items/product-items';
import { productLikeFields } from '../../products/products-items/product-items-utils';
import ProductToBeAddedInSet from './product-to-be-added-in-set';
import {useDragEndHandler} from "src/scripts/hooks/use-drag-end-handler-react-beautiful-dnd";
import {RowClickConfig, RowClickPreventClick} from "src/scripts/hooks/use-row-click";

interface Props {
  isNew: boolean,
  set: SetDto,
  setDispatch: (changedSet: Partial<SetDto>) => void
}

function ProductsInSet({
  isNew,
  set,
  setDispatch
}: Props) {
  const _isMounted = useRef(true);
  const shownFields = [
    'grab',
    'src',
    'name',
    'external_id',
    'price',
    'config'
  ] as const;
  const { products, products_ids } = set;

  const productsInIdsOrder = products_ids
    .map(id => products && products.find(product => product.id === id))
    .filter(a => a) as ExtendedProduct[]

  const [addItemPopupOpen, setAddItemPopupOpen] = useState(false);
  const [confirmDeletion, setConfirmDeletion] = useState<ExtendedProduct | false>(false);

  const deleteProductItemFromSet = () => {
    if(confirmDeletion) {
      deleteProducts([confirmDeletion.id])
    }
  }

  const changer = (item: ExtendedProduct, action: RowItemChangerActions) => {
    if(!set.products) {
      return false
    }

    if(action === 'delete') {
      setConfirmDeletion(item)
      return;
    }

    if(action === 'change') {
      setDispatch({
        ...set,
        products: set.products.map(p => p.id === item.id ? item : p),
      })
      return;
    }
  }

  const addProducts = async (items: ExtendedProduct[]) => {
    if(!set.products) {
      return false
    }
    const withNewIds = [...set.products_ids, ...items.map(i => i.id)]
    const withNewProducts = [...set.products, ...items]

    if(!isNew) {
      changeProducts(withNewIds)
    } else {
      setDispatch({
        products_ids: withNewIds,
        products: withNewProducts
      })
    }

    return true
  }

  const deleteProducts = (items_ids: Id[]) => {
    if(!set.products) {
      return false
    }

    const withoutDeletedIds = set.products_ids.filter(id =>
      !items_ids.some(delId => delId === id)
    )
    const withoutDeletedProducts = set.products.filter(product =>
      !items_ids.some(delId => delId === product.id)
    )

    if(!isNew) {
      changeProducts(withoutDeletedIds)
    } else {
      setDispatch({
        products_ids: withoutDeletedIds,
        products: withoutDeletedProducts
      })
    }
  }

  const changeProducts = (items_ids: Id[]) => {
    mercheryFetch<SetDto[]>('sets', 'PATCH', {
      toChange: [{
        id: set.id,
        products_ids: items_ids
      }]
    })
    .then((sets) => {
      if(!_isMounted.current || !validateResponse(sets) || !sets.records?.[0])
        return false;

      setDispatch(sets.records[0])
    })
  }

  const onDragEnd = useDragEndHandler<Id>(
    products_ids,
    (result, provided, newList, removed) => {
      batch(() => {
        setDispatch({products_ids: newList})
        changeProducts(newList)
      })
    }
  )

  const rowClickConfig: RowClickConfig = (id: Id) => ({
    to: `/app/products/${id}`,
  })

  const preventClickClassNames: RowClickPreventClick = [
    '.grab-zone',
    '.item__config'
  ]

  if(set.newSet) {
    return null
  }

  return (
    <section className='collection-page__products'>
      <div className='collection-page__products__wrapper'>
        <div className='col-products__header'>
          <h3 className='header-font-l'>Товары в комплекте</h3>

          <MyButton className='white-btn collection-page__add-product header-font-xs'
            onClick={() => setAddItemPopupOpen(true)}
          >
            + Добавить товар
          </MyButton>
        </div>

        {addItemPopupOpen ?
          <AddItemsInSet
            popupClose={() => setAddItemPopupOpen(false)}
            addItems={addProducts}
            filters={{}}
          />
        : null}

        <div className='collection-page__products__inner'>
          <DragDropContext
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId={`products-in-collection`} type={`products-in-collection`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {productsInIdsOrder
                    .map((item, index) => (
                      <DraggableProductRow key={item.id}
                        item={item}
                        isDraggable={true}
                        innerHandler={true}
                        dragProps={{
                          draggableId: '' + item.id,
                          index,
                        }}
                        className={'row-item'}
                        disabled={false}
                        preventClickClassNames={preventClickClassNames}
                        rowClickConfig={rowClickConfig}
                        componentsFields={productLikeFields}
                        shownFields={shownFields}
                        changer={changer}
                      />
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

      </div>
      
      {confirmDeletion ?
        <ConfirmPopup
          confirmHandler={() => {
            deleteProductItemFromSet()
            setConfirmDeletion(false)
          }}
          deletion={true}
          children={<>
            <div className='confirm-popup__paragraph'>
              Удаление товара из комплекта
            </div>
          
            <div className='confirm-popup__paragraph'>
              Товар <b>{confirmDeletion.name}</b> будет удален из комплекта
            </div>

            <div className='confirm-popup__paragraph'>
              Вы уверены, что хотите продолжить?
            </div>
          </>}
          popupClose={() => setConfirmDeletion(false)}
        /> 
      : null}
    </section>
  );
}

export const AddItemsInSet = withAddItemPopup<ExtendedProduct>(ProductToBeAddedInSet)

const DraggableProductRow = withDraggableParentTag(ProductRow)

export default ProductsInSet;