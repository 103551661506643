import React, { useContext } from 'react';
import MyInput from 'src/components/_utility-components/input/index';
import { OrderDeliveryContext } from '../delivery';
import { CdekDeliveryContext } from './cdek';
import useCdekCost from 'src/scripts/hooks/use-cdek-cost';
import { CdekWidgetComponent } from './cdek-widget';
import {priceToPennies, priceWithPennies} from "src/scripts/functions";

export function CdekAddressAndCost() {
  const {
    delivery,
    changeSelectedDelivery,
  } = useContext(OrderDeliveryContext)
  const {
    modes, 
    currentTariff
  } = useContext(CdekDeliveryContext);

  const deliveryCost = useCdekCost({
    delivery,
    currentTariff
  })

  const isTariffToTheDoor = currentTariff && modes.find(mode => 
    currentTariff.delivery_mode === mode.code
  )?.to_the_door;

  const refreshCost = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    e.stopPropagation();
    changeSelectedDelivery({
      cost: null
    });
  };

  if(!currentTariff) {
    return null
  }

  return <div className='op-delivery-cdek__address-cost'>
    <div className='op-delivery-cdek__address-cost__column op-delivery-cdek__address-cost__column--address'>
      <h4 className='wide-text-font-xxs'>
        {!isTariffToTheDoor
          ? 'пункт выдачи'
          : 'адрес доставки'}
      </h4> 

      {!isTariffToTheDoor ?
        <CdekWidgetComponent/>
      :
        <MyInput
          id="delivery-address"
          name="delivery-address"
          myClassName='delivery-address-input'
          placeholder="Улица, номер дома, корпус, квартира/офис"
          value={delivery?.address || ''}
          onChange={e => {
            changeSelectedDelivery({
              address: e.target.value
            })
          }}
        />
      }
    </div>

    <div className='op-delivery-cdek__address-cost__column'>
      <h4 className='wide-text-font-xxs'>
        сбор за доставку
      </h4>
      
      <MyInput
        id="delivery-cost"
        name="delivery-cost"
        myClassName='delivery-cost-input'
        placeholder="Сумма, ₽"
        value={priceWithPennies(deliveryCost)}
        onChange={e => {
          changeSelectedDelivery({
            cost: priceToPennies(Number(e.target.value))
          })
        }}
        innerChildren={
          <i className="icofont-refresh merchery-input__icon"
            onClick={refreshCost}
          ></i>
        }
      />
    </div>
  </div>;
}