import React, { useContext } from 'react';
import { ptsansCurrency } from 'src/scripts/functions';
import useOrderDelivery from '../hooks/use-order-delivery';
import {OrderContext} from "src/components/main-pages/orders/order-page";

interface Props {
  orderOpened: boolean
}

function OrderDeliveryView({
  orderOpened
}: Props) {
  const {
    order
  } = useContext(OrderContext)
  const delivery = useOrderDelivery(order)

  if(!orderOpened || !delivery) {
    return null
  }

  const hasPointOfIssue = delivery.type === 7 || delivery.type === 9;

  return (
    <div className={`client-order__delivery ${!orderOpened ? 'display-none' : ''}`}>
      <div className='client-order__delivery__left-side'>
        <div className='wide-text-font-xxs'> {hasPointOfIssue ? 'пункт выдачи' : 'курьерская доставка' }</div>
        <div>
          {hasPointOfIssue ? (
            <span className='header-font-s' style={{marginRight: 13}}>
              {delivery.point_id}
            </span> 
          ) : null}

          <span className='text-font-s'>{delivery.address}</span>
        </div>
      </div>

      <div className='client-order__delivery_right-side'>
        <div className='wide-text-font-xxs'> стоимость доставки </div>
        <div className='header-font-s' 
          style={{textAlign: 'right'}}
        >
          {delivery.cost} {ptsansCurrency('₽')}
        </div>
      </div>
    </div>
  );
}

export default OrderDeliveryView;