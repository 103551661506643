import React from 'react';
import { calcPrice, num2str, ptsansCurrency } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import { usePopup } from '../../../../../scripts/hooks/use-popup';
import MyButton from '../../../../_utility-components/button/button';

function OrderItemsDelete({
  deleteItem
}: {
  deleteItem: () => void
}) {
  const items = useAppSelector(state => state.productItemsInContext)
  const selectedItems = useAppSelector(state => state.selectedProductItemsInContext);
  const {RenderButton, RenderPopup, closePopup} = usePopup()

  return (
    <>
      <RenderButton
        className="red-color">
        <i className='icofont-trash'></i>
        Удалить
      </RenderButton>

      <RenderPopup
        className={'confirm-window fixed-on-center'}
        withBlackout
        withCloseBtn
        tabIndexDeep={2}>
          <h2>Удаление {selectedItems.length > 1 ? 'товаров' : 'товара'}</h2>

          <span>
            {selectedItems[0] ? items.find(item => item.id === selectedItems[0])?.name || '' : null} 
            {selectedItems[1] ? ', ' + items.find(item => item.id === selectedItems[1])?.name || '' : null} 
            {selectedItems.length >= 3 ? 'и еще ' + num2str(selectedItems.length - 2, ['товар', 'товара', 'товаров']) : null}
            <span> на {calcPrice(items.filter(item => selectedItems.some(si => si === item.id)))} {ptsansCurrency('₽')}</span>
          </span>

          <div className="confirm-window-btn">
            <MyButton 
              className='white-btn' 
              onClick={closePopup}>
              Отменить
            </MyButton>
            
            <MyButton 
              className="red-btn" 
              onClick={deleteItem}>
              Удалить
            </MyButton>
          </div>
      </RenderPopup>
    </>
  );
}

export default OrderItemsDelete;