import React, { useContext, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Id, ProductOption, ProductsAttrValues } from 'merchery-lib';
import MyButton from 'src/components/_utility-components/button/button';
import MyInput from 'src/components/_utility-components/input';
import draggable from 'src/img/draggable.svg';
import { toastDown } from "src/scripts/functions";
import { OneOptionContext } from '../one-option';

type Props = {
  value: ProductsAttrValues,
  index: number
}

function ProductOneOptionOneValue({
  value,
  index,
}: Props) {
  const {
    warning,
    setWarning,
    option,
    changeOption,
  } = useContext(OneOptionContext)
  const values = useMemo(() => option ? [...option.values] : [], [option?.values])

  const valueChange = (valueId: Id, value: ProductsAttrValues) => {
    if(warning) {
      toastDown('.merchery-wrapper')
      setWarning(false)
    }
    changeOption({
      values: values.map((v) => valueId === v.id ? value : v)
    })
  }

  const deleteOptionValue = (valueId: Id) => {
    changeOption({
      values: 
        values.filter((v) => valueId !== v.id)
          .sort((a,b) => a.order - b.order)
          .map((v, index) => ({...v, order: index + 1}))
    });
  }

  if(!option) {
    return null
  }
  
  return (
    <Draggable
      draggableId={'' + value.id} 
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className='product-page-additional-options-one-option-values-input-wrapper' 
          id={`option-value-${option.order}-${value.order}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className={`product-page-additional-options-one-option-values-input-left`}>
            <div 
              {...provided.dragHandleProps} 
              tabIndex={-1}
              className={`grab-zone`}>
              <img src={String(draggable)} alt=""/>
            </div>

            <div className='product-page-additional-options-one-option-one-value-inputs'>
              <MyInput
                maxLength={124}
                value={value?.value || ""}
                onChange={(e) => 
                  valueChange(value.id, {
                    ...value,
                    value: e.target.value
                  })}
                noPlaceholder
              />

              <MyInput 
                maxLength={124}
                value={value.description || ""}
                onChange={(e) => 
                  valueChange(value.id, {
                    ...value,
                    description: e.target.value
                  })}
                noPlaceholder
                />
            </div>

          </div>
          
          <MyButton
            tabIndex={-1} 
            onClick={() => deleteOptionValue(value.id)}
            className={`product-page-additional-options-one-option-context-btn delete-btn`}
          >
            <i className="icofont-ui-delete"></i>
          </MyButton>
        </div>
      )}
    </Draggable>
  );
}

export default ProductOneOptionOneValue;