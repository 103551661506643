import React from 'react';
import VisibilitySelect from '../../../_utility-components/visibility-select/visibility-select';
import { CollectionDto } from "merchery-lib";

interface Props {
  collection: CollectionDto,
  collectionDispatch: (changes: Partial<CollectionDto>) => void
}

function CollectionSide({
  collection,
  collectionDispatch
}: Props) {
  return (
    <div className='collection-page__sidebar'>
      <VisibilitySelect 
        element={collection}
        onChange={collectionDispatch}
      />
    </div>
  );
}

export default CollectionSide;