import React, {useContext, useState} from 'react';
import useMounted from "../../../../../scripts/hooks/use-mounted";
import {useAppSelector} from "../../../../../scripts/pre-type/use-selector";
import {batch, useDispatch} from "react-redux";
import { ExtendedProduct, Id } from "merchery-lib";
import CopyImage from "../../../../../img/copy.svg";
import MyCheckbox from "../../../../_utility-components/checkbox";
import {mercheryFetch} from "../../../../../scripts/fetchConstructor";
import {validateResponse} from "../../../../../scripts/functions";
import {useLoad} from "../../../../../scripts/hooks/use-load";
import MultiProductsChangePopup, { MultiProductsChangeHandler } from "./multi-products-change-popup";
import { ProductsTableContext } from '../table';

function MultiCopy ({

}: {

}) {
	const _isMounted = useMounted();
	const selectedProductsIds = useAppSelector(state => state.selectedProducts);
  const {
		newProducts,
    setNewProducts,
    setMultiProductsActionCompletedParams,
  } = useContext(ProductsTableContext);

	const dispatch = useDispatch()
	const [, setLoad] = useLoad()
	const selectedIdsDispatch = (selected: Id[]) => dispatch({type: 'SELECTED_PRODUCTS', payload: selected});

	const [copyRemain, setCopyRemain] = useState(true)
	const [copyOptionsAndVariants, setCopyOptionsAndVariants] = useState(true)
	const [copyImages, setCopyImages] = useState(true)

	const onChange: MultiProductsChangeHandler = (activeVisibilityStatus, value) => {
		setLoad(true)

		mercheryFetch<ExtendedProduct[]>('products/copy-products', 'POST', {
			id: selectedProductsIds,
			targetTop: value.top,
			withRemain: copyRemain,
			withImages: copyImages,
			withOptionsAndVariants: copyOptionsAndVariants,
			...(activeVisibilityStatus.id !== 3 && {
				show_date: activeVisibilityStatus.id === 1 ? new Date().getTime() : null
			})
		})
			.then((res) => {
				if(!_isMounted.current || !validateResponse(res)) {
					return false
				}

        batch(() => {
          selectedIdsDispatch([])
          setMultiProductsActionCompletedParams({
            completeText: 'Товары скопированы',
            filters: [{
              itemIds: value.top === undefined ? value.top : [value.top],
              filterName: 'top',
              cleanPrev: true,
              concreteSelected: true,
              cleanOthers: true
            }]
          })
          setNewProducts(new Set([...newProducts, ...res.records.map(item => item.id)]))
        })
      })
			.finally(() => {
				setLoad(false)
			})
	}

	return (
		<MultiProductsChangePopup
			onChange={onChange}
			btnText={<>
				<img src={String(CopyImage)} alt={''}/>
				Скопировать
			</>}
			children={
				<div>
					<h3 className={'header-font-m'} style={{marginBottom: '12px'}}>
						Что скопировать?
					</h3>

					<MyCheckbox
						condition={copyOptionsAndVariants}
						actionHandler={() => setCopyOptionsAndVariants(!copyOptionsAndVariants)}
						text={'Опции и варианты'}
					/>

					<MyCheckbox
						condition={copyRemain}
						actionHandler={() => setCopyRemain(!copyRemain)}
						text={'Остатки'}
					/>

					<MyCheckbox
						condition={copyImages}
						actionHandler={() => setCopyImages(!copyImages)}
						text={'Изображения'}
					/>
				</div>
			}
		/>
	);
}

export default MultiCopy;