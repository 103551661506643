import React from 'react';
import RefundIcon from 'src/img/refund-icon.svg'
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { OrderItemExtended } from 'merchery-lib';

export interface ProductItemRefundProps  {
  item: OrderItemExtended
}

function ProductItemRefund(props: ProductItemRefundProps) {
  const { item } = props
  const refunds = useAppSelector(state => state.refunds)

  const productWasRefunded = refunds && refunds
    .filter((refund) => refund.status !== 'cancel' && refund.order_id === item.order)
    .some(refund => 
      refund.returned_items.some(returnedItem => 
        returnedItem.id === item.id
      )
    )

  return (
    <div
      className='refund-flag__icon-wrapper'
    >
      {productWasRefunded ? 
        <img src={String(RefundIcon)} alt={'refund'}/> 
      : null}
    </div>
  );
}

export default ProductItemRefund;