import {useContext, useEffect, useState} from "react";
import {WebSocketContext} from "../scripts/web.socket";
import {useAppSelector} from "../scripts/pre-type/use-selector";
import {batch, useDispatch} from "react-redux";
import {updateDispatch} from "./dispatches";
import {
  WsResponse, 
  CollectionChangeWs,
  CreateCollectionWsDto,
  DeleteCollectionWsDto,
  ReorderCollectionWsDto,
  UpdateCollectionWsDto,
  CollectionDto 
} from "merchery-lib";
import { 
	createCollection,
	deleteCollection,
  reorderCollection,
	updateCollection,
} from "src/constants/ws-events";
import {CollectionsTableContext} from "../components/main-pages/collections/modules/table";

const collectionsDispatchType = 'COLLECTIONS';

function useCollectionsChangesListener () {
	const {
		lastMessage,
	} = useContext(WebSocketContext);
	const {
		newCollections,
    setNewCollections
	} = useContext(CollectionsTableContext);

	const collections = useAppSelector(state => state.collections);

	const [changesInQueue, setChangesInQueue] = useState<(ReorderCollectionWsDto | CreateCollectionWsDto | DeleteCollectionWsDto)[]>([]);

	const dispatch = useDispatch()

	const reorderCollectionsDispatch = (collections: CollectionDto[]) =>
		dispatch({ type: collectionsDispatchType, payload: collections })

	const updateCollectionsDispatch = collections && updateDispatch(collections, dispatch, collectionsDispatchType);

	const applyRenderChanges = (changes?: typeof changesInQueue) => {
    if(!collections) {
      return false
    }

		let reorderedCollections: CollectionDto[] = [...collections];
    const newCollectionsShallowCopy = new Set(newCollections)

		if(!changes?.length) {
			changes = changesInQueue
		}

		changes.forEach((data) => {
			if('collection' in data) {
				reorderedCollections.push(data.collection)
        newCollectionsShallowCopy.add(data.collection.id)
			} else if('changes' in data) {
				data.changes.forEach((collectionReorderWs) => {
					const collectionIndex = reorderedCollections.findIndex(collection => 
            collection.id === collectionReorderWs.id);

					if (collectionIndex !== -1) {
						reorderedCollections[collectionIndex] = {
							...reorderedCollections[collectionIndex],
							...collectionReorderWs
						}
					}
				})
			} else {
				reorderedCollections = reorderedCollections.filter(
					collection => Array.isArray(data.id)
						? !data.id.some(id => collection.id === id)
						: collection.id !== data.id
				)
			}
		})

		batch(() => {
      setNewCollections(newCollectionsShallowCopy)
      reorderCollectionsDispatch(reorderedCollections)
			setChangesInQueue([]);
		})
	}

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<ReorderCollectionWsDto>;

			if (message.event === reorderCollection &&
				message.data.changes &&
				message.data.initiatorId
			) {
				const currentAdminId = localStorage.getItem('admin')
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<UpdateCollectionWsDto>;
			const changes = message.data.changes;

			if (message.event === updateCollection &&
				message.data.changes
			) {
				console.log('WsResponse<UpdateCollectionWsDto')
        updateCollectionsDispatch &&
				  updateCollectionsDispatch<CollectionChangeWs>(changes)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<DeleteCollectionWsDto>;
			const currentAdminId = localStorage.getItem('admin')

			if (message.event === deleteCollection &&
				message.data.id &&
				message.data.initiatorId
			) {
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<CreateCollectionWsDto>;
			const currentAdminId = localStorage.getItem('admin')

			if (message.event === createCollection &&
				message.data.collection &&
				message.data.initiatorId
			) {
				if(currentAdminId &&
					!isNaN(Number(currentAdminId)) &&
					Number(currentAdminId) === message.data.initiatorId
				) {
					applyRenderChanges([message.data])
				} else {
					setChangesInQueue([...changesInQueue, message.data])
				}
			}
		}
	}, [lastMessage]);

	return [changesInQueue, applyRenderChanges] as const;
}

export default useCollectionsChangesListener