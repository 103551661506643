import React from 'react';
import { FilterTab, FilterTabRules } from './filter-tab';
import useOrderFilters from "../hooks/use-order-filters";

interface Props {
}

function SearchFilters({
}: Props) {
  const [, filterChanger] = useOrderFilters();

  const tabHandler = (rules: FilterTabRules, concreteSelected: boolean) => 
    filterChanger({ 
      itemIds: rules[0],
      filterName: rules[1], 
      concreteSelected, 
      cleanPrev: true, 
      cleanOthers: true
    });
  
  return (
    <div className="search-close-bar-btns">
      <FilterTab
        handler={(rules: FilterTabRules) => tabHandler(rules, false)}
        text={'Все'} 
        rules={[[], undefined]}
      />
      <FilterTab
        handler={(rules: FilterTabRules) => tabHandler(rules, true)}
        text={'активные'} 
        rules={[[1, 2], 'status']}
      />
      {/* <FilterTab handler={(rules: FilterTabRules) => tabHandler(rules, true)}
        text={'не оплачены'}
        rules={[[0], 'paid_sum']}
      /> */}
      <FilterTab handler={(rules: FilterTabRules) => tabHandler(rules, true)} 
        text={'черновики'} 
        rules={[[5], 'status']}
      />
    </div>
  );
}

export default SearchFilters;