import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import draggable from '../../../../img/draggable.svg';

interface Props {
  provided: DraggableProvided
  disabled?: boolean,
}

function ProductItemGrab({
  disabled,
  provided,
}: Props) {
  const grabberClassName = `grab-zone ${disabled ? 'disabled' : ''}`;

  if(!provided) {
    return null
  }

  return (
    <div className={grabberClassName}
      {...provided.dragHandleProps}
    >
      <img src={String(draggable)} alt=""/>
    </div>
  );
}

export default ProductItemGrab;