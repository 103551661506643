import React, {useContext, useEffect, useState} from "react";
import MyInput from "../../../../_utility-components/input/index";
import SettingModuleGroup from "../../setting-module-group";
import {SettingsContext} from "../../settings-context-wrapper";
import {FaviconDropzone, LogoDropzone} from "./owner-elements/owner-dropzones";

export default function SiteOwnerData() {
  const {
    settingsEdit,
    setSettingsEdit,
    siteName, 
    setSiteName,
    ownerEmail, 
    setOwnerEmail,
    ownerPhone,
    setOwnerPhone,
  } = useContext(SettingsContext);

  const [
    localEdit,
    setLocalEdit
  ] = useState(false);

  useEffect(() => {
    if(!settingsEdit) {
      setLocalEdit(false)
    }
  }, [settingsEdit])

  useEffect(() => {
    if(localEdit && !settingsEdit) {
      setSettingsEdit(true)
    }
  }, [localEdit])

  return <SettingModuleGroup
    header={'Данные магазина'}
    className={'site-settings__summary'}
    headerBtnView={'button'}
    setEdit={setLocalEdit}
    edit={localEdit}
  >
    <div className='site-owner__data'>
      <div className='site-settings__group-column'>
        <h4>Название магазина</h4>

        {localEdit ?
          <MyInput 
            value={siteName || ''}
            onChange={e => setSiteName(e.target.value)}
            name={'site_name_display'}
          />
        : <span>{siteName}</span>}
      </div>

      <div className='site-settings__group-column'>
        <h4>Телефон</h4>

        {localEdit ?
          <MyInput 
            value={ownerPhone || ''}
            onChange={e => setOwnerPhone(e.target.value)}
            name={'owner_phone'}
          />
        : <span>{ownerPhone}</span>}
      </div>
      
      <div className='site-settings__group-column'>
        <h4>Email</h4>

        {localEdit ?
          <MyInput 
            value={ownerEmail || ''}
            onChange={e => setOwnerEmail(e.target.value)}
            name={'owner_email'}
          />
        : <span>{ownerEmail}</span>}
      </div>
    </div>

    <div className={"site-logo"}>
      <LogoDropzone
        localEdit={localEdit}
      />

      <FaviconDropzone
        localEdit={localEdit}
      />
    </div>
  </SettingModuleGroup>;
}