import {RussianPostCountryCode} from 'merchery-lib'

export default function useCountryName({
	selectedCountry,
	search,
	countryInputState,
}: {
	selectedCountry: RussianPostCountryCode | undefined,
	search: string,
	countryInputState: number,
}): string {
	// 0 - ''
	// 1 - possibleValueSelected
	// 2 - search

	const selectedCountryName =
		(countryInputState === 0
			? ''
			: countryInputState === 1
				? selectedCountry?.['name-ru']
				: search) || '';

	return selectedCountryName
}