import React from 'react';
import { Id } from 'merchery-lib';
import MyButton from '../button/button';

export interface MySwitcherProps<T extends { [key: string]: any } & {id: Id}> {
  items: T[]
  className: string;
  itemHandler: (id: Id) => void;
  textField?: keyof T;
  selectedItemId: Id;
}

const MySwitcher = <T extends { [key: string]: any } & {id: Id}> ({
  items,
  textField = 'text',
  itemHandler,
  selectedItemId,
  className,
}: MySwitcherProps<T>) => {
  return (
    <div className='my-switcher'>
      {items.map(
        item => {
          const selectedClassName = item.id === selectedItemId ? 'selected' : '';
          const btnText = item[textField]
          return (
            <MyButton
              key={item.id}
              className={`my-switcher__item header-font-s ${className} ${selectedClassName}`}
              onClick={() => itemHandler(item.id)}
              >
              {btnText}
            </MyButton>
          )
        }
      )}
    </div>
  );
}

export default MySwitcher;