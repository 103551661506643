import React, { useEffect, useState } from 'react';
import MyInput from 'src/components/_utility-components/input';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { ExtendedProductStories } from '../../../../../../../merchery-lib/dist';

const productsStoriesDuration = 3200;

type IProps = {
  storiesDispatch: (stories: ExtendedProductStories) => void
}

function ProductStoriesInputs({ storiesDispatch }: IProps) {
  const stories = useAppSelector(state => state.productStories);
  const [name, setName] = useState<string>(stories?.name || '');
  const [subtitle, setSubtitle] = useState<string | null>(stories?.subtitle || '');
  const [duration, setDuration] = useState<number | null>(stories?.duration || productsStoriesDuration);

  useEffect(() => {
    if(stories) {
      storiesDispatch({
        ...stories,
        name,
        subtitle,
        duration
      })
    }
  }, [name, subtitle, duration])

  useEffect(() => {
    if(stories) {
      if(stories.name !== name) 
        setName(stories.name)

      if(stories.subtitle !== subtitle) 
        setSubtitle(stories.subtitle)

      if(stories.duration !== duration) 
        setDuration(stories.duration)
    }
  }, [stories])

  return (
    <div className='product-page__stories-inputs'>
      <div className="product-page__stories-input product-page__stories-input--name">
        <h4 className='header-font-m'>Название серии</h4>
        <MyInput 
          value={name || ''} 
          onChange={e => setName(e.target.value)}
        />
      </div>

      <div className="product-page__stories-input product-page__stories-input--subtitle">
        <h4 className='header-font-m'>Подзаголовок</h4>
        <MyInput 
          value={subtitle || ''} 
          placeholder='опционально'
          noPlaceholder
          onChange={e => setSubtitle(e.target.value)}
        />
      </div>

      <div className="product-page__stories-input product-page__stories-input--duration">
        <h4 className='header-font-m'>Время, мс</h4>
        <MyInput 
          value={duration || ''} 
          onChange={e => setDuration(+e.target.value)}
        />
      </div>
    </div>
  );
}

export default ProductStoriesInputs;