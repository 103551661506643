/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect } from 'react';
import { useAppSelector } from "src/scripts/pre-type/use-selector";
import { useDispatch } from 'react-redux';

interface Props {
  children: React.ReactElement | JSX.Element | string | null | Array<React.ReactElement | JSX.Element | string | null>
  fixed?: boolean
  className?: string
  topPanelOpened?: boolean
}

function TopPagePanel({
  fixed,
  className,
  children,
  topPanelOpened
}: Props) {
  const topPanelHeight = useAppSelector(state => state.staticValues.top_panel_height + 'px')
  const opened = useAppSelector(state => state.topPanelShown);
  const dispatch = useDispatch();
  const dispatchChange = (payload: boolean) => dispatch({ type: 'TOP_PANEL_SHOWN_STATUS', payload})

  useEffect(() => {
    if(!!topPanelOpened !== opened) {
      dispatchChange(!!topPanelOpened)
    }
  }, [topPanelOpened])

  if(topPanelOpened !== undefined ? !topPanelOpened : !opened) {
    return null
  }
  const topPanelClassName = `top-page-panel ${fixed ? 'fixed' : ''} ${className || ''}`
  return (
    <div className={topPanelClassName} style={{height: topPanelHeight}}>
      <div className='top-page-panel__inner'>
        {children}
      </div>
    </div>
  );
}

export default TopPagePanel;