import React, {useMemo, useRef, useState} from 'react';
import {usePopup} from "../../../scripts/hooks/use-popup";
import ReactDOM from "react-dom";
import MyButton from "../button/button";
import VisibilitySelect from "./visibility-select";

function MassVisibilityPopup ({
	elements,
	onChange,
	entityNameInHeader,
}: {
	elements: { show_date: number | null }[],
	onChange: (changes: {show_date: number | null}) => void,
	entityNameInHeader: string,
}) {
	const isValueTheSame = elements.every(
		item =>
			item.show_date === elements[0].show_date
	);

	const averageElement = useMemo(() => ({
		show_date: elements?.length && isValueTheSame ? elements?.[0].show_date : undefined
	}), [])

	const {
		RenderPopup, RenderButton, closePopup
	} = usePopup();

	const [
		value,
		setValue
	] = useState<{
		show_date: number | null | undefined
	}>(averageElement)

	return (
		<>
			<RenderButton
				removeDefaultClass={true}
				className={'flex-center blue-color header-font-s'}
				style={{gap: '4px'}}
			>
				<i className="icofont-ui-edit"></i>
				Изменить статус
			</RenderButton>

			{ReactDOM.createPortal(
				<RenderPopup
					className={'fixed-on-center confirm-popup'}
					withCloseBtn
					tabIndexDeep={3}
				>
					<h3 className={'header-font-xl'}>Изменение статуса {entityNameInHeader}</h3>

					<div style={{display: 'flex', flexDirection: 'column', rowGap: '8px'}}>
						<h4 className={'header-font-s'}>Статус для выбранных коллекций</h4>

						<VisibilitySelect
							element={averageElement}
							multiFormat={true}
							onChange={setValue}
						/>
					</div>

					<div className={'flex-wrapper'}>
						<MyButton
							className={'white-btn'}
							onClick={closePopup}
						>
							Отменить
						</MyButton>

						<MyButton
							className="blue-btn"
							onClick={() => {
								if(value.show_date !== undefined) {
									onChange({show_date: value.show_date})
									closePopup()
								}
							}}
						>
							Сохранить
						</MyButton>
					</div>

				</RenderPopup>
			, document.body)}
		</>
	);
}

export default MassVisibilityPopup;