import React from 'react';
import ClientCreate from './create';

interface Props {
  
}

function Header(props: Props) {

  return (
    <div className='clients-page__header'>
      <h1>Покупатели</h1>

      <ClientCreate/>
    </div>
  );
}

export default Header;