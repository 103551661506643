import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Id, CharScopeDto, CharsLabelDto, CharValue, MultiSelectDto } from 'merchery-lib';
import { uuidv4 } from '../../../../../scripts/functions';
import { useAppSelector } from '../../../../../scripts/pre-type/use-selector';
import Grabber from '../../../../_utility-components/grabber';
import TrashBtn from '../../../../_utility-components/trash-btn/index';
import CharLabelBody from './char-label-modules/label-body';
import CharLabelHeader from './char-label-modules/header';
import MultiSelectEditing from './char-label-modules/multi-select-editing';
import {
  OneCharContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/one-char-logic";
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";

function getRusLabelTypeText (label: CharsLabelDto) {
  switch(label.type_id) {
    case 1: return `(число, ${label.unit})`;
    case 2: return '(текст)';
    case 3: return '(длинный текст)';
    case 4: return '(множественный выбор)';
    default: return '';
  }
}

interface Props {
  label: CharsLabelDto,
  labelsDispatch: (label: CharsLabelDto) => void,
  removeLabelAndDispatch: (label: CharsLabelDto) => void,
}

export interface CharLabelIntermediateValue {
  id: Id | null | undefined, 
  value: CharValue | undefined
}

function CharLabel({
  label,
  labelsDispatch,
  removeLabelAndDispatch
}: Props) {
  const {
    scopes: allScopes,
    multiSelects: multiSelects,
    setMultiSelects: setMS,
    setScopes,
    page,
    charEditingId,
  } = useContext(CharacteristicsContext)
  const {
    selectedOptionValue,
    thisCharGroup: group,
    charScope,
  } = useContext(OneCharContext)

  const charEditing = charEditingId === group?.id
  const multiSelectLabel = useMemo( () => multiSelects.find(ms => ms.label_id === label.id), [label.id, multiSelects] );
  const product = useAppSelector( state => state.product );
  const productId = product?.id

  const categoryId = product?.top;
  const charScopeValue = selectedOptionValue?.id || null

  const scopes = useMemo(() => 
    allScopes.filter(scope => scope.label_id === label.id)
  , [allScopes, label] );

  const currentShownScope =  
    scopes.find(scope => 
      scope.option_id === charScope 
      && scope.option_value_id === charScopeValue
      && scope.product_id === productId
    ) || null
  
  const [value, setValue] = useState<CharLabelIntermediateValue>({id: currentShownScope?.value_id, value: currentShownScope?.value});

  const createMultiSelect = (label_id: Id) => {
    const newMS: MultiSelectDto = {
      id: uuidv4(),
      label_id: label_id,
      names: [],
      newMultiSelect: true
    }

    setMS([...multiSelects, newMS])
  }

  useEffect(() => {
    if(!multiSelectLabel && label.type_id === 4 && charEditing) {
      createMultiSelect(label.id)
    }
  }, [label.type_id, charEditing, multiSelectLabel, label.id])

  const removeNewMultiSelects = useCallback(() => {
    setMS(multiSelects.filter(ms => !(ms.label_id === label.id && ms.newMultiSelect)))
  }, [label.id, multiSelects, setMS])

  const labelChangeByField = useCallback((changes: Partial<CharsLabelDto>) => {
    const updatedLabel = {...label, ...changes};
    if(label.type_id === 4 && changes?.type_id !== 4) {
      removeNewMultiSelects()
    }
    labelsDispatch(updatedLabel);
    return updatedLabel;
  }, [label, labelsDispatch, removeNewMultiSelects])

  useEffect(() => {
    const valueHasBeenChanged = currentShownScope?.value !== value.value
    if(valueHasBeenChanged) {
      const scopeId = currentShownScope?.id;
      
      if(scopeId) {
        if(value) {
          scopeChange([{
            id: scopeId,
            value: value.value,
            ...(value.id && {value_id: value.id})
          }])
        } else {
          setScopes(allScopes.filter(s => s.id !== scopeId))
        }
      } else {
        scopeCreate({
          value: value.value,
          option_value_id: charScopeValue,
          ...(value.id && {value_id: value.id})
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if(currentShownScope && currentShownScope?.value !== value.value) {
      setValue({
        id: currentShownScope.value_id, 
        value: currentShownScope.value
      })
    }
  }, [currentShownScope])

  const scopeCreate = (props: Partial<CharScopeDto>) => {
    if(!productId) return false

    const newScope: CharScopeDto = {
      id: uuidv4(),
      label_id: label.id,
      product_id: productId,
      value: value.value || null,
      value_id: props.value_id || value.id || null,
      option_id: charScope,
      option_value_id: charScopeValue || null,
      newScope: true
    }

    setScopes([...allScopes, newScope])

    return newScope;
  }

  const scopeChange = (scopeProps:( Partial<CharScopeDto> & {id: Id})[]) => {
    const scopesWithChanges = allScopes.map(scope => {
      const changedScope = scopeProps.find(sc => sc.id === scope.id);
      if(!changedScope) return scope;

      const {id, ...rest} = changedScope
      return {
        ...scope,
        ...rest
      }
    })
    setScopes(scopesWithChanges)
  }

  const editingClassName = charEditing ? 'char-label--editing' : '';
  const typedClassName = (() => {
    switch(label.type_id) {
      case 1: return 'char-label_type_number';
      case 4: return 'char-label_type_multiselect';
      default: return '';
    }
  })()
  const charLabelClassName = `char-label ${page === 'categories' && !charEditing ? 'char-label__bubble text-font-s' : ''} ${editingClassName} ${typedClassName}`;

  if(page === 'categories' && !charEditing) {
    return (
      <div className={charLabelClassName}>
        {label.name} <span className='char-label__bubble__half-opacity'>{getRusLabelTypeText(label)}</span>
      </div>
    )
  }

  return (
    <Draggable
      draggableId={`group-${label.char_group}_label-${label.order}`}
      index={label.order}
      isDragDisabled={!charEditing}
    >
      {(provided, snapshot) => 
        <div
          className={charLabelClassName}//@ts-ignore
          valueid={`group-${label.char_group}_label-${label.order}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {charEditing && provided.dragHandleProps ?
            <Grabber {...provided.dragHandleProps} />
          : null}

          <CharLabelHeader
            label={label}
            charEditing={charEditing}
            labelChangeByField={labelChangeByField}
          />

          <CharLabelBody
            label={label}
            currentShownScope={currentShownScope}
            charEditing={charEditing}
            value={value}
            setValue={setValue}
            scopeCreate={scopeCreate}
            labelChangeByField={labelChangeByField}
          />
          
          {charEditing ?
            <TrashBtn
              handler={() => removeLabelAndDispatch(label)}
            /> 
          : null}

          {charEditing && multiSelectLabel && categoryId ?
            <MultiSelectEditing
              labelId={label.id}
              thisMultiSelect={multiSelectLabel}
              categoryId={categoryId}
            />
          : null}
        </div>
      }
    </Draggable>
  );
}
export default CharLabel;