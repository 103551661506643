import React from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { batch, useDispatch } from 'react-redux';
import { ImageExtended, ProductVariantExtended, Id, ImageCreate, ImageDeleteResponse, MyResponse } from 'merchery-lib';
import { extractImages } from 'src/scripts/utils/extractImages';
import {mercheryFetch, } from 'src/scripts/fetchConstructor';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import MyDropzone, { dropzoneAcceptAllImagePreset, postBase64 } from '../../../../_utility-components/dropzone';
import VariantImage from './one-variant-image';
import { validateResponse } from 'src/scripts/functions';

function ProductOneVariantDropzone(props: {
  variant: ProductVariantExtended,
}) {
  const {
    variant,
  } = props;
  const _isMounted = useRef(true);

  const disableDropzone = Boolean(variant.newVariant)

  const product = useAppSelector(state => state.product)
  const variants = useAppSelector(state => state.productVariants)
  const initVariants = useAppSelector(state => state.initVariants)
  
  const dispatch = useDispatch()
  const variantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_VARIANTS', payload: variants })
  const initVariantsDispatch = (variants: ProductVariantExtended[]) => dispatch({ type: 'PRODUCTS_INIT_VARIANTS', payload: variants })


  const extractedImages = useMemo(() => {
    return (variant.id && extractImages(variant.src.find(img => img.main) || variant.src[0], 'small')) || null
  }, [variant])

  const removeImagesFromVariants = useCallback((variantId: Id, deletedImagesId: Id[] = []) => {
    const imageRemover = (variant: ProductVariantExtended) => {
      if(variantId !== variant.id) return variant;
      return {
        ...variant,
        src: variant.src.filter(img => !deletedImagesId.some(delImgId => img.id === delImgId))
      }
    }

    batch(() => {
      variantsDispatch(variants.map(imageRemover))
      initVariantsDispatch(initVariants.map(imageRemover))
    })
  }, [initVariants, variants])

  const uploadNewImages = async (filesToAdd: ImageCreate[]): Promise<undefined | MyResponse<ImageExtended | ImageExtended[], false>> => {
    if(!product) {
      return undefined
    }

    const res = await mercheryFetch<ImageExtended[]>('images/create', 'POST', {
      module_id: variant.id,
      module: 'Variant',
      newImages: filesToAdd,
      cropSizes: ['original', 'medium', 'small'],
    }, {
      withoutToastUp: true
    })

    if(!validateResponse(res) || !_isMounted.current) {
      return res
    }

    const createdImages = res.records
  
    const variantAddNewImages = (v: ProductVariantExtended) => v.id === variant.id ? {...v, src: createdImages} : v;

    batch(() => {
      variantsDispatch(variants.map(variantAddNewImages))
      initVariantsDispatch(initVariants.map(variantAddNewImages))
    })
    
    return res
  }

  const filesStateChanger = async (newFiles: File[]) => {
    let imageFiles: ImageCreate[] = []

    for (const file of newFiles) {
      const imageProps: ImageCreate = {
        imageName: file.name,
        order: 1,
        videoSource: null,
        videoLink: null,
        image: String(await postBase64(file))
      }

      imageFiles.push(imageProps)
    }

    return uploadNewImages(imageFiles)
  }

  const removeFiles = useCallback(() => {

    mercheryFetch<ImageDeleteResponse | ImageDeleteResponse[]>('images', 'DELETE', {
      filters: {
        module_id: variant.id,
        module: 'Variant'
      },
    })
    .then((res) => {
      if(!validateResponse(res)) return false

      const deletedIds = Array.isArray(res.records) ? res.records.flatMap(d => d.deletedImagesId) : res.records.deletedImagesId
      removeImagesFromVariants(variant.id, deletedIds)
    })
  }, [removeImagesFromVariants, variant.id])

  return (
    <MyDropzone
      className={'variant-dropzone'}
      fileHandler={filesStateChanger}
      files={Array(1)}
      disabled={disableDropzone}
      optionalProps={{
        multiple: false,
        maxFiles: 1,
        disabled: disableDropzone,
      }}
      accept={[...dropzoneAcceptAllImagePreset]}
      contentRender={
        <VariantImage
          images={extractedImages}
          removeFiles={removeFiles}
          disableDropzone={disableDropzone}
          />
      }
    />
  )
}

export default ProductOneVariantDropzone