import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface NotFoundProps {
  optionalMessage?: string,
  contextLinks?: (LinkProps & {text: string})[]
}

function NotFoundGlobal(props: NotFoundProps) {
  return (
    <NotFoundInner {...props}/>
  );
}

function NotFoundLocalApp(props: NotFoundProps) {
  return (
    <NotFoundInner {...props}/>
  );
}

function NotFoundInner(props: NotFoundProps) {
  const {
    optionalMessage,
    contextLinks
  } = props

  return (
    <div className='not-found-page'>

      <div className="not-found-page-header" style={{
        height: 200,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: 'hsl(223deg 86% 63%)',
        alignItems: 'center',
        columnGap: 20,
      }}>
        <Link to="/app/"
          style={{
            color: 'white',
            width: 'max-content',
            height: 'max-content',
          }}
        >В админку</Link>

        <Link to="/sign-in/" style={{
          color: 'white',
          width: 'max-content',
          height: 'max-content',
        }}>Зайти под другим логином</Link>

        {contextLinks && contextLinks?.map((linkProps, index) => {
          const {to, text, ...rest} = linkProps
          return (
            <Link 
              key={index}
              to={to}
              style={{
                color: 'white',
                width: 'max-content',
                height: 'max-content',
              }}
              {...rest}
            >
              {text}
            </Link>
          )
        })}
      </div>

      <h1>Not found</h1>

      {optionalMessage ?
        <p>{optionalMessage}</p>
      : null}

    </div>

  )
}

export {
  NotFoundGlobal,
  NotFoundLocalApp
};