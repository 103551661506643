import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import draggable from '../../../../../../img/draggable.svg';

function DragElement({
  provided
}: {
  provided: DraggableProvided
}) {
  return (
    <div 
      {...provided.dragHandleProps} 
      tabIndex={-1}
      className={`grab-zone`}
    >
      <img src={String(draggable)} alt=""/>
    </div>
  );
}

export default DragElement;