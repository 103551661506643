import React, {useContext, useEffect, useState} from "react";
import {usePossibleValues} from "../../../../../../scripts/hooks/use-possible-values";
import {batch} from "react-redux";
import MyInput from "../../../../../_utility-components/input";
import Popup from "../../../../../_utility-components/popup";
import MyButton from "../../../../../_utility-components/button/button";
import {RussianPostCountryCode} from 'merchery-lib'
import useCountryName from "./hooks/use-country-name";
import {OrderDeliveryContext} from "../delivery";
import useSelectedCountry
	from "src/components/main-pages/orders/order-modules/delivery/russian-post/hooks/use-selected-country";

export function CountryCode () {
	const {
		delivery,
		changeSelectedDelivery,
	} = useContext(OrderDeliveryContext);
	const defaultCode = 643 // rus

	const {
		search, setSearch, setShowData, dataLoading, data, showData
	} = usePossibleValues<RussianPostCountryCode, 'name-ru'>({
		urlPath: 'integrations/russian-post/country-codes',
		textField: 'name-ru',
		params: {
			pagination: {
				page: 0,
				size: 20
			}
		}
	});

	const [countryInputState, setCountryInputState] = useState(0);

	const selectedCountry = useSelectedCountry({
		defaultCode,
		codes: data,
	})

	const countryName = useCountryName({
		selectedCountry,
		search,
		countryInputState,
	})

	useEffect(() => {
		if(selectedCountry) {
			setCountryInputState(1)
		}
	}, [selectedCountry])

	useEffect(() => {
		if(delivery?.country_iso_num_code !== selectedCountry?.code) {
			changeSelectedDelivery({
				country_iso_num_code: selectedCountry?.code || null,
			})
		}
	}, [selectedCountry])
	//
	// console.log('selectedCountry', selectedCountry)
	// console.log('countryName', countryName)
	// console.log('data', data)
	// console.log('delivery', delivery)

	const countryInputOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const value = event.target.value;

		batch(() => {
			setSearch(value)
			setCountryInputState(2)
		})
	}

	const countryHandler = (country: RussianPostCountryCode) => {
		batch(() => {
			changeSelectedDelivery({
				country_iso_num_code: country.code,
			})
			setCountryInputState(1)
			setShowData(false)
		})
	};

	return <div className={"delivery_country-code"}>
		<MyInput
			id="delivery-country"
			name="delivery-country"
			myClassName="delivery-country-input"
			placeholder="Страна получателя"
			value={countryName}
			onChange={countryInputOnChangeHandler}
		/>

		{showData ? (
			<Popup
				className={"possible-values-popup op-delivery-cdek__city-dimensions__possible-cities"}
				popupClose={() => setShowData(false)}
				changingDirection={true}
				tabIndexDeep={1}
			>
				{dataLoading ?
					<div className="popup-element">
						Загрузка...
					</div>
				: !data.length ?
					<div className="popup-element">
						Ничего не найдено
					</div>
				:
					<CountryPopup
						data={data}
						countryHandler={countryHandler}
					/>
				}
			</Popup>
		) : null}
	</div>;
}

function CountryPopup ({
	data,
	countryHandler
}: {
	data: RussianPostCountryCode[],
	countryHandler: (country: RussianPostCountryCode) => void
}) {
	return (
		<div className='possible-values-wrapper popup-group'>
			{data.map((n) => (
				<MyButton
					key={n.id}
					removeDefaultClass
					onClick={() => countryHandler(n)}
					className='popup-element'
				>
					{n['name-ru']}
				</MyButton>
			))}
		</div>
	);
}