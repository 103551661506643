import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Id } from 'merchery-lib';
import { MainRouteChild } from '../main-page';
import SetsTable from './modules/table';
import useSetsWsListener from "../../../ws-listeners/sets.listener";

interface Props extends MainRouteChild {

}

interface SetsContextDto {
  selectedSets: Id[],
  setSelectedSets: (ids: Id[]) => void,
  newSets: Set<Id>,
  setNewSets: (newCollections: Set<Id>) => void,
}

export const SetsContext = createContext<SetsContextDto>({
  selectedSets: [],
  setSelectedSets: () => {},
  newSets: new Set([]),
  setNewSets: () => {},
})

function Sets ({ 
  setCurrentPage
}: Props) {
  const [selectedSets, setSelectedSets] = useState<Id[]>([]);
  const [newSets, setNewSets] = useState<Set<Id>>(new Set([]));

  useEffect(() => {
    setCurrentPage('sets')
  }, [])

  const toCreation = useMemo(() => ({
    pathname: '/app/sets/new',
  }), [])

  const providerValue = {
    selectedSets,
    setSelectedSets,
    newSets,
    setNewSets,
  }

  return (
    <SetsContext.Provider value={providerValue}>
      <div className='collections product-sets'>
        <div className="collections__header product-sets__header">
          <h1>Комплекты</h1>

          <Link 
            className='my-btn blue-btn text-font-m'
            to={toCreation}
          >
            Собрать комплект
          </Link>
        </div>

        <SetsTable/>
      </div>
    </SetsContext.Provider>
  );
}

export default Sets;