import useMounted from "src/scripts/hooks/use-mounted";
import {useContext, useEffect, useState} from "react";
import {OrderDeliveryContext} from "src/components/main-pages/orders/order-modules/delivery/delivery";
import {querify, validateResponse} from "src/scripts/functions";
import {mercheryFetch} from "src/scripts/fetchConstructor";
import { RussianPostCountryCode } from "merchery-lib";

export default function useSelectedCountry ({
	defaultCode,
	codes,
}: {
	defaultCode?: number,
	codes: RussianPostCountryCode[],
}): RussianPostCountryCode | undefined {
	const _isMounted = useMounted()
	const {
		delivery,
		currentDeliveryId,
	} = useContext(OrderDeliveryContext);

	const [selectedCountry, setSelectedCountry] = useState<RussianPostCountryCode | undefined>(undefined)

	useEffect(() => {
		const foundSelectedCountryCode = countryNameFromCode(delivery?.country_iso_num_code, codes)
		if(foundSelectedCountryCode) {
			setSelectedCountry(foundSelectedCountryCode)
		}
	}, [delivery])

	useEffect(() => {
		const code = delivery?.country_iso_num_code || defaultCode;

		if (code) {
			const query = querify({
				filters: {
					code: code
				}
			})

			mercheryFetch<RussianPostCountryCode[]>(`integrations/russian-post/country-codes?${query}`, 'GET')
			.then((res) => {
				if (!_isMounted.current ||
					!validateResponse(res) ||
					!res.records?.[0]
				) {
					return false
				}

				setSelectedCountry(res.records[0])
			})
		}
	}, [currentDeliveryId]);

	return selectedCountry
}

function countryNameFromCode (
	isoNumCode: number | null | undefined,
	data: RussianPostCountryCode[]
): RussianPostCountryCode | undefined {
	if(!isoNumCode) {
		return undefined
	}

	const foundCountry = data.find(country => country.code === isoNumCode);

	return foundCountry
}