import React from 'react';
import { ItemChanger } from 'src/scripts/with-row-components';
import { TabIndex } from '../../../../dto/master-dto/tabindex.dto';
import TrashBtn from '../../../_utility-components/trash-btn/trash-btn';

export interface ItemConfigProps <T, >{
  withInlineChange?: true
  changer?: ItemChanger<T>,
  item: T,
  disabled: boolean,
  tabIndex: TabIndex
}

function ItemConfig <T> (props: ItemConfigProps<T>) {
  const { item, changer, disabled } = props;
  const handler = () => changer?.(item, 'delete')

  return (
    <div className='item__config'>
      <TrashBtn 
        // tabIndex={tabIndex}
        handler={handler}
        disabled={disabled}
      />
    </div>
  );
}

export default ItemConfig;