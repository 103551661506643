import React, {memo, useContext, useEffect, useMemo, useRef, useState} from 'react';
import MyInput from '../../../../../../_utility-components/input/index';
import {
  OneCharContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/one-char-logic";
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";
import { CharGroupsDto } from 'merchery-lib';

function HeaderTitle ({
  charEditing,
  charGroupsDispatch
}: {
  charEditing: boolean,
  charGroupsDispatch: (changes: Partial<CharGroupsDto>) => void
}) {
  const {
    thisCharGroup,
  } = useContext(OneCharContext)
  const {
    charGroups: groups,
  } = useContext(CharacteristicsContext)
  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const [localCharEditing, setLocalCharEditing] = useState(false);
  const [name, setName] = useState(thisCharGroup?.name || '');

  useEffect(() => {
    if(charEditing && charEditing !== localCharEditing) {
      setTimeout(() => {
        nameInputRef.current?.focus()
      }, .04)
      setLocalCharEditing(charEditing)
    }
  }, []);

  useEffect(() => {
    if(thisCharGroup && thisCharGroup?.name !== name) {
      setName(thisCharGroup.name)
    }
  }, [thisCharGroup])

  useEffect(() => {
    if(thisCharGroup?.name !== name) {
      let timeOutId = setTimeout(() => {
        charGroupsDispatch({name})
      }, 50);
      return () => clearTimeout(timeOutId)
    }
  }, [name]);
  
  const anotherGroupInCategoryHaveSameName = useMemo(() => {
    const labelWithThisName = groups.find(l => l.name.toLowerCase() === name.toLowerCase());
    return labelWithThisName && labelWithThisName.id !== thisCharGroup?.id
  }, [groups, name, thisCharGroup])

  if(!thisCharGroup) {
    return null
  }
  
  if(!charEditing) {
    return (
      <h4 className='characteristic-header__title header-font-m'>
        {thisCharGroup.name}
      </h4>
    )
  }

  return (
    <MyInput
      inputRef={nameInputRef}
      value={name}
      onChange={(e) => setName(e.target.value)}
      hasError={anotherGroupInCategoryHaveSameName}
      placeholder={anotherGroupInCategoryHaveSameName ? 'Повторяющееся название в одной категории' : ' '}
      myClassName={`characteristic-header__title--input ${!anotherGroupInCategoryHaveSameName ? `placeholder--animation-off` : ''}`}
    >
      <h5 className='header-font-s' style={{marginBottom: '8px'}}>
        Название группы характеристик
      </h5>
    </MyInput>
  )
}

export default memo(HeaderTitle)