import React, {ComponentType, createContext, useCallback, useEffect, useMemo, useState} from "react";
import {batch, useDispatch} from "react-redux";
import {
	CharsWrapperProps
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/product-characteristics";
import {useAppSelector} from "src/scripts/pre-type/use-selector";
import {mercheryFetch} from "src/scripts/fetchConstructor";
import { Id,
	CharGroupsDto,
	CharScopeDto,
	CharsDto,
	CharsLabelDto,
	CharsTypesDto, MultiSelectDto, MultiSelectValueDto, MultiSelectValueName,
  UnitOfMeasurement,
  Category, 
  MyResponse
 } from "merchery-lib";
import {
	getArrayOfObjectsChanges,
	objOfArraysHasProps,
	querify,
	TwoArraysDiffs,
	validateMultipleResponses,
	validateResponse
} from "src/scripts/functions";
import useMounted from "src/scripts/hooks/use-mounted";
import {useLoad} from "src/scripts/hooks/use-load";

export type ICharacteristicsContext = {
	charEditingId: Id | null,
	setCharEditingId: (value: Id | null) => void,
	charGroups: CharGroupsDto[],
	chars: CharsDto[],
	scopes: CharScopeDto[],
	labelTypes: CharsTypesDto[],
	multiSelects: MultiSelectDto[],
	initialMultiSelects: MultiSelectDto[],
	multiSelectValues: MultiSelectValueDto[],
	multiSelectNames: MultiSelectValueName[],
	initialMultiSelectsNames: MultiSelectValueName[],
	initialCharGroups: CharGroupsDto[],
	setInitialCharGroups: (value: CharGroupsDto[]) => void,
	initialChars: CharsDto[],
	setInitialChars: (value: CharsDto[]) => void,
	initialLabels: CharsLabelDto[],
	setInitialLabels: (value: CharsLabelDto[]) => void,
	initialScopes: CharScopeDto[],
	setInitialScopes: (value: CharScopeDto[]) => void,
	initialMultiSelectValues: MultiSelectValueDto[],
  setInitialMultiSelects: (value: MultiSelectDto[]) => void
	setInitialMultiSelectValues: (value: MultiSelectValueDto[]) => void,
  setInitialMultiSelectsNames: (value: MultiSelectValueName[]) => void,
	setCharGroups: (value: CharGroupsDto[]) => void,
	setChars: (value: CharsDto[]) => void,
	setScopes: (value: CharScopeDto[]) => void,
	setLabelTypes: (value: CharsTypesDto[]) => void,
	setMultiSelects: (value: MultiSelectDto[]) => void,
	setMultiSelectValues: (value: MultiSelectValueDto[]) => void,
	setMultiSelectNames: (value: MultiSelectValueName[]) => void,
	charGroupsDiff: TwoArraysDiffs<CharGroupsDto>,
	charsDiff: TwoArraysDiffs<CharsDto>,
	labelsDiff: TwoArraysDiffs<CharsLabelDto>,
	scopesDiff: TwoArraysDiffs<CharScopeDto>,
	multiSelectValuesDiff: TwoArraysDiffs<MultiSelectValueDto>,
	saveHandler: () => void,
	cancelHandler: () => void,
	postReq: <T>(link: string, added: {}[]) => (Promise<MyResponse<T, false>> | undefined)
	patchReq: <T>(link: string, added: {}[]) => (Promise<MyResponse<T, false>> | undefined)
	deleteReq: (link: string, deleted: Id[]) => (Promise<MyResponse<number[]>> | undefined)
} & CharsWrapperProps;

export const CharacteristicsContext = createContext<ICharacteristicsContext>({
	charEditingId: null,
	setCharEditingId: () => {},
	charGroups: [],
	chars: [],
	scopes: [],
	labelTypes: [],
	multiSelects: [],
	multiSelectValues: [],
	multiSelectNames: [],
	initialCharGroups: [],
  initialMultiSelects: [],
  initialMultiSelectsNames: [],
	setInitialCharGroups: () => {},
	initialChars: [],
	setInitialChars: () => {},
	initialLabels: [],
	setInitialLabels: () => {},
	initialScopes: [],
	setInitialScopes: () => {},
	initialMultiSelectValues: [],
  setInitialMultiSelects: () => {},
	setInitialMultiSelectValues: () => {},
	setInitialMultiSelectsNames: () => {},
	setCharGroups: () => {},
	setChars: () => {},
	setScopes: () => {},
	setLabelTypes: () => {},
	setMultiSelects: () => {},
	setMultiSelectValues: () => {},
	setMultiSelectNames: () => {},
	charGroupsDiff: {added: [], changes: [], deleted: []},
	charsDiff: {added: [], changes: [], deleted: []},
	labelsDiff: {added: [], changes: [], deleted: []},
	scopesDiff: {added: [], changes: [], deleted: []},
	multiSelectValuesDiff: {added: [], changes: [], deleted: []},
	saveHandler: () => {},
	cancelHandler: () => {},
	productCategory: 1,
	page: 'product',
	isCreatePage: false,
	postReq: () => new Promise(() => {}),
	patchReq: () => new Promise(() => {}),
	deleteReq: () => new Promise(() => {})
});

export function withFetchAndStoreChars <T extends CharsWrapperProps>(Component: ComponentType<T>) {
	return function WrappedComponent(props: T) {
		const _isMounted = useMounted()
		const dispatch = useDispatch()
		const [, setLoad] = useLoad();

		const {
			productCategory,
			page,
			isCreatePage
		} = props;

		const labelsReducerType = 'CHARS_LABELS';
		const categoriesType = 'PRODUCT_ASSOCIATED_CATEGORIES';
		const unitsType = 'UNITS_OF_MEASUREMENT';

		const currentProductId = useAppSelector(state => state.product?.id);
		const labels = useAppSelector(state => state.labels);
		const options = useAppSelector(state => state.productOptions);

		const setUnits = (value: UnitOfMeasurement[]) =>
			dispatch({type: unitsType, payload: value})

		const setLabels = (value: CharsLabelDto[]) =>
			dispatch({type: labelsReducerType, payload: value})

		const setCategories = (value: Category[]) =>
			dispatch({ type: categoriesType, payload: value})

		const [charEditingId, setCharEditingId] = useState<Id | null>(null);
		const [charGroups, setCharGroups] = useState<CharGroupsDto[]>([]);
		const [chars, setChars] = useState<CharsDto[]>([]);
		const [scopes, setScopes] = useState<CharScopeDto[]>([]);
		const [labelTypes, setLabelTypes] = useState<CharsTypesDto[]>([]);
		const [multiSelects, setMultiSelects] = useState<MultiSelectDto[]>([]);
		const [multiSelectValues, setMultiSelectValues] = useState<MultiSelectValueDto[]>([]);
		const [multiSelectNames, setMultiSelectNames] = useState<MultiSelectValueName[]>([]);

		const [initialCharGroups, setInitialCharGroups] = useState<CharGroupsDto[]>([]);
		const [initialChars, setInitialChars] = useState<CharsDto[]>([]);
		const [initialLabels, setInitialLabels] = useState<CharsLabelDto[]>([]);
		const [initialScopes, setInitialScopes] = useState<CharScopeDto[]>([]);
		const [initialMultiSelectValues, setInitialMultiSelectValues] = useState<MultiSelectValueDto[]>([]);
		const [initialMultiSelects, setInitialMultiSelects] = useState<MultiSelectDto[]>([]);
		const [initialMultiSelectsNames, setInitialMultiSelectsNames] = useState<MultiSelectValueName[]>([]);

		const categoriesRoutePath = 'category/ancestors';
		const charGroupsRoutePath = 'characteristics/groups';
		const charRoutePath = 'characteristics';
		const typesRoutePath = 'characteristics/labels/types';
		const labelsRoutePath = 'characteristics/labels';
		const scopesRoutePath = 'characteristics/scopes';
		const msRoutePath = 'characteristics/multiselects';
		const msValuesRoutePath = 'characteristics/multiselects/values';
		const msNamesRoutePath = 'characteristics/multiselects/names';
		const scopesExtendsRoutePath = 'characteristics/scopes/extend';

		const charGroupsDiff = useMemo(() => getArrayOfObjectsChanges(charGroups, initialCharGroups, ['name', 'category_id']), [charGroups, initialCharGroups]);
		const charsDiff = useMemo(() => getArrayOfObjectsChanges(chars, initialChars, ['order', 'scope']), [chars, initialChars]);
		const labelsDiff = useMemo(() => getArrayOfObjectsChanges(labels, initialLabels, ['name', 'type_id', 'order', 'unit', 'unit_id']), [labels, initialLabels]);
		const scopesDiff = useMemo(() => getArrayOfObjectsChanges(scopes, initialScopes, ['value', 'value_id']), [scopes, initialScopes]);
		const multiSelectValuesDiff = useMemo(() => getArrayOfObjectsChanges(multiSelectValues, initialMultiSelectValues, ['value', 'order']), [multiSelectValues, initialMultiSelectValues]);

		const postReq = useCallback(<T,>(link: string, added: {}[]): Promise<MyResponse<T>> | undefined =>
			added.length ?
				mercheryFetch<T>(link, 'POST', { toCreate: added })
				: undefined
		, [])
		const patchReq = useCallback(<T,>(link: string, changes: {}[]): Promise<MyResponse<T>> | undefined =>
			changes.length ?
				mercheryFetch<T>(link, 'PATCH', { changes })
				: undefined
		, [])
		const deleteReq = useCallback((link: string, deleted: Id[]): Promise<MyResponse<number[]>> | undefined =>
			deleted.length ?
				mercheryFetch(link, 'DELETE', { toDelete: {id: deleted} })
				: undefined
		, [])

		useEffect(() => {
			if(isCreatePage) {
				return;
			}

			mercheryFetch<UnitOfMeasurement[]>('unit-of-measurement', 'GET')
				.then((gotUnits) => {
					if(!_isMounted.current || !validateResponse(gotUnits)) {
						return false
					}

					const units = gotUnits.records;
					setUnits( units )
				})

			return () => {
				batch(() => {
					setChars([])
					setScopes([])
				})
			}
		}, [])

		useEffect(() => {
			getData()
		}, [location.pathname])

		useEffect(() => {
			if(page === 'product') {
				const scopeToDelete = scopes.filter((scope) =>
					scope.option_id !== null &&
					!options.some(option =>
						option.id === scope.option_id &&
						option.values.some(optionValue =>
							optionValue?.id === scope.option_value_id)
					)
				)

				const charsChanges = (
					chars
						.filter(char =>
							!options.some(option =>
								option.id === char.scope)
						)
						.map(char => ({
							id: char.id,
							scope: null
						}))
				)

				batch(() => {
					if(scopeToDelete.length) {
						deleteReq(scopesRoutePath, scopeToDelete.map(scope => scope.id))
						setScopes(scopes.filter(scope => !scopeToDelete.some(delScope => scope.id === delScope.id)))
						setInitialScopes(initialScopes.filter(scope => !scopeToDelete.some(delScope => scope.id === delScope.id)))
					}

					if(charsChanges.length) {
						patchReq(charRoutePath, charsChanges)
						setChars(chars.map(char => {
							const hasChanges = charsChanges.find(change => change.id === char.id)
							if(hasChanges) return ({
								...char,
								...hasChanges
							})
							return char
						}))
						setInitialChars(initialChars.map(char => {
							const hasChanges = charsChanges.find(change => change.id === char.id)
							if(hasChanges) return ({
								...char,
								...hasChanges
							})
							return char
						}))
					}
				})
			}
		}, [options])

		const getData = async () => {
			try {
				if(isCreatePage) {
					return;
				}

				const shortGet = <T,>(path: string, query?: object) =>
					mercheryFetch<T>(`${path}${query ? '?' + querify(query) : ''}`, 'GET')

				setLoad(true)

				if(!productCategory) {
					return false
				}

				const [gotCharGroups,
					gotChars, gotTypes, gotMSNames, gotCategories
				] = await Promise.all([
					shortGet<CharGroupsDto[]>(charGroupsRoutePath, {
						filters: {
							category_id: productCategory,
							deleted: false
						},
						byAncestors: page === 'product'
					}),
					currentProductId
						? shortGet<CharsDto[]>(charRoutePath, {product_id: currentProductId})
						: undefined,
					shortGet<CharsTypesDto[]>(typesRoutePath),
					shortGet<MultiSelectValueName[]>(msNamesRoutePath, {
						filters: {category: productCategory},
						options: {getUpperLevelCategories: true}
					}),
					shortGet<Category[]>(categoriesRoutePath, {filters: {id: productCategory} })
				])

				if(!_isMounted.current ||
					!validateResponse(gotCharGroups) ||
					!validateResponse(gotTypes) ||
					!validateResponse(gotCategories)
				) {
					return false
				}

				const charGroups = gotCharGroups.records;
				const types = gotTypes.records;
				const categories = gotCategories.records;

				batch(() => {
					setLabelTypes(types)

					setCharGroups(charGroups)
					setInitialCharGroups(charGroups)

					if(validateResponse(gotChars)) {
						const chars = gotChars.records;
						setChars(chars)
						setInitialChars(chars)
					}

					if(validateResponse(gotMSNames)) {
						const msNames = gotMSNames.records;
						setMultiSelectNames(msNames)
						setInitialMultiSelectsNames(msNames)
					}
					setCategories(categories)
				})

				const gotLabels = await shortGet<CharsLabelDto[]>(labelsRoutePath, {
					char_group: charGroups.map(char => char.id)
				});

				if(!_isMounted.current || !validateResponse(gotLabels)) {
					return false
				}

				const labels = [...gotLabels.records] || []

				batch(() => {
					setLabels(labels)
					setInitialLabels(labels)
				})

				if(!labels.length) {
					return false
				}

				const labelsWithMultiSelectsIds = labels
					.filter(sc => sc.type_id === 4)
					.map(label => label.id)

				const gotMultiSelects = await shortGet<MultiSelectDto[]>(msRoutePath, {
					label_id: labelsWithMultiSelectsIds
				})

				if(!_isMounted.current) return false
				if(!validateResponse(gotMultiSelects)) return false

				const multiSelects = gotMultiSelects.records || []

				const gotScopes = page === 'product'
					? await shortGet<CharScopeDto[]>(
						scopesExtendsRoutePath,
						{
							label_id: labels.map(ms => ms.id),
							product_id: currentProductId
						})
					: false
				let msValuesRequest = page === 'product' && multiSelects.length
					? shortGet<MultiSelectValueDto[]>(
						msValuesRoutePath,
						{
							filters: {multiselect_id: multiSelects.map(ms => ms.id)}
						})
					: false

				const gotMSValues = await Promise.all([
					...(msValuesRequest ? [msValuesRequest] : [])
				])

				if(!_isMounted.current) return false;

				batch(() => {
					if(gotScopes && validateResponse(gotScopes)) {
						setScopes(gotScopes.records)
						setInitialScopes(gotScopes.records)
					}

					setMultiSelects(multiSelects)
          setInitialMultiSelects(multiSelects)
					if(validateMultipleResponses(gotMSValues)) {
						const values = gotMSValues.flatMap(msv => msv.records) || [];
						setMultiSelectValues(values)
						setInitialMultiSelectValues(values)
					}
				})
			} catch (error) {
				console.log(error)
			} finally {
				_isMounted.current &&
				setLoad(false)
			}
		}

		const scopesUpdate = async (): Promise<false | [ CharScopeDto[], CharScopeDto[] ]> => {
			const scopesChanges = scopesDiff.changes.map(scope => {
				const addValueId = scope.value_id || scopes.find(sc => sc.id === scope.id)?.value_id
				return ({
					...scope,
					...(addValueId && {value_id: addValueId})
				})
			})

			const [createRes, changeRes] = await Promise.all([
				postReq<CharScopeDto[]>(scopesRoutePath, scopesDiff.added),
				patchReq<CharScopeDto[]>(scopesRoutePath, scopesChanges),
				deleteReq(scopesRoutePath, scopesDiff.deleted),
			])

			if(!_isMounted.current || !validateResponse(createRes) || !validateResponse(changeRes)) {
				return false
			}

			return [createRes.records, changeRes.records]
		}

		const charsUpdate = async (): Promise<false | [ CharsDto[], CharsDto[] ]> => {
			const [createRes, changeRes] = await Promise.all([
				postReq<CharsDto[]>(charRoutePath, charsDiff.added),
				patchReq<CharsDto[]>(charRoutePath, charsDiff.changes),
				deleteReq(charRoutePath, charsDiff.deleted),
			])

			if(!_isMounted.current || !validateResponse(createRes) || !validateResponse(changeRes)) {
				return false
			}

			return [createRes.records, changeRes.records]
		}

		const msValuesUpdate = async (scopesIdsForReplace: (Id | undefined)[][]): Promise<[ MultiSelectValueDto[] | false, MultiSelectValueDto[] | false ]> => {
			console.log('scopesIdsForReplace', scopesIdsForReplace)
			let valuesWithReplacedIds = [];

			for (let index = 0; index < multiSelectValuesDiff.added.length; index++) {
				const value = multiSelectValuesDiff.added[index];
				valuesWithReplacedIds.push({...value})

				if(scopesIdsForReplace?.length) {
					valuesWithReplacedIds[index].scope_id = scopesIdsForReplace.find(([uuid, id]) =>
						value.scope_id === uuid
					)?.[1] || value.scope_id
				}
			}

			const [createRes, changeRes] = await Promise.all([
				postReq<MultiSelectValueDto[]>(msValuesRoutePath, valuesWithReplacedIds),
				patchReq<MultiSelectValueDto[]>(msValuesRoutePath, multiSelectValuesDiff.changes),
				deleteReq(msValuesRoutePath, multiSelectValuesDiff.deleted),
			])

			if(!_isMounted.current) {
				return [false, false]
			}

			return [validateResponse(createRes) ? createRes.records : false, validateResponse(changeRes) ? changeRes.records : false]
		}

		const saveHandler = async () => {
			if(objOfArraysHasProps(charsDiff)) {
				await charsUpdate()
			}

			let newScopes: CharScopeDto[] = [];
			let changedScopes: CharScopeDto[] = [];
			let scopesIdsForReplace: (Id | undefined)[][] = [];


			if(objOfArraysHasProps(scopesDiff)) {
				const scopesChanges = await scopesUpdate()
				if(scopesChanges) {
					newScopes = scopesChanges[0]

					scopesIdsForReplace = scopesDiff.added.map(addedScope => ([
						addedScope.id,
						newScopes.find(createdScope =>
							createdScope.label_id === addedScope.label_id &&
							createdScope.option_id === addedScope.option_id &&
							createdScope.option_value_id === addedScope.option_value_id
						)?.id
					]))

					changedScopes = scopesChanges[1]
				}
			}

			let newValues: MultiSelectValueDto[] = [];
			let changedValues: MultiSelectValueDto[] = [];

			if(objOfArraysHasProps(multiSelectValuesDiff)) {
				let valuesWithReplacedIds = []

				for (let index = 0; index < multiSelectValuesDiff.added.length; index++) {
					const value = multiSelectValuesDiff.added[index];
					valuesWithReplacedIds.push({...value})

					if(scopesIdsForReplace?.length) {
						valuesWithReplacedIds[index].scope_id = scopesIdsForReplace.find(([uuid, id]) =>
							value.scope_id === uuid)?.[1] || value.scope_id
					}
				}

				const msValuesChanges = await msValuesUpdate(scopesIdsForReplace)

				if(msValuesChanges) {
					if(msValuesChanges[0]) 
            newValues = msValuesChanges[0]
          
					if(msValuesChanges[1]) 
            changedValues = msValuesChanges[1]
				}
			}

			batch(() => {
				if(!_isMounted.current)
					return false;

				const needCharsUpdate = objOfArraysHasProps(charsDiff)
				if(needCharsUpdate) {
					setInitialChars(chars)
				}

				const needLabelsUpdate = labelsDiff.changes.length
				if(needLabelsUpdate) {
					setInitialLabels(labels)
				}

				const needScopesUpdate = objOfArraysHasProps(scopesDiff)
				if(needScopesUpdate) {
					let notDeletedNotAdded = scopes.flatMap(scope => {
						if(scopesDiff.added.some(addedScope => addedScope.id === scope.id)) {
							return []
						}
						return changedScopes.find(changedScope => changedScope.id === scope.id) || scope
					})

					const updatedScopes = [...notDeletedNotAdded, ...newScopes]
					setScopes(updatedScopes)
					setInitialScopes(updatedScopes)
				}

				const needValueUpdate =
					objOfArraysHasProps(multiSelectValuesDiff)

				if(needValueUpdate) {
					const notDeletedNotAdded = multiSelectValues.flatMap(value => {
						if(multiSelectValuesDiff.added.some(addedValue =>
							addedValue.id === value.id)
						) {
							return [];
						}
						if(scopesDiff.deleted.some(scope => scope === value.id)) return [];

						const changedValue = changedValues && changedValues.find(changedValue => changedValue.id === value.id)
						if(changedValue) return changedValue
						return value
					})

					const updatedValues = [...notDeletedNotAdded, ...newValues]
					setMultiSelectValues(updatedValues)
					setInitialMultiSelectValues(updatedValues)
				}
			})
		}

		const cancelHandler = () => {
			if(!_isMounted.current) return false
			batch(() => {
				if(charGroupsDiff.deleted.length)
					setCharGroups(initialCharGroups)

				if(objOfArraysHasProps(charsDiff))
					setChars(initialChars)

				if(labelsDiff.changes.length) {
					setLabels(labels.map(label =>
						labelsDiff.changes.some(changedLabel =>
							changedLabel.id === label.id)
								? initialLabels.find((l => l.id === label.id)) || label
								: label
					))
				}

				if(objOfArraysHasProps(scopesDiff))
					setScopes(initialScopes)

				if(objOfArraysHasProps(multiSelectValuesDiff))
					setMultiSelectValues(initialMultiSelectValues)
			})
		}

		const contextValue: ICharacteristicsContext = {
			charEditingId,
			setCharEditingId,
			charGroups,
			chars,
			scopes,
			labelTypes,
			multiSelects,
			multiSelectValues,
			multiSelectNames,
			initialCharGroups,
			setInitialCharGroups,
			initialChars,
			setInitialChars,
			initialLabels,
			setInitialLabels,
			initialScopes,
			setInitialScopes,
			initialMultiSelectValues,
			setInitialMultiSelectValues,
			setCharGroups,
			setChars,
			setScopes,
			setLabelTypes,
			setMultiSelects,
			setMultiSelectValues,
			setMultiSelectNames,
			charGroupsDiff,
			charsDiff,
			labelsDiff,
			scopesDiff,
			multiSelectValuesDiff,
			saveHandler,
			cancelHandler,
			productCategory,
			page,
			isCreatePage,
			postReq,
			patchReq,
			deleteReq,
      initialMultiSelects,
      setInitialMultiSelects,
      initialMultiSelectsNames,
      setInitialMultiSelectsNames,
		};

		return (
			<CharacteristicsContext.Provider value={contextValue}>
				<Component {...props} />
			</CharacteristicsContext.Provider>
		);
	};
}