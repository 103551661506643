/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from "src/scripts/pre-type/use-selector";
import { Id, Integration } from "merchery-lib";
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { validateResponse } from 'src/scripts/functions';
import { MainRouteChild } from '../main-page';
import SingleIntegration from './single-integration'

interface Props extends MainRouteChild {
}

function Integrations({
  setCurrentPage
}: Props) {
  const _isMounted = useRef(true);;

  const integrations = useAppSelector(state => state.integrations);

  const dispatch = useDispatch();
  const setIntegrations = (integs: Integration[]) => dispatch({type: 'STORE_INTEGRATIONS', payload: integs})

  useEffect(()=>{
    setCurrentPage('integrations');
    getIntegrations()
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getIntegrations = () => {
    mercheryFetch<Integration[]>('integrations', 'GET')
    .then((res) => {
      if(_isMounted.current && validateResponse(res)) {
        setIntegrations(res.records)
      }
    })
  }

  const integChange = (id: Id, data: Partial<Integration>) => {
    setIntegrations(
      integrations.map(integ => 
        integ.id === id ? {...integ, ...data} : integ)
      )
  }

  return (
    <div>
      {integrations.map(integ => {
        return <SingleIntegration
          key={integ.id}
          integChange={integChange}
          integration={integ}
        />
      })}
    </div>
  );
}

export default Integrations;