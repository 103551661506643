import {
	OneCharProps, SelectOption
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/one-char";
import React, {
	ComponentType,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";
import {batch} from "react-redux";
import {
	CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";
import {useAppSelector} from "src/scripts/pre-type/use-selector";
import {
  compareArrays,
	CompareResult,
	uuidv4,
} from "src/scripts/functions";
import { Id,
	CharsDto,
	CharsLabelDto,
	MultiSelectDto,
  MultiSelectWithPlainNames,
  ProductsAttrValues
} from "merchery-lib";
import useMounted from "src/scripts/hooks/use-mounted";

export type IOneCharContext = {
	clearDeletedCharData: (char: CharsDto) => void,
	thisCharIsEditing: boolean,
	selectedOption: SelectOption,
	selectOptions: SelectOption[],
	toDelete: boolean,
	setToDelete: (value: boolean) => void
	char: CharsDto | null
	setCharScope: (scopeId: Id | null) => void,
	selectedOptionValue: ProductsAttrValues | null,
	setSelectedOptionValue: (value: ProductsAttrValues | null) => void
	charScope: Id | null,
	charLabels: CharsLabelDto[],
	multiSelectDiff: CompareResult<MultiSelectWithPlainNames>,
	charMultiSelects: MultiSelectDto[]
} & OneCharProps;

export const OneCharContext = createContext<IOneCharContext>({
	thisCharGroup: null,
	clearDeletedCharData: () => {},
	thisCharIsEditing: false,
	selectedOption: {
		id: null,
		text: '',
		values: [undefined]
	},
	selectOptions: [],
	toDelete: false,
	setToDelete: () => {},
	char: null,
	setCharScope: () => {},
	selectedOptionValue: null,
	setSelectedOptionValue: () => {},
	charScope: null,
	charLabels: [],
	multiSelectDiff: {added: [], changes: [], deleted: []},
	charMultiSelects: []
})

export function withOneCharLogic <T extends OneCharProps>(Component: ComponentType<T>) {
	return function WrappedComponent(props: T) {
		const {
			thisCharGroup
		} = props
		const _isMounted = useMounted()

		const defaultScopeValue = 0;

		const {
			chars,
			scopes,
			setChars,
			setScopes,
			multiSelectValues,
			setMultiSelectValues,
			multiSelects: allMultiSelect,
      initialMultiSelects,
			charEditingId,
		} = useContext(CharacteristicsContext)

		const thisCharIsEditing = useMemo(() =>
			charEditingId === thisCharGroup?.id
		, [charEditingId, thisCharGroup])

		const char = useMemo(() =>
			chars.find(ch =>
				ch.char_group === thisCharGroup?.id) || null
		, [chars, thisCharGroup]);

		const [toDelete, setToDelete] = useState<boolean>(false);
		const [charScope, setCharScope] = useState<Id | null>(char?.scope || null);

		const productId = useAppSelector(state => state.product?.id);
		const allLabels = useAppSelector( state => state.labels);
		const options = useAppSelector(state => state.productOptions);

		const charLabels = useMemo(() =>
			allLabels
				.filter(label => label.char_group === thisCharGroup?.id)
				.sort((a,b) => a.order - b.order),
		[allLabels, thisCharGroup])

		const multiSelect: MultiSelectDto[] = useMemo(() =>
			allMultiSelect.filter(ms =>
				charLabels.some(l => l.id === ms.label_id))
		, [allMultiSelect, charLabels])

		const initialMultiSelect: MultiSelectDto[] = useMemo(() =>
			initialMultiSelects.filter(ms =>
				charLabels.some(l => l.id === ms.label_id))
		, [allMultiSelect, charLabels])

		const multiSelectDiff = useMemo(() => compareArrays({
      init: initialMultiSelect.map(ms => ({
				...ms,
				names: ms.names.join(', ')
			})),
      actual: multiSelect.map(ms => ({
				...ms,
				names: ms.names.join(', ')
			})),
    }), [multiSelect, initialMultiSelect]);

		useEffect(() => {
			if(_isMounted.current && char && char.scope !== charScope) {
				setCharScope(char.scope || null)
			}
		}, [char])

		useEffect(() => {
			if(charScope !== null) {
				let newChar: CharsDto | false | undefined = undefined;
				batch(() => {
					if(!char?.id) {
						newChar = createChar()
					} else {
						charChangeByField({scope: charScope})
					}

					if((char?.id && char?.scope !== charScope) || (newChar && newChar.id)) {
						const thisCharLabelsIds = charLabels.map(l => l.id);
						const deleteThisCharScopes = scopes.filter(scope => !thisCharLabelsIds.some((labelId) => scope.label_id === labelId))
						setScopes(deleteThisCharScopes)
					}
				})
			}
		}, [charScope])

		const charsDispatch = useCallback((changedChar: CharsDto) =>
			setChars(
				chars.map(char =>
					char.id !== changedChar.id ? char : changedChar
				)
			)
		, [setChars, chars])

		const charChangeByField = useCallback((changes: Partial<CharsDto>) =>
			char && charsDispatch({
				...char,
				...changes
			})
		, [charsDispatch, char])

		const selectOptions: SelectOption[] = useMemo(() => (
			options ? [
				{id: null, text: 'Общее для всех вариантов', values: []},
				...options.map(o => ({
					id: o.id,
					text: `Если выбран ${o.title}`,
					values: o.values
				})),
			] : []
		), [options]);

		const selectedOption: SelectOption = useMemo(() =>
			selectOptions.find(i => i.id === charScope) || selectOptions[0]
		, [charScope, selectOptions]);

		const [selectedOptionValue, setSelectedOptionValue] = useState(
			getCurrentOptionValue({
				valueId: charScope,
				selectedOption
			})
		);

		useEffect(() => {
			if(_isMounted.current && selectedOption) {
				if(selectedOption.id === char?.scope) {
					setSelectedOptionValue(
						getCurrentOptionValue({
							valueId: defaultScopeValue,
							selectedOption
						})
					)
				}

				if(selectedOption.id !== null) {
					setSelectedOptionValue(
						getCurrentOptionValue({selectedOption})
					)
				}

				if(selectedOption.id === null) {
					setSelectedOptionValue(null)
				}
			}
		}, [selectedOption, char]);

		const createChar = () => {
			if(!productId || !thisCharGroup) return false
			const thisProductChars = chars.filter(ch => ch.product_id === productId);
			const arrayOfOrders = [...thisProductChars.map((v) => v.order), 0];
			const maxOrder = Math.max.apply(null, arrayOfOrders);
			const newOrder = maxOrder + 1;

			const newChar: CharsDto = {
				id: uuidv4(),
				char_group: thisCharGroup.id,
				newChar: true,
				order: newOrder,
				product_id: productId,
				scope: charScope
			};
			setChars([...chars, newChar])
			return newChar
		}

		const clearDeletedCharData = (char: CharsDto) => {
      const newChars = chars.filter(ch => ch.id !== char.id);
      const thisCharLabels = charLabels.filter(label =>
        label.char_group === char.id)

      const thisCharScopes = scopes.filter(scope =>
        thisCharLabels.some(label =>
          scope.label_id === label.id)
      )
      
      const newScopes = scopes.filter(scope => !thisCharLabels.some(label => scope.label_id === label.id)
      );

      const newMSValues = multiSelectValues.filter(value => !thisCharScopes.some(scope => value.scope_id === scope.id)
      );

			batch(() => {
				setChars(newChars)
				setScopes(newScopes)
				setMultiSelectValues(newMSValues)
			})
		}

		const contextValue: IOneCharContext = {
			thisCharGroup,
			clearDeletedCharData,
			thisCharIsEditing,
			selectedOption,
			selectOptions,
			toDelete,
			setToDelete,
			char,
			setCharScope,
			selectedOptionValue,
			setSelectedOptionValue,
			charScope,
			charLabels,
			multiSelectDiff,
			charMultiSelects: multiSelect,
		}

		return (
			<OneCharContext.Provider value={contextValue}>
				<Component {...props} />
			</OneCharContext.Provider>
		)
	}
}


export function getCurrentOptionValue ({valueId, selectedOption}: {valueId?: Id | null, selectedOption: SelectOption}): ProductsAttrValues | null {
	if(!selectedOption?.values?.length) {
		return null
	}
	if(valueId) {
		return selectedOption.values.find(i => i && i.id === valueId) || null
	}

	return (selectedOption.values[0] as ProductsAttrValues)
}