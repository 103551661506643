import { getToday } from "../../../scripts/functions";
// import utils from 'XLSX/utils'

function arrayExport (array: unknown[], prefix: string) {
  import('xlsx').then(({utils, writeFile}) => {
    const ws = utils.json_to_sheet(array);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");
    writeFile(wb, `${prefix}_${getToday()}.xls`);
  });
};

export {
  arrayExport}