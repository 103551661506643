// Честно взято с https://www.smashingmagazine.com/2020/03/sortable-tables-react/ и подправлено под себя

import React, { useMemo } from "react";
import useMounted from "./use-mounted";

export type Direction = 'ascending' | 'descending'

export type SortableDataConfig<T> = {
  key: keyof T | string,
  direction: Direction,
}

export type SortableDataHandler<T> = (key: keyof T | string, direction?: Direction) => void

const useSortableData = <T>(items: T[], config?: SortableDataConfig<T>): [
  T[],
  SortableDataHandler<T>,
  SortableDataConfig<T> | undefined
] => {
  const _isMounted = useMounted()
  const [
    sortConfig,
    setSortConfig
  ] = React.useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig) {
      sortableItems.sort((a, b) => {
        const sortKey = sortConfig.key as keyof T;

        // Handle nested properties
        if (typeof sortKey === 'string' && sortKey.includes('.')) {

          const nestedKeys = sortKey.split('.');
          let aValue: any = a;
          let bValue: any = b;
          for (const nestedKey of nestedKeys) {
            aValue = aValue?.[nestedKey] ?? 0;
            bValue = bValue?.[nestedKey] ?? 0;
          }

          if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }

        // Handle regular properties
        if (a[sortKey] < b[sortKey]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortKey] > b[sortKey]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort: SortableDataHandler<T> = (key: keyof T | string, direction?: Direction) => {
    if(!direction) {
      direction = sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending'
    }
    _isMounted.current && setSortConfig({ key: key as keyof T, direction });
  }

  return [ sortedItems, requestSort, sortConfig ];
}

export default useSortableData