import {num2str, validateResponse} from "../../../../../scripts/functions"
import React from "react"
import { useAppSelector } from "../../../../../scripts/pre-type/use-selector"
import ProductsDeletePopupRender from "../../popups/delete-products-popup";
import MassVisibilityPopup from "../../../../_utility-components/visibility-select/mass-visibility-popup";
import {batch, useDispatch} from "react-redux";
import {mercheryFetch} from "../../../../../scripts/fetchConstructor";
import { ExtendedProduct, Id } from 'merchery-lib';
import useMounted from "../../../../../scripts/hooks/use-mounted";
import MultiCategoryReplace from "./multi-category-replace";
import MultiCopy from "./multi-copy";
import {useLoad} from "src/scripts/hooks/use-load";

function HeaderSelectedRowsContextMenu () {
  const _isMounted = useMounted()
  const products = useAppSelector(state => state.products)
  const selectedProductsIds = useAppSelector(state => state.selectedProducts);
  const [, setLoad] = useLoad()

  const selectedProductsText = num2str(
    selectedProductsIds.length, 
    ['товар', 'товара', 'товаров'], 
    ['Выбран', 'Выбрано', 'Выбрано']
  )

  // const selectedVariantsText = num2str(
  //   selectedVariantsIds.length,
  //   ['вариант', 'варианта', 'вариантов'],
  //   ['Выбран', 'Выбрано', 'Выбрано']
  // )
  //
  // const thirdSomethingText = num2str(
  //   [...new Set(
  //     selectedVariants
  //     .map((variantId) =>
  //       variantId
  //     )
  //   )].length,
  //   ['товара', 'товаров', 'товаров']
  // )

  const selectedProducts = products.filter(
    p =>
      selectedProductsIds.some(
        id =>
          p.id === id
      )
  )

  const dispatch = useDispatch()
  const productsDispatch = (products: ExtendedProduct[]) => dispatch({type: 'PRODUCTS', payload: products});
  const selectedIdsDispatch = (selected: Id[]) => dispatch({type: 'SELECTED_PRODUCTS', payload: selected});

  const onChange = (changes: Partial<ExtendedProduct>) => {
    setLoad(true)

    mercheryFetch<ExtendedProduct[]>('products', 'PATCH', {
      changes: selectedProducts.map(
        product => ({
          id: product.id,
          ...changes
        })
      ),
    })
      .then((res) => {
        if(!_isMounted.current || !validateResponse(res)) {
          return false
        }

        const changedProducts = products.map(product => {
          const thisChangedProduct = res.records.find(
            reorderedItem =>
              reorderedItem.id === product.id
          );

          return thisChangedProduct
            ? thisChangedProduct
            : product;
        });

        batch(() => {
          selectedIdsDispatch([])
          productsDispatch(changedProducts)
        })
      })
      .finally(() => {
        setLoad(false)
      })
  }

  return (
    <div>
      <div className="selected-items-menu">
        {selectedProductsIds?.length ?
          <>
            {selectedProductsText}
          </>
        : null}
        
        <div style={{
          display: 'flex',
          columnGap: '32px'
        }}>
          <MultiCopy
          />

          <MultiCategoryReplace
          />
          {/* {!selectedVariants?.length ? 
            <MyButton 
              className="blue-color" 
              onClick={() => {
                popupOpen(7)
                //setCopy true
              }}
            >
              <i className="icofont-copy"></i>
              Копировать
            </MyButton> 
          : null} */}

          <MassVisibilityPopup
            onChange={onChange}
            elements={selectedProducts}
            entityNameInHeader={'коллекций'}
          />

          <ProductsDeletePopupRender/>

        </div>
      </div>
    </div>
  )
}

export default HeaderSelectedRowsContextMenu