import React, {useContext} from 'react';
import { useMemo } from 'react';
import { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../scripts/pre-type/use-selector';
import useRouteId from '../../../../scripts/hooks/use-route-id';
import { useTabIndex } from '../../../../scripts/hooks/use-tabindex';
import ManagersOnPage from '../../../_utility-components/manager-on-page';
import {ProductPageContext} from "../product";
import { ExtendedProduct } from 'merchery-lib';
import OpenLink from '../../../../img/open-link.svg'
import ReactTooltip from 'react-tooltip';

interface Props {
  pageIsCreatePage: boolean,
}

function ProductHeader({
  pageIsCreatePage
}: Props) {
  const productName: ExtendedProduct['name'] = useAppSelector(state => state.product)?.name || '';
  const {
    managersOnPage,
  } = useContext(ProductPageContext)

  return (
    <div className="product-page-header">
      <div className={'product-page-header_left-inner'}>
        <ToProductsLink />

        <h2>
          {pageIsCreatePage 
            ? 'Добавление товара' 
            : productName}
        </h2>
        
        {pageIsCreatePage 
          ? null
          : <ClientSiteProductLink />}
      </div>

      <ManagersOnPage managersOnPage={managersOnPage}/>
    </div>
  );
}

const ToProductsLink = memo(() => {
  const productId = useRouteId('productid');
  const location = useLocation<{prevPage?: string}>();
  const tabIndex = useTabIndex();

  const toParams = useMemo(() => ({
    pathname: '/app/products',
    search: location.state ? location.state.prevPage : '',
    state: {toProduct: productId}
  }), [location, productId])

  return (
    <Link
      tabIndex={tabIndex}
      to={toParams}
      className="to-products-btn hide-for-print"
    >
      <i className="icofont-simple-left"></i>
    </Link>
  )
})

export default ProductHeader;

function ClientSiteProductLink() {
  const product = useAppSelector(state => state.product);
  const settings = useAppSelector(state => state.settings);

  const prodClientDomain = settings.find(setting => setting.callname === 'site_client_front_domain');

  if(!prodClientDomain || !product) {
    return null
  }

  const href = process.env.NODE_ENV === 'development'
    ? `http://localhost:3030/catalog/${product.categoryNav}/${product.id}`
    : `https://${prodClientDomain}/catalog/${product.categoryNav}/${product.id}`;

  return <a
    href={href}
    style={{ position: 'relative', opacity: .8, color: '#4a5267' }}
    data-tip={`Открыть товар на сайте`} 
    data-for={'product-link'} 
  >
    <img 
      src={String(OpenLink)} 
      alt={'Открыть товар на сайте'} 
      width={20} 
      height={20}
    />

    <ReactTooltip
      id={"product-link"}
      resizeHide={true}
      place={'bottom'}
      multiline={true}
      effect={"solid"}
      isCapture={true}
    />
  </a>
}