import React from 'react';
import { SubscriptionDto } from 'merchery-lib';
import { BodyCell } from 'src/components/_utility-components/common-table/body-cell';

interface Props {
  subscription: SubscriptionDto
}

function SubscriptionRemain({
  subscription
}: Props) {
  return (
    <BodyCell myClassName='center-align'>
      {subscription.productItem.remain}
    </BodyCell>
  );
}

export default SubscriptionRemain;