import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { usePopup } from '../../../scripts/hooks/use-popup';
import { VisibilityOption } from '../../main-pages/products/dto/visibility-options.dto';
import MyButton from '../button/button';
import { MyDatePicker } from '../date-input/date-input';
import SelectInput from '../select-input/select-input';

const visibilityOptions = [
  {
    id: 1,
    text: 'Отображается на сайте',
  },
  {
    id: 2,
    text: 'Скрыто',
  }, 
  {
    id: 3,
    text: 'Запланированная публикация'
  }
];

interface Props<T extends {show_date: number | null | undefined}> {
  element: T,
  onChange: (changes: {show_date: number | null}) => void,
  multiFormat?: boolean;
  tabIndexLevel?: number
}

function VisibilitySelect<T extends {show_date: number | null | undefined}>({
  element,
  onChange,
  multiFormat,
  tabIndexLevel,
}: Props<T>) {
  const [openedDatePicker, setOpenedDatePicker] = useState(false);
  const [currentVisibility, setCurrentVisibility] = useState(
    element.show_date ? 
      element.show_date < new Date().getTime() ? 
        visibilityOptions[0] 
      : visibilityOptions[2] 
    : element.show_date === null ?
      visibilityOptions[1] 
    : undefined
  );
  const [plannedPostDate, setPlannedPostDate] = useState(
    currentVisibility ? 
      currentVisibility.id === 3 && 
      element.show_date ? 
        element.show_date 
      : null 
    : undefined
  );

  const onSelect = (item: typeof visibilityOptions[number]) => {
    if(item.id === 3) {
      setOpenedDatePicker(true)
      return false
    }

    batch(() => {
      setCurrentVisibility(item)
      setPlannedPostDate(null)
    })
  }

  useEffect(() => {
    if(element.show_date !== plannedPostDate) {
      const toDefault = 
        element.show_date ? 
          element.show_date < new Date().getTime() ? 
            visibilityOptions[0]
          : visibilityOptions[2]
        : element.show_date === null ?
          visibilityOptions[1]
        : undefined

      // if(toDefault !== undefined) {
        batch(() => {
          setCurrentVisibility(toDefault)
          setPlannedPostDate(toDefault !== undefined ? toDefault.id === 3 && element.show_date ? element.show_date : null : undefined)
        })
      // }
    }
  }, [element.show_date])

  useEffect(() => {
    const newShowDate = !currentVisibility ? undefined :
      currentVisibility.id === 1 ? new Date().getTime() 
    : currentVisibility.id === 3 ? plannedPostDate 
    : null;

    if(currentVisibility && newShowDate !== undefined && plannedPostDate !== undefined) {
      if(currentVisibility.id === 1 && (element.show_date && newShowDate ? element.show_date > newShowDate : true)) {
        onChange({
          show_date: newShowDate
        })
      } else if(currentVisibility.id === 2 && element.show_date !== null) {
        onChange({
          show_date: newShowDate
        })
      } else if(currentVisibility.id === 3 && plannedPostDate !== element.show_date) {
        onChange({
          show_date: plannedPostDate
        })
      }
    }
  }, [currentVisibility, plannedPostDate])

  const onDatePick = (date: number) => {
    batch(() => {
      setCurrentVisibility(visibilityOptions[2])
      setPlannedPostDate(date)
    })
  }

  const removePlannedPostDate = () => {
    batch(() => {
      setCurrentVisibility(visibilityOptions[1])
      setPlannedPostDate(null)
    })
  }
  // ${multiFormat ? 'visibility-select-section__wrapper--fdirection-row' : ''}
  return (
    <div className={`visibility-select-section__wrapper `}>
      <SelectInput<VisibilityOption> 
        className={'grid-element'}
        items={visibilityOptions || []}
        selectedItem={currentVisibility}
        selectHandler={onSelect}
        tabIndexLevel={tabIndexLevel}
        {...(!multiFormat && {
          inputProps: { 
            children: (
              <h5 className="char-label__header header-font-s">
                Статус
              </h5> 
            )
          }
        })}
      />
      
      {openedDatePicker ? 
        <MyDatePicker
          shouldCloseOnSelect={false}
          withWrapper={false}
          startOpen={openedDatePicker}
          value={plannedPostDate || null} 
          onChange={onDatePick}
          disablePast={true}
          onClickOutside={() => setOpenedDatePicker(false)}
        /> 
      : null}

      {plannedPostDate ? 
        <VisibilityDateReselect
          dateTime={plannedPostDate}
          setOpenedDatePicker={setOpenedDatePicker}
          removePlannedPostDate={removePlannedPostDate}
          multiFormat={multiFormat}
        />
      : null}
    </div>
  );
}

export default VisibilitySelect;

function VisibilityDateReselect({
  dateTime,
  setOpenedDatePicker,
  removePlannedPostDate,
  multiFormat,
}: {
  dateTime: number,
  setOpenedDatePicker: (value: boolean) => void,
  removePlannedPostDate: () => void,
  multiFormat?: boolean
}) {
  const {
    RenderPopup,
    closePopup,
    openPopup
  } = usePopup();

  const date = new Date(dateTime);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  } as const;

  const dateString = date.toLocaleString('ru-RU', options);

  return <div className='visibility-date-reselect'>
    <div className={'visibility-date-reselect__text-wrapper'}>
      <h5 className='visibility-date-reselect__header text-font-m'>
        Будет опубликовано
      </h5>

      <div className='text-font-m'>
        {dateString}
      </div>
    </div>

    <div className='visibility-date-reselect__btns'>
      <MyButton 
        className='text-btn blue-color header-font-m'
        removeDefaultClass
        onClick={() => setOpenedDatePicker(true)}
      >
        Изменить
      </MyButton>

      <MyButton
        onClick={() => {
          if(multiFormat) {
            removePlannedPostDate()
          } else {
            openPopup()
          }
        }}
        className='text-btn blue-color header-font-m'
        removeDefaultClass
      >
        Сбросить
      </MyButton>
    </div>

    <RenderPopup
      className={'confirm-popup fixed-on-center'}
      tabIndexDeep={3}
      withCloseBtn
      withBlackout
    >
      <h3 className='confirm-popup__h header-font-xl'>
        Вы действительно хотите удалить отложенную публикацию?
      </h3>

      <div className='confirm-popup__btn-wrapper'>
        <MyButton className={'white-btn width-228'}
          onClick={closePopup}
        >
          Назад
        </MyButton>

        <MyButton className={`blue-btn width-228`}
          onClick={removePlannedPostDate}
        >
          Удалить
        </MyButton>
      </div>
    </RenderPopup>
  </div>
}