import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { mercheryFetch } from "../../../../../scripts/fetchConstructor";
import { formatBytes, validateResponse } from "../../../../../scripts/functions";
import { useAppSelector } from "../../../../../scripts/pre-type/use-selector";
import useCurrentTariff, { TariffMode } from "../../../../../scripts/hooks/use-current-tariff";
import useMounted from "../../../../../scripts/hooks/use-mounted";
import { StorageInfoHeaders, StorageInfo } from "../../dto/file-storage-info-res";
import SettingModuleGroup from "../../setting-module-group";
import Slider from 'react-slider';

export default function SiteFileStorage() {
  const _isMounted = useMounted();

  const fileStorageInfo = useAppSelector(state => state.fileStorageInfo)
  const settings = useAppSelector(state => state.settings);
  const site_tariff = settings.find(setting => setting.callname === 'site_tariff')?.value as number | null;
  const site_tariff_mode = settings.find(setting => setting.callname === 'site_tariff_mode')?.value as TariffMode | null;

  const dispatch = useDispatch()
  const setFileStorageInfo = (info: StorageInfoHeaders) => dispatch({ type: 'FILE_STORAGE_INFO', payload: info })

  const currentTariff = useCurrentTariff(site_tariff, site_tariff_mode);
  const storageInUse = fileStorageInfo && Number(fileStorageInfo['x-container-bytes-used']);
  const maxStorageAvailable = currentTariff && currentTariff.storageLimit;
  const readableStorageInUse = storageInUse && formatBytes({ bytes: Number(storageInUse), decimals: 2, ru: true, targetUnit: 'ГБ', withoutUnit: true });
  const readableMaxStorageAvailable = maxStorageAvailable && formatBytes({ bytes: maxStorageAvailable, decimals: 2, ru: true, targetUnit: 'ГБ' });

  useEffect(() => {
    if(!fileStorageInfo) {
      mercheryFetch<StorageInfo>('images-storage/info', 'GET')
      .then((res) => {
        if(_isMounted.current && validateResponse(res) && res.records.headers) {
          setFileStorageInfo(res.records.headers)
        }
      })
    }
  }, [])

  const storageInUsePercent = storageInUse && maxStorageAvailable
		? (storageInUse / maxStorageAvailable * 100)
				.toFixed(2)
		: null;

  function getColorForPercentage(percentage: number) {
    if (percentage <= 60) {
      return '#1DB447';
    } else if (percentage <= 85) {
      return '#FAB913';
    } else {
      return '#F47373';
    }
  }

  return <SettingModuleGroup
    header={'Файловое хранилище'}
    className={'site-settings__file-storage'}
    headerBtnView={'link'}
  >
    <div className='file-storage__usage-and-limit header-font-m'>
      <div className='file-storage__usage-and-limit__row header-font-xs'>
        <div>
          Занято {storageInUsePercent}%
        </div>

        <div>
          {readableStorageInUse} из {readableMaxStorageAvailable}
        </div>
      </div>

      <div className='file-storage__slider'>
        <Slider
          disabled={true}
          min={0}
          max={100}
          value={storageInUsePercent ? Number(storageInUsePercent) : undefined}
          renderTrack={(
            props,
            state
          ) => (
            <div 
              {...props} 
              style={{
                ...props.style, 
                backgroundColor: state.index === 0 ?
                  getColorForPercentage(state.value)
                  : 'rgba(228, 228, 228, 0.5)',
              }} 
            />
          )}
        />
      </div>
    </div>

    {storageInUsePercent && Number(storageInUsePercent) > 85 ?
      <div className='text-font-s'>
        Место скоро закончится, вы можете <span className='blue-color'>подключить расширенный лимит</span>
      </div>
    : null}
  </SettingModuleGroup>;
}