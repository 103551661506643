import React, {useContext, useEffect, useMemo, useRef} from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { batch } from 'react-redux';
import { Id, MultiSelectDto, MultiSelectValueName } from 'merchery-lib';
import MultiSelectAddNew from './multi-select-editing-modules/add-new';
import MultiSelectTemplateEditingRow from './multi-select-editing-modules/value';
import {
  CharacteristicsContext
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";

interface Props {
  thisMultiSelect: MultiSelectDto,
  labelId: Id,
  categoryId: Id,
}

function MultiSelectEditing({
  labelId,
  thisMultiSelect,
  categoryId,
}: Props) {
  const newAddedNames = useRef<MultiSelectValueName[]>([]);
  const {
    multiSelects,
    setMultiSelects,
    multiSelectNames: names,
    multiSelectValues: values,
    setMultiSelectValues,
    setMultiSelectNames,
  } = useContext(CharacteristicsContext)

  const changeMultiSelect = (value: MultiSelectDto) =>
    setMultiSelects(
      multiSelects.map(ms =>
        ms.id !== value.id ? ms : value)
    )

  const thisMultiSelectNames = useMemo(() =>  // filter ms values by ms and remove duplicates from scopes
    thisMultiSelect.names.flatMap(msName => 
      names.find(name => msName === name.id) || []
    )
  , [names, thisMultiSelect])

  useEffect(() => { //focus on new row
    const newValues = thisMultiSelectNames.filter(l => l.newName)
    if(newValues.length > newAddedNames.current.length) {
      const lastNewAddedLabel = newValues[newValues.length - 1]
      const newLabelInDOM = document.querySelector<HTMLInputElement>(`[valueid='value-${lastNewAddedLabel.id}'] .multiselect-header__label .merchery-label__input`)
      newLabelInDOM?.focus()
      newAddedNames.current = newValues
    }
    newAddedNames.current = newValues
  }, [thisMultiSelectNames])

  const deleteValue = (name_id: Id) => { // delete value from multiselect
    newAddedNames.current = newAddedNames.current.filter(n => n.id !== name_id)
    const valueName = names.find(n => n.id === name_id)

    batch(() => {
      if(valueName?.newName) { // vanish not created name
        const filteredNames = names.filter(n => n.id !== name_id)
        setMultiSelectNames(filteredNames)
      }

      const updatedMultiSelect = {
        ...thisMultiSelect, 
        names: thisMultiSelect.names.filter(nameId => nameId !== name_id)
      }
      const filteredValues = values.filter(value => value.name_id !== name_id)

      changeMultiSelect(updatedMultiSelect)
      setMultiSelectValues(filteredValues)
    })
  }

  const wrapperClassName = `characteristic-labels multiselect-items-wrapper flex-gap-16 side-padding-16`;
  
  return (
    <Droppable
      type={`multiselect-${labelId}`}
      droppableId={`label_id-${labelId}`}
    >
      {(provided, snapshot) => 
        <div 
          className={wrapperClassName}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <h5 className="header-font-s char-label_sub-label characteristic-labels__header">Значения множественного выбора</h5>
          
          {thisMultiSelectNames.map((msName, index) => 
            <MultiSelectTemplateEditingRow
              key={msName.id}
              msName={msName}
              index={index}
              deleteName={() => deleteValue(msName.id)}
            />
          )}
          {provided.placeholder}

          <MultiSelectAddNew
            labelId={labelId}
            categoryId={categoryId}
          />

        </div>
      }
    </Droppable>
  );
}

export default MultiSelectEditing;