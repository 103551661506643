import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Id } from 'merchery-lib';
import { useTabIndex } from '../../scripts/hooks/use-tabindex';
import MyButton from './button/button';
import { LocationDescriptorObject, LocationState } from 'history';
import { Link } from 'react-router-dom';

interface LocationExtended<T extends BubbleExtends, S = LocationState> extends Omit<LocationDescriptorObject<S>, 'pathname'> {
  pathname: LocationDescriptorObject['pathname'] | ((item: T) => LocationDescriptorObject['pathname'])
}

interface BubbleProps<T extends BubbleExtends, S = LocationState> {
  item: T;
  deleteHandler?: (item: T) => void;
  tooltip?: string;
  onClick?: (item: T) => void;
  linkConfig?: LocationExtended<T, S>;
}

interface BubblesProps<T extends BubbleExtends, S = LocationState> {
  items: T[],
  deleteHandler?: BubbleProps<T, S>['deleteHandler']
  tooltipConstructor?: (item: T) => string
  onClick?: BubbleProps<T, S>['onClick']
  linkConfig?: BubbleProps<T, S>['linkConfig']
}

interface BubbleExtends {
  id: Id;
  name: string;
}


function Bubbles<T extends BubbleExtends, S = LocationState> ({
  items,
  deleteHandler,
  tooltipConstructor,
  onClick,
  linkConfig,
}: BubblesProps<T, S>) {
  const BubbleWithWrapper = withLinkWrapper<T>(Bubble)

  return (
    <div className='merchery-bubbles'>
      {items.map((item) => (
        <BubbleWithWrapper
          key={item.id}
          deleteHandler={deleteHandler}
          item={item}
          onClick={onClick}
          {...(tooltipConstructor && {
            tooltip: tooltipConstructor(item)
          })}
          linkConfig={linkConfig}
        />
      ))}
    </div>
  );
}

export default Bubbles


function Bubble<T extends BubbleExtends, S = LocationState>({
  item,
  deleteHandler,
  tooltip,
}: BubbleProps<T, S>) {
  return (
    <>
      <div className='bubble-text'>
        {item.name}
      </div>

      {tooltip ? 
        <ReactTooltip
          id={`bubble-${item.id}`} 
          resizeHide={true} 
          place={'top'} 
          multiline={true} 
          effect={"solid"} 
          isCapture={true}
        />
      : null}

      {deleteHandler !== undefined ? 
        <MyButton
          removeDefaultClass
          className="bubble-btn"
          onClick={(e) => {
            e.preventDefault()
            deleteHandler(item)
          }}
        >
          <i className='icofont-close'></i>
        </MyButton>
      : null}
    </>
  );
}



function withLinkWrapper<T extends BubbleExtends, S = LocationState>(Component: React.FC<BubbleProps<T, S>>) {
  return (props: BubbleProps<T, S>) => {
    const tabIndex = useTabIndex();
    const { linkConfig, deleteHandler, onClick, tooltip, item } = props;
    const className = `bubble ${deleteHandler ? 'bubble--deletable' : ''} ${onClick ? 'clickable' : ''}`;

    if(!linkConfig) {
      return (
        <div 
          className={className}
          {...(tooltip && {
            'data-tip': tooltip,
            'data-for': `bubble-${item.id}`
          })}
          {...(onClick && {
            onClick: () => onClick(item),
            tabIndex
          })}
        >
          <Component {...props} />
        </div>
      )
    }

    const pathname = linkConfig.pathname === undefined 
      ? undefined 
      : typeof linkConfig.pathname === 'string' 
        ? linkConfig.pathname
        : linkConfig.pathname(item)

    return (
      <Link 
        className={className} 
        to={{
          ...linkConfig,
          pathname
        }}
        {...(tooltip && {
          'data-tip': tooltip,
          'data-for': `bubble-${item.id}`
        })}
        {...(onClick && {
          onClick: () => onClick(item),
          tabIndex
        })}
      >
        <Component {...props} />
      </Link>
    );
  };
}