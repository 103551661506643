import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { login } from '../../scripts/fetchConstructor';
import { toastUp } from '../../scripts/functions';
import history from '../../scripts/history';
import { useTabIndex } from '../../scripts/hooks/use-tabindex';
import MyButton from '../_utility-components/button/button';
import MyInput, { PasswordInput } from '../_utility-components/input/index';

function SignInForm () {
  const [loginValue, setLoginValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')
  const tabIndex = useTabIndex()

  const dispatch = useDispatch()

  const sendRequest = () => {
    if(!loginValue.length || !passwordValue.length) {
      toastUp('Незаполненные поля авторизации')
      return false
    }

    dispatch({type: 'LOADING', payload: true})
    
    login({
      login: loginValue,
      password: passwordValue
    })
    .then((res) => {
      if('success' in res && res.success) {
        localStorage.setItem("admin", res.records.admin.id);
        localStorage.setItem("jwt", res.records.jwt);

        // const pathname = localStorage.getItem('pathnameFromRedirect');
        history.push('/');
        // localStorage.removeItem('pathnameFromRedirect');
      } else {
        toastUp(res.message || 'Ошибка авторизации')
      }
    })
    .finally(() => {
      dispatch({type: 'LOADING', payload: false})
    })
  };

  return (
    <div id="sign-in" className="sign-in-form">
      <h1>Вход в магазин</h1>

      <MyInput name="login" 
        value={loginValue}
        onChange={(e) => setLoginValue(e.target.value)}
        placeholder="Логин или email" 
        onEnter={sendRequest}
        tabIndex={tabIndex}
      />

      <PasswordInput name="password"
        value={passwordValue}
        placeholder="Пароль"
        tabIndex={tabIndex} 
        onEnter={sendRequest}
        onChange={(e) => setPasswordValue(e.target.value)}
      />

      <MyButton 
        id="merchery-btn" 
        type="button" 
        className="blue-btn" 
        onClick={sendRequest}>
        Войти
      </MyButton>

      <Link 
        tabIndex={+tabIndex} 
        className="password-recovery-link" 
        to="/sign-in/password-recovery"
      >
        Забыли пароль?
      </Link>
    </div>
  );
}

export default SignInForm;