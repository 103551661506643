import React, {useContext, useEffect, useRef, useState} from "react";
import HtmlParser from "react-html-parser";
import { BicInfoRes, BicInfoSuccessRes } from "../../../../../dto/bic-info-res";
import { addMessage, toastUp } from "../../../../../scripts/functions";
import SiteSettingsGroupColumn from "../../group-column";
import SettingModuleGroup from "../../setting-module-group";
import { SettingsContext } from "../../settings-context-wrapper";
import MyMaskedInput from "../../../../_utility-components/masked-input/masked-input";
import useMounted from "../../../../../scripts/hooks/use-mounted";

export default function SiteRequisites() {
  const _isMounted = useMounted()

  const {
    settingsEdit,
    setSettingsEdit,
    city, setCity,
    address, setAddress,
    legalAddress, setLegalAddress,
    companyName, setCompanyName,
    bankAccount, setBankAccount,
    bic, setBic,
  } = useContext(SettingsContext);

  const [
    localEdit,
    setLocalEdit
  ] = useState(false);
  const [
    bankData,
    setBankData
  ] = useState<BicInfoSuccessRes | null>(null);

  useEffect(() => {
    if(bic && bic.length === 9) {
      getBankData(bic);
    } else {
      setBankData(null);
    }
  }, [bic])

  const bicOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newBic = event.target.value
    setBic(newBic)
  }

  function getBankData(bic: string) {
    fetch(`https://bik-info.ru/api.html?type=json&bik=${bic}`)
      .then(res => res.json())
      .then((res: BicInfoRes) => {
        if(!_isMounted.current) {
          return false
        }
        if ('error' in res) {
          addMessage('.bic-input .merchery-label__inner', 'Некорректный бик');
          return false;
        }

        setBankData(res);
      })
      .catch(() => {
        toastUp('Некорректный бик или сервис не доступен. Попробуйте еще или позже');
      });
  }

  useEffect(() => {
    if(!settingsEdit) {
      setLocalEdit(false)
    }
  }, [settingsEdit])

  useEffect(() => {
    if(localEdit && !settingsEdit) {
      setSettingsEdit(true)
    }
  }, [localEdit])
  
  return <SettingModuleGroup
    header={'Адрес и реквизиты'}
    className={'site-settings__requisites'}
    headerBtnView={'button'}
    setEdit={setLocalEdit}
    edit={localEdit}
  >
    <div className='site-address'>
      <SiteSettingsGroupColumn
        header="Город"
        value={city || ''}
        onChange={setCity} 
        edit={localEdit}
        name={'city'}
      />

      <SiteSettingsGroupColumn
        header="Юридический адрес"
        value={legalAddress || ''}
        onChange={setLegalAddress} 
        edit={localEdit}
        name={'legal_address'}
      />

      <SiteSettingsGroupColumn
        header="Фактический адрес"
        value={address || ''}
        onChange={setAddress} 
        edit={localEdit}
        name={'address'}
      />
    </div>

    <div className='site-requisites'>
      <SiteSettingsGroupColumn
        header="Наименование организации"
        value={companyName || ''}
        onChange={setCompanyName} 
        edit={localEdit}
        name={'company_name'}
      />

      <ItnInput
       edit={localEdit}
      />

      <div className='site-settings__group-column'>
        <h4>Расчетный счет</h4>

        {localEdit ?
          <MyMaskedInput
            mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
            value={bankAccount || ''}
            onChange={e => setBankAccount(e.target.value)}
            name={'bank_account'}
          />
        : <span>{bankAccount}</span>}

        <div className="text-font-s">
          {bankData ? `${HtmlParser(bankData.namemini)}, г. ${bankData.city}, `: null}
          {bankData?.ks ?
            <p>
              к/сч.
              {bankData.ks.replace(/(\d{4})/g, '\$1 ')}
            </p>
            : null}
        </div>
      </div>

      <BicInput
        edit={localEdit}
        bicOnChange={bicOnChange}
      />
    </div>
  </SettingModuleGroup>;
}

function ItnInput ({
  edit,
}: {
  edit: boolean,
}) {
  const {
    itn,
    setItn,
  } = useContext(SettingsContext);
  const itnMask = [/\d/, /\d/, /\d/,/\d/, /\d/, /\d/,/\d/, /\d/, /\d/,/\d/, /\d/, /\d/,]

  return <div className='site-settings__group-column'>
    <h4>ИНН</h4>

    {edit ?
      <MyMaskedInput
        mask={itnMask}
        value={itn || ''}
        myClassName={'itn-input'}
        onChange={e => setItn(e.target.value)}
        name={'itn'}
      />
      : <span>{itn}</span>}
  </div>
}

function BicInput ({
  edit,
  bicOnChange,
}: {
  edit: boolean,
  bicOnChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}) {
  const {
    bic, 
  } = useContext(SettingsContext);
  const bicMask = [/\d/, /\d/, /\d/,/\d/, /\d/, /\d/,/\d/, /\d/, /\d/,]

  return <div className='site-settings__group-column'>
    <h4>БИК</h4>

    {edit ?
      <MyMaskedInput
        mask={bicMask}
        value={bic || ''}
        myClassName={'bic-input'}
        onChange={bicOnChange}
        name={'bic'}
      />
    : <span>{bic}</span>}
  </div>
}