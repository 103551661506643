import React, { useContext } from "react";
import { dateToRuLocale } from "../../../../../scripts/utils/date-to-locale";
import { priceWithStep, ptsansCurrency } from "../../../../../scripts/functions";
import { useAppSelector } from "../../../../../scripts/pre-type/use-selector";
import useCurrentTariff, { TariffMode } from "../../../../../scripts/hooks/use-current-tariff";
import MyCheckbox from "../../../../_utility-components/checkbox/my-checkbox";
import AppLoader from "../../../../_utility-components/loaders/app-loader";
import SettingModuleGroup from "../../setting-module-group";
import { SettingsContext } from "../../settings-context-wrapper";

export default function SiteTariff() {
  const settings = useAppSelector(state => state.settings);
  const {
    autoRenewal, setAutoRenewal
  } = useContext(SettingsContext)
  const paid_till = settings.find(setting => setting.callname === 'paid_till')?.value as string | null;
  const site_tariff = settings.find(setting => setting.callname === 'site_tariff')?.value as number | null;
  const site_tariff_mode = settings.find(setting => setting.callname === 'site_tariff_mode')?.value as TariffMode | null;
  
  const currentTariff = useCurrentTariff(site_tariff, site_tariff_mode);

  const pricePerSiteTariffMode = site_tariff_mode === 'month' ? 'мес' : site_tariff_mode === 'halfYear' ? 'полгода' : 'год';
  
  return <SettingModuleGroup
    header={'Тарифный план'}
    className={'site-settings__tariff'}
    headerBtnView={'link'}
  >
    {currentTariff ? 
      <div className='site-tariff'>
        <div className='site-tariff__row'>
          <h4 className="header-font-l">{currentTariff.name}</h4>
          <span className={'text-font-m'}>{priceWithStep(currentTariff.price)} {ptsansCurrency('₽')}/{pricePerSiteTariffMode}</span>
        </div>

        <div className='site-tariff__row'>
          <h4 className="text-font-s">Оплачен до</h4>
          <span className={'text-font-s'}>{paid_till ? dateToRuLocale(paid_till) : null}</span>
        </div>

        <div className='site-tariff__row'>
          <h4 className="header-font-s">Автопродление</h4>
          <MyCheckbox
            stylePreset={'switch'}
            condition={autoRenewal || false} 
            actionHandler={() => setAutoRenewal(!autoRenewal)}
          />
        </div>
      </div>
    : <AppLoader/>}
  </SettingModuleGroup>;
}