import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/scripts/pre-type/use-selector";
import { WebSocketContext } from "src/scripts/web.socket";
import { createDispatch, deleteDispatch, updateDispatch } from "./dispatches";
import {
	ClientChangeWs,
	CreateClientsWsDto,
	DeleteClientsWsDto,
	UpdateClientsWsDto,
  WsResponse,
} from "merchery-lib";
import { 
  createClient, 
  deleteClient, 
  updateClient
} from "src/constants/ws-events";

export default function useClientsWsListener () {
	const {
		lastMessage,
	} = useContext(WebSocketContext);

  const clients = useAppSelector(state => state.clients);
	const dispatch = useDispatch();
  const clientsDispatchType = 'CLIENTS'

	const updateItemsDispatch = clients && updateDispatch(clients, dispatch, clientsDispatchType);
	const deleteItemsDispatch = clients && deleteDispatch(clients, dispatch, clientsDispatchType);
	const createItemsDispatch = clients && createDispatch(clients, dispatch, clientsDispatchType);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<UpdateClientsWsDto>;

			if (message.event === updateClient &&
				message.data.changes
			) {
				const changes = message.data.changes;

				updateItemsDispatch &&
					updateItemsDispatch<ClientChangeWs>(changes)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<DeleteClientsWsDto>;

			if (message.event === deleteClient &&
				message.data.id
			) {
				const ids = message.data.id;

				deleteItemsDispatch &&
					deleteItemsDispatch(ids)
			}
		}
	}, [lastMessage]);

	useEffect(() => {
		if (lastMessage?.data) {
			const message = JSON.parse(lastMessage.data) as WsResponse<CreateClientsWsDto>;

			if (message.event === createClient &&
				message.data.client
			) {
				const client = message.data.client;

				createItemsDispatch &&
					createItemsDispatch(client)
			}
		}
	}, [lastMessage]);
}