import React from 'react';
import MyButton from '../../_utility-components/button/button';
import arrowLeft from 'src/img/arrow-left.png';

type SettingModuleGroupProps = ({
  headerBtnView: 'button';
  edit: boolean;
  setEdit: (value: boolean) => void;
} | {
  headerBtnView: 'link';
} | {
  headerBtnView?: undefined
}) & {
  header: string | JSX.Element | null;
  className: string;
  children: JSX.Element | null | (JSX.Element | null)[];
};

function SettingModuleGroup({
  className,
  header,
  children,
  ...props
}: SettingModuleGroupProps) {
  const editClassName = props.headerBtnView === 'button' && props.edit ? 'setting-module__group--edit' : '';

  return (
    <div className={`${className} setting-module__group ${editClassName}`}>
      {header !== null ?
        <div className='setting-module__group-header'>
          <h2 className='header-font-l'>{header}</h2>
          
          {props.headerBtnView === 'button' ? 
            <MyButton 
              className='setting-module__group-header__btn'
              onClick={() => props.setEdit(!props.edit)}
              removeDefaultClass
            >
              {props.edit ?
                <i className="icofont-not-allowed"></i>
              : <i className="icofont-ui-edit"></i>}
            </MyButton>
          : props.headerBtnView === 'link' ? 
            <MyButton 
              className="setting-module__group-header__btn arrow-btn" 
              onClick={() => {}}
              removeDefaultClass
            >
              <img alt="Назад" src={arrowLeft}/>
            </MyButton>
          : null}
        </div>
      : null}

      <div className={`${className}__inner setting-module__group-body`}>
        {children}
      </div>
    </div>
  );
}

export default SettingModuleGroup;