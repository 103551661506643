import React from 'react';
import { calcPrice, numWithSep, ptsansCurrency } from '../../../../../scripts/functions';
import { ExtendedProduct, CollectionItem, CollectionItemCommonProps } from 'merchery-lib';

export interface CollectionItemPriceProps <T extends CollectionItem>{
  item: T,
}

function CollectionItemPrice <T extends CollectionItem>(props: CollectionItemPriceProps<T>) {
  const { item } = props;

  if(itemIsProduct(item)) {
    const price = item.price
    const priceWithFloat = calcPrice([{price: price, count: 1, cutDigits: 2}])
    const priceWithSeparator = numWithSep(priceWithFloat)
  
    return (
      <div className='row-item__price'>
        <div>{priceWithSeparator}</div>
        &nbsp;
        {ptsansCurrency('₽')}
      </div>
    );
  }

  return <div></div>
}

function itemIsProduct(item: CollectionItem): item is (ExtendedProduct & CollectionItemCommonProps) {
  return (item as ExtendedProduct).price !== undefined
}

export default CollectionItemPrice;