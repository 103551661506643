import React, {createContext, useEffect, useState} from 'react';
import { useLocation } from 'react-router';
import { MainRouteChild } from '../main-page';
import { CreateProductPageLink } from './products-page-modules/links';
import ProductsTable from './products-page-modules/table';
import ManagersOnPage from "../../_utility-components/manager-on-page";
import usePageViewers from "src/scripts/hooks/use-page-viewers";
import {Category} from "merchery-lib";

interface Props extends MainRouteChild {}

export const ProductsContext = createContext<{
  selectedCategory: Category | undefined,
  setSelectedCategory: (category: Category | undefined) => void,
}>({
  selectedCategory: undefined,
  setSelectedCategory: () => {},
})

function Products ({
  setCurrentPage
}: Props) {
  const location = useLocation();
  const pathname = location.pathname;
  const managersOnPage = usePageViewers(pathname)
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined)

  useEffect(() => {
    setCurrentPage('products');
  }, []);

  return (
    <ProductsContext.Provider value={{
      selectedCategory,
      setSelectedCategory,
    }}>
      <div id="products" className="products">
        <div className={`products-header with-filters`}>
          <h1>Товары</h1>

          <div className={'products-header_right-inner'}>

            <ManagersOnPage
              managersOnPage={managersOnPage}
              size={'small'}
            />

            <CreateProductPageLink
              prevPage={location.search}
            />
          </div>
        </div>

        <ProductsTable />
      </div>
    </ProductsContext.Provider>
  );
}

export default Products