import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { AppLoaderWrapper } from './loaders/app-loader';
import FallbackComponent from './error-boundary';

interface Props {
  loaded: boolean,
  children: JSX.Element
}

function PageLoading({
  loaded,
  children,
}: Props) {
  if(!loaded) {
    return <AppLoaderWrapper/>
  } else {
    return (
      <ErrorBoundary
        FallbackComponent={FallbackComponent}
      >
        {children}
      </ErrorBoundary>
    )
  }
}

export default PageLoading;