import React, { useEffect, useMemo, useRef, useState } from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import useRowFocus from 'src/scripts/hooks/use-row-focus';
import { Id, CollectionDto, CollectionReorderedOne } from "merchery-lib";
import { mercheryFetch } from 'src/scripts/fetchConstructor';
import { validateResponse } from 'src/scripts/functions';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import useMounted from '../../../../scripts/hooks/use-mounted';
import MyTable from '../../../_utility-components/common-table/table';
import ConfirmPopup from '../../../_utility-components/confirm-popup';
import CommonTableLoader from '../../../_utility-components/loaders/common-table-loader';
import { CollectionReorder } from '../dto/collection.reorder.api';
import CollectionTableHeader from './header';
import HeaderSelectedControl from './header-selected-control';
import {useTabIndex} from "src/scripts/hooks/use-tabindex";
import {useLocation} from "react-router";
import useRowClick from "../../../../scripts/hooks/use-row-click";
import {BodyCell} from "../../../_utility-components/common-table/body-cell";
import DragElement from "../../products/products-page-modules/table-body/table-row/drag-element";
import MyCheckbox from "../../../_utility-components/checkbox";
import Picture from "../../../_utility-components/picture/picture";
import {extractImages} from "src/scripts/utils/extractImages";
import {HideableEntityHideBtn} from "../../products/products-page-modules/table-body/table-row/row";
import {useShiftSelect} from "src/scripts/hooks/use-shift-select";
import useCollectionsChangesListener from "../../../../ws-listeners/collections.listener";
import PageIsOutOfDate from "../../../_utility-components/page-is-out-of-date/page-is-out-of-date";
import {useDragEndHandler} from "src/scripts/hooks/use-drag-end-handler-react-beautiful-dnd";

export const CollectionsTableContext = React.createContext<{
  newCollections: Set<Id>,
  setNewCollections: (newCollections: Set<Id>) => void,
}>({
  newCollections: new Set([]),
  setNewCollections: () => {},
})

function CollectionsTable() {
  const _isMounted = useMounted();
  const timeOutId = useRef<NodeJS.Timeout | undefined>(undefined);
  const collections = useAppSelector(state => state.collections);

  const [selectedCollectionsIds, setSelectedCollectionsIds] = useState<Id[]>([]);
  const [popupOpened, setPopupOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newCollections, setNewCollections] = useState<Set<Id>>(new Set([]));

  const { focusedItem, focusHandler } = useRowFocus<CollectionDto>(null);

  const dispatch = useDispatch();
  const collectionsDispatch = (colls: CollectionDto[]) => dispatch({ type: 'COLLECTIONS', payload: colls });

  const tabIndex = useTabIndex()
  const location = useLocation()

  const sortedCollections = useMemo(() =>
    [...(collections || [])]
    .sort((a, b) => a.order - b.order)
  , [collections])

  const categoryPage = (id: Id) => ({
    to: `/app/collections/${id}`,
    state: {prevPage: location.search}
  })

  const [rowClick, setActiveRowId] = useRowClick(
    categoryPage,
    ['.prevent-page-transition', ]
  );

  const [changesInQueue, applyRenderChanges] = useCollectionsChangesListener()

  const disableDraggable = useMemo(() => {
    return Boolean(changesInQueue.length)
  }, [changesInQueue])

  const [selectedIds, handleSelect, selectAll] = useShiftSelect(sortedCollections)

  useEffect(() => {
    if(selectedCollectionsIds.length !== selectedIds.length) {
      selectAll(selectedCollectionsIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollectionsIds])

  useEffect(() => {
    getCollections()

    return () => {
      clearTimeout(timeOutId.current)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCollections = () => {
    console.log(" getCollections")
    setLoading(true)

    mercheryFetch<CollectionDto[]>(`collections`, 'GET')
    .then(res => {
      if(!_isMounted.current || !validateResponse(res)) return false

      collectionsDispatch(res.records)
    }) 
    .finally(() => {
      setLoading(false)
    })
  };

  const headerCheckHandler = () => {
    setSelectedCollectionsIds(selectedCollectionsIds.length !== sortedCollections.length ? sortedCollections.map(c => c.id) : [])
  };

  const deletedCollections = () => {
    const collectionsBackup = [...sortedCollections]

    collectionsDispatch(sortedCollections.filter(c => !selectedCollectionsIds.some(id => id === c.id)))

    mercheryFetch<boolean>('collections', 'DELETE', {
      id: selectedCollectionsIds
    })
    .then((res) => {
      if(!_isMounted.current) return false;
      if(!validateResponse(res) || !res.records) {
        collectionsDispatch(collectionsBackup)
      } else {
        setSelectedCollectionsIds(selectedCollectionsIds.filter(id => !collectionsBackup.some(c => c.id === id)))
      }
    })
    .finally(() => {
      setPopupOpened(false)
    })
  }

  const reorder = (reorderData: CollectionReorder) => {
    mercheryFetch<CollectionReorderedOne[]>('collections/reorder', 'PATCH', reorderData)
    .then((res) => {
      if(_isMounted.current && validateResponse(res)) {
        // const sorted = sortedCollections.map(c => {
        //   const reorderedColl = res.records.find(reorderedItem => reorderedItem.id === c.id)
        //   return reorderedColl ? { ...c, ...reorderedColl } : c
        // })
        //
        // console.log('sorted', sorted.map(item => ({id: item.id, name: item.name, order: item.order})))
        // collectionsDispatch(sorted)
      }
    })
  }

  const rowCheckHandler = (collection: CollectionDto) => {
    const updatedSelectedIds = handleSelect(collection)
    setSelectedCollectionsIds(updatedSelectedIds);
  };

  const onDragEnd = useDragEndHandler<CollectionDto>(
    sortedCollections,
    (result, provided, newList, removed) => {

      const reorderedListPreview = newList.map((item, index) => ({...item, order: index + 1}))
      collectionsDispatch(reorderedListPreview)

      reorder({
        id: +result.draggableId,
        difference: result.destination.index - result.source.index
      })
    }
  )

  const changeData = (id: Id, changes: Partial<CollectionDto>) => {
    const requestBody = {
      toChange: [{
        id: id,
        ...changes,
      }]
    }

    const path = 'collections';

    mercheryFetch<CollectionDto[]>(path, 'PATCH', requestBody)
    .then((res) => {
      if(!_isMounted.current || !validateResponse(res)) {
        return false
      }
      const colls = sortedCollections.map(c => {
        const reorderedColl = res.records.find(reorderedItem => reorderedItem.id === c.id)
        return reorderedColl ? { ...c, ...reorderedColl } : c
      })

      collectionsDispatch(colls)

      return true
    })
  }

  const checkboxIsActive = sortedCollections.length > 0 && sortedCollections.length === selectedCollectionsIds.length;

  if(loading) {
    return <CommonTableLoader/>
  }

  if(!sortedCollections.length) {
    return <div>
      Коллекции отсутствуют
    </div>
  }
  return (
    <CollectionsTableContext.Provider value={{
      newCollections,
      setNewCollections
    }}>
      <MyTable id="collections-table" myClassName='collections__table'>
        <div className='table__inner'>

          {!selectedCollectionsIds.length ?
            <CollectionTableHeader
              headerCheckHandler={headerCheckHandler}
              checkboxIsActive={checkboxIsActive}
            />
          :
            <HeaderSelectedControl
              headerCheckHandler={headerCheckHandler}
              selectedCollectionsIds={selectedCollectionsIds}
              setSelectedCollectionsIds={setSelectedCollectionsIds}
              checkboxIsActive={checkboxIsActive}
              deleteHandler={() => setPopupOpened(true)}
            />
          }

          <DragDropContext
            onDragEnd={onDragEnd}
          >
          <Droppable droppableId={`collections`} type={`collections`}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {sortedCollections
                .map((el, index) => {
                  const focused = focusedItem?.id === el.id ? 'focused' : ''; // eslint-disable-next-line
                  const active = selectedCollectionsIds.some(s => s == el.id) ? 'active' : '';
                  const hidden = !el.show_date || new Date(el.show_date).getTime() > new Date().getTime() ? 'hidden' : '';

                  return (
                    <Draggable
                      draggableId={'' + el.id}
                      isDragDisabled={disableDraggable}
                      index={index}
                      key={el.id}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={`${active} table__row collections__row ${focused} ${hidden}`}
                          onFocus={() => focusHandler(el.id, sortedCollections)}
                          onMouseUp={e => rowClick(e, el.id)}
                          onMouseDown={() => setActiveRowId(el.id)}
                          onKeyUp={e=> e.key === 'Enter' ? rowClick(e, el.id) : null}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          tabIndex={tabIndex}
                        >
                          <BodyCell myClassName='control-cell prevent-page-transition'>
                            <DragElement provided={provided}/>
                            <MyCheckbox text={''}
                                        condition={selectedCollectionsIds.some(sId => sId === el.id)}
                                        actionHandler={() => rowCheckHandler(el)}/>
                          </BodyCell>

                          <BodyCell myClassName='image__cell'>
                            <Picture images={extractImages(el.src, 'small') || null}/>
                          </BodyCell>

                          <BodyCell>
                            {el.name}
                          </BodyCell>

                          <BodyCell myClassName='center-align'>
                            {el.items.filter(item => item.item_type === 'set').length}
                          </BodyCell>

                          <BodyCell myClassName='center-align'>
                            {el.items.filter(item => item.item_type === 'product').length}
                          </BodyCell>

                          <BodyCell myClassName={'prevent-page-transition center-align'}>
                            <HideableEntityHideBtn
                              changeData={changeData}
                              entity={el}
                            />
                          </BodyCell>
                        </div>
                      )}
                    </Draggable>
                  );
                  }
                )}
              {provided.placeholder}
            </div>
          )}
          </Droppable>
          </DragDropContext>
        </div>

        {!popupOpened
          ? null
          : <ConfirmPopup
            confirmHandler={deletedCollections}
            deletion={true}
            popupClose={() => setPopupOpened(false)}
            children={<>
              <div className='confirm-popup__paragraph'>
                Удаление коллекций
              </div>

              <div className='confirm-popup__paragraph'>
                Следующие Коллекции будут удалены: <br/>
                <b>
                  {sortedCollections
                    .filter(c => selectedCollectionsIds.some(id => id === c.id))
                    .map(c => c.name)
                    .join(", ")
                  }
                </b>
              </div>

              <div className='confirm-popup__paragraph'>
                Вы уверены, что хотите продолжить?
              </div>
            </>}
          /> }
      </MyTable>

      {changesInQueue.length ?
        <PageIsOutOfDate
          condition={Boolean(changesInQueue.length)}
          actionHandler={applyRenderChanges}
        />
      : null}
    </CollectionsTableContext.Provider>
  );
}

export default CollectionsTable;
