/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TabIndex } from '../../../dto/master-dto/tabindex.dto';
import MyButton from '../button/button';
import MyInput from '../input/index';

interface Props {
  searchInput: string,
  applySearch: (search: string) => void,
  tabIndex?: TabIndex,
  id?: string
}

function SearchInput({
  searchInput,
  applySearch,
  tabIndex,
  id,
}: Props) {
  const [value, setValue] = useState<string>(searchInput || '');

  useEffect(() => {
    let timeOutId = setTimeout(() => {
      searchInput !== value && applySearch(value)
    }, 200);
    return () => clearTimeout(timeOutId)
  }, [value]);

  useEffect(() => {
    setValue(searchInput || '')
  }, [searchInput])

  return (
    <MyInput 
      required={false}
      myClassName={'search'}
      noPlaceholder
      value={value} 
      placeholder="Поиск" 
      onChange={(e) => setValue(e.target.value)} 
      onEnter={() => applySearch(value)} 
      {...(tabIndex && {tabIndex})}
      {...(id && {id})}
      innerChildren={
        <MyButton className='input-search-icon'
          removeDefaultClass
          onClick={() => applySearch(value)}
        >
          <i className="icofont-search-1"></i>
        </MyButton>
      }
    />
  );
}

export default SearchInput;