import React, {  useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import staticValues from '../../data/static-values.json';
import MainPage, {MainPageWebsocketWrapper} from '../main-pages/main-page';
import SignIn from '../sign-in/sign-in-wrapper';
import AppLoader from '../_utility-components/loaders/app-loader';
import BrightnessFilter from '../_utility-components/brightness-filter';
import { NotFoundGlobal } from '../_utility-components/not-found';
import PrivateRoute from '../_utility-components/private-component';
import './_app.scss';
import { StaticValues } from '../../data/static-values.dto';
import { Location } from 'history';
import { useAppSelector } from '../../scripts/pre-type/use-selector';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';

registerLocale('ru', ru)
setDefaultLocale('ru')

function App () {
  const load = useAppSelector(state => state.load)
  const [mercheryWrapperClassName, setMercheryWrapperClassName] = useState<string>('');
  const appBlackout = useAppSelector(state => state.blackout)

  const dispatch = useDispatch()
  const switchPrintWindow = (bool: boolean) => dispatch({type: 'IS_PRINT_WINDOW_OPEN', payload: bool})
  const setStaticValues = (sv: StaticValues.RootObject) => dispatch({type: 'STATIC_VALUES', payload: sv})

  const location = useLocation()

  useEffect(() => {
    setWrapperClassName(location);

    setStaticValues(staticValues)

    window.onbeforeprint = function(event) {
      switchPrintWindow(true)
    };

    window.onafterprint = function(event) {
      switchPrintWindow(false)
    };
  }, [])

  const setWrapperClassName = (location: Location) => {
    setMercheryWrapperClassName(location.pathname.indexOf('sign-in') > -1 ? 'sign-in-wrapper' : '')
  }

  const loader = load ? <AppLoader className="app-loader"/> : null;
  const filterClassName = appBlackout ? 'filter' : ''
  
  return (
    <div className={`merchery-wrapper ${mercheryWrapperClassName} ${filterClassName}`}>

      {loader}

      <BrightnessFilter/>
      <ToastContainer />
      <Switch>
        <Route path={`/sign-in/`} component={SignIn}/>
        {/* <Route path={`/test/`} render={() => <Test />}/> */}
        <PrivateRoute path={`/app/`} component={MainPageWebsocketWrapper}/>
        <Route path={'/'} render={() => <Redirect to={`/app/`}/>}/>
        <Route component={NotFoundGlobal}/>
      </Switch>

      <div id="cdek-map"></div>
    </div>
  );
}

export default App