import {PaymentModeDto} from "src/components/main-pages/orders/order-modules/payment/dto/payment-mode.dto";
import {useAppSelector} from "src/scripts/pre-type/use-selector";
import {useEffect, useState} from "react";
import {mercheryFetch} from "src/scripts/fetchConstructor";
import {useDispatch} from "react-redux";
import useMounted from "src/scripts/hooks/use-mounted";
import {validateResponse} from "src/scripts/functions";

export default function usePaymentModes (): PaymentModeDto[] | null {
	const _isMounted = useMounted()
	const paymentModesStored = useAppSelector(state => state.paymentModes);
	const [paymentModes, setPaymentModes] = useState(paymentModesStored);

	const dispatch = useDispatch()
	const dispatchPaymentModes = (newModes: PaymentModeDto[]) => dispatch({
		type: 'PAYMENT_MODES',
		payload: newModes
	})

	useEffect(() => {
		if(paymentModesStored) {
			return;
		}

		mercheryFetch<PaymentModeDto[]>('payment-modes', 'GET')
		.then(res => {
			if(!_isMounted.current || !validateResponse(res)) {
				return;
			}

			dispatchPaymentModes(res.records)
		})
	}, [])

	useEffect(() => {
		setPaymentModes(paymentModesStored)
	}, [paymentModesStored])

	return paymentModes
}