import React, {useCallback, useContext, useMemo, useState} from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { withErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import FallbackComponent from 'src/components/_utility-components/error-boundary';
import { useAppSelector } from 'src/scripts/pre-type/use-selector';
import { Id, CharGroupsDto, CharsLabelDto } from 'merchery-lib';
import { arraymove, } from '../../../../../scripts/functions';
import CharsBottom from './char-section-bottom';
import CharsTop from './char-section-top';
import OneChar from './one-char';
import {
  CharacteristicsContext,
  withFetchAndStoreChars
} from "src/components/main-pages/products/product-page-modules/characteristics-modules/fetch-and-store-chars";

export type CharacteristicsPage = 'product' | 'categories'

export interface CharsWrapperProps {
  productCategory: Id,
  page: CharacteristicsPage,
  isCreatePage: boolean
}

function ProductsCharacteristics(props: CharsWrapperProps) {
  const {
    productCategory,
    isCreatePage,
    charGroups,
    multiSelects,
    setMultiSelects,
  } = useContext(CharacteristicsContext)

  const labels = useAppSelector(state => state.labels);
  const categories = useAppSelector(state => state.categoriesAssociatedWithProduct);
  const categoriesReverse = useMemo(() => [...categories].reverse(), [categories])
  const getCategIndex = useCallback((group: CharGroupsDto) =>
    categoriesReverse.findIndex(cat => cat.id === group.category_id)
  , [categoriesReverse])

  const orderedGroups = useMemo(() => 
    [...charGroups].sort((a,b) =>
      getCategIndex(a) - getCategIndex(b)
    )
  , [charGroups, getCategIndex]);

  const [loaded, ] = useState();
  const dispatch = useDispatch();

  const labelsReducerType = 'CHARS_LABELS';

  const setLabels = (value: CharsLabelDto[]) =>
    dispatch({type: labelsReducerType, payload: value})

  const onDragEnd = (info: DropResult) => {
    const {destination, source} = info
    if(!destination || destination?.index === source.index) 
      return false;
    
    const nodeType = info.type.split('-')[0]
    if(!nodeType) 
      return false;

    if( nodeType === 'groupLabel' ) {
      const parentId: Id = destination.droppableId.split('-')[1]
      const difference = destination.index - source.index;
      const newOrderIsBiggest = source.index < source.index + difference;
      const sortedArray = [...labels].filter(item => item.char_group === +parentId)
                          .sort((a,b) => a.order - b.order);
      const reorderedArray = sortedArray.map(item => {
        let order = item.order === source.index ? destination.index :
          item.order === destination.index && newOrderIsBiggest ? item.order - 1 :
          item.order === destination.index && !newOrderIsBiggest ? item.order + 1 :
          item.order > destination.index && item.order < source.index ? item.order + 1 :
          item.order < destination.index && item.order > source.index ? item.order - 1 :
          item.order;
        return {
          ...item, 
          order
        }
      })

      setLabels(labels.map(item => reorderedArray.find(rItem => rItem.id === item.id) || item))
    } else if( 'multiselect' ) {
      const reorderMultiSelect = multiSelects.find(ms => ms.label_id === +destination.droppableId.split('-')[1])
      if(!reorderMultiSelect) return false

      const newOrderArray = arraymove(reorderMultiSelect.names, source.index, destination.index)
      
      setMultiSelects(multiSelects.map(ms => ms.id !== reorderMultiSelect.id ? ms : {
        ...reorderMultiSelect,
        names: newOrderArray
      }))
    }
  };

  if(isCreatePage) {
    return null
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} >
      <div>
        <CharsTop />
        {orderedGroups.length ?
          <div className='product-page__characteristics flex-gap-16'>
            {orderedGroups.map(gr =>
              <OneChar
                key={gr.id}
                thisCharGroup={gr}
              />
            )}
          </div>
        : null}

        <CharsBottom
          categoryId={productCategory}
        />
      </div>
    </DragDropContext>
  )
}

const CharsWithFetchAndStoreData = withFetchAndStoreChars(ProductsCharacteristics);
const CharsWithErrorBoundary = withErrorBoundary(CharsWithFetchAndStoreData, {FallbackComponent: FallbackComponent});
export default CharsWithErrorBoundary