import React from 'react';

function OpExternalId({
  external_id
}: {
  external_id: string | null
}) {
  return (
    <div className="op-quantity">
      <div>
        {external_id || ''}
      </div>
    </div>
  );
}

export default OpExternalId;